/* eslint-disable */

import { actualizarCampo } from '@actions/infoUser/actions';
import { resetModal, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trash } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

import { AddButton } from '@components/AddButton';
import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import { DateInput } from '@components/FormElemntsLibrary/DateInput';
import { NumberInput } from '@components/FormElemntsLibrary/NumberInput';
import { ScaleInput } from '@components/FormElemntsLibrary/ScaleInput';
import { ModalForm } from '@components/modals/ModalForm';
import AlertDialog from '@components/AlertDialogs/AlertDialog';

import {
  IGinecoObstetrico,
  ginecoObstetricoInitial,
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/GinecoObstetricos/types';
import { isOxxo } from '@constants/oxxo';
import { RootState } from '@reducer/index';
import {
  antecedentesNoPatologicosGuardarGinecoObstetricos,
  antecedentesNoPatologicosUsuarioGuardarGinecoObstetricos,
  antecedentesNoPatologicosUsuarioPacienteGuardarGinecoObstetricos,
} from '@utils/sendInfo';
import { IFechaObj } from '@common/types';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';
import { SatisfactionIndicator } from '@components/FormElemntsLibrary/SatisfactionIndicator';
import { useTranslation } from 'react-i18next';

const dateUtils = {
  // Convierte IFechaObj a formato YYYY-MM-DD
  formatFechaToDateString: (fecha: IFechaObj | undefined): string => {
    if (!fecha || !fecha.dia || !fecha.mes || !fecha.anio) return '';
    return `${fecha.anio}-${fecha.mes}-${fecha.dia}`;
  },

  // Convierte IFechaObj a objeto Date
  fechaObjToDate: (fecha: IFechaObj): Date | null => {
    if (!fecha.dia || !fecha.mes || !fecha.anio) return null;
    return new Date(`${fecha.anio}-${fecha.mes}-${fecha.dia}`);
  },

  // Crea un IFechaObj desde un string en formato YYYY-MM-DD
  createFechaObj: (dateString: string): IFechaObj => {
    if (!dateString) return { dia: '', mes: '', anio: '' };
    const [anio, mes, dia] = dateString.split('-');
    return {
      dia: dia || '',
      mes: mes || '',
      anio: anio || '',
    };
  },
};

const anticonceptivosOptions = [
  { id: 'ninguno', label: 'Ninguno' },
  { id: 'anillo_vaginal', label: 'Anillo vaginal' },
  { id: 'anticonceptivo_emergencia', label: 'Anticonceptivo de emergencia' },
  { id: 'anticonceptivo_oral', label: 'Anticonceptivo oral' },
  { id: 'condon', label: 'Condón' },
  { id: 'diafragma_o_cupula_vaginal', label: 'Diafragma o cúpula vaginal' },
  { id: 'dispositivo_intrauterino', label: 'Dispositivo intrauterino' },
  { id: 'espermicida', label: 'Espermicida' },
  { id: 'esponja', label: 'Esponja' },
  { id: 'esterilizacion_femenina', label: 'Esterilización femenina' },
  { id: 'esterilizacion_masculina', label: 'Esterilización masculina' },
  { id: 'implante', label: 'Implante' },
  { id: 'inyectable', label: 'Inyectable' },
  {
    id: 'metodos_basados_fertilidad',
    label: 'Métodos basados en el conocimiento de la fertilidad',
  },
];

const tiempoDeUsoOptions = [
  { id: '≤1a', label: '≤ 1 año' },
  { id: '2a', label: '2 años' },
  { id: '3a', label: '3 años' },
  { id: '4a', label: '4 años' },
  { id: '5a', label: '5 años' },
  { id: '6a', label: '6 años' },
  { id: '7a', label: '7 años' },
  { id: '8a', label: '8 años' },
  { id: '9a', label: '9 años' },
  { id: '≥10a', label: '≥ 10 años' },
];

export const schema = z.object({
  loaded: z.boolean(),
  generalidades: z.object({
    enGestacion: z.enum(['', 'si', 'no']),
    menarquia: z.string(),
    menarquiaNA: z.boolean(),
    fur: z.object({
      dia: z.string(),
      mes: z.string(),
      anio: z.string(),
    }),
    lactancia: z.string(),
    menopausia: z.string(),
    menopausiaNA: z.boolean(),
    sintomasDelClimaterio: z.string(),
    comentarios: z.string(),
  }),
  menstruacionHabitual: z.object({
    ritmoCada: z.string(),
    ritmoDuracion: z.string(),
    cantidad: z.string(),
    dolor: z.string(),
    comentarios: z.string(),
  }),
  anticonceptivo: z
    .object({
      arrayTabla: z.array(z.string()),
      metodoEnUso: z.array(z.string()),
      tiempoEnUso: z.array(z.string()),
      comentarios: z.string(),
    })
    .superRefine((data, ctx) => {
      // Validar misma longitud de arrays
      const lengths = [data.arrayTabla.length, data.metodoEnUso.length, data.tiempoEnUso.length];

      if (!lengths.every((len) => len === lengths[0])) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Los arrays de anticonceptivos deben tener la misma longitud',
          path: ['anticonceptivo'],
        });
      }
    }),
  embarazos: z.object({
    embarazos: z.string(),
    gestas: z.string(),
    para: z.string(),
    cesareas: z.string(),
    abortos: z.string(),
    ectopicos: z.string(),
    nacidosVivos: z.string(),
    comentarios: z.string(),
  }),
  citologiaRadio: z.string(),
  consultaRadio: z.string(),
  estudioRadio: z.string(),
  mastografia: z.object({
    radio: z.string(),
    fecha: z.object({
      dia: z.string(),
      mes: z.string(),
      anio: z.string(),
    }),
  }),
  ultrasonido: z.object({
    radio: z.string(),
    fecha: z.object({
      dia: z.string(),
      mes: z.string(),
      anio: z.string(),
    }),
  }),
  despistajeCancer: z.object({
    cervicoUterino: z.object({
      arrayTabla: z.array(z.string()),
      estudio: z.array(z.string()),
      especifica: z.array(z.string()),
      resultado: z.array(z.any()),
      dia: z.array(z.string()),
      mes: z.array(z.string()),
      anio: z.array(z.string()),
      comentarios: z.array(z.string()),
    }),
    mama: z.object({
      arrayTabla: z.array(z.string()),
      estudio: z.array(z.string()),
      especifica: z.array(z.string()),
      resultado: z.array(z.string()),
      dia: z.array(z.string()),
      mes: z.array(z.string()),
      anio: z.array(z.string()),
      comentarios: z.array(z.string()),
    }),
  }),
  sintomasClimaterio: z.object({
    vasomotores: z.object({
      radiosValues: z.array(z.string()),
      comentariosValues: z.array(z.string()),
      items: z.array(z.string()),
    }),
    tractoGenital: z.object({
      radiosValues: z.array(z.string()),
      comentariosValues: z.array(z.string()),
      items: z.array(z.string()),
    }),
    somaticos: z.object({
      radiosValues: z.array(z.string()),
      comentariosValues: z.array(z.string()),
      items: z.array(z.string()),
    }),
    psicologicos: z.object({
      radiosValues: z.array(z.string()),
      comentariosValues: z.array(z.string()),
      items: z.array(z.string()),
    }),
  }),
});

type GinecologiaFormData = z.infer<typeof schema>;

export const Ginecologia = () => {
  const [showEmbarazosFields, setShowEmbarazosFields] = useState(false);
  const [enGestacionTemp, setEnGestacionTemp] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMensaje, setAlertMensaje] = useState<string>('');
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    ginecoObstetrico,
    ginecoObstetricoUsuario,
    cambiosGinecoObstetrico,
  } = useSelector((state: RootState) => state.InfoUser);

  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);

  const hayCambios: boolean =
    idUsuario > 0 && cambiosGinecoObstetrico.filter((c: string) => c.length > 0).length > 0;

  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);

  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<GinecologiaFormData>({
    defaultValues: ginecoObstetricoInitial,
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  const handleInputChange = (e: { name: string; value: any }) => {
    const { name, value } = e;
    const numbersToStringFields = [
      'generalidades.menarquia',
      'generalidades.menopausia',
      'menstruacionHabitual.ritmoCada',
      'menstruacionHabitual.ritmoDuracion',
      'menstruacionHabitual.cantidad',
      'embarazos.gestas',
      'embarazos.para',
      'embarazos.cesareas',
      'embarazos.abortos',
      'embarazos.nacidosVivos',
    ];

    const finalValue = numbersToStringFields.includes(name) ? String(value) : value;

    // Manejar el cambio del checkbox de embarazos
    if (name === 'embarazos.embarazos') {
      setShowEmbarazosFields(value === 'si');
    }

    const nameParts = name.split('.');
    if (nameParts.length > 1) {
      const [section, field] = nameParts;
      const sectionKey = section as keyof IGinecoObstetrico;
      const currentValue = watch(sectionKey);
      const initialValue = ginecoObstetricoInitial[sectionKey];

      const updatedValue = Object.assign({}, initialValue, currentValue, { [field]: finalValue });

      setValue(sectionKey, updatedValue);
    } else {
      setValue(name as keyof IGinecoObstetrico, finalValue);
    }
  };

  const handleDespistajeCancerChange = (e: { name: string; value: any }) => {
    const { name, value } = e;

    const defaultCervicoUterinoResult = {
      check1: null,
      check2: null,
      check3: null,
      selector2: null,
      selector3: null,
      resultadoRadio: null,
      resultadoTexto: '',
    };

    if (name.includes('despistajeCancer')) {
      const currentDespistaje = watch('despistajeCancer');
      const path = name.split('.');
      const section = path[1]; // cervicoUterino o mama

      // Actualizamos el objeto completo de despistajeCancer
      setValue('despistajeCancer', {
        ...currentDespistaje,
        [section]: {
          arrayTabla: ['I'],
          estudio: [value],
          especifica: [''],
          resultado: section === 'cervicoUterino' ? [defaultCervicoUterinoResult] : [''],
          dia: [''],
          mes: [''],
          anio: [''],
          comentarios: [''],
        },
      });
    }
  };

  const handleDateChange = (name: string, dateObj: { value: IFechaObj }) => {
    setValue(name as any, dateObj.value);
  };

  const romanize = (num: number): string => {
    const romanNumerals = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
    return romanNumerals[num - 1] || 'I';
  };

  const handleMethodUpdate = (
    methodId: string,
    field: 'metodoEnUso' | 'tiempoEnUso',
    value: any,
  ) => {
    const currentValues = watch('anticonceptivo');
    const index = currentValues.arrayTabla.indexOf(methodId);

    if (index !== -1) {
      // No hacemos ningún mapeo, usamos el valor directamente
      const updatedAnticonceptivo = {
        ...currentValues,
        [field]: [...currentValues[field]],
      };

      // El value ya viene con el formato correcto del SelectInput
      updatedAnticonceptivo[field][index] = value;

      console.log('Actualizando valor:', {
        field,
        value,
        updatedValues: updatedAnticonceptivo[field],
      });

      setValue('anticonceptivo', updatedAnticonceptivo, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  };

  const handleAddMethod = () => {
    const currentValues = watch('anticonceptivo');
    const newId = romanize(currentValues.arrayTabla.length + 1);

    // Creamos un objeto actualizado completo
    const updatedAnticonceptivo = {
      ...currentValues,
      arrayTabla: [...currentValues.arrayTabla, newId],
      metodoEnUso: [...currentValues.metodoEnUso, ''],
      tiempoEnUso: [...currentValues.tiempoEnUso, ''],
    };

    // Actualizamos el formulario con el nuevo objeto
    setValue('anticonceptivo', updatedAnticonceptivo, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const handleDeleteMethod = (id: string) => {
    const currentAnticonceptivo = watch('anticonceptivo');

    if (currentAnticonceptivo.arrayTabla.length > 1) {
      const index = currentAnticonceptivo.arrayTabla.indexOf(id);

      if (index !== -1) {
        setValue('anticonceptivo', {
          ...currentAnticonceptivo,
          arrayTabla: currentAnticonceptivo.arrayTabla.filter((_, i) => i !== index),
          metodoEnUso: currentAnticonceptivo.metodoEnUso.filter((_, i) => i !== index),
          tiempoEnUso: currentAnticonceptivo.tiempoEnUso.filter((_, i) => i !== index),
        });
      }
    }
  };

  const getDatosGinecoObstetricosBase = (datos: GinecologiaFormData) => ({
    generalidades: datos.generalidades,
    menstruacionHabitual: datos.menstruacionHabitual,
    anticonceptivo: datos.anticonceptivo,
    embarazos: datos.embarazos,
  });

  const getDatosExpediente = (datos: GinecologiaFormData) => ({
    idMedico,
    idConsultorio,
    idPaciente,
    idUsuario,
    idConsulta,
    ginecoObstetricos: {
      ...getDatosGinecoObstetricosBase(datos),
      despistajeCancer: datos.despistajeCancer,
      sintomasClimaterio: datos.sintomasClimaterio,
    },
  });

  const getDatosUsuario = (datos: GinecologiaFormData) => ({
    idMedico,
    idConsultorio,
    idPaciente,
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    ginecoObstetricos: {
      ...getDatosGinecoObstetricosBase(datos),
      citologiaRadio: datos.citologiaRadio || '',
      mastografia: datos.mastografia || { radio: '', fecha: { dia: '', mes: '', anio: '' } },
      ultrasonido: datos.ultrasonido || { radio: '', fecha: { dia: '', mes: '', anio: '' } },
    },
  });

  const guardarDatosExpediente = () => {
    const sendFunctions = [
      antecedentesNoPatologicosGuardarGinecoObstetricos(getDatosExpediente(watch())),
    ];

    if (idUsuario > 0) {
      sendFunctions.push(
        antecedentesNoPatologicosUsuarioPacienteGuardarGinecoObstetricos(getDatosUsuario(watch())),
      );
    }

    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunction: () => {
          dispatch(
            actualizarCampo('ginecoObstetrico', {
              ...watch(),
              loaded: true,
            }),
          );

          if (idUsuario > 0) {
            dispatch(
              actualizarCampo('ginecoObstetricoUsuario', {
                ...watch(),
                loaded: true,
              }),
            );
          }
          dispatch(resetModal('Ginecologia'));
          dispatch(setActiveModal('MisMedidas'));
        },
        errorFunction: (error) => {
          setAlertMensaje(error.message || 'Error al guardar los datos');
          setAlertOpen(true);
        },
      }),
    );
  };

  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioGuardarGinecoObstetricos(
          getDatosUsuario(watch()),
        ),
        successFunction: () => {
          dispatch(
            actualizarCampo('ginecoObstetrico', {
              ...watch(),
              loaded: true,
            }),
          );
          setEnGestacionTemp(ginecoObstetrico.generalidades.enGestacion);
          dispatch(resetModal('Ginecologia'));
          dispatch(setActiveModal('MisMedidas'));
        },
        errorFunction: (error) => {
          setAlertMensaje(error.message || 'Error al guardar los datos');
          setAlertOpen(true);
        },
      }),
    );
  };

  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosGuardarGinecoObstetricos(
          getDatosExpediente(ginecoObstetricoUsuario),
        ),
        successFunction: () => {
          setEnGestacionTemp(ginecoObstetrico.generalidades.enGestacion);
          dispatch(
            actualizarCampo('ginecoObstetrico', {
              ...ginecoObstetricoUsuario,
              loaded: true,
            }),
          );
          dispatch(resetModal('Ginecologia'));
          dispatch(setActiveModal('MisMedidas'));
        },
        errorFunction: (error) => {
          setAlertMensaje(error.message || 'Error al guardar los datos');
          setAlertOpen(true);
        },
      }),
    );
  };

  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarGinecoObstetricos(
          getDatosUsuario(watch()),
        ),
        successFunction: () => {
          dispatch(
            actualizarCampo('ginecoObstetricoUsuario', {
              ...watch(),
              loaded: true,
            }),
          );
          dispatch(resetModal('Ginecologia'));
          dispatch(setActiveModal('MisMedidas'));
        },
        errorFunction: (error) => {
          setAlertMensaje(error.message || 'Error al guardar los datos');
          setAlertOpen(true);
        },
      }),
    );
  };

  const onSubmit = handleSubmit(async (data: GinecologiaFormData) => {
    try {
      // Verificamos si es paciente
      if (esPaciente) {
        guardarDatosUsuario();
      } else {
        guardarDatosExpediente();
      }
    } catch (error: any) {
      console.error('Error en la validación:', error);
      setAlertMensaje(error.message || 'Error en la validación');
      setAlertOpen(true);
    }
  });

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    setAlertOpen(false);
  };

  useEffect(() => {
    // Si es paciente, establecer valores por defecto para campos específicos
    if (esPaciente) {
      setValue('loaded', true);
      setValue('citologiaRadio', ginecoObstetrico?.citologiaRadio || '');
      setValue('mastografia', {
        radio: ginecoObstetrico?.mastografia?.radio || '',
        fecha: ginecoObstetrico?.mastografia?.fecha || { dia: '', mes: '', anio: '' },
      });
      setValue('ultrasonido', {
        radio: ginecoObstetrico?.ultrasonido?.radio || '',
        fecha: ginecoObstetrico?.ultrasonido?.fecha || { dia: '', mes: '', anio: '' },
      });
    }

    // Generalidades
    setValue('generalidades', {
      enGestacion: ginecoObstetrico?.generalidades?.enGestacion ?? '',
      menarquia: ginecoObstetrico?.generalidades?.menarquia ?? '',
      menarquiaNA: ginecoObstetrico?.generalidades?.menarquiaNA ?? false,
      fur: {
        dia: ginecoObstetrico?.generalidades?.fur?.dia ?? '',
        mes: ginecoObstetrico?.generalidades?.fur?.mes ?? '',
        anio: ginecoObstetrico?.generalidades?.fur?.anio ?? '',
      },
      lactancia: ginecoObstetrico?.generalidades?.lactancia ?? '',
      menopausia: ginecoObstetrico?.generalidades?.menopausia ?? '',
      menopausiaNA: ginecoObstetrico?.generalidades?.menopausiaNA ?? false,
      sintomasDelClimaterio: ginecoObstetrico?.generalidades?.sintomasDelClimaterio ?? '',
      comentarios: ginecoObstetrico?.generalidades?.comentarios ?? '',
    });

    // Menstruación Habitual
    setValue('menstruacionHabitual', {
      ritmoCada: ginecoObstetrico?.menstruacionHabitual?.ritmoCada ?? '',
      ritmoDuracion: ginecoObstetrico?.menstruacionHabitual?.ritmoDuracion ?? '',
      cantidad: ginecoObstetrico?.menstruacionHabitual?.cantidad ?? '',
      dolor: ginecoObstetrico?.menstruacionHabitual?.dolor ?? '',
      comentarios: ginecoObstetrico?.menstruacionHabitual?.comentarios ?? '',
    });

    // Anticonceptivo
    setValue('anticonceptivo', {
      arrayTabla: ginecoObstetrico?.anticonceptivo?.arrayTabla ?? ['I'],
      metodoEnUso: ginecoObstetrico?.anticonceptivo?.metodoEnUso ?? [''],
      tiempoEnUso: ginecoObstetrico?.anticonceptivo?.tiempoEnUso ?? [''],
      comentarios: ginecoObstetrico?.anticonceptivo?.comentarios ?? '',
    });

    // Embarazos
    setValue('embarazos', {
      embarazos: ginecoObstetrico?.embarazos?.embarazos ?? '',
      gestas: ginecoObstetrico?.embarazos?.gestas ?? '',
      para: ginecoObstetrico?.embarazos?.para ?? '',
      cesareas: ginecoObstetrico?.embarazos?.cesareas ?? '',
      abortos: ginecoObstetrico?.embarazos?.abortos ?? '',
      ectopicos: ginecoObstetrico?.embarazos?.ectopicos ?? '',
      nacidosVivos: ginecoObstetrico?.embarazos?.nacidosVivos ?? '',
      comentarios: ginecoObstetrico?.embarazos?.comentarios ?? '',
    });

    // Despistaje Cáncer
    setValue('despistajeCancer', {
      cervicoUterino: {
        arrayTabla: ginecoObstetrico?.despistajeCancer?.cervicoUterino?.arrayTabla ?? ['I'],
        estudio: ginecoObstetrico?.despistajeCancer?.cervicoUterino?.estudio ?? [''],
        especifica: ginecoObstetrico?.despistajeCancer?.cervicoUterino?.especifica ?? [''],
        resultado: ginecoObstetrico?.despistajeCancer?.cervicoUterino?.resultado ?? [
          {
            check1: null,
            check2: null,
            check3: null,
            selector2: null,
            selector3: null,
            resultadoRadio: null,
            resultadoTexto: '',
          },
        ],
        dia: ginecoObstetrico?.despistajeCancer?.cervicoUterino?.dia ?? [''],
        mes: ginecoObstetrico?.despistajeCancer?.cervicoUterino?.mes ?? [''],
        anio: ginecoObstetrico?.despistajeCancer?.cervicoUterino?.anio ?? [''],
        comentarios: ginecoObstetrico?.despistajeCancer?.cervicoUterino?.comentarios ?? [''],
      },
      mama: {
        arrayTabla: ginecoObstetrico?.despistajeCancer?.mama?.arrayTabla ?? ['I'],
        estudio: ginecoObstetrico?.despistajeCancer?.mama?.estudio ?? [''],
        especifica: ginecoObstetrico?.despistajeCancer?.mama?.especifica ?? [''],
        resultado: ginecoObstetrico?.despistajeCancer?.mama?.resultado ?? [''],
        dia: ginecoObstetrico?.despistajeCancer?.mama?.dia ?? [''],
        mes: ginecoObstetrico?.despistajeCancer?.mama?.mes ?? [''],
        anio: ginecoObstetrico?.despistajeCancer?.mama?.anio ?? [''],
        comentarios: ginecoObstetrico?.despistajeCancer?.mama?.comentarios ?? [''],
      },
    });

    // Síntomas Climaterio
    setValue('sintomasClimaterio', {
      vasomotores: {
        radiosValues: ginecoObstetrico?.sintomasClimaterio?.vasomotores?.radiosValues ?? [
          '',
          '',
          '',
        ],
        comentariosValues: ginecoObstetrico?.sintomasClimaterio?.vasomotores?.comentariosValues ?? [
          '',
          '',
          '',
        ],
        items: ginecoObstetrico?.sintomasClimaterio?.vasomotores?.items ?? [
          'bochorno',
          'sudoracion_nocturna',
          'palpitaciones',
        ],
      },
      tractoGenital: {
        radiosValues: ginecoObstetrico?.sintomasClimaterio?.tractoGenital?.radiosValues ?? ['', ''],
        comentariosValues: ginecoObstetrico?.sintomasClimaterio?.tractoGenital
          ?.comentariosValues ?? ['', ''],
        items: ginecoObstetrico?.sintomasClimaterio?.tractoGenital?.items ?? [
          'resequedad_vaginal',
          'dispareunia',
        ],
      },
      somaticos: {
        radiosValues: ginecoObstetrico?.sintomasClimaterio?.somaticos?.radiosValues ?? [
          '',
          '',
          '',
          '',
          '',
          '',
        ],
        comentariosValues: ginecoObstetrico?.sintomasClimaterio?.somaticos?.comentariosValues ?? [
          '',
          '',
          '',
          '',
          '',
          '',
        ],
        items: ginecoObstetrico?.sintomasClimaterio?.somaticos?.items ?? [
          'insomnio',
          'piel_reseca',
          'cefalea',
          'mialgias',
          'artralgias',
          'mareos',
        ],
      },
      psicologicos: {
        radiosValues: ginecoObstetrico?.sintomasClimaterio?.psicologicos?.radiosValues ?? [
          '',
          '',
          '',
          '',
          '',
          '',
        ],
        comentariosValues: ginecoObstetrico?.sintomasClimaterio?.psicologicos
          ?.comentariosValues ?? ['', '', '', '', '', ''],
        items: ginecoObstetrico?.sintomasClimaterio?.psicologicos?.items ?? [
          'depresion',
          'disminucion_de_la_libido',
          'fatiga',
          'alteraciones_en_la_memoria',
          'ansiedad',
          'cambios_en_el_estado_de_animo',
        ],
      },
    });

    // Actualizar estados relacionados
    setEnGestacionTemp(ginecoObstetrico.generalidades.enGestacion);
    setShowEmbarazosFields(ginecoObstetrico?.embarazos?.embarazos === 'si');

    // Agregar un console.log para verificar el estado del formulario después de cargar todo
    console.log('Estado final del formulario:', watch());
  }, [ginecoObstetrico, setValue, ginecoObstetrico.generalidades.enGestacion, esPaciente, watch]);

  return (
    <>
      <ModalForm
        title="Ginecología"
        id="Ginecologia"
        submitForm={onSubmit}
        hayCambios={hayCambios}
        aceptarCambios={guardarDatosDeUsuarioAExpediente}
        rechazarCambios={guardarDatosDeExpedienteAUsuario}
      >
        <div className="space-y-8">
          {/* Sección General */}
          {!isOxxo && (
            <section>
              <h3 className="text-lg font-semibold mb-4 px-4 text-blue-800">Generalidades</h3>
              <div className="flex flex-col gap-2 space-y-4">
                <NumberInput
                  name="generalidades.menarquia"
                  label="Edad de inicio de menstruación"
                  fixedValue="Año(s)"
                  value={Number(watch('generalidades.menarquia')) || 0}
                  setValue={handleInputChange}
                  disableOption={{ label: 'N/A', position: 'right' }}
                />

                {/* <CheckboxInput
                  name="embarazos.estaEmbarazada"
                  label="¿Estás embarazada?"
                  value={watch('embarazos.estaEmbarazada')}
                  options={[
                    { value: 'si', label: 'Sí' },
                    { value: 'no', label: 'No' },
                    { value: 'sospecho_que_si', label: 'Sospecho que sí' },
                  ]}
                  distribution="vertical"
                  setValue={handleInputChange}
                /> */}

                <DateInput
                  name="generalidades.fur"
                  label="¿Cuándo inició tu última regla?"
                  value={dateUtils.formatFechaToDateString(watch('generalidades.fur'))}
                  setValue={(e) => handleDateChange('generalidades.fur', e)}
                />

                <CheckboxInput
                  name="generalidades.lactancia"
                  label="Lactancia"
                  value={watch('generalidades.lactancia')}
                  options={[
                    { value: 'si', label: 'Sí' },
                    { value: 'no', label: 'No' },
                  ]}
                  distribution="horizontal-inline"
                  setValue={handleInputChange}
                  numberPosition={0}
                />

                <NumberInput
                  name="generalidades.menopausia"
                  label="Menopausia (edad)"
                  fixedValue="Año(s)"
                  value={Number(watch('generalidades.menopausia')) || 0}
                  setValue={handleInputChange}
                  disableOption={{ label: 'N/A', position: 'right' }}
                />
              </div>
            </section>
          )}

          {/* Sección Menstruación */}
          <section>
            <h3 className="text-lg font-semibold mb-4 px-4 text-blue-800">Menstruación habitual</h3>
            <div className="space-y-4">
              {/* <CheckboxInput
                name="menstruacionHabitual.esRegular"
                label="¿Tu menstruación es regular?"
                value={watch('menstruacionHabitual.esRegular')}
                options={[
                  { value: 'si', label: 'Sí' },
                  { value: 'no', label: 'No' },
                ]}
                distribution="horizontal-inline"
                setValue={handleInputChange}
              /> */}

              {!isOxxo && (
                <>
                  <div className="flex flex-col md:flex-row items-center gap-4">
                    <NumberInput
                      name="menstruacionHabitual.ritmoCada"
                      label="Frecuencia"
                      placeholder="Cada"
                      fixedValue="(días)"
                      value={Number(watch('menstruacionHabitual.ritmoCada')) || 0}
                      setValue={handleInputChange}
                    />
                    <NumberInput
                      name="menstruacionHabitual.ritmoDuracion"
                      label="Duración"
                      placeholder="Duración"
                      fixedValue="(días)"
                      value={Number(watch('menstruacionHabitual.ritmoDuracion')) || 0}
                      setValue={handleInputChange}
                    />
                  </div>
                </>
              )}

              <NumberInput
                name="menstruacionHabitual.cantidad"
                label="Cantidad"
                fixedValue="Toallas o tampones/día"
                value={Number(watch('menstruacionHabitual.cantidad')) || 0}
                setValue={handleInputChange}
              />

              <ScaleInput
                name="menstruacionHabitual.dolor"
                label="¿Qué tan fuerte es el dolor?"
                value={watch('menstruacionHabitual.dolor')}
                options={[
                  { value: '1' },
                  { value: '2' },
                  { value: '3' },
                  { value: '4' },
                  { value: '5' },
                ]}
                scaleLabels={{
                  start: 'LEVE',
                  middle: 'MODERADO',
                  end: 'INTENSO',
                }}
                setValue={handleInputChange}
              />

              <SatisfactionIndicator />
            </div>
          </section>

          {/* Sección Anticonceptivos */}
          {!isOxxo && (
            <section>
              <h3 className="text-lg font-semibold mb-4 px-4 text-blue-800">Anticonceptivo</h3>
              <div className="space-y-4 px-2">
                {watch('anticonceptivo.arrayTabla').map((id, index) => {
                  const currentAnticonceptivo = watch('anticonceptivo');
                  const metodoEnUso = currentAnticonceptivo?.metodoEnUso?.[index] || '';
                  const tiempoEnUso = currentAnticonceptivo?.tiempoEnUso?.[index] || '';

                  return (
                    <div key={id} className="relative p-4 border rounded-lg bg-white">
                      {index > 0 && (
                        <button
                          onClick={() => handleDeleteMethod(id)}
                          className="absolute right-3 top-4 text-gray-400 hover:text-red-500"
                          type="button"
                        >
                          <Trash size={18} />
                        </button>
                      )}
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <SelectInput
                          placeholder="Selecciona"
                          name={`anticonceptivo.metodoEnUso.${index}`}
                          label="Método anticonceptivo"
                          options={anticonceptivosOptions}
                          value={metodoEnUso || ''} // Aseguramos que siempre haya un valor
                          setValue={(e) => {
                            handleMethodUpdate(id, 'metodoEnUso', e.value);
                          }}
                        />

                        <SelectInput
                          placeholder="Selecciona"
                          name={`anticonceptivo.tiempoEnUso.${index}`}
                          label="Tiempo de uso"
                          options={tiempoDeUsoOptions}
                          value={
                            tiempoEnUso
                              ? tiempoDeUsoOptions.find((opt) => opt.id === tiempoEnUso)?.id || ''
                              : ''
                          }
                          setValue={(e) => {
                            handleMethodUpdate(id, 'tiempoEnUso', e.value);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
                <div className="flex justify-center">
                  <AddButton
                    variant="enabled"
                    label="Agregar otro método"
                    onClick={handleAddMethod}
                  />
                </div>
              </div>
            </section>
          )}

          {/* Sección Embarazos */}
          {!isOxxo && (
            <section>
              <h3 className="text-lg font-semibold mb-4 px-4 text-blue-800">Embarazos</h3>
              <div className="space-y-4">
                <CheckboxInput
                  name="embarazos.embarazos"
                  label="¿Has tenido embarazos?"
                  value={watch('embarazos.embarazos')}
                  options={[
                    { value: 'si', label: 'Sí' },
                    { value: 'no', label: 'No' },
                  ]}
                  distribution="vertical-flex"
                  setValue={handleInputChange}
                />

                {showEmbarazosFields && (
                  <div className="grid grid-cols-2 lg:grid-cols-3">
                    <NumberInput
                      name="embarazos.gestas"
                      label="Embarazos"
                      value={Number(watch('embarazos.gestas'))}
                      setValue={handleInputChange}
                    />
                    <NumberInput
                      name="embarazos.para"
                      label="Partos"
                      value={Number(watch('embarazos.para'))}
                      setValue={handleInputChange}
                    />
                    <NumberInput
                      name="embarazos.cesareas"
                      label="Cesáreas"
                      value={Number(watch('embarazos.cesareas'))}
                      setValue={handleInputChange}
                    />
                    <NumberInput
                      name="embarazos.abortos"
                      label="Abortos"
                      value={Number(watch('embarazos.abortos'))}
                      setValue={handleInputChange}
                    />
                    <NumberInput
                      name="embarazos.nacidosVivos"
                      label="Nacidos vivos"
                      value={Number(watch('embarazos.nacidosVivos'))}
                      setValue={handleInputChange}
                    />
                  </div>
                )}
              </div>
            </section>
          )}

          {/* Sección Preguntas */}
          <section className="">
            <div>
              <CheckboxInput
                name="citologiaRadio"
                label="¿Has tenido citología cervical en los últimos 12 meses?"
                value={watch('citologiaRadio')}
                options={[
                  { value: 'si', label: 'Sí' },
                  { value: 'no', label: 'No' },
                ]}
                distribution="horizontal-inline"
                setValue={handleInputChange}
                numberPosition={0}
              />
            </div>

            <div>
              <CheckboxInput
                name="mastografia.radio"
                label="¿Te has realizado en los últimos meses una mastografía?"
                value={watch('mastografia.radio')}
                options={[
                  { value: 'si', label: 'Sí' },
                  { value: 'no', label: 'No' },
                ]}
                distribution="horizontal-inline"
                setValue={handleInputChange}
                numberPosition={1}
              />

              {watch('mastografia.radio') === 'si' && (
                <div className="py-2 bg-gray-50 rounded-lg border border-gray-100">
                  <DateInput
                    name="mastografia.fecha"
                    label="Fecha de la mastografía"
                    value={dateUtils.formatFechaToDateString(watch('mastografia.fecha'))}
                    setValue={(e) => handleDateChange('mastografia.fecha', e)}
                  />
                </div>
              )}
            </div>

            <div>
              <CheckboxInput
                name="ultrasonido.radio"
                label="¿Te has realizado en los últimos meses un ultrasonido de mama?"
                value={watch('ultrasonido.radio')}
                options={[
                  { value: 'si', label: 'Sí' },
                  { value: 'no', label: 'No' },
                ]}
                distribution="horizontal-inline"
                setValue={handleInputChange}
                numberPosition={2}
              />

              {watch('ultrasonido.radio') === 'si' && (
                <div className="py-2 bg-gray-50 rounded-lg border border-gray-100">
                  <DateInput
                    name="ultrasonido.fecha"
                    label="Fecha del ultrasonido"
                    value={dateUtils.formatFechaToDateString(watch('ultrasonido.fecha'))}
                    setValue={(e) => handleDateChange('ultrasonido.fecha', e)}
                  />
                </div>
              )}
            </div>

            {/* CAMPOS NUEVOS */}
            {isOxxo && (
              <>
                <div>
                  <CheckboxInput
                    name="despistajeCancer.cervicoUterino.estudio.0"
                    label="¿Has ido a consulta con un ginecólogo en el último año?"
                    value={watch('despistajeCancer.cervicoUterino.estudio.0')}
                    options={[
                      { value: 'si', label: 'Sí' },
                      { value: 'no', label: 'No' },
                    ]}
                    distribution="horizontal-inline"
                    setValue={handleDespistajeCancerChange}
                    numberPosition={3}
                  />
                </div>

                <div>
                  <CheckboxInput
                    name="despistajeCancer.mama.estudio.0"
                    label="¿Te hiciste algún estudio ginecológico en el último año?"
                    value={watch('despistajeCancer.mama.estudio.0')}
                    options={[
                      { value: 'si', label: 'Sí' },
                      { value: 'no', label: 'No' },
                    ]}
                    distribution="horizontal-inline"
                    setValue={handleDespistajeCancerChange}
                    numberPosition={4}
                  />
                </div>
              </>
            )}
          </section>
        </div>
      </ModalForm>
      <AlertDialog
        open={alertOpen}
        titulo={t('_atencion_')}
        descripcion={alertMensaje}
        nombreCancelar={t('regresar')}
        nombreAceptar={t('guardar')}
        callBackAceptar={guardarDatosExpediente}
        callBackClose={handleAlertClose}
      />
    </>
  );
};

export default Ginecologia;

import { actualizarCampo } from '@actions/infoUser/actions';
import { resetModal, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import { AddButton } from '@components/AddButton';
import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { ModalForm } from '@components/modals/ModalForm';
import {
  discapacidadesInitial,
  discapacidadSimpleInitial,
  IDiscapacidad,
  IDiscapacidades,
  IDiscapacidadSimple,
} from '@containers/TabsPaciente/PerfilPaciente/Discapacidades/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { RootState } from '@reducer/index';
import {
  updateDiscapacidades,
  updateDiscapacidadesPaciente,
  updateDiscapacidadesUsuario,
} from '@utils/sendInfo';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { Discapacidad } from './Discapacidad';
import { initialShowInput } from './type';

const MAX_DISABILITIES = 4;

const discapacidadSchema = z.object({
  tipoId: z.string(),
  tipo: z.string(),
  gradoId: z.string(),
  grado: z.string(),
  origenId: z.string(),
  origen: z.string(),
});

export const schema = z.object({
  loaded: z.boolean(),
  arrayDiscapacidades: z.array(discapacidadSchema),
  sinDiscapacidad: z.boolean(),
  certificado: z.union([z.literal(''), z.literal('si'), z.literal('no')]),
  comentarios: z.string(),
});

type FormData = z.infer<typeof schema>;
type FormDiscapacidad = z.infer<typeof discapacidadSchema>;

export const Discapacidades = () => {
  const [formData, setFormData] = useState<FormData>(discapacidadesInitial);
  const [showInput, setShowInput] = useState(initialShowInput);

  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { discapacidades, cambiosDiscapacidades, discapacidadesUsuario } = useSelector(
    (state: RootState) => state.InfoUser,
  );

  const dispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });
  const canAddMore = formData.arrayDiscapacidades.length < MAX_DISABILITIES;
  const hayCambios: boolean = idUsuario > 0 && cambiosDiscapacidades.filter((c: string) => c.length > 0).length > 0;

  const handleMainInputChange = (e: { name: string; value: any }) => {
    const { name, value } = e;

    setFormData((prev) => ({
      ...prev,
      [name]: value == 'si',
    }));
  };

  const handleDeleteDisability = (indexDelete: number) => {
    setFormData({
      ...formData,
      arrayDiscapacidades: formData.arrayDiscapacidades.filter((_, i) => i !== indexDelete),
    });
  };

  const handleDisabilityChange = (e: { name: string; value: any; position?: number }) => {
    const { name, value, position } = e;

    if (['tipoId', 'gradoId'].includes(name)) {
      setShowInput(
        showInput.map((item, i) => (i === position
          ? {
            ...item,
            [name === 'tipoId' ? 'grado' : 'origen']: true,
          }
          : item)),
      );
    }

    setFormData({
      ...formData,
      arrayDiscapacidades: formData.arrayDiscapacidades.map((item, i) => (i === position
        ? {
          ...item,
          [name]: value,
        }
        : item)),
    });
  };

  const handleCertificadoChange = (e: { name: string; value: any }) => {
    setFormData((prev) => ({
      ...prev,
      certificado: e.value,
    }));
  };

  const handleComentarioChange = (e: { name: string; value: any }) => {
    setFormData((prev) => ({
      ...prev,
      comentarios: e.value.id,
    }));
  };

  const handleAddDisability = () => {
    if (
      formData.sinDiscapacidad == false
      || formData.arrayDiscapacidades.length < MAX_DISABILITIES
    ) {
      setShowInput([
        ...showInput,
        {
          tipo: true,
          grado: false,
          origen: false,
        },
      ]);
      setFormData({
        ...formData,
        arrayDiscapacidades: [
          ...formData.arrayDiscapacidades,
          {
            tipoId: '',
            tipo: '',
            gradoId: '',
            grado: '',
            origenId: '',
            origen: '',
          },
        ],
      });
    }
  };

  const getData = (datos: IDiscapacidades, idUser?: number) => {
    const arrD: IDiscapacidadSimple[] = datos.arrayDiscapacidades
      .filter((e) => e.tipoId !== '')
      .map((d: IDiscapacidad) => ({
        tipoId: d.tipoId,
        gradoId: d.gradoId,
        origenId: d.origenId,
      })) || [discapacidadSimpleInitial];
    return {
      idMedico,
      idConsultorio,
      idPaciente,
      idUsuario: idUser,
      sinDiscapacidad: datos.sinDiscapacidad,
      certificado: datos.certificado,
      arrayDiscapacidades: arrD,
      comentarios: datos.comentarios,
    };
  };

  const guardarDatosUsuario = () => {
    setFormData({ ...formData, sinDiscapacidad: !formData.sinDiscapacidad });
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDiscapacidadesUsuario(getData(formData, idMiUsuario)),
        successFunction: () => {
          dispatch(actualizarCampo('discapacidades', { ...formData, loaded: true }));
          dispatch(resetModal('Discapacidades'));
          dispatch(setActiveModal('InformacionEmergencia'));
        },
      }),
    );
  };

  const guardarDatosExpediente = () => {
    const datosDiscapacidades = getData(formData, idUsuario);
    const sendFunctions = [updateDiscapacidades(datosDiscapacidades)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(updateDiscapacidadesPaciente(datosDiscapacidades));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => dispatch(actualizarCampo('discapacidades', { ...formData, loaded: true })),
          () => dispatch(actualizarCampo('discapacidadesUsuario', { ...formData, loaded: true })),
        ],
      }),
    );
  };

  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDiscapacidades(getData(discapacidadesUsuario)),
        successFunction: () => {
          dispatch(actualizarCampo('discapacidades', discapacidadesUsuario));
          setFormData(discapacidadesUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDiscapacidadesPaciente(getData(discapacidades, idUsuario)),
        successFunction: () => dispatch(actualizarCampo('discapacidadesUsuario', discapacidades)),
      }),
    );
  };

  const handleSubmitForm = () => {
    try {
      if (esPaciente) {
        guardarDatosUsuario();
      } else {
        guardarDatosExpediente();
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    const newArrayDiscapacidades = discapacidades.arrayDiscapacidades.map((discapacidad: FormDiscapacidad) => ({
      ...discapacidad,
      tipoId: String(discapacidad.tipoId),
      gradoId: String(discapacidad.gradoId),
      origenId: String(discapacidad.origenId),
    }));
    setFormData({ ...discapacidades, arrayDiscapacidades: newArrayDiscapacidades });
  }, [discapacidades]);
  useEffect(() => {
    reset(formData);
  }, [formData]);

  return (
    <ModalForm
      currentStep={3}
      title="Discapacidades"
      id="Discapacidades"
      submitForm={handleSubmit(handleSubmitForm)}
      hayCambios={hayCambios}
      aceptarCambios={guardarDatosDeUsuarioAExpediente}
      rechazarCambios={guardarDatosDeExpedienteAUsuario}
    >
      <div className="w-full space-y-6">
        <CheckboxInput
          label="¿Tienes alguna discapacidad?"
          name="sinDiscapacidad"
          options={[
            { value: 'si', label: 'No' },
            { value: 'no', label: 'Sí' },
          ]}
          value={formData.sinDiscapacidad ? 'si' : 'no'}
          setValue={handleMainInputChange}
          distribution="vertical-flex"
          errorMessage={errors.sinDiscapacidad?.message}
        />

        {!formData.sinDiscapacidad && (
          <div className="space-y-6">
            {formData.arrayDiscapacidades.map((discapacidad, index) => (
              <Discapacidad
                key={`${discapacidad.tipoId}_${index}`}
                index={index}
                discapacidad={discapacidad}
                onDelete={handleDeleteDisability}
                onChange={handleDisabilityChange}
                showDelete={index !== 0}
                showInput={showInput[index]}
              />
            ))}

            {canAddMore && !formData.sinDiscapacidad && showInput[showInput.length - 1].origen && (
              <AddButton
                variant="enabled"
                label="Agregar discapacidad"
                onClick={handleAddDisability}
              />
            )}

            <div className="space-y-4">
              <CheckboxInput
                label="¿Tienes certificado de persona con discapacidad?"
                name="certificado"
                value={formData.certificado}
                options={[
                  { value: 'no', label: 'No' },
                  { value: 'si', label: 'Sí' },
                ]}
                setValue={handleCertificadoChange}
                layout="row"
                errorMessage={errors.certificado?.message}
              />
            </div>
          </div>
        )}
        <TextArea
          label="Comentario"
          name="comentario"
          value={formData.comentarios}
          maxLength={300}
          setValue={handleComentarioChange}
          errorMessage={errors.comentarios?.message}
        />
      </div>
    </ModalForm>
  );
};

export default Discapacidades;

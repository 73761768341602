import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import obtenerDatosGeograficos from '@utils/geografia';
import { capitalize } from '@common/functions';
import { HtmlTooltip } from '@utils/ComponentesJSX';
import { RootState } from 'src/store';
import { IConsultoriosProps } from './types';

function Consultorios({
  consultorio,
  objConsultorio,
  index,
  editConsultorio,
  setDeleteAlert,
  setEditAlert,
  setIndexToDelete,
}: IConsultoriosProps) {
  const { t } = useTranslation();
  const { pais } = useSelector((state: RootState) => state.Me);
  const geo = obtenerDatosGeograficos(pais);
  const handleEditConsultorio = () => {
    if (
      geo.agee &&
      geo.agee.required &&
      !objConsultorio.agee &&
      geo.agem &&
      geo.agem.required &&
      !objConsultorio.agem &&
      geo.agel &&
      geo.agel.required &&
      !objConsultorio.agel &&
      !objConsultorio.calle &&
      !objConsultorio.clinicaHospital.id &&
      !objConsultorio.nombre &&
      !objConsultorio.telefono1 &&
      objConsultorio.tipoEstablecimiento.id === 0 &&
      objConsultorio.otroEstablecimiento.length === 0 &&
      !objConsultorio.nombreEstablecimiento
    ) {
      editConsultorio(index);
    } else {
      setIndexToDelete(index);
      setEditAlert(true);
    }
  };
  const handleDeleteConsultorio = () => {
    setIndexToDelete(index);
    setDeleteAlert(true);
  };
  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
      <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
        <h3 className="font-normal m-0 text-gray-500">
          {`${capitalize(consultorio.nombre)} - ${consultorio.label}`}
        </h3>
        <p className="text-gray-500 mb-0">
          {`${t('direccion')}: ${consultorio.calle}${
            consultorio.num1 ? ` ${consultorio.num1}.` : ''
          }${consultorio.num2 ? ` ${consultorio.num2}.` : ''}${
            consultorio.agea ? ` ${consultorio.agea!.id}.` : ''
          }${consultorio.agem ? ` ${consultorio.agem.id}.` : ''}${
            consultorio.cp ? ` ${consultorio.cp}.` : ''
          }${consultorio.agel ? ` ${consultorio.agel.id}.` : ''}${
            consultorio.agee ? ` ${consultorio.agee.id}.` : ''
          }`}
        </p>
        <p className="font-normal m-0 text-gray-500">
          {`${t('telefono(s)')}: ${
            consultorio.telefono1
              ? `${consultorio.telefono1}${
                consultorio.extension1 ? ` ext. ${consultorio.extension1}` : ''
              }; `
              : ''
          }${
            consultorio.telefono2
              ? `${consultorio.telefono2}${
                consultorio.extension2 ? ` ext. ${consultorio.extension2}` : ''
              }; `
              : ''
          }${
            consultorio.telefono3
              ? `${consultorio.telefono3}${
                consultorio.extension3 ? ` ext. ${consultorio.extension3}` : ''
              };`
              : ''
          }`}
        </p>
        {consultorio.correoElectronico && (
          <p className="font-normal m-0 text-gray-500">
            {`${t('correo_electronico_del_consultorio')}: ${consultorio.correoElectronico}`}
          </p>
        )}
        {consultorio.sitioWeb && (
          <p className="font-normal m-0 text-gray-500">
            {`${t('sitio_web')}: ${consultorio.sitioWeb}`}
          </p>
        )}
      </div>
      <div className="min-w-max self-center">
        <HtmlTooltip
          title={`${t('solo_editar_consultorio')}`}
          disableHoverListener={!consultorio.tienePacientes}
        >
          <span>
            <IconButton aria-label="edit" color="primary" onClick={handleEditConsultorio}>
              <EditIcon />
            </IconButton>
          </span>
        </HtmlTooltip>
        <HtmlTooltip
          title={`${t('no_se_puede_eliminar_consultorio')}`}
          disableHoverListener={!consultorio.tienePacientes}
        >
          <span>
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={handleDeleteConsultorio}
              disabled={!!consultorio.tienePacientes}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </HtmlTooltip>
      </div>
    </div>
  );
}

export default Consultorios;

import { CirclePlus } from 'lucide-react';
import { FC } from 'react';
import { AddButtonProps } from './type';

export const AddButton: FC<AddButtonProps> = ({
  label,
  onClick,
  variant = 'default',
  showIcon = true,
  customIcon,
  disabled = false,
  xPadding = 2,
}) => {
  const getVariantStyles = () => {
    if (disabled) {
      return 'text-gray-500';
    }
    switch (variant) {
      case 'enabled':
        return 'text-[#3B82F6] hover:text-blue-700';
      default:
        return 'text-gray-500 hover:text-gray-600';
    }
  };

  const renderIcon = () => {
    if (!showIcon) return null;
    if (customIcon) return customIcon;

    return <CirclePlus />;
  };

  const handleClick = () => {
    if (variant === 'enabled' && onClick) {
      onClick();
    }
  };
  return (
    <div className={`flex justify-end w-full h-auto px-${xPadding}`}>
      <button
        type="button"
        onClick={handleClick}
        className={`
        inline-flex justify-self-end items-center gap-2
        text-sm font-medium 
        ${getVariantStyles()}
        transition-colors duration-200
        `}
        disabled={disabled}
      >
        {renderIcon()}
        <span>{label}</span>
      </button>
    </div>
  );
};

import { actualizarCampo } from '@actions/infoUser/actions';
import { resetModal, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import CheckboxInput from '@components/FormElemntsLibrary/CheckboxInput';
import { NumberInput } from '@components/FormElemntsLibrary/NumberInput';
import { ModalForm } from '@components/modals/ModalForm';
import {
  validacionIngresoSoloUnPunto,
  validacionSoloNumeros,
  validacionSoloNumerosTresDecimales,
} from '@constants/validaciones';
import {
  exploracionFisicaInitial,
  IExploracionFisica,
} from '@containers/TabsPaciente/ExploracionFisica/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { RootState } from '@reducer/index';
import { handleInputChange } from '@utils/handleInputChange';
import { sendExploracionFisica, sendExploracionFisicaPaciente } from '@utils/sendInfo';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

const schema = z.object({
  loaded: z.boolean(),
  sitioMedicion: z.object({
    id: z.number(),
    label: z.string(),
  }),
  temperatura: z.string(),
  fCardiaca: z.string(),
  fCardiacaRadio: z.string(),
  diastolica: z
    .string()
    .refine(
      (val) => !val || (validacionSoloNumeros(val) && parseInt(val) >= 20 && parseInt(val) <= 200),
      'Valor fuera de rango (20-200)',
    ),
  sistolica: z
    .string()
    .refine(
      (val) => !val || (validacionSoloNumeros(val) && parseInt(val) >= 50 && parseInt(val) <= 300),
      'Valor fuera de rango (50-300)',
    ),
  pMedia: z.string(),
  pPulso: z.string(),
  pArterialRadio: z.string(),
  fRespiratoria: z.string(),
  satOxigeno: z.string(),
  peso: z
    .string()
    .refine(
      (val) => !val
        || ((validacionSoloNumerosTresDecimales(val) || validacionIngresoSoloUnPunto(val))
          && parseFloat(val) >= 1
          && parseFloat(val) <= 400),
      'Valor fuera de rango (1-400)',
    ),
  altura: z
    .string()
    .refine(
      (val) => !val || (validacionSoloNumeros(val) && parseInt(val) >= 30 && parseInt(val) <= 220),
      'Valor fuera de rango (30-220)',
    ),
  imc: z.string(),
  perCintura: z
    .string()
    .refine(
      (val) => !val || (validacionSoloNumeros(val) && parseInt(val) >= 20 && parseInt(val) <= 300),
      'Valor fuera de rango (20-300)',
    ),
  contenidoTabla: z.array(
    z.object({
      nombreSintoma: z.string(),
      comentarioSintoma: z.string(),
      normal: z.string(),
    }),
  ),
});

type FormData = z.infer<typeof schema>;

export const MisMedidas = () => {
  const [formData, setFormData] = useState<FormData>(exploracionFisicaInitial);

  const dispatch = useDispatch();
  const { exploracionFisica, edad } = useSelector((state: RootState) => state.InfoUser);
  const { idMedico, idUsuario, esPaciente } = useSelector((state: RootState) => state.Me);

  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);

  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<IExploracionFisica>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });

  const handleCalculatePresiones = (data: any, sistolica: string, diastolica: string) => {
    if (sistolica.length !== 0 && diastolica.length !== 0) {
      const pMedia = (parseInt(sistolica, 10) + parseInt(diastolica, 10) * 2) / 3;
      const pPulso = parseInt(sistolica, 10) - parseInt(diastolica, 10);
      setFormData({
        ...data,
        pMedia: pMedia.toFixed(1),
        pPulso: pPulso > 0 ? pPulso.toFixed(1) : '',
      });
    } else if (data.loaded) {
      setFormData({
        ...data,
        pMedia: '',
        pPulso: '',
      });
    }
  };

  const handleCalculateIMC = (data: any) => {
    if (
      data.peso.length !== 0
      && data.altura.length !== 0
      && edad.edad >= 5
      && edad.periodo === 'anios'
    ) {
      const imc = parseFloat(data.peso) / ((parseFloat(data.altura) / 100) * (parseFloat(data.altura) / 100));
      setFormData({
        ...data,
        imc: imc.toFixed(1),
      });
    } else if (data.loaded) {
      setFormData({
        ...data,
        imc: '',
      });
    }
  };

  const handleExploracionChange = (data: { name: string; value: any }) => {
    if (data.name in formData) {
      handleInputChange(setFormData, { ...data, value: String(data.value) });
    } else {
      console.error(`La clave ${data.name} no es válida para exploración física.`);
    }
  };

  const getDatosExploracion = (datos: IExploracionFisica) => ({
    PESO: datos.peso.length === 0 ? 999 : datos.peso,
    ALTURA: datos.altura.length === 0 ? 999 : datos.altura,
    IMC: datos.imc.length === 0 ? '-1' : datos.imc,
    PARTERIAL_SISTOLICA: datos.sistolica.length === 0 ? 0 : datos.sistolica,
    PARTERIAL_DIASTOLICA: datos.diastolica.length === 0 ? 0 : datos.diastolica,
    PARTERIAL_SEL: datos.pArterialRadio,
    PER_CINTURA: datos.perCintura.length === 0 ? 999 : datos.perCintura,
  });

  const getDatosExpediente = (datos: IExploracionFisica) => ({
    ...getDatosExploracion(datos),
    ID_PACIENTE: idPaciente,
    ID_CONSULTA: idConsulta,
    idMedico,
    idConsultorio,
  });

  const getDatosUsuario = (datos: IExploracionFisica) => ({
    ...getDatosExploracion(datos),
    ID_USUARIO: idUsuario,
  });

  const guardarDatosExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendExploracionFisica(getDatosExpediente(formData)),
        successFunction: () => {
          dispatch(
            actualizarCampo('cambiosExploracionFisica', {
              ...formData,
              loaded: true,
            }),
          );
          dispatch(resetModal('MisMedidas'));
          dispatch(setActiveModal('ResumenDeSalud'));
        },
      }),
    );
  };

  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendExploracionFisicaPaciente(getDatosUsuario(formData)),
        successFunction: () => {
          dispatch(
            actualizarCampo('cambiosExploracionFisica', {
              ...formData,
              loaded: true,
            }),
          );
          dispatch(resetModal('MisMedidas'));
          dispatch(setActiveModal('ResumenDeSalud'));
        },
      }),
    );
  };

  const handleSubmitForm = async () => {
    try {
      // await schema.parseAsync(formData);
      if (esPaciente) {
        guardarDatosUsuario();
      } else {
        guardarDatosExpediente();
      }
    } catch (error) {
      console.error('Error en el envío del formulario:', error);
    }
  };


  useEffect(() => {
    reset(formData);
  }, [formData]);

  useEffect(() => {
    setFormData({
      ...exploracionFisica,
      altura: String(exploracionFisica.altura),
      diastolica: String(exploracionFisica.diastolica),
      perCintura: String(exploracionFisica.perCintura),
      peso: String(exploracionFisica.peso),
      sistolica: String(exploracionFisica.sistolica)
    });
    handleCalculatePresiones(
      formData,
      formData.sistolica,
      formData.diastolica,
    );
    handleCalculateIMC(formData);
  }, [exploracionFisica]);

  // calculo de presiones
  useEffect(() => {
    handleCalculatePresiones(formData, formData.sistolica, formData.diastolica);
  }, [formData.sistolica, formData.diastolica]);
  // calculo de IMC

  useEffect(() => {
    handleCalculateIMC(formData);
  }, [formData.peso, formData.altura]);

  return (
    <ModalForm
      title="Mis medidas"
      id="MisMedidas"
      submitForm={handleSubmit(handleSubmitForm)}
      hayCambios={false}
      aceptarCambios={() => { }}
      rechazarCambios={() => { }}
    >
      <div>
        <h3 className="text-lg font-medium mb-4 px-4 text-blue-800">Signos vitales</h3>
        <div className="w-full mb-8">
          <CheckboxInput
            name="pArterialRadio"
            label="Presión arterial"
            options={[
              { value: 'sentado', label: 'Sentado' },
              { value: 'acostado', label: 'Acostado' },
              { value: 'de_pie', label: 'De pie' },
            ]}
            allowMultiple={false}
            value={formData.pArterialRadio}
            setValue={handleExploracionChange}
            distribution="vertical-flex"
            helperText={errors.pArterialRadio?.message}
          />
        </div>

        <div className="grid grid-cols-2 mt-4">
          <NumberInput
            name="sistolica"
            label="Num. superior"
            fixedValue="mmHg"
            labelAligmet="border"
            value={Number(formData.sistolica)}
            setValue={handleExploracionChange}
            helperText={errors.sistolica?.message}
          />
          <NumberInput
            name="diastolica"
            label="Num. inferior"
            fixedValue="mmHg"
            labelAligmet="border"
            value={Number(formData.diastolica)}
            setValue={handleExploracionChange}
            helperText={errors.diastolica?.message}
          />
        </div>
        <div className="grid grid-cols-2 mt-4">
          <NumberInput
            name="Presion-arterial-media"
            label="Presión arterial media"
            fixedValue="mmHg"
            labelAligmet="top"
            readOnly
            value={Number(formData.pMedia)}
            setValue={handleExploracionChange}
          />
          <NumberInput
            name="Presion-de-Pulso"
            label="Presión de Pulso"
            fixedValue="mmHg"
            labelAligmet="top"
            readOnly
            value={Number(formData.pPulso)}
            setValue={handleExploracionChange}
          />
        </div>
      </div>

      <div className="mt-8">
        <h3 className="text-lg font-medium mb-4 px-4 text-blue-800">Medidas</h3>
        <div className="grid grid-cols-2">
          <NumberInput
            name="peso"
            label="Peso"
            fixedValue="Kg"
            labelAligmet="border"
            value={Number(formData.peso)}
            setValue={handleExploracionChange}
            helperText={errors.peso?.message}
          />
          <NumberInput
            name="altura"
            label="Altura"
            fixedValue="cm"
            labelAligmet="border"
            value={Number(formData.altura)}
            setValue={handleExploracionChange}
            helperText={errors.altura?.message}
          />
        </div>
        <div className="mt-4">
          <NumberInput
            name="IMC"
            label="IMC"
            fixedValue="Kg/m2"
            labelAligmet="top"
            readOnly
            value={Number(formData.imc)}
            setValue={handleExploracionChange}
          />
        </div>
        <div className="mt-4">
          <NumberInput
            name="perCintura"
            label="Medida de cintura"
            fixedValue="cm"
            labelAligmet="border"
            value={Number(formData.perCintura)}
            setValue={handleExploracionChange}
            helperText={errors.perCintura?.message}
          />
        </div>
      </div>
    </ModalForm>
  );
};

export default MisMedidas;

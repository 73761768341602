import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberInputProps } from './type';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { GLOBALS } from '@constants/prueba/globals';
import { useBreakPoint } from '@hooks/useBreakpoint';

export const NumberInput: FC<NumberInputProps> = ({
  index,
  name,
  containerClass = '',
  label: externalLabel,
  floatingLabel,
  isRequired = false,
  placeholder: externalPlaceholder,
  helperText,
  fixedValue,
  min = 0,
  max,
  decimals,
  isCurrency,
  isInteger = false,
  isString = false,
  labelAligmet = 'top',
  setValue,
  value: externalValue,
  disableOption,
  validacion,
  readOnly,
  modal,
  onClick,
  action,
  disabled = false,
  setStatus,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [value, setInternalValue] = useState<number | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [label, setLabel] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const isFloating = isFocused || value !== null;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);

  useEffect(() => {
    setStatus?.({
      listName: 'missing',
      name,
      value: value !== undefined || value !== null || value !== '',
      index,
    });
  }, [isRequired]);

  useEffect(() => {
    setStatus?.({
      listName: 'error',
      name,
      value: errorMessage.length > 0,
      index,
    });
  }, [errorMessage]);

  useEffect(() => {
    if (floatingLabel) {
      setLabel('');
      setPlaceholder('');
    } else {
      setLabel(externalLabel === undefined ? t(name) : externalLabel);
      setPlaceholder(externalPlaceholder === undefined ? t(name) : externalPlaceholder);
    }
  }, [floatingLabel, externalLabel, externalPlaceholder]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    setInternalValue(externalValue);
    setErrorMessage('');
    if (validacion) {
      setErrorMessage(validacion(value));
    }
  }, [externalValue]);

  const validateNumber = (value: number | null) => {
    setErrorMessage('');
    if (isRequired && value === null) {
      setErrorMessage('campo_requerido');
    } else if (validacion) {
      setErrorMessage(validacion(value));
    } else {
      if (value !== null) {
        if (min !== undefined && value < min) {
          setErrorMessage(`El valor mínimo es ${min}`);
        }
        if (max !== undefined && value > max) {
          setErrorMessage(`El valor máximo es ${max}`);
        }
      }
    }
  };

  const handleInputClick = () => {
    if (modal && onClick && value !== null) {
      onClick(value as number);
    }
    if (action) {
      action();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (['-', '+', 'e'].includes(e.key) || (isInteger && ['.', ','].includes(e.key))) {
      e.preventDefault();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (readOnly) return;

    const newValue = event.target.value;
    const numericValue = newValue ? Number(newValue) : null;

    if (decimals && (
      (newValue.includes('.') && newValue.split('.')[1].length > decimals) ||
      (newValue.includes(',') && newValue.split(',')[1].length > decimals)
    )) {
      return;
    }

    setValue({
      name,
      value: isString ? newValue : numericValue,
    });
    setInternalValue(numericValue);
    if (validacion) {
      setErrorMessage(validacion(numericValue));
    }
    if (onClick && numericValue !== null) {
      onClick(numericValue);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    validateNumber(value);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!isWideScreen) {
      setTimeout(() => {
        event.target.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);  
    }
    setIsFocused(true);
  };

  const handleDisableChange = () => {
    setIsDisabled(!isDisabled);
    if (!isDisabled) {
      setInternalValue(null);
      setErrorMessage('');

      const syntheticEvent = {
        name,
        value: '',
        type: 'number',
      };

      setValue(syntheticEvent);
    }
  };

  const renderDisableCheckbox = () => (
    <label className="flex items-center text-sm text-gray-600 whitespace-nowrap ml-4">
      <input
        type="checkbox"
        className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
        checked={isDisabled}
        onChange={handleDisableChange}
      />
      {disableOption?.label}
    </label>
  );

  return (
    <div className={`w-full h-fit my-[10px] ${containerClass}`}>
      <div className="px-4">
        <div className="">
          {labelAligmet === 'top' ? (
            <div className="flex items-center justify-between mb-2">
              {label && (
                <label
                  htmlFor={name}
                  className={`block font-medium text-base ${
                    errorMessage ? 'text-red-500' : 'text-gray-700'
                  } ${isDisabled ? 'text-gray-500' : ''}`}
                >
                  {label} {isRequired && <span className="text-red-500">*</span>}
                </label>
              )}
              {disableOption?.position === 'right' && renderDisableCheckbox()}
            </div>
          ) : null}

          <div className="flex items-center gap-4">
            <div className="flex-1 relative">
              {floatingLabel &&
                <label
                  onClick={() => inputRef.current?.focus() }
                  className={`absolute left-3 text-gray-400 bg-white rounded-sm px-1 transition-all 
                    ${(isFocused || value) ? "-top-2 text-xs" : "top-[15px] text-sm"} cursor-text`}
                >
                  {`${floatingLabel}${isRequired ? '*' : ''}`}
                </label>
              }
              <input
                ref={inputRef}
                type="number"
                id={name}
                name={name}
                placeholder={labelAligmet === 'border' && !isFloating ? '' : placeholder}
                value={value || undefined}
                onChange={handleChange}
                onClick={handleInputClick}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                disabled={isDisabled}
                readOnly={readOnly}
                min={min}
                max={max}
                // pattern={decimals ? `^\d*(\.\d{0,${decimals}})?$` : undefined}
                className={`w-full h-[48px] px-3 ${fixedValue ? 'pr-16' : 'pr-3'} ${
                  isCurrency ? 'pl-7' : ''
                } rounded-md border focus:outline-none transition
                  ${
                    isDisabled
                      ? 'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed'
                      : readOnly
                        ? 'bg-gray-50 cursor-pointer hover:bg-gray-100 border-gray-300'
                        : errorMessage
                          ? 'border-red-500 ring-2 ring-red-200'
                          : 'border-gray-300 focus:ring-2 focus:ring-blue-500'
                  }`}
              />
              {labelAligmet === 'border' && label && (
                <label
                  htmlFor={name}
                  className={`absolute text-sm transition-all duration-200
                    ${
                      isFloating
                        ? `-top-2.5 left-2 bg-white px-1 ${
                            errorMessage ? 'text-red-500' : 'text-blue-500'
                          }`
                        : 'top-[50%] -translate-y-[50%] left-3 text-gray-500'
                    }
                    ${isDisabled ? 'text-gray-400' : ''}`}
                >
                  {label} {isRequired && <span className="text-red-500">*</span>}
                </label>
              )}
              {isCurrency && (
                <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">$</span>
              )}
              {fixedValue && (
                <span
                  className={`absolute right-3 top-1/2 -translate-y-1/2 text-sm ${
                    isDisabled ? 'text-gray-400' : 'text-gray-500'
                  }`}
                >
                  {fixedValue}
                </span>
              )}
            </div>
            {disableOption?.position === 'after' && renderDisableCheckbox()}
          </div>

          {(errorMessage || helperText) && (
            <p className={`text-sm mt-1 ${errorMessage ? 'text-red-500' : 'text-gray-500'}`}>
              {errorMessage || helperText}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NumberInput;

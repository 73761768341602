import { AddButton } from '@components/AddButton';
import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import { NumberInput } from '@components/FormElemntsLibrary/NumberInput';
import { SelectInput } from '@components/FormElemntsLibrary/SelectInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { TextInput } from '@components/FormElemntsLibrary/TextInput';
import { IArrTipoDrogas } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Toxicomania/types';
import { Trash2 } from 'lucide-react';
import { FC } from 'react';
import { SubstanceFormProps } from './types';

const substanceTypeOptions = [
  { id: 'otra', label: 'Otra' },
  { id: 'ayahuasca', label: 'Ayahuasca' },
  { id: 'depresores_nerviosos', label: 'Depresores nerviosos' },
  { id: 'cocaina', label: 'Cocaína' },
  { id: 'dmt', label: 'DMT' },
  { id: 'ghb', label: 'GHB' },
  { id: 'alucinogenos', label: 'Alucinógenos' },
  { id: 'heroina', label: 'Heroína' },
  { id: 'inhalantes', label: 'Inhalantes' },
  { id: 'ketamina', label: 'Ketamina' },
  { id: 'khat', label: 'Khat' },
  { id: 'kratom', label: 'Kratom' },
  { id: 'lsd', label: 'LSD' },
  { id: 'marihuana', label: 'Marihuana' },
  { id: 'mdma', label: 'MDMA' },
  { id: 'mescalina', label: 'Mescalina' },
  { id: 'metanfetamienta', label: 'Metanfetamina' },
  { id: 'venta_libre_dextrometorfano', label: 'Venta libre dextrometorfano' },
  { id: 'venta_libre_loperamida', label: 'Venta libre loperamida' },
  { id: 'pcp', label: 'PCP' },
  { id: 'opioides_con_receta', label: 'Opioides con receta' },
  { id: 'estimulantes_con_receta', label: 'Estimulantes con receta' },
  { id: 'psilocibina', label: 'Psilocibina' },
  { id: 'rohypnol', label: 'Rohypnol' },
  { id: 'salvia', label: 'Salvia' },
  { id: 'esteroides', label: 'Esteroides' },
  { id: 'cannabinoides_sinteticos', label: 'Cannabinoides sintéticos' },
  { id: 'catinonas_sinteticas', label: 'Catinonas sintéticas' },
];

const frequencyOptions = [
  { id: 'diario', label: 'Diario' },
  { id: '1_vez_por_semanas', label: '1 vez por semana' },
  { id: '2_veces_por_semana', label: '2 veces por semana' },
  { id: '3_veces_por_semana', label: '3 veces por semana' },
  { id: '4_veces_por_semana', label: '4 veces por semana' },
  { id: '5_veces_por_semana', label: '5 veces por semana' },
  { id: '6_veces_por_semana', label: '6 veces por semana' },
  { id: 'mensual_o_menos', label: 'Mensual o menos' },
];

const statusOptions = [
  { value: 'activo', label: 'Activo' },
  { value: 'abandonado', label: 'Abandonado' },
];

export const SubstanceForm: FC<SubstanceFormProps> = ({
  formData,
  onInputChange,
  onSubstancesChange,
}) => {
  const handleAddSubstance = () => {
    if (formData.otrasDrogas.arrTipoDrogas.arrTabla.length >= 10) return;

    const currentLastId =
      formData.otrasDrogas.arrTipoDrogas.arrTabla[
        formData.otrasDrogas.arrTipoDrogas.arrTabla.length - 1
      ];

    const newSubstances: IArrTipoDrogas = {
      arrTabla: [...formData.otrasDrogas.arrTipoDrogas.arrTabla, `${currentLastId}I`],
      tipoDroga: [...formData.otrasDrogas.arrTipoDrogas.tipoDroga, ''],
      especifica: [...formData.otrasDrogas.arrTipoDrogas.especifica, ''],
      cantidadDroga: [...formData.otrasDrogas.arrTipoDrogas.cantidadDroga, ''],
      edadInicio: [...formData.otrasDrogas.arrTipoDrogas.edadInicio, ''],
      edadTermino: [...formData.otrasDrogas.arrTipoDrogas.edadTermino, ''],
      frecuencia: [...formData.otrasDrogas.arrTipoDrogas.frecuencia, ''],
      estatus: [...formData.otrasDrogas.arrTipoDrogas.estatus, 'activo'],
    };
    onSubstancesChange(newSubstances);
  };

  const handleDeleteSubstance = (index: number) => (e: React.MouseEvent) => {
    e.preventDefault(); // Prevenir cualquier comportamiento por defecto
    e.stopPropagation(); // Detener la propagación del evento

    if (index === 0) return;

    if (formData.otrasDrogas.arrTipoDrogas.arrTabla.length > 1) {
      const newSubstances: IArrTipoDrogas = {
        arrTabla: formData.otrasDrogas.arrTipoDrogas.arrTabla.filter((_, i) => i !== index),
        tipoDroga: formData.otrasDrogas.arrTipoDrogas.tipoDroga.filter((_, i) => i !== index),
        especifica: formData.otrasDrogas.arrTipoDrogas.especifica.filter((_, i) => i !== index),
        cantidadDroga: formData.otrasDrogas.arrTipoDrogas.cantidadDroga.filter(
          (_, i) => i !== index,
        ),
        edadInicio: formData.otrasDrogas.arrTipoDrogas.edadInicio.filter((_, i) => i !== index),
        edadTermino: formData.otrasDrogas.arrTipoDrogas.edadTermino.filter((_, i) => i !== index),
        frecuencia: formData.otrasDrogas.arrTipoDrogas.frecuencia.filter((_, i) => i !== index),
        estatus: formData.otrasDrogas.arrTipoDrogas.estatus.filter((_, i) => i !== index),
      };
      onSubstancesChange(newSubstances);
    } else {
      onSubstancesChange({
        arrTabla: ['I'],
        tipoDroga: [''],
        especifica: [''],
        cantidadDroga: [''],
        edadInicio: [''],
        edadTermino: [''],
        frecuencia: [''],
        estatus: ['activo'],
      });
    }
  };

  const handleFieldChange = (index: number, field: keyof IArrTipoDrogas, value: string) => {
    const newSubstances = { ...formData.otrasDrogas.arrTipoDrogas };
    (newSubstances[field] as string[])[index] = value;
    onSubstancesChange(newSubstances);
  };

  return (
    <div className="space-y-4">
      {formData.otrasDrogas.arrTipoDrogas.arrTabla.map((_, index) => (
        <div key={index} className="space-y-4 pb-4 m-2 border rounded-lg bg-white relative">
          <div className="flex">
            <CheckboxInput
              name={`otrasDrogas.arrTipoDrogas.estatus.${index}`}
              label=""
              options={statusOptions}
              distribution="horizontal-inline"
              value={formData.otrasDrogas.arrTipoDrogas.estatus[index]}
              setValue={(event) => {
                const newSubstances = { ...formData.otrasDrogas.arrTipoDrogas };
                newSubstances.estatus[index] = event.value;
                if (event.value === 'activo') {
                  newSubstances.edadTermino[index] = '';
                }
                onSubstancesChange(newSubstances);
              }}
            />
          </div>

          <SelectInput
            name={`otrasDrogas.arrTipoDrogas.tipoDroga.${index}`}
            label="Tipo"
            placeholder="Selecciona"
            options={substanceTypeOptions}
            value={formData.otrasDrogas.arrTipoDrogas.tipoDroga[index]}
            setValue={(event) => {
              const newSubstances = { ...formData.otrasDrogas.arrTipoDrogas };
              newSubstances.tipoDroga[index] = event.value;
              if (event.value !== 'otra') {
                newSubstances.especifica[index] = '';
              }
              onSubstancesChange(newSubstances);
            }}
          />

          {formData.otrasDrogas.arrTipoDrogas.tipoDroga[index] === 'otra' && (
            <TextInput
              name={`otrasDrogas.arrTipoDrogas.especifica.${index}`}
              label="Especifica"
              value={formData.otrasDrogas.arrTipoDrogas.especifica[index]}
              setValue={(event) => {
                const newSubstances = { ...formData.otrasDrogas.arrTipoDrogas };
                newSubstances.especifica[index] = event.value;
                onSubstancesChange(newSubstances);
              }}
            />
          )}

          <TextInput
            name={`otrasDrogas.arrTipoDrogas.cantidadDroga.${index}`}
            label="Cantidad / Presentación"
            value={formData.otrasDrogas.arrTipoDrogas.cantidadDroga[index]}
            setValue={(event) => {
              const newSubstances = { ...formData.otrasDrogas.arrTipoDrogas };
              newSubstances.cantidadDroga[index] = event.value;
              onSubstancesChange(newSubstances);
            }}
          />

          <div className="grid grid-cols-2">
            <NumberInput
              name={`otrasDrogas.arrTipoDrogas.edadInicio.${index}`}
              label="Edad de inicio"
              fixedValue="Año(s)"
              value={Number(formData.otrasDrogas.arrTipoDrogas.edadInicio[index]) || 0}
              setValue={(event) => {
                const newSubstances = { ...formData.otrasDrogas.arrTipoDrogas };
                newSubstances.edadInicio[index] = event.value.toString();
                onSubstancesChange(newSubstances);
              }}
            />
            <SelectInput
              name={`otrasDrogas.arrTipoDrogas.frecuencia.${index}`}
              label="Frecuencia"
              placeholder="Selecciona"
              options={frequencyOptions}
              value={formData.otrasDrogas.arrTipoDrogas.frecuencia[index]}
              setValue={(event) => {
                const newSubstances = { ...formData.otrasDrogas.arrTipoDrogas };
                newSubstances.frecuencia[index] = event.value;
                onSubstancesChange(newSubstances);
              }}
            />
          </div>

          {formData.otrasDrogas.arrTipoDrogas.estatus[index] === 'abandonado' && (
            <NumberInput
              name={`otrasDrogas.arrTipoDrogas.edadTermino.${index}`}
              label="Edad de término"
              fixedValue="Año(s)"
              value={Number(formData.otrasDrogas.arrTipoDrogas.edadTermino[index]) || 0}
              setValue={(event) => {
                const newSubstances = { ...formData.otrasDrogas.arrTipoDrogas };
                newSubstances.edadTermino[index] = event.value.toString();
                onSubstancesChange(newSubstances);
              }}
            />
          )}

          {index > 0 && (
            <button
              onClick={() => handleDeleteSubstance(index)}
              type="button"
              className="absolute right-5 top-1 text-gray-400 hover:text-red-500"
              title="Eliminar"
            >
              <Trash2 size={18} />
            </button>
          )}
        </div>
      ))}

      <AddButton
        variant="enabled"
        label="Agregar otra sustancia"
        onClick={handleAddSubstance}
        // disabled={formData.otrasDrogas.arrTipoDrogas.arrTabla.length >= 10}
      />

      <TextArea
        name="otrasDrogas.comentarios"
        label="Comentarios"
        value={formData.otrasDrogas.comentarios}
        setValue={onInputChange}
      />
    </div>
  );
};

export default SubstanceForm;

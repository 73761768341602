import { actualizarCampo } from '@actions/infoUser/actions';
import { resetModal, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import { ICatalogosEstandar } from '@common/types';
import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import { PhoneInput } from '@components/FormElemntsLibrary/PhoneInput';
import { SelectInput } from '@components/FormElemntsLibrary/SelectInput';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import { ModalForm } from '@components/modals/ModalForm';
import { isOxxo } from '@constants/oxxo';
import {
  IInfoEmergencia,
  infoEmergenciaInitial,
} from '@containers/TabsPaciente/PerfilPaciente/InfoEmergencia/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { RootState } from '@reducer/index';
import { getCatalogoParentesco } from '@utils/getCatalogos';
import {
  updateInfoEmergencia,
  updateInfoEmergenciaPaciente,
  updateInfoEmergenciaUsuario,
} from '@utils/sendInfo';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

const schema = z.object({
  loaded: z.boolean(),
  desconoceGSanguineo: z.boolean(),
  tipoSanguineo: z.string(),
  factorSanguineo: z.string(),
  nombreContacto: z.string(),
  apellidosContacto: z.string(),
  parentescoContacto: z.string(),
  especifiqueParentescoContacto: z.string(),
  emailContacto: z.string(),
  ladaTel: z.string(),
  telefonoContacto: z.string(),
  tipoTelefonoContacto: z.string(),
});

type FormData = z.infer<typeof schema>;

export const InformacionEmergencia = () => {
  const [formData, setFormData] = useState<FormData>(infoEmergenciaInitial);
  const [catParentesco, setCatParentesco] = useState<Array<ICatalogosEstandar>>([]);
  const [refGrupoSanguineo, setRefGrupoSanguineo] = useState(false);

  const { infoEmergencia, cambiosInfoEmergencia, infoEmergenciaUsuario } = useSelector(
    (state: RootState) => state.InfoUser,
  );
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);

  const dispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });
  const hayCambios: boolean = idUsuario > 0 && cambiosInfoEmergencia.filter((c: string) => c.length > 0).length > 0;

  const getData = (datos: IInfoEmergencia, idUser?: number) => ({
    idMedico,
    idPaciente,
    idUsuario: idUser,
    idConsultorio,
    desconoceGSanguineo: datos.desconoceGSanguineo,
    tipoSanguineo: datos.tipoSanguineo,
    factorSanguineo: datos.factorSanguineo,
    nombreContacto: datos.nombreContacto,
    apellidosContacto: datos.apellidosContacto,
    parentescoContacto: datos.parentescoContacto,
    especifiqueParentescoContacto: datos.especifiqueParentescoContacto,
    emailContacto: datos.emailContacto,
    ladaTel: datos.ladaTel,
    telefonoContacto: datos.telefonoContacto,
    tipoTelefonoContacto: datos.tipoTelefonoContacto,
  });

  const guardarDatosExpediente = () => {
    const datosInfoEmergencia = getData(formData, idUsuario);
    const sendFunctions = [updateInfoEmergencia(datosInfoEmergencia)];

    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(updateInfoEmergenciaPaciente(datosInfoEmergencia));
    }

    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => {
            dispatch(actualizarCampo('infoEmergenciaUsuario', { ...formData, loaded: true }));
            dispatch(actualizarCampo('cambiosInfoEmergencia', { ...formData, loaded: true }));

            dispatch(resetModal('InformacionEmergencia'));
            dispatch(setActiveModal('SegurosPlanes'));
          },
        ],
      }),
    );
  };

  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateInfoEmergenciaUsuario(getData(formData, idMiUsuario)),
        successFunction: () => {
          dispatch(actualizarCampo('infoEmergencia', { ...formData, loaded: true }));
          dispatch(resetModal('InformacionEmergencia'));
          dispatch(setActiveModal('SegurosPlanes'));
        },
      }),
    );
  };

  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateInfoEmergencia(getData(infoEmergenciaUsuario)),
        successFunction: () => {
          dispatch(actualizarCampo('infoEmergencia', infoEmergenciaUsuario));
          setFormData(infoEmergenciaUsuario);
        },
      }),
    );
  };

  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateInfoEmergenciaPaciente(getData(infoEmergencia, idUsuario)),
        successFunction: () => dispatch(actualizarCampo('infoEmergenciaUsuario', infoEmergencia)),
      }),
    );
  };

  const handleInputChange = (e: { name: string; value: any }) => {
    const { name, value } = e;

    let newValue = value;

    if (name === 'parentescoContacto') {
      newValue = '1';
    }

    setFormData((prev: any) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const desconoce = event.target.checked;
    setFormData((prev) => ({
      ...prev,
      desconoceGSanguineo: desconoce,
      tipoSanguineo: desconoce ? '' : prev.tipoSanguineo,
      factorSanguineo: desconoce ? '' : prev.factorSanguineo,
    }));
    setRefGrupoSanguineo(desconoce);
  };

  const handleSubmitForm = () => {
    if (esPaciente) {
      guardarDatosUsuario();
    } else {
      guardarDatosExpediente();
    }
  };

  useEffect(() => {
    getCatalogoParentesco().then((result: ICatalogosEstandar[]) => {
      setCatParentesco(result);
    });
  }, []);

  useEffect(() => {
    setFormData({...infoEmergencia, parentescoContacto: String(infoEmergencia.parentescoContacto)});
    setRefGrupoSanguineo(infoEmergencia.desconoceGSanguineo);
  }, [infoEmergencia]);
  useEffect(() => {
    reset(formData);
  }, [formData]);  

  return (
    <ModalForm
      currentStep={4}
      title="Información de emergencia"
      id="InformacionEmergencia"
      submitForm={handleSubmit(handleSubmitForm)}
      // hayCambios={hayCambios}
      aceptarCambios={guardarDatosDeUsuarioAExpediente}
      rechazarCambios={guardarDatosDeExpedienteAUsuario}
    >
      <div>
        {/* Grupo sanguíneo */}
        <div>
          <h3 className="text-lg font-semibold px-4 mb-4">Grupo sanguíneo</h3>
          <div className="grid grid-cols-1 md:grid-cols-3">
            <CheckboxInput
              name="tipoSanguineo"
              label="Tipo"
              options={[
                { value: 'A', label: 'A' },
                { value: 'B', label: 'B' },
                { value: 'AB', label: 'AB' },
                { value: 'O', label: 'O' },
              ]}
              allowMultiple={false}
              distribution="vertical-flex"
              value={formData.tipoSanguineo}
              setValue={handleInputChange}
              isDisabled={refGrupoSanguineo}
              errorMessage={errors.tipoSanguineo?.message}
            />
            <CheckboxInput
              name="factorSanguineo"
              label="Factor Rh"
              options={[
                { value: 'POSITIVO', label: '+' },
                { value: 'NEGATIVO', label: '-' },
              ]}
              allowMultiple={false}
              distribution="vertical-flex"
              value={formData.factorSanguineo}
              setValue={handleInputChange}
              isDisabled={refGrupoSanguineo}
              errorMessage={errors.factorSanguineo?.message}
            />
            <label
              htmlFor="desconoce"
              className="flex items-center text-gray-600 whitespace-nowrap ml-4 mb-4 md:mb-0"
            >
              <input
                id="desconoce"
                type="checkbox"
                className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
                checked={formData.desconoceGSanguineo}
                onChange={handleCheckboxChange}
              />
              Desconoce su tipo sanguineo
            </label>
          </div>
        </div>

        {/* Contacto de emergencia */}
        {!isOxxo && (
          <div>
            <h3 className="text-lg font-semibold mb-4 px-4">Contacto de emergencia</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <TextInput
                label="Nombre(s)"
                name="nombreContacto"
                value={formData.nombreContacto}
                setValue={handleInputChange}
                errorMessage={errors.nombreContacto?.message}
              />
              <TextInput
                label="Apellido(s)"
                name="apellidosContacto"
                value={formData.apellidosContacto}
                setValue={handleInputChange}
                errorMessage={errors.apellidosContacto?.message}
              />
              <SelectInput
                label="Parentesco"
                name="parentescoContacto"
                options={catParentesco}
                placeholder="Seleccione"
                value={formData.parentescoContacto}
                setValue={handleInputChange}
                errorMessage={errors.parentescoContacto?.message}
              />
              <TextInput
                label="Correo electrónico"
                name="emailContacto"
                value={formData.emailContacto}
                setValue={handleInputChange}
                errorMessage={errors.emailContacto?.message}
              />
              <PhoneInput
                label="Teléfono"
                name="telefonoContacto"
                value={{ codigo: formData.ladaTel, numero: formData.telefonoContacto }}
                setValue={handleInputChange}
                errorMessage={errors.telefonoContacto?.message}
              />
            </div>
          </div>
        )}
      </div>
    </ModalForm>
  );
};

export default InformacionEmergencia;

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { setRutaAntNoPatologicos, setRutaPrincipal } from '@actions/rutasExpediente/actions';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { RootState } from 'src/store';
import {
  antecedentesNoPatologicosGuardarAlimentacion,
  antecedentesNoPatologicosUsuarioPacienteGuardarAlimentacion,
  antecedentesNoPatologicosUsuarioGuardarAlimentacion,
} from '@utils/sendInfo';
import { setRequest } from '@actions/request/types';
import { setAlimentacion, setAlimentacionUsuario } from '@actions/paciente/actions';
import useStyles from './styles';
import AlimentosComponente from './Alimentos/index';
import { IAlimentacion, alimentacionInitial } from './types';
import { ModalForm } from '@components/modals/ModalForm';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import ScaleInput from '@components/FormElemntsLibrary/ScaleInput';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';

function Alimentacion() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const { isOxxo } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    expediente: {
      antNoPatologicos: { alimentacion },
    },
    usuario: {
      antNoPatologicos: { alimentacion: alimentacionUsuario },
    },
    cambios: {
      antNoPatologicos: { alimentacion: cambios },
    },
  } = useSelector((state: RootState) => state.Paciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [alimentacionTemp, setAlimentacionTemp] = useState<IAlimentacion>(alimentacionInitial);

  const hayCambios: boolean = idUsuario > 0 && cambios.some((c: string) => c.length > 0);
  const frecuencias = isOxxo
    ? ['carneAlt', 'arrTorFriPas', 'dulPapPast']
    : ['comidas-f', 'carne', 'pollo', 'pescado', 'refrescos', 'postres'];
  const frecuencia: { tipo: string; veces: number | null }[] = [
    { tipo: 'comidas-f', veces: alimentacionTemp.comidas },
    { tipo: 'carne', veces: alimentacionTemp.carne },
    { tipo: 'carneAlt', veces: alimentacionTemp.carneAlt },
    { tipo: 'pollo', veces: alimentacionTemp.pollo },
    { tipo: 'pescado', veces: alimentacionTemp.pescado },
    { tipo: 'refrescos', veces: alimentacionTemp.refrescos },
    { tipo: 'postres', veces: alimentacionTemp.postres },
    { tipo: 'arrTorFriPas', veces: alimentacionTemp.arrTorFriPas },
    { tipo: 'dulPapPast', veces: alimentacionTemp.dulPapPast },
  ].filter(frec => frecuencias.includes(frec.tipo));
  const alimentoInitial = { alimento: '', especifica: '' };

  const handleInputChange = (e: { name: string; value: any }) =>
    setAlimentacionTemp((prevData) => ({ ...prevData, [e.name]: e.value }));

  const handleAlimentoChange = (e: { name: string; value: any, index: number }) => {
    setAlimentacionTemp((prevData) => ({
      ...prevData,
      alimentos: prevData.alimentos.map((alimento, indx) => indx === e.index
      ? { ...alimento, [e.name]: e.value }
      : alimento
    ),
    }));
  };

  const addAlimentos = () => {
    setAlimentacionTemp({
      ...alimentacionTemp,
      alimentos: [
        ...alimentacionTemp.alimentos,
        alimentoInitial,
      ],
    });
  };

  const eliminarAlimentos = (index: number) => {
    if (alimentacionTemp.alimentos.length === 1) {
      setAlimentacionTemp(prev => ({ ...prev, alimentos: [alimentoInitial] }));
    } else {
      setAlimentacionTemp(prev => ({
        ...prev,
        alimentos: prev.alimentos.filter((al, indx) => indx !== index),
      }));
    }
  };

  const getDatosAlimentacion = (datos: IAlimentacion) => ({
    dieta: datos.dieta,
    alimentos: datos.alimentos,
    botonComidas: datos.botonComidas || null,
    botonFruta: datos.botonFruta || null,
    botonVegetales: datos.botonVegetales || null,
    comidas: datos.comidas || null,
    carne: datos.carne || null,
    carneAlt: datos.carneAlt || null,
    pollo: datos.pollo || null,
    pescado: datos.pescado || null,
    refrescos: datos.refrescos || null,
    postres: datos.postres || null,
    arrTorFriPas: datos.arrTorFriPas || null,
    dulPapPast: datos.dulPapPast || null,
    comentarios: datos.comentarios,
  });
  const getDatosExpediente = (datos: IAlimentacion) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    alimentacion: getDatosAlimentacion(datos),
  });
  const getDatosUsuario = (datos: IAlimentacion) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    alimentacion: getDatosAlimentacion(datos),
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(alimentacionTemp);
    const sendFunctions = [antecedentesNoPatologicosGuardarAlimentacion(datosToSend)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(antecedentesNoPatologicosUsuarioPacienteGuardarAlimentacion(datosToSend));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => dispatch(setAlimentacion({ ...alimentacionTemp, loaded: true })),
          () => dispatch(setAlimentacionUsuario({ ...alimentacionTemp, loaded: true })),
        ],
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioGuardarAlimentacion(
          getDatosUsuario(alimentacionTemp),
        ),
        successFunction: () => {
          dispatch(setAlimentacion({ ...alimentacionTemp, loaded: true }));
          dispatch(isOxxo
            ? setRutaAntNoPatologicos('toxicomania')
            : setRutaAntNoPatologicos('actividad-fisica')
          );
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosGuardarAlimentacion(
          getDatosExpediente(alimentacionUsuario),
        ),
        successFunction: () => {
          dispatch(setAlimentacion(alimentacionUsuario));
          setAlimentacionTemp(alimentacionUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarAlimentacion(
          getDatosExpediente(alimentacion),
        ),
        successFunction: () => dispatch(setAlimentacionUsuario(alimentacion)),
      }),
    );
  };

  useEffect(() => {
    if (alimentacion.loaded && !alimentacionTemp.loaded) {
      setAlimentacionTemp(alimentacion);
    }
  }, [alimentacion]);
  useEffect(() => {
    if (alimentacionTemp.dieta === 'F') {
      setAlimentacionTemp(prev => ({ ...prev, alimentos: [alimentoInitial] }));
    }
  }, [alimentacionTemp.dieta]);

  return (
    <ModalForm
      title={t('antecedentes-pnp-alimentacion')}
      id="antecedentes-pnp-alimentacion-form"
      aceptarCambios={guardarDatosDeUsuarioAExpediente}
      rechazarCambios={guardarDatosDeExpedienteAUsuario}
      submitForm={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
      hayCambios={hayCambios}
      cambios={cambios}
      backAction={() => dispatch(setRutaPrincipal('main'))}
    >
      <div className="flex flex-col">
        {!isOxxo &&
          <>
            <CheckboxGroup
              name="dieta"
              label={t('antecedentes-pnp-alimentacion-restriccion')}
              options={[
                { value: 'T', label: t('si') },
                { value: 'F', label: t('no') },
              ]}
              distribution="vertical-flex"
              value={alimentacionTemp.dieta}
              setValue={handleInputChange}
              disabled={hayCambios}
            />
            {alimentacionTemp.dieta === 'T'
              ? <>
                {alimentacionTemp.alimentos.map((val, index) => (
                  <AlimentosComponente
                    {...val}
                    index={index}
                    eliminar={eliminarAlimentos}
                    handleChange={handleAlimentoChange}
                    hayCambios={hayCambios}
                  />
                ))}
                <div className="flex justify-end mb-4">
                  <Button
                    color="primary"
                    size="small"
                    onClick={addAlimentos}
                    className={classes.noTextTranform}
                    startIcon={<AddCircleOutlineIcon />}
                    disabled={hayCambios}
                  >
                    {t('antecedentes-pnp-alimentacion-aalimento')}
                  </Button>
                </div>
              </> : null
            }
          </>
        }
        <div className={`grid grid-cols-1 md:grid-cols-${isOxxo ? '2' : '3'} md:gap-2 md:mt-2`}>
          <ScaleInput
            name="botonComidas"
            label={t('antecedentes-pnp-alimentacion-comidas-d')}
            options={[...Array(7)].map((x, i) => ({
              value: i + 1,
              label: `${i + 1}${i < 6 ? '' : '+'}`,
            }))}
            value={alimentacionTemp.botonComidas || undefined}
            setValue={handleInputChange}
            disabled={hayCambios}
          />
          {!isOxxo &&
            <ScaleInput
              name="botonFruta"
              label={t('antecedentes-pnp-alimentacion-fruta')}
              options={[...Array(5)].map((x, i) => ({
                value: i + 1,
                label: `${i === 0 ? '≤' : ''}${i + 1}${i < 4 ? '' : '+'}`,
              }))}
              value={alimentacionTemp.botonFruta || undefined}
              setValue={handleInputChange}
              disabled={hayCambios}
            />
          }
          <ScaleInput
            name="botonVegetales"
            label={t('antecedentes-pnp-alimentacion-vegetales')}
            options={[...Array(5)].map((x, i) => ({
              value: i + 1,
              label: `${i === 0 ? '≤' : ''}${i + 1}${i < 4 ? '' : '+'}`,
            }))}
            value={alimentacionTemp.botonVegetales || undefined}
            setValue={handleInputChange}
            disabled={hayCambios}
          />
        </div>
      </div>
      <h2 className="px-4 text-lg font-medium my-4">{t('antecedentes-pnp-alimentacion-recurrencia')}</h2>
      <div className="grid grid-cols-2 md:grid-cols-4">
        {frecuencia.map((consumo) => (
          <NumberInput
            name={consumo.tipo}
            label={t(`antecedentes-pnp-alimentacion-${consumo.tipo}`)}
            fixedValue={t('antecedentes-pnp-alimentacion-veces')}
            value={consumo.veces}
            isInteger
            setValue={handleInputChange}
            disabled={hayCambios}
          />
        ))}
      </div>
      {!esPaciente && (
        <TextArea
          name="comentarios"
          value={alimentacionTemp.comentarios}
          setValue={handleInputChange}
          maxLength={500}
          disabled={hayCambios}
        />
      )}
   </ModalForm>
  );
}

export default Alimentacion;

import { ICatalogosEstandar } from '@common/types';
import { IFamiliar } from '@containers/TabsPaciente/Antecedentes/Familiares/types';
import {
  IAntecedenteLaboral,
  IObjLaboral,
} from '@containers/TabsPaciente/Antecedentes/Laborales/types';
import { IActividadFisica } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/ActividadFisica/types';
import { IAlimentacion } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Alimentacion/types';
import { IGinecoObstetrico } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/GinecoObstetricos/types';
import { IHigiene } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Higiene/types';
import { IHistoriaSexual } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/HistoriaSexual/types';
import { IInmunizacion } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Inmunizaciones/types';
import { IToxicomania } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Toxicomania/types';
import { IVivienda } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Vivienda/types';
import { IAlergia } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/Alergias/types';
import { IDiagnosticosGenerales } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/DiagnosticosGenerales/types';
import {
  IEnfermedad,
  IEnfermedadNegada,
  IGrupoEnfermedadesNegadas,
} from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/EnfermedadesTratamientos/Enfermedades/types';
import { IHospitalizacion } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/Hospitalizacion/types';
import { IProcedimiento } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/Procedimiento/types';
import { ITransfusion } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/Transfusiones/types';
import { IAparatosYSistemas } from '@containers/TabsPaciente/AparatosYSistemas/types';
import { ILaboratorioSolicitud } from '@containers/TabsPaciente/DiagnosticoPlan/Plan/Laboratorio/types';
import { ISolicitudProcedimientos } from '@containers/TabsPaciente/DiagnosticoPlan/Plan/Procedimientos/types';
import { IPronostico } from '@containers/TabsPaciente/DiagnosticoPlan/Plan/Pronostico/types';
import {
  IListaRecetas,
  IMedicamento,
} from '@containers/TabsPaciente/DiagnosticoPlan/Plan/TerapiaFarmacologica/types';
import { IExploracionFisica } from '@containers/TabsPaciente/ExploracionFisica/types';
import { IDatosIncapacidad } from '@containers/TabsPaciente/Incapacidades/types';
import { IMcpa } from '@containers/TabsPaciente/MCPA/types';
import { IDatosUbicacion } from '@containers/TabsPaciente/PerfilPaciente/DatosDeUbicacion/types';
import { IDatosPersonales } from '@containers/TabsPaciente/PerfilPaciente/DatosPersonales/types';
import { IDiscapacidades } from '@containers/TabsPaciente/PerfilPaciente/Discapacidades/types';
import { IInfoEmergencia } from '@containers/TabsPaciente/PerfilPaciente/InfoEmergencia/types';
import { ISegurosPlanes } from '@containers/TabsPaciente/PerfilPaciente/SegurosPlanes/types';
import {
  SET_DATOS_EXPEDIENTE,
  SET_DATOS_USUARIO,
  SET_DATOS_PERSONALES,
  SET_DATOS_UBICACION,
  SET_DISCAPACIDADES,
  SET_INFO_EMERGENCIA,
  SET_SEGUROS_PLANES,
  SET_MCPA,
  SET_ENF_REFERIDAS,
  SET_ENF_NEGADAS,
  SET_GRUPO_ENF_NEGADAS,
  SET_CAT_DIAGNOSTICO,
  SET_DIAGNOSTICOS_GENERALES,
  SET_TRATAMIENTOS,
  SET_RECETAS,
  SET_SIN_HOSPITALIZACIONES,
  SET_HOSPITALIZACIONES,
  SET_SIN_ALERGIAS,
  SET_ALERGIAS,
  SET_SIN_TRANSFUSIONES,
  SET_TRANSFUSIONES,
  SET_EN_GESTACION,
  SET_VIVIENDA,
  SET_HIGIENE,
  SET_ALIMENTACION,
  SET_ACTIVIDAD_FISICA,
  SET_INMUNIZACIONES,
  SET_TOXICOMANIA,
  SET_GINECO_OBSTETRICO,
  SET_HISTORIA_SEXUAL,
  SET_SIN_PROC_QUIRURGICOS,
  SET_PROC_DIAGNOSTICOS,
  SET_PROC_TERAPEUTICOS,
  SET_PROC_QUIRURGICOS,
  SET_SOL_PROC_DIAGNOSTICOS,
  SET_SOL_PROC_TERAPEUTICOS,
  SET_SOL_PROC_QUIRURGICOS,
  SET_FAMILIARES,
  SET_OBJ_LABORAL,
  SET_ANTECEDENTES_LABORALES,
  SET_ACCIDENTES_LABORALES,
  SET_APARATOS_Y_SISTEMAS,
  SET_EXPLORACION_FISICA,
  SET_LABORATORIO_SOLICITUD,
  SET_PRONOSTICOS,
  SET_INCAPACIDADES,
  SET_DATOS_EXPEDIENTE_USUARIO,
  SET_DATOS_PERSONALES_USUARIO,
  SET_DATOS_UBICACION_USUARIO,
  SET_DISCAPACIDADES_USUARIO,
  SET_INFO_EMERGENCIA_USUARIO,
  SET_SEGUROS_PLANES_USUARIO,
  SET_DIAGNOSTICOS_GENERALES_USUARIO,
  SET_EN_GESTACION_USUARIO,
  SET_VIVIENDA_USUARIO,
  SET_HIGIENE_USUARIO,
  SET_ALIMENTACION_USUARIO,
  SET_ACTIVIDAD_FISICA_USUARIO,
  SET_TOXICOMANIA_USUARIO,
  SET_GINECO_OBSTETRICO_USUARIO,
  SET_CAMBIOS_DATOS_PACIENTE,
  RESET_DATOS_PACIENTE,
  PacienteActionTypes,
  IExpediente,
  IUsuarioPaciente,
  ICambios,
} from './types';

export function setDatosExpediente(datos: IExpediente): PacienteActionTypes {
  return {
    type: SET_DATOS_EXPEDIENTE,
    payload: datos,
  };
}

export function setDatosUsuario(datos: IExpediente): PacienteActionTypes {
  return {
    type: SET_DATOS_USUARIO,
    payload: datos,
  };
}

export function setDatosPersonales(datosPersonales: IDatosPersonales): PacienteActionTypes {
  return {
    type: SET_DATOS_PERSONALES,
    payload: datosPersonales,
  };
}

export function setDatosUbicacion(datosUbicacion: IDatosUbicacion): PacienteActionTypes {
  return {
    type: SET_DATOS_UBICACION,
    payload: datosUbicacion,
  };
}

export function setDiscapacidades(discapacidades: IDiscapacidades): PacienteActionTypes {
  return {
    type: SET_DISCAPACIDADES,
    payload: discapacidades,
  };
}

export function setInfoEmergencia(infoEmergencia: IInfoEmergencia): PacienteActionTypes {
  return {
    type: SET_INFO_EMERGENCIA,
    payload: infoEmergencia,
  };
}

export function setSegurosPlanes(segurosPlanes: ISegurosPlanes): PacienteActionTypes {
  return {
    type: SET_SEGUROS_PLANES,
    payload: segurosPlanes,
  };
}

export function setMcpa(mcpa: IMcpa): PacienteActionTypes {
  return {
    type: SET_MCPA,
    payload: mcpa,
  };
}

export function setEnfReferidas(enfReferidas: IEnfermedad[]): PacienteActionTypes {
  return {
    type: SET_ENF_REFERIDAS,
    payload: enfReferidas,
  };
}

export function setEnfNegadas(enfNegadas: IEnfermedadNegada[]): PacienteActionTypes {
  return {
    type: SET_ENF_NEGADAS,
    payload: enfNegadas,
  };
}

export function setGrupoEnfNegada(grupoEnfNegada: IGrupoEnfermedadesNegadas): PacienteActionTypes {
  return {
    type: SET_GRUPO_ENF_NEGADAS,
    payload: grupoEnfNegada,
  };
}

export function setCatDiagnostico(catDiagnostico: ICatalogosEstandar[]): PacienteActionTypes {
  return {
    type: SET_CAT_DIAGNOSTICO,
    payload: catDiagnostico,
  };
}

export function setDiagnosticosGenerales(
  diagnosticosGenerales: IDiagnosticosGenerales,
): PacienteActionTypes {
  return {
    type: SET_DIAGNOSTICOS_GENERALES,
    payload: diagnosticosGenerales,
  };
}

export function setTratamientos(tratamientos: IMedicamento[]): PacienteActionTypes {
  return {
    type: SET_TRATAMIENTOS,
    payload: tratamientos,
  };
}

export function setRecetas(recetas: IListaRecetas[]): PacienteActionTypes {
  return {
    type: SET_RECETAS,
    payload: recetas,
  };
}

export function setSinHospitalizaciones(sinHospitalizaciones: boolean): PacienteActionTypes {
  return {
    type: SET_SIN_HOSPITALIZACIONES,
    payload: sinHospitalizaciones,
  };
}

export function setHospitalizaciones(hospitalizaciones: IHospitalizacion[]): PacienteActionTypes {
  return {
    type: SET_HOSPITALIZACIONES,
    payload: hospitalizaciones,
  };
}

export function setSinAlergias(sinAlergias: boolean): PacienteActionTypes {
  return {
    type: SET_SIN_ALERGIAS,
    payload: sinAlergias,
  };
}

export function setAlergias(alergias: IAlergia[]): PacienteActionTypes {
  return {
    type: SET_ALERGIAS,
    payload: alergias,
  };
}

export function setSinTransfusiones(sinTransfusiones: boolean): PacienteActionTypes {
  return {
    type: SET_SIN_TRANSFUSIONES,
    payload: sinTransfusiones,
  };
}

export function setTransfusiones(transfusiones: ITransfusion[]): PacienteActionTypes {
  return {
    type: SET_TRANSFUSIONES,
    payload: transfusiones,
  };
}

export function setEnGestacion(enGestacion: 'si' | 'no' | ''): PacienteActionTypes {
  return {
    type: SET_EN_GESTACION,
    payload: enGestacion,
  };
}

export function setVivienda(vivienda: IVivienda): PacienteActionTypes {
  return {
    type: SET_VIVIENDA,
    payload: vivienda,
  };
}

export function setHigiene(higiene: IHigiene): PacienteActionTypes {
  return {
    type: SET_HIGIENE,
    payload: higiene,
  };
}

export function setAlimentacion(alimentacion: IAlimentacion): PacienteActionTypes {
  return {
    type: SET_ALIMENTACION,
    payload: alimentacion,
  };
}

export function setActividadFisica(actividadFisica: IActividadFisica): PacienteActionTypes {
  return {
    type: SET_ACTIVIDAD_FISICA,
    payload: actividadFisica,
  };
}

export function setInmunizaciones(inmunizaciones: IInmunizacion[]): PacienteActionTypes {
  return {
    type: SET_INMUNIZACIONES,
    payload: inmunizaciones,
  };
}

export function setToxicomania(toxicomania: IToxicomania): PacienteActionTypes {
  return {
    type: SET_TOXICOMANIA,
    payload: toxicomania,
  };
}

export function setGinecoObstetrico(ginecoObstetrico: IGinecoObstetrico): PacienteActionTypes {
  return {
    type: SET_GINECO_OBSTETRICO,
    payload: ginecoObstetrico,
  };
}

export function setHistoriaSexual(historiaSexual: IHistoriaSexual): PacienteActionTypes {
  return {
    type: SET_HISTORIA_SEXUAL,
    payload: historiaSexual,
  };
}

export function setSinProcQuirurgicos(sinProcQuirurgicos: boolean): PacienteActionTypes {
  return {
    type: SET_SIN_PROC_QUIRURGICOS,
    payload: sinProcQuirurgicos,
  };
}

export function setProcDiagnosticos(procDiagnosticos: IProcedimiento[]): PacienteActionTypes {
  return {
    type: SET_PROC_DIAGNOSTICOS,
    payload: procDiagnosticos,
  };
}

export function setProcTerapeuticos(procTerapeuticos: IProcedimiento[]): PacienteActionTypes {
  return {
    type: SET_PROC_TERAPEUTICOS,
    payload: procTerapeuticos,
  };
}

export function setProcQuirurgicos(procQuirurgicos: IProcedimiento[]): PacienteActionTypes {
  return {
    type: SET_PROC_QUIRURGICOS,
    payload: procQuirurgicos,
  };
}

export function setSolProcDiagnosticos(
  solProcDiagnosticos: ISolicitudProcedimientos[],
): PacienteActionTypes {
  return {
    type: SET_SOL_PROC_DIAGNOSTICOS,
    payload: solProcDiagnosticos,
  };
}

export function setSolProcTerapeuticos(
  solProcTerapeuticos: ISolicitudProcedimientos[],
): PacienteActionTypes {
  return {
    type: SET_SOL_PROC_TERAPEUTICOS,
    payload: solProcTerapeuticos,
  };
}

export function setSolProcQuirurgicos(
  solProcQuirurgicos: ISolicitudProcedimientos[],
): PacienteActionTypes {
  return {
    type: SET_SOL_PROC_QUIRURGICOS,
    payload: solProcQuirurgicos,
  };
}

export function setFamiliares(familiares: IFamiliar[]): PacienteActionTypes {
  return {
    type: SET_FAMILIARES,
    payload: familiares,
  };
}

export function setObjLaboral(objLaboral: IObjLaboral): PacienteActionTypes {
  return {
    type: SET_OBJ_LABORAL,
    payload: objLaboral,
  };
}

export function setAntecedentesLaborales(
  antecedentesLaborales: IAntecedenteLaboral[],
): PacienteActionTypes {
  return {
    type: SET_ANTECEDENTES_LABORALES,
    payload: antecedentesLaborales,
  };
}

export function setAccidentesLaborales(
  accidentesLaborales: ICatalogosEstandar[],
): PacienteActionTypes {
  return {
    type: SET_ACCIDENTES_LABORALES,
    payload: accidentesLaborales,
  };
}

export function setAparatosYSistemas(aparatosYSistemas: IAparatosYSistemas): PacienteActionTypes {
  return {
    type: SET_APARATOS_Y_SISTEMAS,
    payload: aparatosYSistemas,
  };
}

export function setExploracionFisica(exploracionFisica: IExploracionFisica): PacienteActionTypes {
  return {
    type: SET_EXPLORACION_FISICA,
    payload: exploracionFisica,
  };
}

export function setLaboratorioSolicitud(
  laboratorioSolicitud: ILaboratorioSolicitud[],
): PacienteActionTypes {
  return {
    type: SET_LABORATORIO_SOLICITUD,
    payload: laboratorioSolicitud,
  };
}

export function setPronosticos(pronosticos: IPronostico[]): PacienteActionTypes {
  return {
    type: SET_PRONOSTICOS,
    payload: pronosticos,
  };
}

export function setIncapacidades(incapacidades: IDatosIncapacidad[]): PacienteActionTypes {
  return {
    type: SET_INCAPACIDADES,
    payload: incapacidades,
  };
}

export function setDatosExpedienteUsuario(datos: IUsuarioPaciente): PacienteActionTypes {
  return {
    type: SET_DATOS_EXPEDIENTE_USUARIO,
    payload: datos,
  };
}

export function setDatosPersonalesUsuario(
  datosPersonalesUsuario: IDatosPersonales,
): PacienteActionTypes {
  return {
    type: SET_DATOS_PERSONALES_USUARIO,
    payload: datosPersonalesUsuario,
  };
}

export function setDatosUbicacionUsuario(
  datosUbicacionUsuario: IDatosUbicacion,
): PacienteActionTypes {
  return {
    type: SET_DATOS_UBICACION_USUARIO,
    payload: datosUbicacionUsuario,
  };
}

export function setDiscapacidadesUsuario(
  discapacidadesUsuario: IDiscapacidades,
): PacienteActionTypes {
  return {
    type: SET_DISCAPACIDADES_USUARIO,
    payload: discapacidadesUsuario,
  };
}

export function setInfoEmergenciaUsuario(
  infoEmergenciaUsuario: IInfoEmergencia,
): PacienteActionTypes {
  return {
    type: SET_INFO_EMERGENCIA_USUARIO,
    payload: infoEmergenciaUsuario,
  };
}

export function setSegurosPlanesUsuario(segurosPlanesUsuario: ISegurosPlanes): PacienteActionTypes {
  return {
    type: SET_SEGUROS_PLANES_USUARIO,
    payload: segurosPlanesUsuario,
  };
}

export function setDiagnosticosGeneralesUsuario(
  diagnosticosGenerales: IDiagnosticosGenerales,
): PacienteActionTypes {
  return {
    type: SET_DIAGNOSTICOS_GENERALES_USUARIO,
    payload: diagnosticosGenerales,
  };
}

export function setEnGestacionUsuario(enGestacionUsuario: 'si' | 'no' | ''): PacienteActionTypes {
  return {
    type: SET_EN_GESTACION_USUARIO,
    payload: enGestacionUsuario,
  };
}

export function setViviendaUsuario(viviendaUsuario: IVivienda): PacienteActionTypes {
  return {
    type: SET_VIVIENDA_USUARIO,
    payload: viviendaUsuario,
  };
}

export function setHigieneUsuario(higieneUsuario: IHigiene): PacienteActionTypes {
  return {
    type: SET_HIGIENE_USUARIO,
    payload: higieneUsuario,
  };
}

export function setAlimentacionUsuario(alimentacionUsuario: IAlimentacion): PacienteActionTypes {
  return {
    type: SET_ALIMENTACION_USUARIO,
    payload: alimentacionUsuario,
  };
}

export function setActividadFisicaUsuario(
  actividadFisicaUsuario: IActividadFisica,
): PacienteActionTypes {
  return {
    type: SET_ACTIVIDAD_FISICA_USUARIO,
    payload: actividadFisicaUsuario,
  };
}

export function setToxicomaniaUsuario(toxicomaniaUsuario: IToxicomania): PacienteActionTypes {
  return {
    type: SET_TOXICOMANIA_USUARIO,
    payload: toxicomaniaUsuario,
  };
}

export function setGinecoObstetricoUsuario(
  ginecoObstetricoUsuario: IGinecoObstetrico,
): PacienteActionTypes {
  return {
    type: SET_GINECO_OBSTETRICO_USUARIO,
    payload: ginecoObstetricoUsuario,
  };
}

export function setCambiosDatosPaciente(cambios: ICambios): PacienteActionTypes {
  return {
    type: SET_CAMBIOS_DATOS_PACIENTE,
    payload: cambios,
  };
}

export function resetDatosPaciente(): PacienteActionTypes {
  return {
    type: RESET_DATOS_PACIENTE,
  };
}

/* eslint-disable */

import { initialDiabetesForm, initialHipertensionForm } from '@constants/prueba/initialForms';
import { DiabetesForm } from './MiSalud/Diabetes/type';
import { HipertensionForm } from './MiSalud/Hipertension/type';
import { initialMascotasForm, MascotasForm } from './EstiloDeVida/Mascotas/type';

export interface HealthDataSubModals {
  diabetes: DiabetesForm;
  hipertension: HipertensionForm;
  mascotas: MascotasForm;
}

export const initialHealthDataSubModals = {
  diabetes: initialDiabetesForm,
  hipertension: initialHipertensionForm,
  mascotas: initialMascotasForm,
};

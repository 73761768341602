import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, IconButton, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import ComentariosComponente from '@components/Comentarios';
// import { useSelector, useDispatch } from 'react-redux';
// import { setLoading } from '@actions/loading/actions';
// import { RootState } from 'src/store';
// import { setSnackComplete } from '@actions/snackbar/types';
import { IObjCampania } from '../types';
import { IGestionProps } from './types';

const Gestion = (props: IGestionProps) => {
  const {
    // tipoPacientes,
    listaCampanias,
    setListaCampanias,
  } = props;
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const abortController = new AbortController();
  // const { signal } = abortController;
  // const { idMedico } = useSelector((state: RootState) => state.Me);
  // const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  // const { consultorios } = useSelector((state: RootState) => state.Consultorios);
  const [campaniaNombre, setCampaniaNombre] = useState<string>('');
  const [correoAsunto, setCorreoAsunto] = useState<string>('');
  const [correoCuerpo, setCorreoCuerpo] = useState<RawDraftContentState>({
    entityMap: {},
    blocks: [],
  });
  const [documento, setDocumento] = useState<string>('');

  return (
    <>
      <div className="md:p-4 bg-white md:container mx-auto shadow rounded-b-md border-solid border border-gray-100">
        <h1 className="text-blue-800 font-normal m-0 text-center pb-4">{t('business_campania')}</h1>
        <div className="mb-2">
          <h4 className="text-gray-500 font-normal mb-2">
            {`${t('business_campanias_nombre_campania')} *`}
          </h4>
          <TextField
            variant="outlined"
            onChange={(e) => setCampaniaNombre(e.target.value as string)}
            value={campaniaNombre}
            // error={error}
            // helperText={helperText}
            // FormHelperTextProps={{ className: classes.absoluteBottom }}
            fullWidth
            inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
          />
        </div>
        <div className="mt-2">
          <h4 className="text-gray-500 font-normal mb-2">
            {`${t('business_campanias_asunto_correo')} *`}
          </h4>
          <TextField
            variant="outlined"
            onChange={(e) => setCorreoAsunto(e.target.value as string)}
            value={correoAsunto}
            // error={error}
            // helperText={helperText}
            // FormHelperTextProps={{ className: classes.absoluteBottom }}
            fullWidth
            inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
          />
        </div>
        <div className="mt-2">
          <div className="flex justify-between items-center text-gray-500">
            <h4 className="text-gray-500 font-normal mb-2">
              {`${t('business_campanias_cuerpo_correo')}`}
            </h4>
            <div>
              <label htmlFor="btn-upload-file">
                <input
                  id="btn-upload-file"
                  name="btn-upload-file"
                  style={{ display: 'none' }}
                  type="file"
                  accept=".csv, .odt, .ods, odp, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                  onChange={(e) =>
                    setDocumento(
                      e.target.files && e.target.files.length ? e.target.files[0].name : '',
                    )}
                />
                <Button component="span" color="primary" startIcon={<CloudUploadIcon />}>
                  {t('cargar_archivo')}
                </Button>
              </label>
            </div>
          </div>
          <ComentariosComponente
            comentarios={correoCuerpo}
            setComentarios={(data: RawDraftContentState) => setCorreoCuerpo(data)}
            rows={4}
            maxLength={1000}
            placeholder={t('business_campanias_cuerpo_correo')}
            lang="es"
            disabled={false}
            // key={}
          />
        </div>
        <div className="text-right pt-4">
          <Button
            disabled={!campaniaNombre || !correoAsunto}
            variant="contained"
            color="primary"
            onClick={() => {
              setListaCampanias([
                ...listaCampanias,
                {
                  campaniaId: listaCampanias[listaCampanias.length - 1].campaniaId + 1,
                  campaniaNombre,
                  correoAsunto,
                  correoCuerpo,
                  documento,
                },
              ]);
              setCampaniaNombre('');
              setCorreoAsunto('');
              setCorreoCuerpo({ entityMap: {}, blocks: [] });
              setDocumento('');
            }}
          >
            {t('guardar')}
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <h2 className="text-blue-800 font-normal my-4">{t('business_campanias_lista')}</h2>
        {listaCampanias.map((campania: IObjCampania) => (
          <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
            <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
              <h3 className="font-normal m-0">
                <span className="text-gray-500">{campania.campaniaNombre}</span>
                {campania.documento && (
                  <>
                    <span className="text-gray-500"> - </span>
                    <span className="text-blue-700">{campania.documento}</span>
                  </>
                )}
              </h3>
              <p className="text-gray-500 mb-0">
                {`${t('business_campanias_asunto')}: ${campania.correoAsunto}`}
              </p>
            </div>
            <div className="min-w-max self-center">
              <IconButton aria-label="edit" color="primary" onClick={() => {}}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" color="primary" onClick={() => {}}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Gestion;

/** @format */

export const HEALTH_FORM_CONFIG = {
  progressSteps: [
    {
      status: 'completed',
      title: 'Enfermedades del corazón y crónicas',
    },
    {
      status: 'current',
      title: 'Problemas nerviosos y dolor',
    },
    {
      status: 'default',
      title: 'Problemas respiratorios y corporales',
    },
  ],
  checkboxGroups: {
    conditions: {
      type: 'Checkbox',
      distribution: 'horizontal-inline',
      allowMultiple: false,
      fields: [
        {
          id: 'problemas_corazon',
          name: 'problemas_corazon',
          label: 'Problemas del corazón (infarto, miocardiopatía, arritmia, soplos)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'embolia',
          name: 'embolia',
          label: 'Embolia, derrames',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'Hipertension',
          name: 'hipertension',
          label: 'Hipertensión (presión alta)',
          options: [
            { value: 'Si', label: 'Si', modal: 'Hipertension' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'Diabetes',
          name: 'diabetes',
          label: 'Diabetes (azúcar alta)',
          options: [
            { value: 'Si', label: 'Si', modal: 'Diabetes' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'problemas_rinones',
          name: 'problemas_rinones',
          label: 'Problemas en los riñones (diálisis)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'hepatitis',
          name: 'hepatitis',
          label: 'Hepatitis u otras enfermedades del hígado (cirrosis o hígado graso)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'tumores',
          name: 'tumores',
          label: 'Tumores o cáncer (leucemia, mama, matriz)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'trastornos_inmunes',
          name: 'trastornos_inmunes',
          label: 'Trastornos del sistema inmune (artritis reumatoide, psoriasis, lupus, VIH)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'problemas_circulacion',
          name: 'problemas_circulacion',
          label: 'Várices/problemas de la circulación que te generen dolor',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'enfermedades_raras',
          name: 'enfermedades_raras',
          label: "¿Te han diagnosticado alguna enfermedad de las llamadas 'enfermedades raras'?",
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
      ],
    },
    hipertension: {
      type: 'Checkbox',
      distribution: 'vertical',
      allowMultiple: false,
      fields: [
        {
          id: 'seguimiento_tratamiento',
          name: 'seguimiento_tratamiento',
          label: '¿Qué tanto sigues las indicaciones de tu tratamiento?',
          options: [
            { value: 'siempre', label: 'Siempre' },
            { value: 'casiSiempre', label: 'Casi siempre' },
            { value: 'aVeces', label: 'A veces' },
            { value: 'raraVez', label: 'Rara vez' },
            { value: 'nunca', label: 'Nunca' },
          ],
        },
        {
          id: 'seguimiento_consultas',
          name: 'seguimiento_consultas',
          label: '¿Qué tanto acudes a tus consultas de seguimiento de Hipertensión?',
          options: [
            { value: 'siempre', label: 'Siempre' },
            { value: 'casiSiempre', label: 'Casi siempre' },
            { value: 'aVeces', label: 'A veces' },
            { value: 'raraVez', label: 'Rara vez' },
            { value: 'nunca', label: 'Nunca' },
          ],
        },
        {
          id: 'plan_alimentacion',
          name: 'plan_alimentacion',
          label: '¿Sigues el plan de alimentación específico o dieta recomendada por tu médico?',
          options: [
            { value: 'siempre', label: 'Siempre' },
            { value: 'casiSiempre', label: 'Casi siempre' },
            { value: 'aVeces', label: 'A veces' },
            { value: 'raraVez', label: 'Rara vez' },
            { value: 'nunca', label: 'Nunca' },
          ],
        },
      ],
    },
    diabetes: {
      type: 'Checkbox',
      distribution: 'vertical',
      allowMultiple: false,
      fields: [
        {
          id: 'seguimiento_tratamiento_diabetes',
          name: 'seguimiento_tratamiento_diabetes',
          label: '¿Qué tanto sigues las indicaciones de tu tratamiento?',
          options: [
            { value: 'siempre', label: 'Siempre' },
            { value: 'casiSiempre', label: 'Casi siempre' },
            { value: 'aVeces', label: 'A veces' },
            { value: 'raraVez', label: 'Rara vez' },
            { value: 'nunca', label: 'Nunca' },
          ],
        },
        {
          id: 'seguimiento_consultas_diabetes',
          name: 'seguimiento_consultas_diabetes',
          label: '¿Qué tanto acudes a tus consultas de seguimiento de Diabetes?',
          options: [
            { value: 'siempre', label: 'Siempre' },
            { value: 'casiSiempre', label: 'Casi siempre' },
            { value: 'aVeces', label: 'A veces' },
            { value: 'raraVez', label: 'Rara vez' },
            { value: 'nunca', label: 'Nunca' },
          ],
        },
        {
          id: 'plan_alimentacion_diabetes',
          name: 'plan_alimentacion_diabetes',
          label: '¿Sigues el plan de alimentación específico o dieta recomendada por tu médico?',
          options: [
            { value: 'siempre', label: 'Siempre' },
            { value: 'casiSiempre', label: 'Casi siempre' },
            { value: 'aVeces', label: 'A veces' },
            { value: 'raraVez', label: 'Rara vez' },
            { value: 'nunca', label: 'Nunca' },
          ],
        },
      ],
    },
  },
  nerveProblemsAndPain: {
    conditions: {
      type: 'Checkbox',
      distribution: 'horizontal-inline',
      allowMultiple: false,
    },
    fields: [
      {
        id: 'convulsiones_epilepsia_desmayos',
        name: 'convulsiones_epilepsia_desmayos',
        label: 'Convulsiones, epilepsia, desmayos repentinos',
        options: [
          { value: 'Si', label: 'Si' },
          { value: 'No', label: 'No' },
        ],
      },
      {
        id: 'ansiedad_ataques_panico',
        name: 'ansiedad_ataques_panico',
        label: 'Ansiedad o ataques de pánico',
        options: [
          { value: 'Si', label: 'Si' },
          { value: 'No', label: 'No' },
        ],
      },
      {
        id: 'depresion_diagnosticada',
        name: 'depresion_diagnosticada',
        label: 'Depresión (diagnosticada)',
        options: [
          { value: 'Si', label: 'Si' },
          { value: 'No', label: 'No' },
        ],
      },
      {
        id: 'estres_diagnosticado',
        name: 'estres_diagnosticado',
        label: 'Estrés (diagnosticado)',
        options: [
          { value: 'Si', label: 'Si' },
          { value: 'No', label: 'No' },
        ],
      },
      {
        id: 'vertigo_mareo',
        name: 'vertigo_mareo',
        label: 'Vértigo (mareo)',
        options: [
          { value: 'Si', label: 'Si' },
          { value: 'No', label: 'No' },
        ],
      },
      {
        id: 'dolor_espalda_baja',
        name: 'dolor_espalda_baja',
        label: 'Dolor de espalda baja',
        options: [
          { value: 'Si', label: 'Si' },
          { value: 'No', label: 'No' },
        ],
      },
      {
        id: 'dolor_hombros_articulaciones',
        name: 'dolor_hombros_articulaciones',
        label: 'Dolor de hombros, codos, rodillas, tobillos',
        options: [
          { value: 'Si', label: 'Si' },
          { value: 'No', label: 'No' },
        ],
      },
      {
        id: 'problemas_munecas_dolor',
        name: 'problemas_munecas_dolor',
        label: 'Problemas en las muñecas (dolor/adormecimiento de dedos y manos)',
        options: [
          { value: 'Si', label: 'Si' },
          { value: 'No', label: 'No' },
        ],
      },
      {
        id: 'hernia_disco_espalda',
        name: 'hernia_disco_espalda',
        label: 'Hernia de disco (de espalda)',
        options: [
          { value: 'Si', label: 'Si' },
          { value: 'No', label: 'No' },
        ],
      },
      {
        id: 'falta_dedos_extremidades',
        name: 'falta_dedos_extremidades',
        label: '¿Te faltan dedos o extremidades?',
        options: [
          { value: 'Si', label: 'Si' },
          { value: 'No', label: 'No' },
        ],
      },
      {
        id: 'pension_enfermedad_accidente',
        name: 'pension_enfermedad_accidente',
        label: '¿Has recibido una pensión por enfermedad, accidente o cesantía?',
        options: [
          { value: 'Si', label: 'Si' },
          { value: 'No', label: 'No' },
        ],
      },
      {
        id: 'incapacidad_mas_30_dias',
        name: 'incapacidad_mas_30_dias',
        label:
          '¿Has tenido alguna incapacidad por más de 30 días? (que no sea de maternidad/paternidad)',
        options: [
          { value: 'Si', label: 'Si' },
          { value: 'No', label: 'No' },
        ],
      },
    ],
  },
};

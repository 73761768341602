import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import {
//   TextField, InputAdornment, ButtonGroup, Button,
// } from '@material-ui/core';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import MoodIcon from '@material-ui/icons/Mood';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { RootState } from 'src/store';
import { validacionSoloNumeros } from '@constants/validaciones';
import { IMenstruacionHabitualProps } from './types';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import ScaleInput from '@components/FormElemntsLibrary/ScaleInput';
import SatisfactionIndicator from '@components/FormElemntsLibrary/SatisfactionIndicator';

function MenstruacionHabitual(props: IMenstruacionHabitualProps) {
  const { hayCambios, ginecoObstetrico, setGinecoObstetrico } = props;
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { isOxxo } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');

  const handleInputChange = (e: { name: string; value: any }) =>
    setGinecoObstetrico((prevData) => ({
      ...prevData,
      menstruacionHabitual: {
        ...ginecoObstetrico.menstruacionHabitual,
        [e.name]: e.value,
      },
    }));

  const components: { show: boolean, component: JSX.Element }[] = [
    {
      show: !isOxxo,
      component:
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="flex flex-col">
            <h3 className="ml-4 text-gray-600 font-medium">
              {t('ritmo')}
            </h3>
            <div className="flex flex-row">
              <NumberInput
                name="ritmoCada"
                label=""
                placeholder={t('cada')}
                fixedValue={t('dias')}
                value={Number(ginecoObstetrico.menstruacionHabitual.ritmoCada)}
                setValue={handleInputChange}
                disabled={hayCambios}
              />
              <span className="pt-5">X</span>
              <NumberInput
                name="ritmoDuracion"
                label=""
                placeholder={t('duracion')}
                fixedValue={t('dias')}
                value={Number(ginecoObstetrico.menstruacionHabitual.ritmoDuracion)}
                setValue={handleInputChange}
                disabled={hayCambios}
              />
            </div>
          </div>
          <NumberInput
            name="cantidad"
            fixedValue={t('aposito-dia')}
            value={Number(ginecoObstetrico.menstruacionHabitual.cantidad)}
            setValue={handleInputChange}
            disabled={hayCambios}
          />
        </div>

    },
    {
      show: true,
      component:
        <div className="flex flex-col md:w-1/2">
          <ScaleInput
            name="dolor"
            label={t('dolor(intensidad)')}
            value={ginecoObstetrico.menstruacionHabitual.dolor}
            options={[...Array(esPaciente ? 5 : 10)].map((x, i) => ({ value: String(i + 1)}))}
            scaleLabels={{
              start: t('menstruacion_leve').toUpperCase(),
              middle: t('menstruacion_moderada').toUpperCase(),
              end: t('menstruacion_intensa').toUpperCase(),
            }}
            setValue={handleInputChange}
          />
          <SatisfactionIndicator />
        </div>
    }
  ].filter(component => component.show);


  return (
    <>
      <h2 className="font-normal text-blue-800 mb-0">{t('menstruacion_habitual')}</h2>
      <div className={`grid grid-cols-1 md:grid-cols-${components.length} mt-2`}>
        {components.map(component => <>{component.component}</>)}
      </div>
      {/* <div className="grid grid-cols-4 gap-x-4 mb-4">
        <h3 className="mt-4 mb-2 text-gray-600 font-medium">
          {t('ritmo')}
        </h3>
        <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('cantidad')}</h3>
        <h3 className="mt-4 mb-2 col-span-2 text-gray-600 font-medium">{t('dolor(intensidad)')}</h3>
        <div className="w-100 flex items-center self-start">
          <TextField
            variant="outlined"
            label={t('cada')}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('(dias)')}</InputAdornment>,
              inputProps: {
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                maxLength: '3',
              },
            }}
            value={ginecoObstetrico.menstruacionHabitual.ritmoCada}
            onChange={handleChangeRitmoCada}
            disabled={hayCambios}
          />
          <span className="px-2">X</span>
          <TextField
            variant="outlined"
            label={t('duracion')}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('(dias)')}</InputAdornment>,
              inputProps: {
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                maxLength: '2',
              },
            }}
            value={ginecoObstetrico.menstruacionHabitual.ritmoDuracion}
            onChange={handleChangeRitmoDuracion}
            disabled={hayCambios}
          />
        </div>
        <TextField
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {t('aposito-dia')}
              </InputAdornment>
            ),
            inputProps: {
              autoComplete: 'off',
              'aria-autocomplete': 'none',
              maxLength: '2',
            },
          }}
          value={ginecoObstetrico.menstruacionHabitual.cantidad}
          onChange={handleChangeCantidad}
          disabled={hayCambios}
        />
        <div className="col-span-2 border-solid border border-gray-400 rounded pt-4">
          <div className="grid grid-cols-3 justify-items-center">
            <h3 className="m-0 uppercase">{t('menstruacion_leve')}</h3>
            <h3 className="m-0 uppercase">{t('menstruacion_moderada')}</h3>
            <h3 className="m-0 uppercase">{t('menstruacion_intensa')}</h3>
            <MoodIcon />
            <SentimentSatisfiedIcon />
            <MoodBadIcon />
          </div>
          <div className="px-2 my-2">
            <ButtonGroup size="small" aria-label="small outlined button group" fullWidth>
              {[...Array(10)].map((x, i) => (
                <Button
                  variant={
                    parseInt(ginecoObstetrico.menstruacionHabitual.dolor, 10) === i + 1
                      ? 'contained'
                      : 'outlined'
                  }
                  color="primary"
                  onClick={handleChangeDolor}
                  disabled={hayCambios}
                >
                  {i + 1}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div
            className="w-full h-2.5"
            style={{
              background:
                'linear-gradient(270deg, #D9E9F7 0%, #0170A5 12.5%, #495678 25%, #00A13A 37.5%, #F7DE60 50%, #E87E18 62.5%, #DA271D 75%, #8F2829 87.5%)',
              borderRadius: '3px',
              transform: 'rotate(180deg)',
            }}
          />
        </div>
      </div> */}
      {!esPaciente && (
        <TextArea
          name="comentarios"
          value={ginecoObstetrico.menstruacionHabitual.comentarios}
          setValue={handleInputChange}
          maxLength={500}
          disabled={hayCambios}
        />
      )}
    </>
  );
}

export default MenstruacionHabitual;

import { FC, FormEvent, useEffect, useRef } from 'react';

import { resetModal } from '@actions/modal/actions';
import { SubmitButton } from '@components/FormElemntsLibrary/SubmitButton';
import { GLOBALS } from '@constants/prueba/globals';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import { X } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { SubModalFormProps } from './type';
import { RootState } from 'src/store';

export const SubModalForm: FC<SubModalFormProps> = ({
  children,
  title,
  subtitle,
  id,
  displayButton = true,
  saveModal,
  handleClose,
  hiddenEffect = false,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);
  const form = useRef<HTMLDivElement | null>(null);
  // const dispatch = useDispatch();

  const hideModal = () => {
    if (form.current) {
      form.current.classList.remove('animate-fade');
      form.current.classList.remove('animate-popUp');
      form.current.classList.add(isWideScreen ? 'animate-fadeExit' : 'animate-popDown');
    }
  };

  const handleCloseModal = async (e: FormEvent) => {
    e.preventDefault();
    if (hiddenEffect) {
      hideModal();
    }
    if (handleClose) {
      handleClose();
    }
  };

  // const handleCloseModal = (e: FormEvent) => {
  //   e.preventDefault();

  //   setTimeout(() => {
  //     dispatch(resetModal(id));
  //   }, 300);

  //   if (form.current) {
  //     if (!isWideScreen) {
  //       form.current.classList.add('animate-popDown');
  //     }
  //   }
  // };

  // const handleSubmitForm = async (e: FormEvent) => {
  //   e.preventDefault();
  //   try {
  //       saveModal();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleSubmitForm = async () => {
    saveModal();
    if (hiddenEffect) {
      hideModal();
    }
    if (handleClose) {
      handleClose();
    }
  };

  useEffect(() => {
    // Ajustar altura del viewport para Safari
    const setDynamicHeight = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    // Configurar altura dinámica en carga y cuando se cambia el tamaño de la ventana
    if (!isWideScreen) {
      setDynamicHeight();
      window.addEventListener('resize', setDynamicHeight);
    }

    return () => window.removeEventListener('resize', setDynamicHeight);
  }, [id]);

  useEffect(() => {
    // Ajustar altura del viewport para Safari
    const setDynamicHeight = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    // Configurar altura dinámica en carga y cuando se cambia el tamaño de la ventana
    if (!isWideScreen) {
      setDynamicHeight();
      window.addEventListener('resize', setDynamicHeight);
    }

    return () => window.removeEventListener('resize', setDynamicHeight);
  }, [isWideScreen]);

  return (
    <div
      ref={form}
      className={`fixed top-0 z-50 w-full h-screen flex flex-col justify-between bg-white ${
        isWideScreen ? 'animate-fade' : 'animate-popUp'
      } border-b-[1px] border-[#C4C4C4] overflow-y-scroll md:fixed md:bottom-0 md:right-0 md:flex md:justify-center md:items-center md:min-h-[100vh] md:bg-black/40`}
      style={{
        height: 'calc(var(--vh, 1vh) * 100)', // Altura dinámica compatible con Safari
        overflowY: 'auto', // Permitir scroll dentro del modal
        WebkitOverflowScrolling: 'touch', // Scroll suave en Safari
      }}
    >
      <form className="w-full h-full md:w-[65vw] md:h-[70vh] mt-14 md:mt-0 p-2 flex flex-col justify-between bg-white border-b-[1px] border-[#C4C4C4] overflow-y-scroll md:z-40  md:max-w-screen-xl">
        <div className="w-full h-auto ">
          <div className="relative flex items-center h-[68px] bg-white border-b border-[#C4C4C4]">
            <div className="flex-1 text-center">
              <h2 className="font-semibold text-base">{title}</h2>
              {subtitle && <h3 className="text-center font-normal text-sm mt-1">{subtitle}</h3>}
            </div>
            <div className="absolute right-4 cursor-pointer" onClick={handleCloseModal}>
              <X size={20} className="text-gray-600" />
            </div>
          </div>
          <section className="w-full h-fit py-4">{children}</section>
        </div>

        {displayButton && <SubmitButton saveCallback={handleSubmitForm} label={t('aceptar')} />}
      </form>
    </div>
  );
};

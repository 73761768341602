import { useTranslation } from 'react-i18next';
import { FormControlLabel, Radio } from '@material-ui/core/';
import { capitalize } from '@common/functions';
import { IConsultoriosOrganizacionProps } from './types';

function ConsultoriosOrganizacion({ consultorio, selected }: IConsultoriosOrganizacionProps) {
  const { t } = useTranslation();
  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
      <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
        <div className="grid grid-cols-12 gap-4">
          <div className="self-center text-center col-span-1">
            <FormControlLabel
              label=""
              value={consultorio.idConsultorio}
              control={<Radio color="primary" />}
              checked={parseInt(selected, 10) === consultorio.idConsultorio}
            />
          </div>
          <div className="col-span-11">
            <h3 className="font-normal m-0 text-gray-500">
              {`${capitalize(consultorio.nombre)} - ${consultorio.label}`}
            </h3>
            <p className="text-gray-500 mb-0">
              {`${t('direccion')}: ${consultorio.calle}${
                consultorio.num1 ? ` ${consultorio.num1}.` : ''
              }${consultorio.num2 ? ` ${consultorio.num2}.` : ''}${
                consultorio.agea ? ` ${consultorio.agea!.id}.` : ''
              }${consultorio.agem ? ` ${consultorio.agem.id}.` : ''}${
                consultorio.cp ? ` ${consultorio.cp}.` : ''
              }${consultorio.agel ? ` ${consultorio.agel.id}.` : ''}${
                consultorio.agee ? ` ${consultorio.agee.id}.` : ''
              }`}
            </p>
            <p className="font-normal m-0 text-gray-500">
              {`${t('telefono(s)')}: ${
                consultorio.telefono1.telefono
                  ? `${consultorio.telefono1.telefono}${
                    consultorio.telefono1.extension
                      ? ` ext. ${consultorio.telefono1.extension}`
                      : ''
                  }; `
                  : ''
              }${
                consultorio.telefono2.telefono
                  ? `${consultorio.telefono2.telefono}${
                    consultorio.telefono2.extension
                      ? ` ext. ${consultorio.telefono2.extension}`
                      : ''
                  }; `
                  : ''
              }${
                consultorio.telefono3.telefono
                  ? `${consultorio.telefono3.telefono}${
                    consultorio.telefono3.extension
                      ? ` ext. ${consultorio.telefono3.extension}`
                      : ''
                  };`
                  : ''
              }`}
            </p>
            {consultorio.correoElectronico && (
              <p className="font-normal m-0 text-gray-500">
                {`${t('correo_electronico_del_consultorio')}: ${consultorio.correoElectronico}`}
              </p>
            )}
            {consultorio.sitioWeb && (
              <p className="font-normal m-0 text-gray-500">
                {`${t('sitio_web')}: ${consultorio.sitioWeb}`}
              </p>
            )}
            <p className="font-normal m-0 text-gray-500">
              {`${t('logotipo')}: `}
              <span>{consultorio.logotipo ? 'Cargado' : 'No cargado'}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultoriosOrganizacion;

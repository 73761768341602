import React, { useState, useEffect } from 'react';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
} from '@material-ui/core';
import dayjs from 'dayjs';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { setSnackComplete } from '@actions/snackbar/types';
import { constDia, constMes, aniosFuturos } from '@constants/fechas';
import { validarFecha } from '@common/functions';
import { IFechaField, ICatalogosEstandar } from '@common/types';
import { getCatalogoSecuelas } from '@utils/getCatalogos';
import TxtEnriquecido from '@components/Comentarios/index';
import DeleteDialog from '@components/DeleteDialog';
import { RootState } from 'src/store';
import { sendPronosticoGuardar } from '@utils/sendInfo';
import { sendPronosticoActualizar } from '@utils/editInfo';
import { sendPronosticoEliminar } from '@utils/deleteInfo';
import { getToken, getListaPronosticos } from '@utils/commonUrl';
import { removePaciente, removeUserSession } from '@utils/commonStore';
import { limpiarReduxSesion, limpiarReduxPaciente } from '@utils/reduxCommon';
import { setPronosticos } from '@actions/paciente/actions';
import useStyles from './styles';
import { ICatLaboratorio, IPronostico } from './types';
import ListaPronosticos from './ListaPronosticos/index';

const CompPronostico = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = getToken();
  const history = useHistory();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    antPatologicos: { enfReferidas },
    plan: { pronosticos },
  } = useSelector((state: RootState) => state.Paciente.expediente);
  const [anyoFur] = useState<number[]>(aniosFuturos(30));
  const [numTxt, setNumTxt] = useState<number>(0);
  const [catSecuela, setCatSecuela] = useState<ICatalogosEstandar[]>([]);
  const [catEnfermedades, setCatEnfermedades] = useState<ICatLaboratorio[]>([]);
  const [fechaField, setFechaField] = useState<IFechaField>({
    error: false,
    helperText: '',
  });
  const [inxUpdate, setInxUpdate] = useState<number>(-1);
  const [newData, setNewData] = useState<IPronostico>({
    idPronostico: -1,
    diagnostico: { idCat: 0, label: '', categoria: '' },
    radioProcedimiento: '',
    groupCheck: {
      check1: false,
      check2: false,
      check3: false,
      check4: false,
      check5: false,
      check6: false,
      check7: false,
      check8: false,
      check9: false,
    },
    secuelaTratamiento: {
      radio: '',
      area: { id: 0, label: '' },
      tipo: '',
    },
    secuelaEspontanea: {
      radio: '',
      area: { id: 0, label: '' },
      tipo: '',
    },
    causa: { entityMap: {}, blocks: [] },
    cronica: { entityMap: {}, blocks: [] },
    otro: { entityMap: {}, blocks: [] },
    terapeuticas: { entityMap: {}, blocks: [] },
    exacerbaciones: { entityMap: {}, blocks: [] },
    remisiones: { entityMap: {}, blocks: [] },
    recaidas: { entityMap: {}, blocks: [] },
    fecha: {
      dia: '',
      mes: '',
      anio: '',
    },
    comentarios: { entityMap: {}, blocks: [] },
    fechaCreacion: dayjs().toString(),
    actualizado: false,
    borrado: false,
  });
  const valSaveButton = () => {
    let sendActive = true;
    if (
      newData.diagnostico.categoria &&
      newData.diagnostico.categoria === 'cronico' &&
      newData.radioProcedimiento.length > 0 &&
      (newData.groupCheck.check3 ||
        newData.groupCheck.check5 ||
        newData.groupCheck.check6 ||
        newData.groupCheck.check7 ||
        newData.groupCheck.check8 ||
        newData.groupCheck.check9)
    ) {
      sendActive = false;
    } else if (
      newData.diagnostico.categoria &&
      newData.diagnostico.categoria === 'agudo' &&
      newData.fecha.dia.length > 0 &&
      newData.fecha.mes.length > 0 &&
      newData.fecha.anio.length > 0 &&
      !fechaField.error &&
      ((newData.groupCheck.check1 &&
        (newData.secuelaTratamiento.radio === 'N' ||
          (newData.secuelaTratamiento.radio === 'S' &&
            newData.secuelaTratamiento.area.id !== 0))) ||
        (newData.groupCheck.check2 &&
          (newData.secuelaEspontanea.radio === 'N' ||
            (newData.secuelaEspontanea.radio === 'S' &&
              newData.secuelaEspontanea.area.id !== 0))) ||
        newData.groupCheck.check3 ||
        newData.groupCheck.check4 ||
        newData.groupCheck.check5)
    ) {
      sendActive = false;
    }
    return sendActive;
  };
  // cierre del modal
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  const handleAgregarComentario = (contentState: RawDraftContentState) => {
    const oldVal = { ...newData };
    oldVal.comentarios = contentState;
    setNewData(oldVal);
  };
  const handleAgregarCausa = (contentState: RawDraftContentState) => {
    const oldVal = { ...newData };
    oldVal.causa = contentState;
    setNewData(oldVal);
  };
  const handleAgregarCronica = (contentState: RawDraftContentState) => {
    const oldVal = { ...newData };
    oldVal.cronica = contentState;
    setNewData(oldVal);
  };
  const handleAgregarOtro = (contentState: RawDraftContentState) => {
    const oldVal = { ...newData };
    oldVal.otro = contentState;
    setNewData(oldVal);
  };
  const handleAgregarTerapeuticas = (contentState: RawDraftContentState) => {
    const oldVal = { ...newData };
    oldVal.terapeuticas = contentState;
    setNewData(oldVal);
  };
  const handleAgregarExacerbaciones = (contentState: RawDraftContentState) => {
    const oldVal = { ...newData };
    oldVal.exacerbaciones = contentState;
    setNewData(oldVal);
  };
  const handleAgregarRemisiones = (contentState: RawDraftContentState) => {
    const oldVal = { ...newData };
    oldVal.remisiones = contentState;
    setNewData(oldVal);
  };
  const handleAgregarRecaidas = (contentState: RawDraftContentState) => {
    const oldVal = { ...newData };
    oldVal.recaidas = contentState;
    setNewData(oldVal);
  };
  const deleteProcedimiento = () => {
    // logica pero con diferentes variables de acuerdo al guardado
    // if (selArray === delSel) {
    //   setSelArray(delSel - 1);
    // }
    // handleBorrarSolicitud(delSel);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameCheck = event.target.name;
    const checkData = event.target.checked;
    const upCheck = { ...newData };
    if (nameCheck === 'check1') {
      upCheck.groupCheck.check1 = checkData;
      upCheck.secuelaTratamiento = {
        radio: '',
        area: { id: 0, label: '' },
        tipo: '',
      };
    } else if (nameCheck === 'check2') {
      upCheck.groupCheck.check2 = checkData;
      upCheck.secuelaEspontanea = {
        radio: '',
        area: { id: 0, label: '' },
        tipo: '',
      };
    } else if (nameCheck === 'check3') {
      upCheck.groupCheck.check3 = checkData;
      setNumTxt(Math.floor(Math.random() * 10));
      upCheck.causa = { entityMap: {}, blocks: [] };
    } else if (nameCheck === 'check4') {
      upCheck.groupCheck.check4 = checkData;
      setNumTxt(Math.floor(Math.random() * 10));
      upCheck.cronica = { entityMap: {}, blocks: [] };
    } else if (nameCheck === 'check5') {
      upCheck.groupCheck.check5 = checkData;
      setNumTxt(Math.floor(Math.random() * 10));
      upCheck.otro = { entityMap: {}, blocks: [] };
    } else if (nameCheck === 'check6') {
      upCheck.groupCheck.check6 = checkData;
      setNumTxt(Math.floor(Math.random() * 10));
      upCheck.terapeuticas = { entityMap: {}, blocks: [] };
    } else if (nameCheck === 'check7') {
      upCheck.groupCheck.check7 = checkData;
      setNumTxt(Math.floor(Math.random() * 10));
      upCheck.exacerbaciones = { entityMap: {}, blocks: [] };
    } else if (nameCheck === 'check8') {
      upCheck.groupCheck.check8 = checkData;
      setNumTxt(Math.floor(Math.random() * 10));
      upCheck.remisiones = { entityMap: {}, blocks: [] };
    } else if (nameCheck === 'check9') {
      upCheck.groupCheck.check9 = checkData;
      setNumTxt(Math.floor(Math.random() * 10));
      upCheck.recaidas = { entityMap: {}, blocks: [] };
    }
    setNewData(upCheck);
  };
  useEffect(() => {
    if (
      newData &&
      newData.fecha &&
      newData.fecha.dia !== '' &&
      newData.fecha.mes !== '' &&
      newData.fecha.anio !== ''
    ) {
      if (!validarFecha(newData.fecha.anio, newData.fecha.mes, newData.fecha.dia)) {
        setFechaField({
          error: true,
          helperText: t('fecha_invalida'),
        });
      } else {
        setFechaField({
          error: false,
          helperText: '',
        });
      }
    }
  }, [newData]);
  useEffect(() => {
    getCatalogoSecuelas().then((result: ICatalogosEstandar[]) => {
      setCatSecuela(result);
    });
  }, []);
  useEffect(() => {
    if (idMedico > 0 && idConsultorio > 0 && idConsulta > 0 && idPaciente > 0) {
      const catEnfermedad: any = [];
      if (enfReferidas.length > 0) {
        enfReferidas.forEach((enfermedad) => {
          const objPush = {
            idCat: enfermedad.idBD,
            label:
              enfermedad.seleccionDiagnostico === 'cie10'
                ? enfermedad.diagnosticoCie10.label
                : enfermedad.diagnosticoMedico,
            categoria: enfermedad.tipoDiagnostico,
          };
          catEnfermedad.push(objPush);
        });
      }
      setCatEnfermedades(catEnfermedad);
      fetch(getListaPronosticos(idMedico, idPaciente, idConsulta, idConsultorio), {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setPronosticos(result.data);
          } else if (result.code === 300) {
            // el token y el idMedico no son el mismo
            /* Cerrar sesión */
            removeUserSession();
            limpiarReduxSesion();
            history.push('/login');
          } else if (result.code === 301) {
            // el medico no tiene subscripcion activa
            /* Resetear y redirigir al checkout */
            removePaciente();
            limpiarReduxSesion();
            history.push('/subscripcion');
          } else if (result.code === 302) {
            // el paciente y el medico no tienen relacion
            /* Resetear redux paciente y datos paciente y redirigir a paciente */
            removePaciente();
            limpiarReduxPaciente();
            history.push('/pacientes');
          }
        })
        .catch(() => {
          setPronosticos([]);
        });
    }
  }, [enfReferidas, idPaciente, idMedico, idConsultorio, idConsulta]);
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameCheck = event.target.name;
    const checkData = event.target.value;
    const upData = { ...newData };
    if (nameCheck === 'secuela1') {
      upData.secuelaTratamiento.radio = checkData;
      upData.secuelaTratamiento.area = { id: 0, label: '' };
      upData.secuelaTratamiento.tipo = '';
    } else if (nameCheck === 'secuela2') {
      upData.secuelaEspontanea.radio = checkData;
      upData.secuelaEspontanea.area = { id: 0, label: '' };
      upData.secuelaEspontanea.tipo = '';
    }
    setNewData(upData);
  };
  const clearNewData = () => {
    setNewData({
      idPronostico: -1,
      diagnostico: { idCat: 0, label: '', categoria: '' },
      radioProcedimiento: '',
      groupCheck: {
        check1: false,
        check2: false,
        check3: false,
        check4: false,
        check5: false,
        check6: false,
        check7: false,
        check8: false,
        check9: false,
      },
      secuelaTratamiento: {
        radio: '',
        area: { id: 0, label: '' },
        tipo: '',
      },
      secuelaEspontanea: {
        radio: '',
        area: { id: 0, label: '' },
        tipo: '',
      },
      causa: { entityMap: {}, blocks: [] },
      cronica: { entityMap: {}, blocks: [] },
      otro: { entityMap: {}, blocks: [] },
      terapeuticas: { entityMap: {}, blocks: [] },
      exacerbaciones: { entityMap: {}, blocks: [] },
      remisiones: { entityMap: {}, blocks: [] },
      recaidas: { entityMap: {}, blocks: [] },
      fecha: {
        dia: '',
        mes: '',
        anio: '',
      },
      comentarios: { entityMap: {}, blocks: [] },
      fechaCreacion: dayjs().toString(),
      actualizado: false,
      borrado: false,
    });
  };
  const deleteDiagnostico = () => {
    // aqui el cambio
    const dataSend = {
      idPaciente,
      idMedico,
      idConsulta,
      idConsultorio,
      idPronostico: parseFloat(pronosticos[inxUpdate].idPronostico.toString()),
    };
    sendPronosticoEliminar(dataSend)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          const nuevaLista = [...pronosticos];
          nuevaLista.splice(inxUpdate, 1);
          setPronosticos(nuevaLista);
          clearNewData();
          setInxUpdate(-1);
        } else {
          let mensaje = '';
          if (/UNIQUE KEY/i.test(result.msg)) {
            mensaje = t('campo_repetido');
          } else {
            mensaje = result.msg;
          }
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${mensaje}`,
            }),
          );
          clearNewData();
          setInxUpdate(-1);
        }
      })
      .catch((error) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${error.toString()}`,
          }),
        );
      });
  };
  return (
    <div>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('d-p-plan-pro-del-sol')}
        callBackAceptar={() => deleteProcedimiento()}
        callBackClose={handleAlertClose}
      />
      <div className="flex py-3 items-center">
        <h2 className="text-blue-500 font-normal m-0">{t('pronostico')}</h2>
      </div>
      <div>
        <h3 className="text-gray-900 font-normal m-0 pb-4">
          {`${t('d-p-plan-pron-diagnostico')}*`}
        </h3>
        <Autocomplete
          options={catEnfermedades}
          getOptionLabel={(option) => option.label}
          noOptionsText={t('sin_resultados')}
          value={newData.diagnostico}
          onChange={(e, nV) => {
            const upSecuela = { ...newData };
            if (nV) {
              upSecuela.diagnostico = nV;
            } else {
              upSecuela.diagnostico = { idCat: 0, label: '', categoria: '' };
            }
            setNewData(upSecuela);
          }}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
          )}
        />
      </div>
      {newData.diagnostico && newData.diagnostico.idCat !== 0 ? (
        <>
          <div className="py-4">
            <h3 className="text-gray-900 font-normal m-0 pb-3">{`${t('d-p-plan-pron-tipo')}*`}</h3>
            <RadioGroup
              aria-label="realiza"
              name="realiza"
              value={newData.radioProcedimiento}
              onChange={(e) => {
                const radioUpdate = { ...newData };
                radioUpdate.radioProcedimiento = (e.target as HTMLInputElement).value;
                setNewData(radioUpdate);
              }}
              row
            >
              <FormControlLabel
                value="d-p-plan-pron-reservado"
                control={<Radio color="primary" />}
                label={t('d-p-plan-pron-reservado')}
              />
              <FormControlLabel
                value="d-p-plan-pron-malo"
                control={<Radio color="primary" />}
                label={t('d-p-plan-pron-malo')}
              />
              <FormControlLabel
                value="d-p-plan-pron-regular"
                control={<Radio color="primary" />}
                label={t('d-p-plan-pron-regular')}
              />
              <FormControlLabel
                value="d-p-plan-pron-bueno"
                control={<Radio color="primary" />}
                label={t('d-p-plan-pron-bueno')}
              />
            </RadioGroup>
          </div>
          {newData.radioProcedimiento &&
          newData.radioProcedimiento.length > 0 &&
          newData.diagnostico.categoria === 'agudo' ? (
            <>
              <div className="w-full">
                <h3 className="text-gray-900 font-normal m-0 pb-3">
                  {`${t('d-p-plan-pron-curso')}*`}
                </h3>
                <div className="m-0">
                  <div className="-ml-3 flex items-center">
                    <Checkbox
                      checked={newData.groupCheck.check1}
                      color="primary"
                      name="check1"
                      onChange={handleChange}
                      disabled={
                        newData.groupCheck.check2 ||
                        newData.groupCheck.check3 ||
                        newData.groupCheck.check4
                      }
                    />
                    <h3 className="font-normal m-0 text-gray-500">{t('d-p-plan-pron-curara')}</h3>
                  </div>
                  {newData.groupCheck && newData.groupCheck && newData.groupCheck.check1 ? (
                    <>
                      <div className="flex items-center">
                        <h3 className="font-normal m-0 text-gray-500 pr-4">
                          {`${t('d-p-plan-pron-secuela')}*`}
                        </h3>
                        <RadioGroup
                          row
                          name="secuela1"
                          value={newData.secuelaTratamiento.radio}
                          onChange={handleChangeRadio}
                        >
                          <FormControlLabel
                            value="S"
                            control={<Radio color="primary" />}
                            label={t('si')}
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio color="primary" />}
                            label={t('no')}
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </div>
                      {newData.secuelaTratamiento.radio === 'S' ? (
                        <div className="grid grid-cols-12 pb-2">
                          <div className="col-span-4">
                            <Autocomplete
                              options={catSecuela}
                              getOptionLabel={(option) => option.label}
                              noOptionsText={t('sin_resultados')}
                              value={newData.secuelaTratamiento.area}
                              onChange={(e, nV) => {
                                const upSecuela = { ...newData };
                                if (nV) {
                                  upSecuela.secuelaTratamiento.area = nV;
                                } else {
                                  upSecuela.secuelaTratamiento.area = { id: 0, label: '' };
                                }
                                setNewData(upSecuela);
                              }}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={`${t('selecciona')}*`}
                                  variant="outlined"
                                />
                              )}
                            />
                          </div>
                          <div />
                          <div className="col-span-7">
                            <TextField
                              id="secuela-txt1"
                              placeholder={t('comentarios')}
                              variant="outlined"
                              fullWidth
                              value={newData.secuelaTratamiento.tipo}
                              onChange={(event) => {
                                const upTxtSec = { ...newData };
                                upTxtSec.secuelaTratamiento.tipo = event.target.value;
                                setNewData(upTxtSec);
                              }}
                              inputProps={{ maxLength: 200, autoComplete: 'off' }}
                              multiline
                              rows="1"
                            />
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
                <div className="m-0">
                  <div className="-ml-3 flex items-center">
                    <Checkbox
                      checked={newData.groupCheck.check2}
                      color="primary"
                      name="check2"
                      onChange={handleChange}
                      disabled={
                        newData.groupCheck.check1 ||
                        newData.groupCheck.check3 ||
                        newData.groupCheck.check4
                      }
                    />
                    <h3 className="font-normal m-0 text-gray-500">
                      {t('d-p-plan-pron-espontaneamente')}
                    </h3>
                  </div>
                  {newData.groupCheck && newData.groupCheck && newData.groupCheck.check2 ? (
                    <>
                      <div className="flex items-center">
                        <h3 className="font-normal m-0 text-gray-500 pr-4">
                          {`${t('d-p-plan-pron-secuela')}*`}
                        </h3>
                        <RadioGroup
                          row
                          name="secuela2"
                          value={newData.secuelaEspontanea.radio}
                          onChange={handleChangeRadio}
                        >
                          <FormControlLabel
                            value="S"
                            control={<Radio color="primary" />}
                            label={t('si')}
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio color="primary" />}
                            label={t('no')}
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </div>
                      {newData.secuelaEspontanea.radio === 'S' ? (
                        <div className="grid grid-cols-12 pb-2">
                          <div className="col-span-4">
                            <Autocomplete
                              options={catSecuela}
                              getOptionLabel={(option) => option.label}
                              noOptionsText={t('sin_resultados')}
                              value={newData.secuelaEspontanea.area}
                              onChange={(e, nV) => {
                                const upSecuela = { ...newData };
                                if (nV) {
                                  upSecuela.secuelaEspontanea.area = nV;
                                } else {
                                  upSecuela.secuelaEspontanea.area = { id: 0, label: '' };
                                }
                                setNewData(upSecuela);
                              }}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={`${t('selecciona')}*`}
                                  variant="outlined"
                                />
                              )}
                            />
                          </div>
                          <div />
                          <div className="col-span-7">
                            <TextField
                              id="secuela-txt2"
                              placeholder={t('comentarios')}
                              variant="outlined"
                              fullWidth
                              value={newData.secuelaEspontanea.tipo}
                              onChange={(event) => {
                                const upTxtSec = { ...newData };
                                upTxtSec.secuelaEspontanea.tipo = event.target.value;
                                setNewData(upTxtSec);
                              }}
                              inputProps={{ maxLength: 200, autoComplete: 'off' }}
                              multiline
                              rows="1"
                            />
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
                <div className="m-0">
                  <div className="-ml-3 flex items-center">
                    <Checkbox
                      checked={newData.groupCheck.check3}
                      color="primary"
                      name="check3"
                      onChange={handleChange}
                      disabled={newData.groupCheck.check1 || newData.groupCheck.check2}
                    />
                    <h3 className="font-normal m-0 text-gray-500">{t('d-p-plan-pron-causa')}</h3>
                  </div>
                  {newData.groupCheck && newData.groupCheck && newData.groupCheck.check3 ? (
                    <TxtEnriquecido
                      comentarios={newData.causa}
                      setComentarios={handleAgregarCausa}
                      rows={4}
                      maxLength={300}
                      placeholder=""
                      lang="es"
                      disabled={false}
                      key={numTxt}
                    />
                  ) : null}
                </div>
                <div className="m-0">
                  <div className="-ml-3 flex items-center">
                    <Checkbox
                      checked={newData.groupCheck.check4}
                      color="primary"
                      name="check4"
                      onChange={handleChange}
                      disabled={newData.groupCheck.check1 || newData.groupCheck.check2}
                    />
                    <h3 className="font-normal m-0 text-gray-500">{t('d-p-plan-pron-cronica')}</h3>
                  </div>
                  {newData.groupCheck && newData.groupCheck && newData.groupCheck.check4 ? (
                    <TxtEnriquecido
                      comentarios={newData.cronica}
                      setComentarios={handleAgregarCronica}
                      rows={4}
                      maxLength={300}
                      placeholder=""
                      lang="es"
                      disabled={false}
                      key={numTxt}
                    />
                  ) : null}
                </div>
                <div className="m-0">
                  <div className="-ml-3 flex items-center">
                    <Checkbox
                      checked={newData.groupCheck.check5}
                      color="primary"
                      name="check5"
                      onChange={handleChange}
                    />
                    <h3 className="font-normal m-0 text-gray-500">{t('d-p-plan-pron-otros')}</h3>
                  </div>
                  {newData.groupCheck && newData.groupCheck && newData.groupCheck.check5 ? (
                    <>
                      <TxtEnriquecido
                        comentarios={newData.otro}
                        setComentarios={handleAgregarOtro}
                        rows={4}
                        maxLength={300}
                        placeholder=""
                        lang="es"
                        disabled={false}
                        key={numTxt}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </>
            ) : null}
          {newData.radioProcedimiento &&
          newData.radioProcedimiento.length > 0 &&
          newData.diagnostico.categoria === 'cronico' ? (
            <>
              <div className="w-full">
                <h3 className="text-gray-900 font-normal m-0 pb-3">
                  {`${t('d-p-plan-pron-curso')}*`}
                </h3>
                <div className="m-0">
                  <div className="-ml-3 flex items-center">
                    <Checkbox
                      checked={newData.groupCheck.check6}
                      color="primary"
                      name="check6"
                      onChange={handleChange}
                    />
                    <h3 className="font-normal m-0 text-gray-500">
                      {t('d-p-plan-pron-terapeuticas')}
                    </h3>
                  </div>
                  {newData.groupCheck && newData.groupCheck && newData.groupCheck.check6 ? (
                    <TxtEnriquecido
                      comentarios={newData.terapeuticas}
                      setComentarios={handleAgregarTerapeuticas}
                      rows={4}
                      maxLength={300}
                      placeholder=""
                      lang="es"
                      disabled={false}
                      key={numTxt}
                    />
                  ) : null}
                </div>
                <div className="m-0">
                  <div className="-ml-3 flex items-center">
                    <Checkbox
                      checked={newData.groupCheck.check7}
                      color="primary"
                      name="check7"
                      onChange={handleChange}
                    />
                    <h3 className="font-normal m-0 text-gray-500">
                      {t('d-p-plan-pron-exacerbaciones')}
                    </h3>
                  </div>
                  {newData.groupCheck && newData.groupCheck && newData.groupCheck.check7 ? (
                    <TxtEnriquecido
                      comentarios={newData.exacerbaciones}
                      setComentarios={handleAgregarExacerbaciones}
                      rows={4}
                      maxLength={300}
                      placeholder=""
                      lang="es"
                      disabled={false}
                      key={numTxt}
                    />
                  ) : null}
                </div>
                <div className="m-0">
                  <div className="-ml-3 flex items-center">
                    <Checkbox
                      checked={newData.groupCheck.check8}
                      color="primary"
                      name="check8"
                      onChange={handleChange}
                    />
                    <h3 className="font-normal m-0 text-gray-500">
                      {t('d-p-plan-pron-remisiones')}
                    </h3>
                  </div>
                  {newData.groupCheck && newData.groupCheck && newData.groupCheck.check8 ? (
                    <TxtEnriquecido
                      comentarios={newData.remisiones}
                      setComentarios={handleAgregarRemisiones}
                      rows={4}
                      maxLength={300}
                      placeholder=""
                      lang="es"
                      disabled={false}
                      key={numTxt}
                    />
                  ) : null}
                </div>
                <div className="m-0">
                  <div className="-ml-3 flex items-center">
                    <Checkbox
                      checked={newData.groupCheck.check9}
                      color="primary"
                      name="check9"
                      onChange={handleChange}
                    />
                    <h3 className="font-normal m-0 text-gray-500">{t('d-p-plan-pron-recaidas')}</h3>
                  </div>
                  {newData.groupCheck && newData.groupCheck && newData.groupCheck.check9 ? (
                    <TxtEnriquecido
                      comentarios={newData.recaidas}
                      setComentarios={handleAgregarRecaidas}
                      rows={4}
                      maxLength={300}
                      placeholder=""
                      lang="es"
                      disabled={false}
                      key={numTxt}
                    />
                  ) : null}
                </div>
                <div className="m-0">
                  <div className="-ml-3 flex items-center">
                    <Checkbox
                      checked={newData.groupCheck.check3}
                      color="primary"
                      name="check3"
                      onChange={handleChange}
                      disabled={newData.groupCheck.check1 || newData.groupCheck.check2}
                    />
                    <h3 className="font-normal m-0 text-gray-500">{t('d-p-plan-pron-causa')}</h3>
                  </div>
                  {newData.groupCheck && newData.groupCheck && newData.groupCheck.check3 ? (
                    <TxtEnriquecido
                      comentarios={newData.causa}
                      setComentarios={handleAgregarCausa}
                      rows={4}
                      maxLength={300}
                      placeholder=""
                      lang="es"
                      disabled={false}
                      key={numTxt}
                    />
                  ) : null}
                </div>
                <div className="m-0">
                  <div className="-ml-3 flex items-center">
                    <Checkbox
                      checked={newData.groupCheck.check5}
                      color="primary"
                      name="check5"
                      onChange={handleChange}
                    />
                    <h3 className="font-normal m-0 text-gray-500">{t('d-p-plan-pron-otros')}</h3>
                  </div>
                  {newData.groupCheck && newData.groupCheck && newData.groupCheck.check5 ? (
                    <>
                      <TxtEnriquecido
                        comentarios={newData.otro}
                        setComentarios={handleAgregarOtro}
                        rows={4}
                        maxLength={300}
                        placeholder=""
                        lang="es"
                        disabled={false}
                        key={numTxt}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </>
            ) : null}
          {newData.groupCheck.check1 ||
          newData.groupCheck.check2 ||
          newData.groupCheck.check3 ||
          newData.groupCheck.check4 ||
          newData.groupCheck.check5 ||
          newData.groupCheck.check6 ||
          newData.groupCheck.check7 ||
          newData.groupCheck.check8 ||
          newData.groupCheck.check9 ? (
            <>
              {newData.diagnostico.categoria && newData.diagnostico.categoria === 'agudo' ? (
                <>
                  <h3 className="text-gray-900 font-normal m-0 py-2">
                    {`${t('d-p-plan-pron-termino')}*`}
                  </h3>
                  <div className="grid grid-cols-3 py-2">
                    <div className="w-full grid grid-cols-3 gap-2">
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="label-dia">{t('dia')}</InputLabel>
                        <Select
                          labelId="label-dia"
                          id="select-dia"
                          value={newData.fecha.dia}
                          onChange={(event) => {
                            const dateUpd = { ...newData };
                            dateUpd.fecha.dia = event.target.value as string;
                            setNewData(dateUpd);
                          }}
                          label={t('dia')}
                          error={fechaField.error}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 375,
                              },
                            },
                          }}
                        >
                          {constDia.map((diaSingle) => (
                            <MenuItem value={diaSingle.toString()} key={diaSingle}>
                              {diaSingle}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="label-mes">{t('mes')}</InputLabel>
                        <Select
                          labelId="label-mes"
                          id="select-mes"
                          value={newData.fecha.mes}
                          onChange={(event) => {
                            const dateUpd = { ...newData };
                            dateUpd.fecha.mes = event.target.value as string;
                            setNewData(dateUpd);
                          }}
                          label={t('mes')}
                          error={fechaField.error}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 375,
                              },
                            },
                          }}
                        >
                          {constMes.map((mesSingle, indx) => (
                            <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                              {mesSingle.toUpperCase()}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText className={classes.absoluteBottom}>
                          {fechaField.helperText}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="label-año">{t('anio')}</InputLabel>
                        <Select
                          labelId="label-año"
                          id="select-año"
                          value={newData.fecha.anio}
                          onChange={(event) => {
                            const dateUpd = { ...newData };
                            dateUpd.fecha.anio = event.target.value as string;
                            setNewData(dateUpd);
                          }}
                          label={t('anio')}
                          error={fechaField.error}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 375,
                              },
                            },
                          }}
                        >
                          {anyoFur.map((anioSingle) => (
                            <MenuItem key={anioSingle} value={anioSingle.toString()}>
                              {anioSingle}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="pt-2">
                <h3 className="m-0 text-gray-900 pb-3 font-normal">
                  {t('d-p-plan-pron-comentarios')}
                </h3>
                <TxtEnriquecido
                  comentarios={newData.comentarios}
                  setComentarios={handleAgregarComentario}
                  rows={4}
                  maxLength={400}
                  placeholder=""
                  lang="es"
                  disabled={false}
                  key={numTxt}
                />
              </div>
              <div className="flex content-center items-center justify-between">
                <h3 className="m-0 text-gray-500 font-normal">{t('campo-obligatorio')}</h3>
                <Button
                  color="primary"
                  size="small"
                  className={classes.noTextTranform}
                  startIcon={<AddCircleOutlineIcon />}
                  disabled={valSaveButton()}
                  onClick={() => {
                    const dataSend = {
                      ...newData,
                      idPaciente,
                      idMedico,
                      idConsulta,
                      idConsultorio,
                    };
                    if (inxUpdate === -1) {
                      sendPronosticoGuardar(dataSend)
                        .then((response) => response.json())
                        .then((result) => {
                          if (result.code === 200) {
                            dispatch(
                              setSnackComplete({
                                open: true,
                                severity: 'success',
                                mensaje: t('message-success'),
                              }),
                            );
                            const allData = [...pronosticos];
                            const updNewData = {
                              ...newData,
                              idPronostico: result.datos.ID_PD_PRONOSTICO,
                            };
                            allData.push(updNewData);
                            setPronosticos(allData);
                            clearNewData();
                            setInxUpdate(-1);
                          } else {
                            let mensaje = '';
                            if (/UNIQUE KEY/i.test(result.msg)) {
                              mensaje = t('campo_repetido');
                            } else {
                              mensaje = result.msg;
                            }
                            dispatch(
                              setSnackComplete({
                                open: true,
                                severity: 'error',
                                mensaje: `${t('message-error')} ${mensaje}`,
                              }),
                            );
                            clearNewData();
                            setInxUpdate(-1);
                          }
                        })
                        .catch((error) => {
                          dispatch(
                            setSnackComplete({
                              open: true,
                              severity: 'error',
                              mensaje: `${t('message-error-warning')} ${error.toString()}`,
                            }),
                          );
                        });
                    } else {
                      sendPronosticoActualizar(dataSend)
                        .then((response) => response.json())
                        .then((result) => {
                          if (result.code === 200) {
                            dispatch(
                              setSnackComplete({
                                open: true,
                                severity: 'success',
                                mensaje: t('message-success'),
                              }),
                            );
                            const allData = [...pronosticos];
                            allData.splice(inxUpdate, 1, newData);
                            setPronosticos(allData);
                            clearNewData();
                            setInxUpdate(-1);
                          } else {
                            let mensaje = '';
                            if (/UNIQUE KEY/i.test(result.msg)) {
                              mensaje = t('campo_repetido');
                            } else {
                              mensaje = result.msg;
                            }
                            dispatch(
                              setSnackComplete({
                                open: true,
                                severity: 'error',
                                mensaje: `${t('message-error')} ${mensaje}`,
                              }),
                            );
                          }
                        })
                        .catch((error) => {
                          dispatch(
                            setSnackComplete({
                              open: true,
                              severity: 'error',
                              mensaje: `${t('message-error-warning')} ${error.toString()}`,
                            }),
                          );
                        });
                    }
                  }}
                >
                  {inxUpdate === -1 ? t('d-p-plan-pron-agregar') : t('d-p-plan-pron-actualizar')}
                </Button>
              </div>
            </>
            ) : null}
        </>
      ) : null}
      {pronosticos.length > 0 ? (
        <>
          <div className="border-solid border-0 border-b border-gray-600 pt-4" />
          <h2 className="text-blue-500 font-normal m-0 py-4">{t('d-p-plan-pron-lista')}</h2>
          {pronosticos.map((data, inx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`lista-${inx}`}>
              <ListaPronosticos
                {...data}
                newData={newData}
                setNewData={setNewData}
                arrayData={pronosticos}
                deleteDiagnostico={deleteDiagnostico}
                setInxUpdate={setInxUpdate}
                inx={inx}
              />
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};

export default CompPronostico;

import { ChevronDown, XIcon, Search } from 'lucide-react';
import { FC, useEffect, useRef, useState } from 'react';
import { SelectInputProps } from './type';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { GLOBALS } from '@constants/prueba/globals';
import { useBreakPoint } from '@hooks/useBreakpoint';

export const SelectInput: FC<SelectInputProps> = ({
  index,
  name,
  containerClass = '',
  label: externalLabel,
  floatingLabel,
  isRequired,
  options: externalOptions,
  highlightFirst = false,
  placeholder: externalPlaceholder,
  helperText,
  variant = 'normal',
  setValue,
  sendObject = false,
  disableOption,
  value,
  isNumeric = true,
  isDisabled: isDisabledExternal = false,
  position,
  setStatus,
}) => {
  const { size } = useSelector((state: RootState) => state.ScreenSize);
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [label, setLabel] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [options, setOptions] = useState<{ id: number | string; label: string }[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [wasFocused, setWasFocused] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);

  const getStrValue = () => String(typeof value === 'object' ? value.id : value);
  const valueIsNull = !value || ['0', ''].includes(getStrValue());

  const validateSelect = () => {
    setErrorMessage(isRequired && valueIsNull ? t('error_campo_en_blanco') : '');
  };

  useEffect(() => {
    setStatus?.({
      listName: 'missing',
      name,
      value: value !== undefined || value !== null || value !== '',
      index,
    });
  }, [isRequired]);

  useEffect(() => {
    setStatus?.({
      listName: 'error',
      name,
      value: errorMessage.length > 0,
      index,
    });
  }, [errorMessage]);

  useEffect(() => {
    if (floatingLabel) {
      setLabel('');
      setPlaceholder('');
    } else {
      setLabel(externalLabel === undefined ? t(name) : externalLabel);
      setPlaceholder(externalPlaceholder === undefined ? t('selecciona') : externalPlaceholder);
    }
  }, [floatingLabel, externalLabel, externalPlaceholder]);

  useEffect(() => {
    setOptions(externalOptions);
  }, [externalOptions]);

  useEffect(() => {
    setIsDisabled(isDisabledExternal);
  }, [isDisabledExternal]);

  useEffect(() => {
    if (value !== undefined) {
      setSelectedOption(
        valueIsNull
          ? placeholder
          : options.find((opt) => opt.id.toString() === getStrValue())?.label || placeholder,
      );  
    }
  }, [value, options]);

  const handleClickOutside = (event: MouseEvent) => {
    if (!dropdownRef.current?.contains(event.target as Node)) {
      if (isOpen) {
        validateSelect();
      }
      setIsOpen(false);
      setSearchTerm('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, wasFocused]);

  const handleHover = (event: MouseEvent) => {
    setIsHover(!!dropdownRef.current?.contains(event.target as Node));
  };

  useEffect(() => {
    document.addEventListener('mouseover', handleHover);
    return () => document.removeEventListener('mouseover', handleHover);
  }, [isHover]);

  const filteredOptions = options.filter((option) =>
    option.label
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(searchTerm),
  );

  const handleDisableChange = () => {
    const newState = !disableOption?.value;
    setIsDisabled(newState);
    setErrorMessage('');
    if (disableOption?.name) {
      if (disableOption.setValue) {
        disableOption.setValue({ name: disableOption.name, value: newState });
      } else {
        setValue?.({ name: disableOption.name, value: newState });
      }
    }
    if (!newState) {
      setSelectedOption(placeholder);
      setValue?.({ name, value: '' });
      setWasFocused(false);
    }
  };

  const handleSelect = (option: { id: string | number; label: string }) => {
    if (!isDisabled) {
      setSelectedOption(option.label);
      setSearchTerm('');
      setIsOpen(false);
      setErrorMessage('');
      setValue({
        name,
        value: sendObject ? option : option.id,
        position,
      });
    }
  };

  const handleToggleDropdown = () => {
    if (!isDisabled) {
      if (!wasFocused) {
        setWasFocused(true);
      }
      setIsOpen(!isOpen);
      if (isOpen) {
        validateSelect();
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isDisabled) {
      const val = e.target.value as string;
      setSearchTerm(val.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!isWideScreen) {
      setTimeout(() => {
        event.target.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 300);  
    }
  };

  const getStyle = () => {
    if (isDisabled) {
      return 'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed';
    }
    if (errorMessage) {
      return 'border-red-500 ring-2 ring-red-200';
    }
    return `border-gray-300 ${isOpen ? 'ring-2 ring-blue-500' : ''}`;
  };

  return (
    <div className={`w-full h-auto my-[10px] px-4 ${containerClass}`}>
      <div className="w-full">
        <div className="flex items-center justify-between mb-2">
          {label && (
            <label
              htmlFor={name}
              className={`block font-medium text-base ${
                errorMessage ? 'text-red-500' : `text-gray-${isDisabled ? '500' : '700'}`
              }`}
            >
              {label} {isRequired && <span className="text-red-500">*</span>}
            </label>
          )}
          {disableOption?.position === 'right' && (
            <label className="flex items-center text-sm text-gray-600 whitespace-nowrap ml-4">
              <input
                type="checkbox"
                className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
                checked={!!disableOption?.value}
                onChange={handleDisableChange}
              />
              {disableOption.label}
            </label>
          )}
        </div>

        <div className="relative" ref={dropdownRef}>
          {floatingLabel &&
            <label
              onClick={handleToggleDropdown}
              className={`absolute left-3 text-gray-500 bg-white px-1 transition-all 
                ${(isOpen || !valueIsNull) ? "-top-2 text-xs" : "top-3 text-sm"}`}
            >
              {floatingLabel}
            </label>
          }
          {variant === 'normal' ? (
            <div
              className={`w-full h-[48px] border rounded-md flex bg-white justify-between items-center ${getStyle()}`}
            >
              <div className="w-full h-full content-center" onClick={handleToggleDropdown}>
                <span className={`ml-2 ${valueIsNull ? 'text-gray-400' : ''}`}>
                  {selectedOption}
                </span>
              </div>
              <div className="flex flex-row mr-2">
                {!valueIsNull && !isDisabledExternal && (isHover || size === 'sm')
                  ? <XIcon
                    size={18}
                    onClick={() => {
                      if (!isDisabled) {
                        setSelectedOption(placeholder);
                        setValue({
                          name,
                          value: sendObject
                            ? { id: isNumeric ? 0 : '', label: '' }
                            : isNumeric
                              ? 0
                              : '',
                          position,
                        });
                        if (isRequired) {
                          setErrorMessage(t('error_campo_en_blanco'));
                        }
                      }
                    }}
                    className="hover:bg-gray-100 rounded-full"
                  /> : null
                }
                <ChevronDown
                  size={20}
                  className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}
                  hover:bg-gray-100 rounded-full`}
                  onClick={handleToggleDropdown}
                />
              </div>
            </div>
          ) : (
            <div
              className={`relative h-[48px] flex items-center border rounded-md
               ${
                 isDisabled
                   ? 'bg-gray-50 border-gray-300'
                   : `bg-white ${
                       errorMessage ? 'border-red-500 ring-2 ring-red-200' : 'border-gray-300'
                     }`
               }`}
            >
              <div className="w-full flex items-center" onClick={handleToggleDropdown}>
                <Search className="absolute self-center left-2 text-gray-400 bg-white" size={18} />
                <input
                  type="text"
                  disabled={isDisabled}
                  className={`w-full pl-9 pr-8 py-2 focus:outline-none
                  ${valueIsNull ? 'text-gray-400' : ''} ${
                    isDisabled ? 'text-gray-400 cursor-not-allowed' : ''
                  }`}
                  placeholder={placeholder}
                  value={isOpen ? searchTerm : selectedOption}
                  onChange={handleInputChange}
                  onClick={() => !isDisabled && setIsOpen(true)}
                  onFocus={handleFocus}
                />
              </div>
              <div className="flex flex-row">
                {!valueIsNull && !isDisabledExternal && (isHover || size === 'sm')
                  ? <XIcon
                    size={18}
                    onClick={() => {
                      if (!isDisabled) {
                        setSelectedOption(placeholder);
                        setValue({
                          name,
                          value: sendObject
                            ? { id: isNumeric ? 0 : '', label: '' }
                            : isNumeric
                              ? 0
                              : '',
                          position,
                        });
                        if (isRequired) {
                          setErrorMessage(t('error_campo_en_blanco'));
                        }
                      }
                    }}
                    className="hover:bg-gray-100 bg rounded-full"
                  /> : null
                }
                <ChevronDown
                  size={20}
                  className={`mr-2 transition-transform duration-200 ${
                    isOpen ? 'rotate-180' : ''
                  } hover:bg-gray-100 rounded-full`}
                  onClick={handleToggleDropdown}
                />
              </div>
            </div>
          )}

          {isOpen && !isDisabled && (
            <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-56 overflow-auto">
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option, index) => (
                  <div
                    key={option.id}
                    className={`p-2 cursor-pointer hover:bg-gray-100 ${
                      String(option.id) === getStrValue() ? 'bg-blue-50 text-blue-600' : ''
                    } ${highlightFirst && index === 0 ? 'border border-b-gray-300 border-b-[1px]' : ''}`}
                    onClick={() => handleSelect(option)}
                  >
                    {option.label}
                  </div>
                ))
              ) : (
                <div className="p-2 text-gray-500 text-center">{t('sin_resultados')}</div>
              )}
            </div>
          )}
        </div>

        {(errorMessage || helperText) && (
          <p className={`mt-1 text-xs ${errorMessage ? 'text-red-500' : 'text-gray-500'}`}>
            {errorMessage || helperText}
          </p>
        )}
      </div>
      {disableOption?.position === 'after' && (
        <label className="flex items-center text-sm mt-2 text-gray-600 whitespace-nowrap">
          <input
            type="checkbox"
            className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
            checked={isDisabled}
            onChange={handleDisableChange}
          />
          {disableOption.label}
        </label>
      )}
    </div>
  );
};

export default SelectInput;

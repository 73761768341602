import { ChevronDown, MailXIcon } from 'lucide-react';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { SelectInputProps } from './type';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useTranslation } from 'react-i18next';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as FlagMx } from '@assets/flags/mx.svg';
import { ReactComponent as FlagUy } from '@assets/flags/uy.svg';

export const CountryInput: FC<SelectInputProps> = ({
  name,
  label,
  options,
  helperText,
  setValue,
  value,
  isDisabled: isDisabledExternal,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedCountry] = useState<{
    id: string;
    label: string;
    flag: ReactNode;
  }>({ id: '', label: '', flag: null });
  const [isDisabled, setIsDisabled] = useState(false);
  const [wasFocused, setWasFocused] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const flags: { [key: string]: any } = {
    mx: <FlagMx/>,
    uy: <FlagUy/>,
  };
  const countries: {
    id: string;
    label: string;
    flag: ReactNode;
  }[] = (options ? options : t('lista_paises').split(',')).map((pais: string) =>
    ({
      id: pais,
      label: t(`pais_${pais}`),
      flag: flags[pais],
    })
  );

  useEffect(() => {
    if (value) {
      setSelectedCountry(
        countries.find(country => country.id === value) || { id: '', label: '', flag: null }
      );
    }
  }, [value]);

  useEffect(() => {
    if (typeof isDisabledExternal === 'boolean') {
      setIsDisabled(isDisabledExternal);
    }
  }, [isDisabledExternal]);

  useEffect(() => {
    if (typeof isDisabledExternal === 'boolean') {
      setIsDisabled(!isDisabledExternal);
    }
  }, [isDisabledExternal]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, wasFocused]);

  const handleSelect = (country: { id: string; label: string; flag: ReactNode }) => {
    if (!isDisabled) {
      setIsOpen(false);
      setValue({
        name,
        value: country.id,
      });
    }
  };

  const handleToggleDropdown = () => {
    if (!isDisabled) {
      if (!wasFocused) {
        setWasFocused(true);
      }
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className="w-full h-auto px-4">
      <div className="w-full h-[66px] mb-8" ref={dropdownRef}>
        <div className="flex items-center justify-between mb-2">
          {label && (
            <label
              htmlFor={name}
              className={`block font-medium ${
                isDisabled ? 'text-gray-500' : 'text-gray-700'
              }`}
            >
              {label}
            </label>
          )}
        </div>

        <div className="relative">
          <div
            className={`w-full h-[48px] p-2 border rounded-md flex justify-between items-center 
              ${
                isDisabled
                  ? 'bg-gray-50 border-gray-200 text-gray-400 cursor-not-allowed'
                  : 'bg-white border-gray-300 cursor-pointer'
              }`}
            onClick={handleToggleDropdown}
          >
            <SvgIcon className="mr-2">
              {selectedOption.flag}
            </SvgIcon>
            <ChevronDown
              size={20}
              className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
            />
          </div>

          {isOpen && !isDisabled && (
            <div className="absolute z-10 mt-1 w-auto right-0 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
              <>
                {countries.map((country) => (
                  <div
                    key={country.id}
                    className={`p-2 cursor-pointer hover:bg-gray-100 
                      ${
                        country.id.toString() === selectedOption.id.toString()
                          ? 'bg-blue-50 text-blue-600'
                          : ''
                      }`}
                    onClick={() => handleSelect(country)}
                  >
                    {country.label}
                  </div>
                ))}
              </>
            </div>
          )}
        </div>

        {helperText &&
          <p className="mt-1 text-sm text-gray-500">
            {helperText}
          </p>
        }
      </div>
    </div>
  );
};

export default CountryInput;

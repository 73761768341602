import { Check } from 'lucide-react';
import { FC } from 'react';
import { FormProgressStepsProps } from './type';

export const FormProgressSteps: FC<FormProgressStepsProps> = ({
  label, steps, currentIndex, setIndex
}) => (
  <>
    <div className="w-full p-4 h-auto">
      {label && <h2 className="text-xl font-medium text-gray-900 mb-6">{label}</h2>}

      <div className="flex justify-evenly gap-4 w-full max-w-2xl md:max-w-none mx-auto">
        {steps.map((step, index) => (
          <div key={index} className="flex flex-col items-center text-center w-[94px] md:w-full">
            <div
              className={`
                    w-12 h-12 rounded-full cursor-pointer
                    flex items-center justify-center
                    text-lg font-medium mb-2 text-white
                    ${currentIndex === index ? 'bg-blue-900' : 'bg-blue-500'}
                  `}
              onClick={() => {
                if (setIndex) {
                  setIndex(index);
                }
              }}
            >
              {index + 1}
            </div>
            <p className="w-full text-[12px] font-semibold text-center max-w-[120px] md:max-w-none">{step}</p>
          </div>
        ))}
      </div>
    </div>
    <h2 className="hidden md:block text-gray-700 text-center font-medium my-4">{steps[currentIndex]}</h2>    
  </>
);

import { actualizarCampo } from '@actions/infoUser/actions';
import { resetModal, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import { AddButton } from '@components/AddButton';
import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import { NumberInput } from '@components/FormElemntsLibrary/NumberInput';
import { ScaleInput } from '@components/FormElemntsLibrary/ScaleInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { TextInput } from '@components/FormElemntsLibrary/TextInput';
import { ModalForm } from '@components/modals/ModalForm';
import { isOxxo } from '@constants/oxxo';
import {
  IAlimentacion,
  alimentacionInitial,
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Alimentacion/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { RootState } from '@reducer/index';
import {
  antecedentesNoPatologicosGuardarAlimentacion,
  antecedentesNoPatologicosUsuarioGuardarAlimentacion,
  antecedentesNoPatologicosUsuarioPacienteGuardarAlimentacion,
} from '@utils/sendInfo';
import { Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

const alimentoSchema = z.object({
  alimento: z.string(),
  especifica: z.string(),
});

const schema = z.object({
  loaded: z.boolean(),
  dieta: z.string(),
  alimentos: z.array(alimentoSchema),
  botonComidas: z.number().nullable(),
  botonFruta: z.number().nullable(),
  botonVegetales: z.number().nullable(),
  comidas: z.number().nullable(),
  carne: z.number().nullable(),
  carneAlt: z.number().nullable(),
  pollo: z.number().nullable(),
  pescado: z.number().nullable(),
  refrescos: z.number().nullable(),
  postres: z.number().nullable(),
  arrTorFriPas: z.number().nullable(),
  dulPapPast: z.number().nullable(),
  comentarios: z.string(),
  comidasFuera: z.number().nullable(),
  dulces: z.number().nullable(),
});

type FormData = z.infer<typeof schema>;

export const Alimentacion = () => {
  const [formData, setFormData] = useState<FormData>(alimentacionInitial);

  const { alimentacion, cambiosAlimentacion, alimentacionUsuario } = useSelector(
    (state: RootState) => state.InfoUser,
  );
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);

  const dispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<IAlimentacion>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });
  const hayCambios: boolean = idUsuario > 0 && cambiosAlimentacion.filter((c: string) => c.length > 0).length > 0;

  const getDatosAlimentacion = (datos: IAlimentacion) => ({
    dieta: datos.dieta,
    alimentos: datos.alimentos,
    botonComidas: datos.botonComidas,
    botonFruta: datos.botonFruta,
    botonVegetales: datos.botonVegetales,
    comidas: datos.comidas,
    carne: datos.carne,
    carneAlt: datos.carneAlt,
    pollo: datos.pollo,
    pescado: datos.pescado,
    refrescos: datos.refrescos,
    postres: datos.postres,
    arrTorFriPas: datos.arrTorFriPas,
    dulPapPast: datos.dulPapPast,
    comentarios: datos.comentarios,
  });

  const getDatosExpediente = (datos: IAlimentacion) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    alimentacion: getDatosAlimentacion(datos),
  });

  const getDatosUsuario = (datos: IAlimentacion) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    alimentacion: getDatosAlimentacion(datos),
  });

  const handleDeleteFood = (index: number) => {
    if (formData.alimentos.length === 1) {
      setFormData((prev) => ({
        ...prev,
        alimentos: [{ alimento: '', especifica: '' }],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        alimentos: prev.alimentos.filter((_, i) => i !== index),
      }));
    }
  };

  const handleAddFood = () => {
    setFormData((prev) => ({
      ...prev,
      alimentos: [...prev.alimentos, { alimento: '', especifica: '' }],
    }));
  };

  const handleInputChange = (e: { name: string; value: any }) => {
    const { name, value } = e;

    // Manejamos los campos numéricos
    if (['comidas', 'carne', 'pollo', 'pescado', 'refrescos', 'postres'].includes(name)) {
      const numValue = typeof value === 'string' ? value.replace(/\D/g, '') : value;

      setFormData((prev) => ({
        ...prev,
        [name]: numValue ? parseInt(numValue, 10) : null,
      }));
      return;
    }

    // Manejamos los botones de escala
    if (['botonComidas', 'botonFruta', 'botonVegetales'].includes(name)) {
      setFormData((prev) => ({
        ...prev,
        [name]: parseInt(value, 10),
      }));
      return;
    }

    if (name.includes('_')) {
      const [field, index] = name.split('_');
      const idx = parseInt(index, 10);
      const newAlimentos = [...formData.alimentos];
      newAlimentos[idx] = {
        ...newAlimentos[idx],
        [field]: value,
      };
      setFormData((prev) => ({
        ...prev,
        alimentos: newAlimentos,
      }));
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(formData);
    const sendFunctions = [antecedentesNoPatologicosGuardarAlimentacion(datosToSend)];

    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(antecedentesNoPatologicosUsuarioPacienteGuardarAlimentacion(datosToSend));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => {
            dispatch(actualizarCampo('cambiosAlimentacion', { ...formData, loaded: true }));

            dispatch(actualizarCampo('alimentacionUsuario', { ...formData, loaded: true }));

            dispatch(resetModal('Alimentacion'));
            dispatch(setActiveModal('Habitos'));
          },
        ],
      }),
    );
  };

  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioGuardarAlimentacion(
          getDatosUsuario(formData),
        ),
        successFunction: () => {
          dispatch(actualizarCampo('alimentacion', { ...formData, loaded: true }));
          dispatch(resetModal('Alimentacion'));
          dispatch(setActiveModal('Habitos'));
        },
      }),
    );
  };

  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosGuardarAlimentacion(
          getDatosExpediente(alimentacionUsuario),
        ),
        successFunction: () => {
          dispatch(actualizarCampo('alimentacion', alimentacionUsuario));
          setFormData(alimentacionUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarAlimentacion(
          getDatosExpediente(alimentacion),
        ),
        successFunction: () => dispatch(actualizarCampo('alimentacionUsuario', alimentacion)),
      }),
    );
  };

  const handleSubmitForm = async () => {
    try {
      console.log(formData);
      await schema.parseAsync(formData);

      if (esPaciente) {
        guardarDatosUsuario();
      } else {
        guardarDatosExpediente();
      }
    } catch (error) {
      console.error('Error en el envío del formulario:', error);
    }
  };

  useEffect(() => {
    setFormData({ ...alimentacion, dulces: 1, comidasFuera: 1 });
  }, [alimentacion]);
  useEffect(() => {
    reset(formData);
  }, [formData]);

  return (
    <ModalForm
      title="Alimentación"
      id="Alimentacion"
      submitForm={handleSubmit(handleSubmitForm)}
      hayCambios={hayCambios}
      aceptarCambios={guardarDatosDeUsuarioAExpediente}
      rechazarCambios={guardarDatosDeExpedienteAUsuario}
    >
      <div className="w-full flex flex-col gap-6">
        <div>
          <h2 className="px-4 text-lg font-medium mb-4">Restricciones alimentarias</h2>

          <div className="flex flex-col gap-4">
            {!isOxxo && (
              <>
                <CheckboxInput
                  label="¿Tienes alguna restricción para algún alimento?"
                  name="dieta"
                  options={[
                    { value: 'F', label: 'No' },
                    { value: 'T', label: 'Sí' },
                  ]}
                  value={formData.dieta}
                  setValue={handleInputChange}
                  distribution="horizontal-inline"
                  errorMessage={errors.dieta?.message}
                />
              </>
            )}
            {formData.dieta === 'T' && (
              <>
                {formData.alimentos.map((alimento, index) => (
                  <div key={`${alimento.especifica}_${alimento.alimento}`} className="flex flex-col w-full h-auto bg-[#F3F4F6] rounded-md">
                    <div className="w-full flex justify-end p-3">
                      {index !== 0 && (
                        <button
                          type="button"
                          className="text-gray-400 hover:text-gray-600 transition-colors"
                          onClick={() => handleDeleteFood(index)}
                        >
                          <Trash2 size={18} />
                        </button>
                      )}
                    </div>

                    <div className="pb-4">
                      <div className="grid grid-cols-1 md:grid-cols-2">
                        <TextInput
                          label="Alimentos restringidos"
                          name={`alimento_${index}`}
                          value={alimento.alimento}
                          setValue={handleInputChange}
                          errorMessage={errors.alimentos?.[index]?.alimento?.message}
                        />
                        <TextInput
                          label="Especifica"
                          name={`especifica_${index}`}
                          placeholder="(Ej. iniciativa propia, alérgica, intolerancia, indicación médica)"
                          value={alimento.especifica}
                          setValue={handleInputChange}
                          errorMessage={errors.alimentos?.[index]?.especifica?.message}
                        />
                      </div>
                    </div>
                  </div>
                ))}

                <AddButton
                  variant="enabled"
                  label="Agregar otra restricción"
                  onClick={handleAddFood}
                />
              </>
            )}
          </div>
        </div>

        {/* Sección Consumo Diario */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <ScaleInput
            name="botonComidas"
            options={[
              { value: '1' },
              { value: '2' },
              { value: '3' },
              { value: '4' },
              { value: '5' },
            ]}
            value={formData.botonComidas?.toString() || ''}
            setValue={handleInputChange}
            label="Comidas al día"
            errorMessage={errors.botonComidas?.message}
          />

          {!isOxxo && (
            <>
              <ScaleInput
                name="botonFruta"
                options={[
                  { value: '1' },
                  { value: '2' },
                  { value: '3' },
                  { value: '4' },
                  { value: '5' },
                ]}
                value={formData.botonFruta?.toString() || ''}
                setValue={handleInputChange}
                label="Consumo de frutas al día"
                errorMessage={errors.botonFruta?.message}
              />
            </>
          )}

          <ScaleInput
            name="botonVegetales"
            options={[
              { value: '1' },
              { value: '2' },
              { value: '3' },
              { value: '4' },
              { value: '5' },
            ]}
            value={formData.botonVegetales?.toString() || ''}
            setValue={handleInputChange}
            label="Consumo de vegetales al día"
            errorMessage={errors.botonVegetales?.message}
          />
        </div>

        {/* Sección Recurrencia Semanal */}
        <div>
          <h2 className="px-4 text-lg font-medium mb-4">Recurrencia por semana</h2>

          <div className="grid grid-cols-2 lg:grid-cols-3">
            {!isOxxo && (
              <>
                <NumberInput
                  label="Comidas fuera de casa"
                  name="comidasFuera"
                  fixedValue="Veces"
                  value={formData.comidasFuera || 0}
                  setValue={handleInputChange}
                  errorMessage={errors.comidasFuera?.message}
                />
              </>
            )}

            <NumberInput
              label="Carne roja"
              name="carne"
              fixedValue="Veces"
              value={formData.carne || 0}
              setValue={handleInputChange}
              errorMessage={errors.carne?.message}
            />

            {!isOxxo && (
              <>
                <NumberInput
                  label="Pollo"
                  name="pollo"
                  fixedValue="Veces"
                  value={formData.pollo || 0}
                  setValue={handleInputChange}
                  errorMessage={errors.carne?.message}
                />
                <NumberInput
                  label="Pescado"
                  name="pescado"
                  fixedValue="Veces"
                  value={formData.pescado || 0}
                  setValue={handleInputChange}
                  errorMessage={errors.pescado?.message}
                />
                <NumberInput
                  label="Refrescos"
                  name="refrescos"
                  fixedValue="Veces"
                  value={formData.refrescos || 0}
                  setValue={handleInputChange}
                  errorMessage={errors.refrescos?.message}
                />
                <NumberInput
                  label="Postres"
                  name="postres"
                  fixedValue="Veces"
                  value={formData.postres || 0}
                  setValue={handleInputChange}
                  errorMessage={errors.postres?.message}
                />
                <NumberInput
                  label="Arroz, tortilla, frijol"
                  name="comidas"
                  fixedValue="Veces"
                  value={formData.comidas || 0}
                  setValue={handleInputChange}
                  errorMessage={errors.comidas?.message}
                />
                <NumberInput
                  label="Dulces, chips, postres"
                  name="dulces"
                  fixedValue="Veces"
                  value={formData.dulces || 0}
                  setValue={handleInputChange}
                  errorMessage={errors.dulces?.message}
                />
              </>
            )}
          </div>
        </div>

        {/* Comentarios */}
        <div className="mt-5">
          {!esPaciente && (
            <TextArea
              name="comentarios"
              label="Comentarios"
              isRequired={false}
              value={formData.comentarios}
              setValue={handleInputChange}
              errorMessage={errors.comentarios?.message}
            />
          )}
        </div>
      </div>
    </ModalForm>
  );
};

export default Alimentacion;

import dayjs from 'dayjs';
import { IEdad, IFechaObj } from './types';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

export function capitalize(string: string) {
  const str = string.toLowerCase();
  return str[0].toUpperCase() + str.slice(1);
}

export function calculateEdad(anio: string, mes: string, dia: string) {
  const ahora = dayjs();
  const fecha = dayjs(`${anio} ${mes} ${dia}`, 'YYYY M D');
  return ahora.diff(fecha, 'year').toString();
}

export function calculateMeses(anio: string, mes: string, dia: string) {
  const fecha = dayjs(`${anio} ${mes} ${dia}`, 'YYYY M D');
  const ahora = dayjs();
  const hoy = dayjs(`${ahora.year()} ${ahora.month() + 1} ${ahora.date()}`, 'YYYY M D');
  return hoy.diff(fecha, 'month');
}

export function calculateDias(anio: string, mes: string, dia: string) {
  const fecha = dayjs(`${anio} ${mes} ${dia}`, 'YYYY M D');
  const ahora = dayjs();
  const hoy = dayjs(`${ahora.year()} ${ahora.month() + 1} ${ahora.date()}`, 'YYYY M D');
  if (fecha.isSame(hoy)) {
    return 1;
  }
  return hoy.diff(fecha, 'day');
}

export function capitalizeLetter(string: string) {
  const str = string.toLowerCase().trim().split(/\s+/);
  for (let i = 0, x = str.length; i < x; i += 1) {
    if (str[i].length > 1) {
      str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    } else {
      str[i] = str[i].toUpperCase();
    }
  }
  return str.join(' ');
}

export function validarFecha(anio: string, mes: string, dia: string) {
  const fecha = dayjs(`${anio} ${mes} ${dia}`, 'YYYY M D', true);
  return fecha.format('YYYY M D') === `${anio} ${mes} ${dia}`;
}

export function validarFechaEsAnterior(anio: string, mes: string, dia: string) {
  const fecha = dayjs(`${anio} ${mes} ${dia}`, 'YYYY M D');
  const ahora = dayjs();
  return ahora.diff(fecha) >= 0;
}

export function validarFechaEsfutura(anio: string, mes: string, dia: string) {
  const fecha = dayjs(`${anio} ${mes} ${dia}`, 'YYYY M D');
  const ahora = dayjs();
  const hoy = dayjs(`${ahora.year()} ${ahora.month() + 1} ${ahora.date()}`, 'YYYY M D');
  return hoy.diff(fecha) <= 0;
}

export function calcularSemanasGestacion(anio: string, mes: string, dia: string) {
  const fecha = dayjs(`${anio} ${mes} ${dia}`, 'YYYY M D');
  const ahora = dayjs();
  const hoy = dayjs(`${ahora.year()} ${ahora.month() + 1} ${ahora.date()}`, 'YYYY M D');
  if (ahora.diff(fecha) >= 0 && fecha.format('YYYY M D') === `${anio} ${mes} ${dia}`) {
    const totalDias = hoy.diff(fecha) / 86400000;
    const semanas = Math.floor(totalDias / 7);
    const dias = Math.floor(totalDias % 7);
    return { dias, semanas, error: false };
  }
  return { error: true };
}

export function valFechaToSave(fecha: IFechaObj) {
  let newDate: IFechaObj = { dia: '', mes: '', anio: '' };
  if (
    (fecha.dia.length > 0 && fecha.mes.length > 0 && fecha.anio.length > 0) ||
    (fecha.dia.length === 0 && fecha.mes.length > 0 && fecha.anio.length > 0) ||
    (fecha.dia.length === 0 && fecha.mes.length === 0 && fecha.anio.length > 0)
  ) {
    newDate = fecha;
  } else if (fecha.dia.length > 0 && fecha.anio.length > 0) {
    newDate = { dia: '', mes: '', anio: fecha.anio };
  }
  return newDate;
}

export function stringMes(mes: string, t: any) {
  return t(`m${mes}`);
}

export function stringMesKey(mes: string) {
  return `m${mes}`;
}

// regresa un objeto IFechaOBj en string
export function getFechaActual() {
  const ahora = dayjs();
  return {
    dia: ahora.date().toString(),
    mes: (ahora.month() + 1).toString(),
    anio: ahora.year().toString(),
  };
}

// regresa un string con la fecha en formato
// ISO8601
export function getFechaActualISO() {
  return dayjs().format();
}

export function esMismoDiaFechaISO(fecha1: string, fecha2: string) {
  const f1 = dayjs(fecha1);
  const f2 = dayjs(fecha2);
  return f1.date() === f2.date() && f1.month() === f2.month() && f1.year() === f2.year();
}

export function formatoDeIsoAFechaHora(fecha: string, t: any) {
  const fe = dayjs(fecha);
  return `${fe.date().toString().padStart(2, '0')}/${`${stringMes(
    (fe.month() + 1).toString(),
    t,
  )}`}/${fe.year()} ${fe.hour().toString().padStart(2, '0')}:${fe
    .minute()
    .toString()
    .padStart(2, '0')}`;
}

// regresa un objeto tipo IEdad
// un objeto con la propiedad edad numerico de 1 - 120
// clave edad con los valores
// 3 - dias
// 4 - meses
// 5 - meses
// y periodo correspondiente a la label años, meses o dias
export function edadCalculada(anio: string, mes: string, dia: string) {
  let edadCalc = parseInt(calculateEdad(anio, mes, dia), 10);
  let periodo = '' as '' | 'anios' | 'meses' | 'dias';
  let claveEdad = 0;
  if (edadCalc >= 1) {
    periodo = 'anios';
    claveEdad = 5;
  } else {
    edadCalc = calculateMeses(anio, mes, dia);
    if (edadCalc >= 1) {
      periodo = 'meses';
      claveEdad = 4;
    } else {
      edadCalc = calculateDias(anio, mes, dia);
      if (edadCalc === 30) {
        periodo = 'meses';
        claveEdad = 4;
        edadCalc = 1;
      } else {
        periodo = 'dias';
        claveEdad = 3;
      }
    }
  }
  if (Number.isNaN(edadCalc)) {
    edadCalc = 0;
  }
  return { edad: edadCalc, claveEdad, periodo };
}

// para validacion de edad en inmunizaciones para meses de 2 a 4
export function edadAnioMesDia(anio: string, mes: string, dia: string) {
  let edadCalc = parseInt(calculateEdad(anio, mes, dia), 10);
  let periodo = '' as '' | 'anios' | 'meses' | 'dias';
  if (edadCalc >= 2) {
    periodo = 'anios';
  } else {
    edadCalc = calculateMeses(anio, mes, dia);
    if (edadCalc >= 1) {
      periodo = 'meses';
    } else {
      edadCalc = calculateDias(anio, mes, dia);
      if (edadCalc === 30) {
        periodo = 'meses';
        edadCalc = 1;
      } else {
        periodo = 'dias';
      }
    }
  }
  if (Number.isNaN(edadCalc)) {
    edadCalc = 0;
  }
  return { edad: edadCalc, periodo };
}

// `keyof any` is short for "string | number | symbol"
// since an object key can be any of those types, our key can too
// in TS 3.0+, putting just "string" raises an error
export function hasKey<O>(obj: object, key: keyof any): key is keyof O {
  return key in obj;
}

// esta funcion recibe dos IFechaObj y los compara
// para saber si es el mismo dia
export function esMismaFecha(fecha1: IFechaObj, fecha2: IFechaObj) {
  return fecha1.anio === fecha2.anio && fecha1.mes === fecha2.mes && fecha1.dia === fecha2.dia;
}

// esta funcion recibe un objeto IEdad y verifica
// que cumpla con la validacion de la guia
// para dias de 1-30
// para meses de 1-11
// para años de 1-120
export function verificarIEdadValida(edad: IEdad) {
  switch (edad.claveEdad) {
    case 3:
      return edad.edad > 0 && edad.edad <= 30;
    case 4:
      return edad.edad > 0 && edad.edad <= 11;
    case 5:
      return edad.edad > 0 && edad.edad <= 120;
    default:
      return false;
  }
}

// esta function recibe dos objetos IEdad y verifica
// que la el primero no sea mayor que el segundo
export function iEdadMenorOIgual(edadIntroducida: IEdad, edadPaciente: IEdad) {
  if (edadIntroducida.claveEdad === 0 || edadPaciente.claveEdad === 0) {
    return false;
  }
  const edIn = edadIntroducida.edad;
  const clvEdIn = edadIntroducida.claveEdad;
  const edPc = edadPaciente.edad;
  const clvEdPc = edadPaciente.claveEdad;
  switch (clvEdPc) {
    case 3:
      if (clvEdIn === clvEdPc) {
        return edIn <= edPc;
      }
      return false;

    case 4:
      if (clvEdIn <= clvEdPc) {
        if (clvEdIn === clvEdPc) {
          return edIn <= edPc;
        }
        return true;
      }
      return false;

    case 5:
      if (clvEdIn <= clvEdPc) {
        if (clvEdIn === clvEdPc) {
          return edIn <= edPc;
        }
        return true;
      }
      return false;

    default:
      return false;
  }
}

export function currentSmallDatatimeFormat() {
  return dayjs().format('YYYY-MM-DD HH:mm:ss');
}

export function currentSmallDatatimeFormatDate(date: Date) {
  return dayjs(date.toString()).format('YYYY-MM-DD HH:mm:ss');
}

export function currentSmallDateTimePlusDurationAndPeriodDate(
  date: Date,
  duracion: number,
  periodo: number,
) {
  const ahora = dayjs(date.toString());
  let suma;
  switch (periodo) {
    // años
    case 1:
      suma = ahora.add(duracion, 'years');
      break;
    // meses
    case 2:
      suma = ahora.add(duracion, 'months');
      break;
    // semanas
    case 3:
      suma = ahora.add(duracion, 'weeks');
      break;
    // dias
    case 4:
      suma = ahora.add(duracion, 'days');
      break;
    // horas
    case 5:
      suma = ahora.add(duracion, 'hours');
      break;
    // minutos
    case 6:
      suma = ahora.add(duracion, 'minutes');
      break;
    default:
      suma = ahora;
      break;
  }
  return suma.format('YYYY-MM-DD HH:mm:ss');
}

export function currentSmallDateTimeSubstractDurationAndPeriodDate(
  date: Date,
  duracion: number,
  periodo: number,
) {
  const ahora = dayjs(date.toString());
  let suma;
  switch (periodo) {
    // años
    case 1:
      suma = ahora.subtract(duracion, 'years');
      break;
    // meses
    case 2:
      suma = ahora.subtract(duracion, 'months');
      break;
    // semanas
    case 3:
      suma = ahora.subtract(duracion, 'weeks');
      break;
    // dias
    case 4:
      suma = ahora.subtract(duracion, 'days');
      break;
    // horas
    case 5:
      suma = ahora.subtract(duracion, 'hours');
      break;
    // minutos
    case 6:
      suma = ahora.subtract(duracion, 'minutes');
      break;
    default:
      suma = ahora;
      break;
  }
  return suma.format('YYYY-MM-DD HH:mm:ss');
}

export function currentSmallDateTimePlusDurationAndPeriod(duracion: number, periodo: number) {
  const ahora = dayjs();
  let suma;
  switch (periodo) {
    // años
    case 1:
      suma = ahora.add(duracion, 'years');
      break;
    // meses
    case 2:
      suma = ahora.add(duracion, 'months');
      break;
    // semanas
    case 3:
      suma = ahora.add(duracion, 'weeks');
      break;
    // dias
    case 4:
      suma = ahora.add(duracion, 'days');
      break;
    // horas
    case 5:
      suma = ahora.add(duracion, 'hours');
      break;
    // minutos
    case 6:
      suma = ahora.add(duracion, 'minutes');
      break;
    default:
      suma = ahora;
      break;
  }
  return suma.format('YYYY-MM-DD HH:mm:ss');
}

/*
 * Tomando en cuenta el siguiente catalogo en la BD
 * llamado CS_MCPA_RANGO_EDAD convierte un objeto tipo
 * IEdad a un valor del siguiente catalogo (a exepcion de 1 que es para todos los rangos)
 * 1 Aplica a todos los rangos
 * 2 Neonato (menor de 28 días)
 * 3 Lactante menor o infante (menor de un año)
 * 4 Lactante mayor (un año a un año 11 meses)
 * 5 Preescolar (dos a cuatro años)
 * 6 Escolar (de cinco a nueve años)
 * 7 Adolescente (de diez a diecinueve años)
 * 8 Adulto
 * 9 Adulto mayor (mayor de 60 años)
 */
// export function IEdadACSMCPARangoEdad(edad: IEdad) {
//   if (edad.claveEdad === 3) {
//     if (edad.edad <= 28) {
//       return 2;
//     }
//     return 3;
//   }
//   if (edad.claveEdad === 4) {
//     return 3;
//   }
//   if (edad.claveEdad === 5) {
//     if (edad.edad < 2) {
//       return 4;
//     }
//     if (edad.edad < 5) {
//       return 5;
//     }
//     if (edad.edad < 10) {
//       return 6;
//     }
//     if (edad.edad < 20) {
//       return 7;
//     }
//     if (edad.edad < 60) {
//       return 8;
//     }
//     if (edad.edad > 60) {
//       return 9;
//     }
//   }
//   return 0;
// }
/*
 * Tomando en cuenta el siguiente catalogo en la BD
 * llamado CS_MCPA_RANGO_EDAD convierte un objeto tipo
 * cambiando de acuerdo a las nuevas reglas de Carluis 08/12/21
 * IEdad a un valor del siguiente catalogo (a exepcion de 1 que es para todos los rangos)
 * 1 Aplica a todos los rangos
 * 2 Neonato (menor de 28 días)
 * 3 Lactante (1 mes a 1 año 11 meses)
 * 4 Preescolar y escolar (2 a 9 años)
 * 5 Adolescente (de 10 a 19 años)
 * 6 Adulto (mayor a 20 años)
 */
export function IEdadACSMCPARangoEdad(edad: IEdad) {
  if (edad.claveEdad === 3) {
    if (edad.edad <= 28) {
      return 2;
    }
    return 3;
  }
  if (edad.claveEdad === 4) {
    return 3;
  }
  if (edad.claveEdad === 5) {
    if (edad.edad < 2) {
      return 3;
    }
    if (edad.edad < 10) {
      return 4;
    }
    if (edad.edad < 20) {
      return 5;
    }
    if (edad.edad >= 20) {
      return 6;
    }
  }
  return 0;
}

/*
 * tomando en cuenta el siguiente catalogo de la BD llamado
 * CS_MCPA_MOTIVO_SEXO
 * 1 Ambos
 * 2 Mujer
 * 3 Hombre
 */
export function sexoPacienteACSMCPASexo(sexo: string) {
  if (sexo === 'H') {
    return 3;
  }
  if (sexo === 'M') {
    return 2;
  }
  return 0;
}

interface IParametrosFecha {
  sep?: '/' | '-';
  dia?: 'D' | 'DD';
  mes?: 'MMM' | 'MMMM';
  anio?: 'YYY' | 'YYYY';
}

const parametrosFechaInitial: IParametrosFecha = {
  sep: '/',
  dia: 'D',
  mes: 'MMMM',
  anio: 'YYYY',
};

// regresa un string formateado como ejem. 1/Enero/2021
// tiene que recibir una fecha en formato iso ejm. 2021-01-01
export function fechaISOADiaMesAnioStr(fecha: string, params?: IParametrosFecha) {
  const p: IParametrosFecha = { ...parametrosFechaInitial, ...params };
  return dayjs(fecha).format(`${p.dia}${p.sep}${p.mes}${p.sep}${p.anio}`);
}

export function regexPassword(password: string) {
  return /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(password);
}

// regresa un string formateado como ejem. 1/Enero/2021
// tiene que recibir un epoch unix en numerico
export function epochTimeToDiaMesAnioStr(epoch: number) {
  return dayjs.unix(epoch).format('D/MMMM/YYYY');
}

import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

import {
  getPaciente,
  getUsuarioPaciente,
  setPacienteEdad,
  setPacienteNombre,
  setPacienteSexo,
  setPacienteTelefonos,
  setPacienteTipoEmpleado,
} from '@actions/basicosPaciente/actions';
import { setIdPacienteConsulta } from '@actions/consulta/actions';
import { actualizarCampo } from '@actions/infoUser/actions';
import { getMeMedico } from '@actions/me/actions';
import { resetModal, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import { edadCalculada } from '@common/functions';
import AlertDialog from '@components/AlertDialogs/AlertDialog';
import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import DateInput from '@components/FormElemntsLibrary/DateInput2';
import { EmailInput } from '@components/FormElemntsLibrary/EmailInput';
import { PhoneInput } from '@components/FormElemntsLibrary/PhoneInput';
import { SelectInput } from '@components/FormElemntsLibrary/SelectInput';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import AccesoPIN from '@components/ModalDialogs/AccesoPIN';
import { ModalForm } from '@components/modals/ModalForm';
import { isOxxo } from '@constants/oxxo';
import {
  datosPersonalesInitial,
  IDatosPersonales,
} from '@containers/TabsPaciente/PerfilPaciente/DatosPersonales/types';
import { zodResolver } from '@hookform/resolvers/zod';

import { RootState } from '@reducer/index';
import {
  getCatalogoEscolaridad,
  getCatalogoEstadoCivil,
  getCatalogoGrupoEtnico,
  getCatalogoNacionalidad,
  getCatalogoReligion,
  getCatalogoTiempoOcupacion,
  getCatalogoTipoIdentificacion,
  getCatalogoTipoOcupacion,
} from '@utils/getCatalogos';
import {
  guardarTipoEmpleado,
  updateDatosPersonales,
  updateDatosPersonalesPaciente,
  updateDatosPersonalesUsuario,
} from '@utils/sendInfo';
import { Catalogos } from './type';

export const schema = z.object({
  loaded: z.boolean(),
  nombre: z.string().min(3, { message: 'El nombre debe tener al menos 3 letras' }),
  primerApellido: z.string(),
  segundoApellido: z.string(),
  sexo: z.string(),
  fechaNac: z.object({
    dia: z.string(),
    mes: z.string(),
    anio: z.string(),
  }),
  nacionalidad: z.object({
    id: z.string(),
    label: z.string(),
  }),
  grupoEtnico: z.object({
    id: z.number(),
    label: z.string(),
  }),
  estadoCivil: z.object({
    id: z.number(),
    label: z.string(),
  }),
  escolaridad: z.object({
    id: z.number(),
    label: z.string(),
  }),
  religion: z.object({
    id: z.number(),
    label: z.string(),
  }),
  otraReligion: z.string(),
  docId: z.string(),
  noPoseeDocId: z.boolean(),
  tipoIdentificacion: z.object({
    id: z.number(),
    label: z.string(),
  }),
  numeroDocumento: z.string(),
  ocupacion: z.string(),
  tipoOcupacion: z.object({
    id: z.number(),
    label: z.string(),
  }),
  tiempoOcupacion: z.object({
    id: z.number(),
    label: z.string(),
  }),
  celular: z.object({
    numero: z.string(),
    codigo: z.string(),
  }),
  noTieneCelular: z.boolean(),
  fijo: z.object({
    numero: z.string(),
    codigo: z.string(),
  }),
  email: z.string(),
  correoRecuperacion: z.string(),
  noTieneEmail: z.boolean(),
  comoEntero: z.string(),
  otroComoEntero: z.string(),
});

type FormData = z.infer<typeof schema>;

export const Perfil: FC = () => {
  const [formData, setFormData] = useState<FormData>(datosPersonalesInitial);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMensaje, setAlertMensaje] = useState<string>('');
  const [modalPin, setModalPin] = useState<boolean>(false);
  const [pinStr, setPinStr] = useState<string>('');
  const [catalogos, setCatalogos] = useState<Catalogos>({
    catNacionalidad: [],
    catGrupoEtnico: [],
    catTipoIdentificacion: [],
    catEscolaridad: [],
    catTipoOcupacion: [],
    catTiempoOcupacion: [],
    catReligion: [],
    catEstadoCivil: [],
  });

  const {
    datosPersonales, cambiosDatosPersonales, datosPersonalesUsuario, tipoPaciente,
  } = useSelector((state: RootState) => state.InfoUser);
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
    email,
  } = useSelector((state: RootState) => state.Me);
  const {
    idPaciente, idUsuario, docId, sinDocId, tipoEmpleado,
  } = useSelector(
    (state: RootState) => state.BasicosPaciente,
  );
  const { idConsulta, idPaciente: idPacienteConsulta } = useSelector(
    (state: RootState) => state.Consulta,
  );
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    organizacion: { idOrganizacion },
  } = useSelector((state: RootState) => state.Organizaciones);
  const dispatch = useDispatch();

  // TODO: la variable tipoPaciente llega null y el action no puede recibir el parametro null sino que debe ser de tipo string
  const saveTipoEmpleado = () => {
    if (tipoPaciente) {
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: guardarTipoEmpleado({
            idMedico,
            idPaciente,
            idConsultorio,
            idOrganizacion,
            tipoEmpleado: tipoPaciente,
          }),
          successFunction: () => {
            dispatch(setPacienteTipoEmpleado(tipoPaciente));
          },
        }),
      );
    }
  };

  useEffect(() => {
    setFormData({
      ...datosPersonales,
      celular: {
        ...datosPersonales.celular,
        codigo: String(datosPersonales.celular.codigo)
      },
      fijo: {
        ...datosPersonales.fijo,
        codigo: String(datosPersonales.fijo.codigo)
      }
    });
  }, [datosPersonales]);
  useEffect(() => {
    const fetchCatalogos = async () => {
      const catNacionalidad = await getCatalogoNacionalidad();
      const catGrupoEtnico = await getCatalogoGrupoEtnico();
      const catTipoIdentificacion = await getCatalogoTipoIdentificacion();
      const catEscolaridad = await getCatalogoEscolaridad();
      const catTipoOcupacion = await getCatalogoTipoOcupacion();
      const catTiempoOcupacion = await getCatalogoTiempoOcupacion();
      const catReligion = await getCatalogoReligion();
      const catEstadoCivil = await getCatalogoEstadoCivil();

      setCatalogos({
        catNacionalidad,
        catGrupoEtnico,
        catTipoIdentificacion,
        catEscolaridad,
        catTipoOcupacion,
        catTiempoOcupacion,
        catReligion,
        catEstadoCivil,
      });
    };

    fetchCatalogos();
  }, []);

  useEffect(() => {
    if (idPaciente === -1) {
      setFormData({
        ...formData,
        noPoseeDocId: !!sinDocId,
        docId,
      });
    } else if (idPaciente > 0 && !tipoEmpleado) {
      saveTipoEmpleado();
    }
  }, [idPaciente]);

  const { t } = useTranslation();
  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });
  const edad = edadCalculada(formData.fechaNac.anio, formData.fechaNac.mes, formData.fechaNac.dia);
  const hayCambios: boolean = idUsuario > 0 && cambiosDatosPersonales.filter((c: string) => c.length > 0).length > 0;

  useEffect(() => {
    reset(formData);
  }, [formData]);

  const handleInputChange = (e: { name: string; value: any }) => {
    const { name, value } = e;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    setAlertOpen(false);
  };

  const getData = (datos: IDatosPersonales, idUser?: number) => ({
    idMedico,
    idPaciente,
    idUsuario: idUser,
    idConsulta,
    idConsultorio,
    idOrganizacion,
    nombre: datos.nombre.trim().split(/\s+/).join(' '),
    primerApellido: datos.primerApellido.trim().split(/\s+/).join(' '),
    segundoApellido: datos.segundoApellido.trim().split(/\s+/).join(' '),
    sexo: datos.sexo,
    fechaDia: datos.fechaNac.dia,
    fechaMes: datos.fechaNac.mes,
    fechaAnio: datos.fechaNac.anio,
    nacionalidad: datos.nacionalidad.id,
    grupoEtnico: datos.grupoEtnico ? datos.grupoEtnico.id : 0,
    estadoCivil: datos.estadoCivil ? datos.estadoCivil.id : 0,
    escolaridad: datos.escolaridad ? datos.escolaridad.id : 0,
    religion: datos.religion ? datos.religion.id : 0,
    otraReligion: datos.otraReligion,
    docId: datos.docId,
    noPoseeDocId: datos.noPoseeDocId,
    tipoIdentificacion: datos.tipoIdentificacion ? datos.tipoIdentificacion.id : 0,
    numeroDocumento: datos.numeroDocumento,
    ocupacion: datos.ocupacion,
    tipoOcupacion: datos.tipoOcupacion ? datos.tipoOcupacion.id : 0,
    tiempoOcupacion: datos.tiempoOcupacion ? datos.tiempoOcupacion.id : 0,
    ladaCel: datos.celular.codigo,
    celular: datos.celular.numero,
    noTieneCelular: datos.noTieneCelular,
    ladaTel: datos.fijo.codigo,
    fijo: datos.fijo.numero,
    email: datos.email,
    noTieneEmail: datos.noTieneEmail,
    comoEntero: datos.comoEntero,
    otroComoEntero: datos.otroComoEntero,
  });

  const guardarDatosExpediente = () => {
    const dataPersonal = getData(formData, idUsuario);
    const requestFunctions = [updateDatosPersonales(dataPersonal)];
    const successFunctions = [
      (result: any) => {
        if (idPaciente === -1) {
          console.log(result.inmunizaciones);
        }
        if (formData.sexo === 'H') {
          dispatch(actualizarCampo('enGestacion', ''));
        }
        dispatch(getPaciente(parseInt(result.datos, 10), idMedico, idOrganizacion, idConsultorio));
        if (idPacienteConsulta <= 0) {
          dispatch(setIdPacienteConsulta(parseInt(result.datos, 10)));
        }
        dispatch(actualizarCampo('datosPersonales', { ...formData, loaded: true }));
        if (result.pin) {
          setModalPin(true);
          setPinStr(result.pin);
        }
      },
    ];
    if (!esPaciente && idUsuario && idUsuario > 0) {
      requestFunctions.push(updateDatosPersonalesPaciente(dataPersonal));
      successFunctions.push(() => {
        dispatch(
          actualizarCampo('datosPersonalesUsuario', {
            ...formData,
            loaded: true,
          }),
        );
      });
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions,
        successFunctions,
        showMsgSuccess: true,
      }),
    );
  };

  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosPersonales(getData(datosPersonalesUsuario)),
        successFunction: () => {
          dispatch(actualizarCampo('datosPersonalesUsuario', datosPersonalesUsuario));
          setFormData(datosPersonalesUsuario);
          if (datosPersonalesUsuario.sexo === 'H') {
            dispatch(actualizarCampo('enGestacion', ''));
          }
        },
      }),
    );
  };

  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosPersonalesPaciente(getData(datosPersonales, idUsuario)),
        successFunction: () => dispatch(actualizarCampo('datosPersonalesUsuario', datosPersonales)),
      }),
    );
  };

  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosPersonalesUsuario(getData(formData, idMiUsuario)),
        successFunction: () => {
          dispatch(
            actualizarCampo('datosPersonales', {
              ...formData,
              loaded: true,
            }),
          );
          if (formData.sexo === 'H') {
            dispatch(actualizarCampo('enGestacion', ''));
          }
          dispatch(getMeMedico(email));
          dispatch(getUsuarioPaciente(idMedico));
          dispatch(
            setPacienteNombre(
              `${formData.nombre} ${formData.primerApellido} ${formData.segundoApellido}`,
            ),
          );
          dispatch(setPacienteSexo(formData.sexo));
          dispatch(setPacienteEdad(edad));
          dispatch(
            setPacienteTelefonos([
              datosPersonales.celular.numero
                ? `${datosPersonales.celular.codigo}${datosPersonales.celular.numero}`
                : '',
              datosPersonales.fijo.numero
                ? `${datosPersonales.fijo.codigo}${datosPersonales.fijo.numero}`
                : '',
            ]),
          );
          dispatch(resetModal('Perfil'));
          dispatch(setActiveModal('Ubication'));
        },
      }),
    );
  };

  const handleSubmitForm = async () => {
    try {
      if (esPaciente) {
        guardarDatosUsuario();
      } else {
        if (errors) {
          setAlertOpen(true);
          setAlertMensaje(t('texto_llenado_norma_oficial'));
          return;
        }
        guardarDatosExpediente();
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  return (
    <>
      <ModalForm
        currentStep={1}
        title="Perfil"
        id="Perfil"
        submitForm={handleSubmit(handleSubmitForm)}
        hayCambios={hayCambios}
        aceptarCambios={guardarDatosDeUsuarioAExpediente}
        rechazarCambios={guardarDatosDeExpedienteAUsuario}
      >
        <div className="flex flex-col md:gap-2">
          {/* Datos Personales */}
          <div>
            <h3 className="px-4 text-lg font-medium mb-4">Datos Personales</h3>
            <div className="grid grid-cols-1 md:grid-cols-3">
              <TextInput
                label={`${t('nombre(s)')}`}
                name="nombre"
                labelAligmet="top"
                value={formData.nombre}
                setValue={handleInputChange}
                isRequired
                errorMessage={errors.nombre?.message}
              />
              <TextInput
                label={`${t('primer_apellido')}`}
                name="primerApellido"
                labelAligmet="top"
                value={formData.primerApellido}
                setValue={handleInputChange}
                isRequired
                errorMessage={errors.primerApellido?.message}
              />
              <TextInput
                label={t('segundo_apellido')}
                name="segundoApellido"
                labelAligmet="top"
                value={formData.segundoApellido}
                setValue={handleInputChange}
                errorMessage={errors.segundoApellido?.message}
              />
            </div>

            <div className="flex flex-col gap-2">
              <CheckboxInput
                label={`${t('sexo')}`}
                name="sexo"
                options={[
                  { value: 'H', label: t('hombre') },
                  { value: 'M', label: t('mujer') },
                ]}
                value={formData.sexo}
                setValue={handleInputChange}
                distribution="vertical-flex"
                isRequired
                errorMessage={errors.sexo?.message}
              />
              <DateInput
                label="Fecha de nacimiento"
                name="fechaNac"
                setValue={handleInputChange}
                value={formData.fechaNac}
                isRequired
                errorMessage={errors.fechaNac?.message}
              />
            </div>
          </div>

          {/* Información Cultural */}
          <div>
            <h3 className="px-4 text-lg font-medium mb-4">Información Cultural</h3>
            <div className="grid grid-cols-1 md:grid-cols-3">
              {!isOxxo && (
                <>
                  <SelectInput
                    label={t('nacionalidad')}
                    name="nacionalidad"
                    placeholder="Mexicana"
                    options={catalogos.catNacionalidad}
                    value={String(formData.nacionalidad.id)}
                    setValue={handleInputChange}
                    isRequired
                    errorMessage={errors.nacionalidad?.message}
                  />
                  <SelectInput
                    placeholder="Selecciona"
                    label="Lengua materna"
                    name="grupoEtnico"
                    options={catalogos.catGrupoEtnico}
                    value={String(formData.grupoEtnico.id)}
                    setValue={handleInputChange}
                    isRequired
                    errorMessage={errors.grupoEtnico?.message}
                  />
                </>
              )}

              <SelectInput
                placeholder="Selecciona"
                label="Estado civil"
                name="estadoCivil"
                options={catalogos.catEstadoCivil}
                value={String(formData.estadoCivil.id)}
                setValue={handleInputChange}
                errorMessage={errors.estadoCivil?.message}
              />

              {!isOxxo && (
                <>
                  <SelectInput
                    placeholder="Selecciona"
                    label="Escolaridad"
                    name="escolaridad"
                    options={catalogos.catEscolaridad}
                    value={String(formData.escolaridad.id)}
                    setValue={handleInputChange}
                    errorMessage={errors.estadoCivil?.message}
                  />
                  <SelectInput
                    placeholder="Selecciona"
                    label="Religión"
                    name="religion"
                    options={catalogos.catReligion}
                    value={String(formData.religion.id)}
                    setValue={handleInputChange}
                    errorMessage={errors.religion?.message}
                  />

                  <SelectInput
                    placeholder="Selecciona"
                    label={t('tipo_identificacion')}
                    name="tipoIdentificacion"
                    options={catalogos.catTipoIdentificacion}
                    value={String(formData.tipoIdentificacion.id)}
                    setValue={handleInputChange}
                    errorMessage={errors.tipoIdentificacion?.message}
                  />

                  <TextInput
                    name="numeroDocumento"
                    label={t('numero_documento')}
                    disableOption={{
                      label: 'No posee CURP',
                      position: 'right',
                    }}
                    value={formData.numeroDocumento}
                    setValue={handleInputChange}
                    isRequired
                    errorMessage={errors.numeroDocumento?.message}
                  />
                </>
              )}

              <TextInput
                name="curp"
                label="CURP"
                placeholder="Ingresa tu CURP"
                disableOption={{
                  label: 'No posee CURP',
                  position: 'right',
                }}
                value={formData.docId}
                setValue={handleInputChange}
                isRequired
                errorMessage={errors.docId?.message}
              />
            </div>
          </div>

          {/* Información Laboral */}
          {!isOxxo && (
            <div>
              <h3 className="px-4 text-lg font-medium mb-4">Información Laboral</h3>
              <div className="flex flex-col gap-4">
                <CheckboxInput
                  label="Ocupación"
                  name="ocupacion"
                  options={[
                    { value: 'EMPLEADO', label: 'Empleado' },
                    { value: 'PENSIONADO', label: 'Pensionado' },
                    { value: 'DESEMPLEADO', label: 'Desempleado' },
                    { value: 'JUBILADO', label: 'Jubilado' },
                  ]}
                  value={formData.ocupacion}
                  setValue={handleInputChange}
                  distribution="grid"
                  errorMessage={errors.ocupacion?.message}
                />
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <SelectInput
                    label="Tipo de ocupación"
                    variant="search"
                    name="tipoOcupacion"
                    options={catalogos.catTipoOcupacion}
                    value={String(formData.tipoOcupacion.id)}
                    setValue={handleInputChange}
                    errorMessage={errors.tipoOcupacion?.message}
                  />
                  <SelectInput
                    label="Tiempo en la ocupación"
                    name="tiempoOcupacion"
                    options={catalogos.catTiempoOcupacion}
                    value={String(formData.tiempoOcupacion.id)}
                    setValue={handleInputChange}
                    errorMessage={errors.tiempoOcupacion?.message}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Información de Contacto */}
          <div>
            <h3 className="px-4 text-lg font-medium mb-4">Información de Contacto</h3>
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-1 md:grid-cols-2">
                <PhoneInput
                  label="Celular"
                  name="celular"
                  disableOption={{
                    label: 'No tiene',
                    position: 'right',
                  }}
                  value={datosPersonales.celular}
                  setValue={handleInputChange}
                  isRequired
                  errorMessage={errors.celular?.message}
                />
                <PhoneInput
                  label="Teléfono fijo"
                  name="fijo"
                  value={datosPersonales.fijo}
                  setValue={handleInputChange}
                  errorMessage={errors.fijo?.message}
                />
              </div>
              <div className="flex flex-col">
                <EmailInput
                  label="Correo electrónico"
                  name="email"
                  value={formData.email}
                  setValue={handleInputChange}
                  isRequired
                  errorMessage={errors.email?.message}
                />
                <EmailInput
                  name="correoRecuperacion"
                  label="Correo electrónico de recuperación"
                  disableOption={{
                    label: 'No tiene',
                    position: 'right',
                  }}
                  helperText="Correo alternativo para recuperar tu cuenta"
                  value={formData.correoRecuperacion}
                  setValue={handleInputChange}
                  errorMessage={errors.correoRecuperacion?.message}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalForm>
      <AlertDialog
        open={alertOpen}
        titulo={t('_atencion_')}
        descripcion={alertMensaje}
        nombreCancelar={t('regresar')}
        nombreAceptar={t('guardar')}
        callBackAceptar={guardarDatosExpediente}
        callBackClose={handleAlertClose}
      />
      <AccesoPIN
        open={modalPin}
        callBackAceptar={() => setModalPin(false)}
        callBackClose={() => setModalPin(false)}
        usuario={docId}
        pin={pinStr}
      />
    </>
  );
};

export default Perfil;

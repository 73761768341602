import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { capitalize } from '@common/functions';
import { HtmlTooltip } from '@utils/ComponentesJSX';
import { IConsultoriosProps } from './types';

function Consultorios({
  consultorio,
  index,
  setDeleteAlert,
  setEditAlert,
  setIndexToDelete,
}: IConsultoriosProps) {
  const { t } = useTranslation();
  const handleEditConsultorio = () => {
    setIndexToDelete(index);
    setEditAlert(true);
  };
  const handleDeleteConsultorio = () => {
    setIndexToDelete(index);
    setDeleteAlert(true);
  };
  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
      <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
        <h3 className="font-normal m-0 text-gray-500">
          {`${capitalize(consultorio.nombre)} - ${consultorio.label}`}
        </h3>
        <p className="text-gray-500 mb-0">
          {`${t('direccion')}: ${consultorio.calle}${
            consultorio.num1 ? ` ${consultorio.num1}.` : ''
          }${consultorio.num2 ? ` ${consultorio.num2}.` : ''}${
            consultorio.agea ? ` ${consultorio.agea!.id}.` : ''
          }${consultorio.agem ? ` ${consultorio.agem.id}.` : ''}${
            consultorio.cp ? ` ${consultorio.cp}.` : ''
          }${consultorio.agel ? ` ${consultorio.agel.id}.` : ''}${
            consultorio.agee ? ` ${consultorio.agee.id}.` : ''
          }`}
        </p>
        <p className="font-normal m-0 text-gray-500">
          {`${t('telefono(s)')}: ${
            consultorio.telefono1.telefono
              ? `${consultorio.telefono1.telefono}${
                consultorio.telefono1.extension ? ` ext. ${consultorio.telefono1.extension}` : ''
              }; `
              : ''
          }${
            consultorio.telefono2.telefono
              ? `${consultorio.telefono2.telefono}${
                consultorio.telefono2.extension ? ` ext. ${consultorio.telefono2.extension}` : ''
              }; `
              : ''
          }${
            consultorio.telefono3.telefono
              ? `${consultorio.telefono3.telefono}${
                consultorio.telefono3.extension ? ` ext. ${consultorio.telefono3.extension}` : ''
              };`
              : ''
          }`}
        </p>
        {consultorio.correoElectronico && (
          <p className="font-normal m-0 text-gray-500">
            {`${t('correo_electronico_del_consultorio')}: ${consultorio.correoElectronico}`}
          </p>
        )}
        {consultorio.sitioWeb && (
          <p className="font-normal m-0 text-gray-500">
            {`${t('sitio_web')}: ${consultorio.sitioWeb}`}
          </p>
        )}
        <p className="font-normal m-0 text-gray-500">
          {`${t('logotipo')}: `}
          <span>{consultorio.logotipo ? 'Cargado' : 'No cargado'}</span>
        </p>
      </div>
      <div className="min-w-max self-center">
        <HtmlTooltip
          title={`${t('solo_editar_consultorio')}`}
          disableHoverListener={!consultorio.tienePacientes}
        >
          <span>
            <IconButton aria-label="edit" color="primary" onClick={handleEditConsultorio}>
              <EditIcon />
            </IconButton>
          </span>
        </HtmlTooltip>
        <HtmlTooltip
          title={`${t('no_se_puede_eliminar_consultorio')}`}
          disableHoverListener={!consultorio.tienePacientes}
        >
          <span>
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={handleDeleteConsultorio}
              disabled={!!consultorio.tienePacientes}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </HtmlTooltip>
      </div>
    </div>
  );
}

export default Consultorios;

import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { SubModalForm } from '@components/modals/SubModalForm';
import { diabetesFormData } from '@constants/prueba/diabetesFormData';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { initialDiabetesForm } from '@constants/prueba/initialForms';

import { resetModal } from '@actions/modal/actions';
import { useDispatch } from 'react-redux';
import { DiabetesField, DiabetesProps } from './type';

const schema = z.object({
  seguimientoTratamientoDiabetes: z.enum([
    'siempre',
    'casiSiempre',
    'aVeces',
    'raraVez',
    'nunca',
    '',
  ]),
  seguimientoConsultasDiabetes: z.enum([
    'siempre',
    'casiSiempre',
    'aVeces',
    'raraVez',
    'nunca',
    '',
  ]),
  planAlimentacionDiabetes: z.enum(['siempre', 'casiSiempre', 'aVeces', 'raraVez', 'nunca', '']),
  mediPresion: z
    .string()
    .or(z.number())
    .transform((val) => val.toString()),
  medicamentosDiabetes: z.string(),
});

type FormData = z.infer<typeof schema>;

export const Diabetes: FC<DiabetesProps> = ({ setFormDataSubModals, formDataSubModals }) => {
  const [formData, setFormData] = useState<FormData>(
    formDataSubModals.diabetes ?? initialDiabetesForm,
  );

  const dispatch = useDispatch();

  const {
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });

  const handleInputChange = (e: { name: string; value: any }) => {
    const { name, value } = e;

    if (name === 'mediPresion') {
      setFormData((prev) => ({
        ...prev,
        [name]: value.toString(),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const saveModal = async () => {
    try {
      const validatedData = schema.parse(formData);

      setFormDataSubModals({
        ...formDataSubModals,
        diabetes: validatedData,
      });
      dispatch(resetModal('Diabetes'));
    } catch (error) {
      console.error('Error en el envío del formulario:', error);
    }
  };

  const renderField = (field: DiabetesField) => {
    switch (field.type) {
      case 'checkbox':
        return (
          <CheckboxInput
            name={field.name}
            label={field.label}
            options={field.options}
            distribution="vertical"
            value={formData[field.name]}
            setValue={handleInputChange}
            errorMessage={errors[field.name]?.message}
          />
        );

      case 'number':
        return (
          <NumberInput
            name={field.name}
            label={field.label}
            fixedValue={field.fixedValue}
            value={Number(formData[field.name])}
            setValue={handleInputChange}
            errorMessage={errors[field.name]?.message}
          />
        );

      case 'textarea':
        return (
          <TextArea
            name={field.name}
            label={field.label}
            placeHolder={field.placeholder}
            isRequired={field.required}
            maxLength={field.maxLength}
            value={formData[field.name]}
            setValue={handleInputChange}
            errorMessage={errors[field.name]?.message}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    reset(formData);
  }, [formData]);

  return (
    <SubModalForm id="Diabetes" title={diabetesFormData.title} saveModal={saveModal}>
      {diabetesFormData.fields.map((field) => (
        <div key={`${field}_index`} className="flex flex-col gap-6">
          {renderField(field as DiabetesField)}
        </div>
      ))}
    </SubModalForm>
  );
};

export default Diabetes;

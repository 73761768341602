import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { TransitionProps } from '@material-ui/core/transitions';
import IAlertDialogProps from './types';

// const Transition = React.forwardRef(
//   (
//     // eslint-disable-next-line react/require-default-props
//     props: TransitionProps & { children?: React.ReactElement<any, any> },
//     ref: React.Ref<unknown>,
//     // eslint-disable-next-line react/jsx-props-no-spreading
//   ) => <Slide direction="up" ref={ref} {...props} />,
// );
const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  )
);

function ConfirmDialog(props: IAlertDialogProps) {
  const {
    open, titulo, descripcion, nombreCancelar, callBackClose,
  } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="alert-dialog-titulo"
      aria-describedby="alert-dialo-descripcion"
    >
      <div className="flex justify-between items-center bg-blue-800 text-white pl-4">
        <span className="text-lg">{titulo}</span>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="text-justify">
        <div className="text-center my-4">
          <WarningIcon className="text-yellow-500" color="inherit" fontSize="large" />
        </div>
        <DialogContentText className="m-0">{descripcion}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-center w-full mb-3">
          <div className="mr-4">
            <Button onClick={callBackClose} color="primary" variant="contained">
              {nombreCancelar}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;

import { ICatalogosEstandar } from '@common/types';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';
import {
  getCatalogoDiscapacidadGrado,
  getCatalogoDiscapacidadOrigen,
  getCatalogoDiscapacidadTipo,
} from '@utils/getCatalogos';
import { Trash } from 'lucide-react';
import { useEffect, useState } from 'react';
import { CatDiscapacidades, catDiscapacidadesInitial } from '../type';
import { DiscapacidadProps } from './type';

export const Discapacidad = ({
  discapacidad,
  index,
  onChange,
  onDelete,
  showDelete,
}: DiscapacidadProps) => {
  const [catDiscapacidad, setCatDiscapacidad] = useState<CatDiscapacidades>(catDiscapacidadesInitial);

  useEffect(() => {
    getCatalogoDiscapacidadTipo().then((result: ICatalogosEstandar[]) => {
      setCatDiscapacidad((prev) => ({ ...prev, tipo: result }));
    });
    getCatalogoDiscapacidadGrado().then((result: ICatalogosEstandar[]) => {
      setCatDiscapacidad((prev) => ({ ...prev, grado: result }));
    });
    getCatalogoDiscapacidadOrigen().then((result: ICatalogosEstandar[]) => {
      setCatDiscapacidad((prev) => ({ ...prev, origen: result }));
    });
  }, []);

  const handleInputChange = (value: { name: string; value: any; numberPosition?: number }) => {
    onChange({
      ...value,
      position: index,
    });
  };
  return (
    <div className="w-full border-t-2 pt-3">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold mb-4 px-4">{`Discapacidad ${index + 1}`}</h3>
      </div>
      {showDelete && (
        <button
          type="button"
          className="absolute right-4 top-8 p-2 text-gray-500 hover:text-red-500 transition-colors"
          onClick={() => onDelete(index)}
          aria-label="Eliminar discapacidad"
        >
          <Trash size={18} />
        </button>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-2">
        <SelectInput
          label="Tipo de discapacidad"
          name="tipoId"
          options={catDiscapacidad.tipo}
          value={String(discapacidad.tipoId)}
          setValue={handleInputChange}
        // errorMessage={errors.tipoId?.message}
        // isDisabled={showInput.tipo}
        />

        <SelectInput
          label="Grado de la discapacidad"
          name="gradoId"
          options={catDiscapacidad.grado}
          value={String(discapacidad.gradoId)}
          setValue={handleInputChange}
        // errorMessage={errors.gradoId?.message}
        // isDisabled={showInput.grado}
        />

        <SelectInput
          label="Origen de la discapacidad"
          name="origenId"
          options={catDiscapacidad.origen}
          value={String(discapacidad.origenId)}
          setValue={handleInputChange}
        // errorMessage={errors.origenId?.message}
        // isDisabled={showInput.origen}
        />
      </div>
    </div>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PrintIcon from '@material-ui/icons/Print';
import CloseIcon from '@material-ui/icons/Close';
import { Button, ButtonGroup, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useDispatch, useSelector } from 'react-redux';
import TxtEnriquecido from '@components/Comentarios/index';
import { getCatalogoLaboratorios } from '@utils/getCatalogos';
import { ICatLaboratorio } from '@common/types';
import DeleteDialog from '@components/DeleteDialog';
import { setLoading } from '@actions/loading/actions';
import { RootState } from 'src/store';
import { sendSolicitudLaboratorio } from '@utils/sendInfo';
import { setSnackComplete } from '@actions/snackbar/types';
import { setRequest } from '@actions/request/types';
import { setLaboratorioSolicitud } from '@actions/paciente/actions';
import useStyles from './styles';
import LaboratoriosComponente from './Laboratorios/index';
import PrintStyle from './PrintHtml/index';
import Registrar from './Registrar/index';
import { ILaboratorioSolicitud, laboratorioSolicitudInitial } from './types';

function Laboratorio() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { laboratorioSolicitud } = useSelector(
    (state: RootState) => state.Paciente.expediente.plan,
  );
  const laboratoriosRef = useRef(null);
  const [selArray, setSelArray] = useState<number>(0);
  const [saveClick, setSaveClick] = useState<boolean>(true);
  const [radioProcedimiento, setRadioProcedimiento] = useState<string>('solicitar');
  const [catLaboratorios, setCatLaboratorios] = useState<Array<ICatLaboratorio>>([]);
  const [delSel, setDelSel] = useState<number>(0);
  // cierre del modal
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  /** Handlers para enviar la informacion */
  const enviarInformacion = () => {
    const newDatos = [] as ILaboratorioSolicitud[];
    // tenemos el array de solicitudes
    // primero deberias filtramos el laboratorios array para eliminar datos vaciones
    // segundo debemos verificar si hay datos, ya que si no hay lo desechamos para mandarlo a la solicitud
    laboratorioSolicitud.forEach((arrD) => {
      const labArray = arrD.laboratoriosArray.filter((labA) => {
        if (labA.selector === 'catalogo') {
          return labA.catLaboratorios.idCat > 0;
        }
        return labA.txtLibre;
      });
      if (labArray.length > 0) {
        newDatos.push({
          ...arrD,
          laboratoriosArray: labArray,
        });
      }
    });
    // si los nuevos datos terminan vacios, se le agrega solamente un valor vacio a la solicitud 1
    // y se returna con alguna galleta de sin datos a mandar o una vaina parecida
    if (newDatos.length === 0) {
      setSelArray(0);
      setLaboratorioSolicitud([laboratorioSolicitudInitial]);
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'info',
          mensaje: t('message-error-guardado'),
        }),
      );
    } else {
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: sendSolicitudLaboratorio({
            idConsulta,
            datos: newDatos,
            idMedico,
            idPaciente,
            idConsultorio,
          }),
          successFunction: () => {
            // aqui al eliminar ( o nó ) datos alla arriba debemos cambiar el array seleccionado
            // ya que podemos terminar seleccionando un dato en un array que ya no lo tiene por lo que calculamos
            // la cantidad de eliminados, se lo restamos a selArray y si es negativo regresamos 0
            setSelArray((preSel) => {
              const valor = preSel - (laboratorioSolicitud.length - newDatos.length);
              if (valor < 0) {
                return 0;
              }
              return valor;
            });
            setSaveClick(false);
            // aqui deberiamos setear el array con los nuevos datos de los id's, insertados
            // para no actualizar (recargar la página otra vez).
            // dejado a proposito como recuerdo de que se debe mejorar
          },
        }),
      );
    }
  };
  const handleAgregarSolicitud = () => {
    if (laboratorioSolicitud.length < 5) {
      const valArray = [...laboratorioSolicitud];
      valArray.push(laboratorioSolicitudInitial);
      setLaboratorioSolicitud(valArray);
      setSaveClick(true);
    }
  };
  const handleBorrarSolicitud = (inx: number) => {
    if (laboratorioSolicitud.length > 1) {
      setLaboratorioSolicitud(laboratorioSolicitud.filter((_ad, indi) => indi !== inx));
    }
  };
  const handleAgregarProSol = () => {
    const valArray = [...laboratorioSolicitud];
    const proArray = valArray[selArray].laboratoriosArray;
    proArray.push({
      idBd: -1,
      selector: 'catalogo',
      catLaboratorios: {
        id: 0,
        idCat: 0,
        categoria: '',
        label: '',
      },
      txtLibre: '',
      actualizado: false,
    });
    valArray[selArray].laboratoriosArray = proArray;
    setLaboratorioSolicitud(valArray);
    setSaveClick(true);
  };
  const handleAgregarComentario = (contentState: RawDraftContentState) => {
    const valArray = [...laboratorioSolicitud];
    valArray[selArray].comentarios = contentState;
    setLaboratorioSolicitud(valArray);
    setSaveClick(true);
  };
  const deleteProcedimiento = () => {
    if (delSel <= selArray) {
      setSelArray((preSe) => preSe - 1);
    }
    handleBorrarSolicitud(delSel);
  };
  useEffect(() => {
    setLoading(true);
    getCatalogoLaboratorios().then((result: ICatLaboratorio[]) => {
      setCatLaboratorios(result);
      setLoading(false);
    });
  }, []);

  const antesP = (boole: boolean) => {
    dispatch(setLoading(boole));
  };
  return (
    <div>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('d-p-plan-pro-del-sol')}
        callBackAceptar={deleteProcedimiento}
        callBackClose={handleAlertClose}
      />
      <div className="flex py-3 items-center">
        <h2 className="text-blue-500 font-normal m-0 pr-4">{t('laboratorio')}</h2>
      </div>
      <div className="pb-3">
        <RadioGroup
          aria-label="realiza"
          name="realiza"
          value={radioProcedimiento}
          onChange={(e) => setRadioProcedimiento((e.target as HTMLInputElement).value)}
          row
        >
          <FormControlLabel
            value="solicitar"
            control={<Radio color="primary" />}
            label={t('d-p-plan-lab-solicitar')}
          />
          <FormControlLabel
            value="registrar"
            control={<Radio color="primary" />}
            label={t('d-p-plan-lab-registrar')}
          />
        </RadioGroup>
      </div>
      {radioProcedimiento === 'solicitar' ? (
        <>
          <div className="border-solid border-0 border-b border-gray-600 flex items-center">
            <ButtonGroup
              size="large"
              color="primary"
              aria-label="large outlined primary button group"
            >
              {laboratorioSolicitud.map((_val, inx) => {
                const id = inx + 1;
                const lab = `${t('d-p-plan-pro-solicitud')} ${id}`;
                return (
                  <Button
                    // eslint-disable-next-line react/no-array-index-key
                    key={inx}
                    className={selArray === inx ? classes.selected : ''}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => setSelArray(inx)}
                    endIcon={
                      inx !== 0 ? (
                        <CloseIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            setDelSel(inx);
                            setDeleteAlert(true);
                          }}
                        />
                      ) : null
                    }
                  >
                    {lab}
                  </Button>
                );
              })}
            </ButtonGroup>
            {laboratorioSolicitud.length < 5 ? (
              <div className="pl-2">
                <Button
                  color="primary"
                  size="small"
                  onClick={handleAgregarSolicitud}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  {t('d-p-plan-pro-add-sol')}
                </Button>
              </div>
            ) : null}
          </div>
          {laboratorioSolicitud &&
            laboratorioSolicitud[selArray] &&
            laboratorioSolicitud[selArray].laboratoriosArray.map((val, inx) => (
              <div key={`procedimientos-${String(inx)}`} className="pt-4">
                <LaboratoriosComponente
                  laboratorio={val}
                  setSaveClick={setSaveClick}
                  catLaboratorios={catLaboratorios}
                  selArray={selArray}
                  index={inx}
                />
              </div>
            ))}
          <div className="pt-2 flex justify-end">
            <Button
              color="primary"
              size="small"
              onClick={handleAgregarProSol}
              startIcon={<AddCircleOutlineIcon />}
            >
              {t('d-p-plan-pro-add-pro-sol')}
            </Button>
          </div>
          <h3 className="m-0 text-gray-600 pb-3">{t('comentarios')}</h3>
          <TxtEnriquecido
            comentarios={
              laboratorioSolicitud[selArray]?.comentarios || { blocks: [], entityMap: {} }
            }
            setComentarios={handleAgregarComentario}
            rows={4}
            maxLength={1000}
            placeholder=""
            lang="es"
            disabled={false}
            key={selArray}
          />
          <div className="flex justify-end">
            {/* <Button color="primary" size="small" startIcon={<EmailIcon />}>
              {t('d-p-plan-pro-enviar')}
            </Button> */}
            <ReactToPrint
              trigger={() => (
                <Button color="primary" size="small" startIcon={<PrintIcon />} disabled={saveClick}>
                  {t('d-p-plan-pro-imprimir')}
                </Button>
              )}
              content={() => laboratoriosRef.current}
              onBeforeGetContent={() => antesP(true)}
              onAfterPrint={() => antesP(false)}
            />
          </div>
          <div className="text-right">
            <Button
              variant="contained"
              color="primary"
              type="button"
              size="large"
              onClick={enviarInformacion}
              startIcon={<SaveIcon />}
            >
              {t('guardar')}
            </Button>
          </div>
          {laboratorioSolicitud && laboratorioSolicitud[selArray] ? (
            <div key={`print-${selArray}`} className="hidden">
              <PrintStyle
                {...laboratorioSolicitud[selArray]}
                labelPdf={t('d-p-plan-lab-sol')}
                ref={laboratoriosRef}
              />
            </div>
          ) : null}
        </>
      ) : (
        <Registrar />
      )}
    </div>
  );
}

export default Laboratorio;

export const hipertensionFormData = {
  title: 'Hipertensión',
  fields: [
    {
      type: 'checkbox',
      name: 'seguimientoTratamiento',
      label: '¿Qué tanto sigues las indicaciones de tu tratamiento?',
      options: [
        { value: 'siempre', label: 'Siempre' },
        { value: 'casiSiempre', label: 'Casi siempre' },
        { value: 'aVeces', label: 'A veces' },
        { value: 'raraVez', label: 'Rara vez' },
        { value: 'nunca', label: 'Nunca' },
      ],
    },
    {
      type: 'checkbox',
      name: 'seguimientoConsultas',
      label: '¿Qué tanto acudes a tus consultas de seguimiento de Hipertensión?',
      options: [
        { value: 'siempre', label: 'Siempre' },
        { value: 'casiSiempre', label: 'Casi siempre' },
        { value: 'aVeces', label: 'A veces' },
        { value: 'raraVez', label: 'Rara vez' },
        { value: 'nunca', label: 'Nunca' },
      ],
    },
    {
      type: 'checkbox',
      name: 'planAlimentacion',
      label: '¿Sigues el plan de alimentación específico o dieta recomendada por tu médico?',
      options: [
        { value: 'siempre', label: 'Siempre' },
        { value: 'casiSiempre', label: 'Casi siempre' },
        { value: 'aVeces', label: 'A veces' },
        { value: 'raraVez', label: 'Rara vez' },
        { value: 'nunca', label: 'Nunca' },
      ],
    },
    {
      type: 'number',
      name: 'mediPresion',
      label: '¿Hace cuánto mediste por última vez tu presión?',
      fixedValue: '(días)',
    },
    {
      type: 'textarea',
      name: 'medicamentosHipertension',
      label: '¿Qué medicamentos usas?',
      placeholder: 'Escribe los medicamentos que usas',
      maxLength: 500,
      required: false,
    },
  ],
};

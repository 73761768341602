import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, XIcon } from 'lucide-react';
import { DateInputProps } from './type';
import { IFechaObj } from '@common/types';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { validarFechaEsAnterior } from '@common/functions';

export const DateInput: FC<DateInputProps> = ({
  index,
  containerClass = '',
  label: externalLabel,
  isRequired,
  name,
  setValue,
  value,
  validacion,
  untilToday = false,
  helperText,
  disabled = false,
  yearsMore = 0,
  setStatus,
}) => {
  const { size } = useSelector((state: RootState) => state.ScreenSize);
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const currentDate = new Date();
  const [openDay, setOpenDay] = useState(false);
  const [openMonth, setOpenMonth] = useState(false);
  const [openYear, setOpenYear] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const label = externalLabel === undefined ? t(name) : externalLabel;
  const days = [...Array(31)].map((x, i) => ({ value: String(i + 1), label: String(i + 1) }));
  const months = [...Array(12)].map((x, i) => ({ value: String(i + 1), label: t(`mes${i + 1}`) }));
  const years = [...Array(101)].map((x, i) => ({
    value: String(currentDate.getFullYear() - i + yearsMore),
    label: String(currentDate.getFullYear() - i + yearsMore),
  }));

  useEffect(() => {
    setStatus?.({
      listName: 'missing',
      name,
      value: value !== undefined || value !== null || value !== '',
      index,
    });
  }, [isRequired]);

  useEffect(() => {
    setStatus?.({
      listName: 'error',
      name,
      value: errorMessage.length > 0,
      index,
    });
  }, [errorMessage]);

  useEffect(() => {
    if (untilToday && value?.anio && value?.mes && value?.dia && !validarFechaEsAnterior(value.anio, value.mes, value.dia)) {
      setErrorMessage(t('fecha_invalida'));
    } else if (validacion) {
      setErrorMessage(validacion(value?.dia, value?.mes, value?.anio));
    } else {
      setErrorMessage('');
    }
  }, [value?.anio, value?.mes, value?.dia]);

  const handleDateChange = (type: 'day' | 'month' | 'year', newValue: string) => {
    const defaultDate: IFechaObj = { dia: '', mes: '', anio: '' };
    switch (type) {
      case 'day':
        setValue({ name, value: { ...(value || defaultDate), dia: newValue || '' } });
        setOpenDay(false);
        break;
      case 'month':
        setValue({ name, value: { ...(value || defaultDate), mes: newValue || '' } });
        setOpenMonth(false);
        break;
      case 'year':
        setValue({ name, value: { ...(value || defaultDate), anio: newValue || '' } });
        setOpenYear(false);
        break;
      default:
        break;
    }
  };

  const CustomSelect = ({
    value: val,
    onChange,
    options,
    floatingLabel,
    isOpen,
    setIsOpen,
    isDisabled,
  }: {
    value: string;
    onChange: (value: string) => void;
    options: string[] | typeof months;
    floatingLabel: string;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    isDisabled: boolean;
  }) => {
    const [wasFocused, setWasFocused] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const handleToggleDropdown = () => {
      if (!disabled) {
        if (!wasFocused) {
          setWasFocused(true);
        }
        setIsOpen(!isOpen);
      }
    };

    const handleHover = (event: MouseEvent) => {
      setIsHover(!!dropdownRef.current?.contains(event.target as Node));
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current?.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [wasFocused]);

    useEffect(() => {
      document.addEventListener('mouseover', handleHover);
      return () => document.removeEventListener('mouseover', handleHover);
    }, [isHover]);

    return (
      <div className="w-full relative" ref={dropdownRef}>
        <label
          onClick={handleToggleDropdown}
          className={`absolute left-3 text-gray-400 bg-white rounded-sm px-1 ${
            (isOpen || !!val?.length) ? "-top-2 text-xs" : "top-[12px] text-sm"
          } transition-all cursor-pointer`}
        >
          {floatingLabel}
        </label>
        <div
          className={`w-full h-[42px] px-2 border rounded-md flex justify-between items-center 
            ${
              isDisabled
                ? 'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed'
                : 'bg-white border-gray-300 cursor-pointer'
            }`}
          onClick={handleToggleDropdown}
        >
          <div className="w-full" onClick={handleToggleDropdown}>
            <span className={!val?.length ? 'text-sm text-gray-400' : ''}>
              {(options as { value: string; label: string }[]).find(
                (option) => option.value === val,
              )?.label}
            </span>
          </div>
          <div className="flex flex-row">
            {val?.length && !disabled && isHover && size !== 'sm'
              ? <XIcon
                size={18}
                onClick={() => {
                  if (!isDisabled) {
                    onChange('');
                  }
                }}
                className="hover:bg-gray-100 rounded-full"
              /> : null
            }
            <ChevronDown
              size={20}
              className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}
              hover:bg-gray-100 rounded-full`}
              onClick={handleToggleDropdown}
            />
          </div>
        </div>

        {isOpen && !isDisabled && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-64 overflow-auto">
            {(options as { value: string; label: string }[]).map((option) => (
              <div
                key={Number(option.value)}
                className={`p-2 cursor-pointer hover:bg-gray-100 
                  ${option.value.toString() === val.toString() ? 'bg-blue-50 text-blue-600' : ''}`}
                onClick={() => onChange(option.value.toString() || '')}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`w-full h-auto px-4 my-[10px] ${containerClass}`}>
      {label && (
        <label className="block font-medium text-base text-gray-700 mb-4">
          {label}
          {isRequired && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      <div className="flex gap-2">
        <div className="w-24">
          <CustomSelect
            value={value?.dia || ''}
            onChange={(newValue) => handleDateChange('day', newValue)}
            options={days}
            floatingLabel={t('dia')}
            isDisabled={disabled}
            isOpen={openDay}
            setIsOpen={setOpenDay}
          />
        </div>
        <div className="w-44">
          <CustomSelect
            value={value?.mes || ''}
            onChange={(newValue) => handleDateChange('month', newValue)}
            options={months}
            floatingLabel={t('mes')}
            isDisabled={disabled}
            isOpen={openMonth}
            setIsOpen={setOpenMonth}
          />
        </div>
        <div className="w-28">
          <CustomSelect
            value={value?.anio || ''}
            onChange={(newValue) => handleDateChange('year', newValue)}
            options={years}
            floatingLabel={t('anio')}
            isDisabled={disabled}
            isOpen={openYear}
            setIsOpen={setOpenYear}
          />
        </div>
      </div>
      <div className="grid grid-cols-3">
        <div />
        {(errorMessage || helperText) && (
          <span className={`pt-1 text-xs text-center ${errorMessage ? 'text-red-500' : 'text-gray-500'}`}>
            {errorMessage || helperText}
          </span>
        )}
      </div>
    </div>
  );
};

export default DateInput;

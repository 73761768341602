import { HealthDataSubModals } from '@containers/PatientForm/type';
import { Dispatch, SetStateAction } from 'react';

export interface ICatalogosEstandar {
  id: number;
  label: string;
}

export interface Pet {
  id: string;
  idAnimal: ICatalogosEstandar;
  cantidad: string;
  especifica: string;
}

export interface MascotasForm {
  pets: Pet[];
}

export interface MascotasProps {
  setFormDataSubModals: Dispatch<SetStateAction<HealthDataSubModals>>;
  formDataSubModals: HealthDataSubModals;
}

export const initialMascotasForm: MascotasForm = {
  pets: [
    {
      id: 'pet-1',
      idAnimal: { id: 0, label: '' },
      cantidad: '',
      especifica: '',
    },
  ],
};

import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailInputProps } from './type';
import { MailIcon } from 'lucide-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { GLOBALS } from '@constants/prueba/globals';
import { useBreakPoint } from '@hooks/useBreakpoint';

export const EmailInput: FC<EmailInputProps> = ({
  name,
  containerClass = '',
  label: externalLabel,
  floatingLabel,
  value: externalValue,
  validacion,
  validacionBlur,
  isRequired,
  placeholder: externalPlaceholder,
  labelAligmet = 'top',
  setValue,
  helperText,
  errorMessage: externalError,
  disableOption,
  disabled = false,
  disableCopy = false,
  disableCut = false,
  disablePaste = false,
  showIcon = false,
  autocomplete = false,
  setStatus,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [label, setLabel] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [text, setText] = useState(externalValue || '');
  const [isFocused, setIsFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isFloating = isFocused || text.length > 0;
  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setStatus?.({
      listName: 'missing',
      name,
      value: text !== undefined || text !== null || text !== '',
    });
  }, [isRequired]);

  useEffect(() => {
    setStatus?.({
      listName: 'error',
      name,
      value: errorMessage.length > 0,
    });
  }, [errorMessage]);

  useEffect(() => {
    if (floatingLabel) {
      setLabel('');
      setPlaceholder('');
    } else {
      setLabel(externalLabel === undefined ? t(name) : externalLabel);
      setPlaceholder(externalPlaceholder === undefined ? `${t(name)}*` : `${externalPlaceholder}*`);
    }
  }, [floatingLabel, externalLabel, externalPlaceholder, isRequired]);

  useEffect(() => {
    if (externalError) {
      setErrorMessage(externalError);
    }
  }, [externalError]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    if (externalValue !== undefined) {
      setText(externalValue);
    }
  }, [externalValue]);

  const validateEmail = (email: string) => {
    if (isRequired && email.trim() === '') {
      setErrorMessage('Este campo es requerido');
      return false;
    }

    if (email.trim() !== '') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setErrorMessage('Por favor ingrese un correo electrónico válido');
        return false;
      }
    }

    setErrorMessage('');
    return true;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.toLowerCase();
    setText(newValue);
    setErrorMessage('');

    // Crear evento sintético
    const syntheticEvent = {
      name,
      value: newValue,
      type: 'email',
    };
    if (validacion) {
      setErrorMessage(validacion(newValue));
    }
    setValue(syntheticEvent);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (validacionBlur) {
      setErrorMessage(validacionBlur(text));
    } else {
      validateEmail(text);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!isWideScreen) {
      setTimeout(() => {
        event.target.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);  
    }
    setIsFocused(true);
  };

  const handleDisableChange = () => {
    const newState = !isDisabled;
    setIsDisabled(newState);
    setText('');
    setErrorMessage('');

    if (disableOption?.name) {
      setValue?.({ name: disableOption.name, value: newState });
    }
    if (newState) {
      const syntheticEvent = {
        name,
        value: '',
        type: 'email',
      };
      setValue(syntheticEvent);
    }
  };

  const renderDisableCheckbox = () => (
    <label className="flex items-center text-sm text-gray-600 whitespace-nowrap ml-4">
      <input
        type="checkbox"
        className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
        checked={!!disableOption?.value}
        onChange={handleDisableChange}
      />
      {disableOption?.label}
    </label>
  );

  const getStyle = () => {
    if (isDisabled) {
      return 'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed';
    }
    if (errorMessage) {
      return 'border-red-500 ring-2 ring-red-200';
    }
    return 'border-gray-300 focus:ring-2 focus:ring-blue-500';
  };

  return (
    <div className={`w-full h-fit my-[10px] ${containerClass}`}>
      <div className="px-4">
        <div className="">
          <div className="flex items-center justify-between mb-2">
            {label ? (
              <label
                htmlFor={name}
                className={`${
                  labelAligmet === 'top'
                    ? `block font-medium text-base ${
                        errorMessage ? 'text-red-500' : 'text-gray-700'
                      }`
                    : `absolute font-medium text-sm transition-all duration-200 ${
                        errorMessage ? 'text-red-500' : 'text-gray-700'
                      } bg-white ${isFloating ? 'left-0 top-0' : 'absolute top-[13px] left-2'}`
                } ${isDisabled ? 'text-gray-500' : ''}`}
              >
                {label} {isRequired && <span className="text-red-500">*</span>}
              </label>
            ) : <div/>}
            {disableOption?.position === 'right' && renderDisableCheckbox()}
          </div>

          <div className="flex items-center gap-4">
            <div className="flex-1 relative">
              {floatingLabel &&
                <label
                  onClick={() => inputRef.current?.focus() }
                  className={`absolute left-3 text-gray-400 bg-white rounded-sm px-1 transition-all 
                    ${(isFocused || !!text?.length) ? "-top-2 text-xs" : "top-[15px] text-sm"} cursor-text`}
                >
                  {`${floatingLabel}${isRequired ? '*' : ''}`}
                </label>
              }
              <input
                ref={inputRef}
                type="email"
                id={name}
                name={name}
                placeholder={labelAligmet === 'border' && !isFloating ? '' : placeholder}
                value={text}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                autoComplete={autocomplete ? undefined : 'off'}
                onCopy={(e) => {
                  if (disableCopy) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                onCut={(e) => {
                  if (disableCut) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                onPaste={(e) => {
                  if (disablePaste) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                disabled={isDisabled}
                className={`w-full h-[48px] px-3 ${
                  showIcon ? 'pr-16' : 'pr-3'
                } rounded-md border focus:outline-none transition ${getStyle()}`}
              />
              {showIcon && (
                <span
                  className={`absolute right-3 top-1/2 -translate-y-1/2 text-sm ${
                    isDisabled ? 'text-gray-400' : 'text-gray-500'
                  }`}
                >
                  <MailIcon/>
                </span>
              )}
            </div>
            {disableOption?.position === 'after' && renderDisableCheckbox()}
          </div>

          {(errorMessage || helperText) && (
            <p className={`text-xs mt-1 ${errorMessage ? 'text-red-500' : 'text-gray-500'}`}>
              {errorMessage || helperText}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailInput;

export const SET_RUTA_PRINCIPAL = 'SET_RUTA_PRINCIPAL';
export const SET_RUTA_PERFIL = 'SET_RUTA_PERFIL';
export const SET_RUTA_ANTECEDENTES = 'SET_RUTA_ANTECEDENTES';
export const SET_RUTA_ANT_PATOLOGICOS = 'SET_RUTA_ANT_PATOLOGICOS';
export const SET_RUTA_ANT_NO_PATOLOGICOS = 'SET_RUTA_ANT_NO_PATOLOGICOS';
export const SET_RUTA_PLAN = 'SET_RUTA_PLAN';
export const RESET_RUTAS = 'RESET_RUTAS';

export type TRutaPrincipal =
  | 'main'
  | 'perfil'
  | 'mcpa'
  | 'antecedentes'
  | 'aparatos-y-sistemas'
  | 'exploracion-fisica'
  | 'diagnostico-y-plan'
  | 'incapacidades'
  | 'resumen-de-salud';


export type TRutaPerfil =
  | 'main'
  | 'datos-personales'
  | 'datos-ubicacion'
  | 'discapacidades'
  | 'info-emergencia'
  | 'seguros-planes';

export type TRutaAntecedentes =
  | 'personales-patologicos'
  | 'personales-no-patologicos'
  | 'familiares'
  | 'laborales';

export type TRutaAntPatologicos =
  | 'enfermedades-tratamientos'
  | 'diagnosticos-generales'
  | 'hospitalizaciones'
  | 'procedimientos-quirurgicos'
  | 'procedimientos-terapeuticos'
  | 'procedimientos-diagnosticos'
  | 'alergias'
  | 'transfusiones';

export type TRutaAntNoPatologicos =
  | 'vivienda'
  | 'higiene'
  | 'alimentacion'
  | 'actividad-fisica'
  | 'inmunizaciones'
  | 'toxicomania'
  | 'gineco-obstetricos'
  | 'historia-sexual';

export type TRutaPlan =
  | 'laboratorio'
  | 'procedimientos-diagnosticos'
  | 'procedimientos-terapeuticos'
  | 'procedimientos-quirurgicos'
  | 'terapia-farmacologica'
  | 'inmunizacion'
  | 'dictamen-med-lab'
  | 'medicina-preventiva'
  | 'pronostico';


export interface IRutasExpediente {
  rutaPrincipal: TRutaPrincipal;
  rutaPerfil: TRutaPerfil;
  rutaAntecedentes: TRutaAntecedentes;
  rutaAntPatologicos: TRutaAntPatologicos;
  rutaAntNoPatologicos: TRutaAntNoPatologicos;
  rutaPlan: TRutaPlan;
}

interface SetRutaPrincipalAction {
  type: typeof SET_RUTA_PRINCIPAL;
  payload: TRutaPrincipal;
}

interface SetRutaPerfilAction {
  type: typeof SET_RUTA_PERFIL;
  payload: TRutaPerfil;
}

interface SetRutaAntecedentesAction {
  type: typeof SET_RUTA_ANTECEDENTES;
  payload: TRutaAntecedentes;
}

interface SetRutaAntPatologicosAction {
  type: typeof SET_RUTA_ANT_PATOLOGICOS;
  payload: TRutaAntPatologicos;
}

interface SetRutaAntoNoPatologicosAction {
  type: typeof SET_RUTA_ANT_NO_PATOLOGICOS;
  payload: TRutaAntNoPatologicos;
}

interface SetRutaPlanAction {
  type: typeof SET_RUTA_PLAN;
  payload: TRutaPlan;
}

interface ResetRutasAction {
  type: typeof RESET_RUTAS;
}

export type RutasExpedienteActionTypes =
  | SetRutaPrincipalAction
  | SetRutaPerfilAction
  | SetRutaAntecedentesAction
  | SetRutaAntPatologicosAction
  | SetRutaAntoNoPatologicosAction
  | SetRutaPlanAction
  | ResetRutasAction;

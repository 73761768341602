import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { iEdadMenorOIgual } from '@common/functions';
import { ITabacoProps } from './types';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import TextField from '@components/FormElemntsLibrary/TextField';
import TextArea from '@components/FormElemntsLibrary/TextArea';

function Tabaco(props: ITabacoProps) {
  const {
    hayCambios, toxicomania, setToxicomania, getTabaco,
  } = props;
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { edad } = useSelector((state: RootState) => state.BasicosPaciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const estatus = ['niega', 'activo', 'ex-fumador'].map(es => ({ value: es, label: t(es) }));

  const handleInputChange = (e: { name: string; value: any }) => {
    setToxicomania({
      ...toxicomania,
      tabaco: {
        ...toxicomania.tabaco,
        [e.name]: e.value,
      }
    });
  };

  useEffect(() => {
    if (toxicomania.tabaco.consumoTabaco === 'niega') {
      setToxicomania({
        ...toxicomania,
        tabaco: {
          ...toxicomania.tabaco,
          edadInicio: '',
          edadTermino: '',
          cigarrillosDia: '',
          comentarios: '',
        }
      });
    } else {
      setToxicomania({
        ...toxicomania,
        tabaco: getTabaco(toxicomania.tabaco),
      });
    }
  }, [
    toxicomania.tabaco.cigarrillosDia,
    toxicomania.tabaco.edadInicio,
    toxicomania.tabaco.edadTermino,
    toxicomania.tabaco.consumoTabaco,
  ]);

  return (
    <>
      <CheckboxGroup
        name="consumoTabaco"
        label={t('tabaco')}
        options={estatus}
        value={toxicomania.tabaco.consumoTabaco}
        setValue={handleInputChange}
        disabled={hayCambios}
        distribution="vertical-flex"
      />
      {['activo', 'ex-fumador'].includes(toxicomania.tabaco.consumoTabaco) &&
        <>
          <div className={`grid grid-cols-1 md:grid-cols-${
            toxicomania.tabaco.consumoTabaco === 'activo' ? '3' : '4'
          }`}>
            <NumberInput
              name="edadInicio"
              label={t('edad_de_inicio')}
              value={Number(toxicomania.tabaco.edadInicio)}
              isInteger
              isString
              setValue={handleInputChange}
              validacion={(val) =>
                val &&
                !iEdadMenorOIgual({ edad: val, claveEdad: 5, periodo: 'anios' }, edad)
                  ? t('la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente') : ''
              }
              min={0}
              max={99}
              disabled={hayCambios}
            />
            {toxicomania.tabaco.consumoTabaco === 'ex-fumador' &&
              <NumberInput
                name="edadTermino"
                label={t('edad_de_termino')}
                value={Number(toxicomania.tabaco.edadTermino)}
                isInteger
                isString
                setValue={handleInputChange}
                validacion={(val) => {
                  if (val) {
                    if (!iEdadMenorOIgual({ edad: val, claveEdad: 5, periodo: 'anios' }, edad)) {
                      return t('la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente');
                    }
                    if (iEdadMenorOIgual(
                      { edad: val, claveEdad: 5, periodo: 'anios' },
                      { edad: parseInt(toxicomania.tabaco.edadInicio, 10), claveEdad: 5, periodo: 'anios' },
                    )) {
                      return t('la_edad_de_termino_es_menor_a_la_edad_actual_de_inicio');
                    }
                  }
                  return '';
                }}
                min={0}
                max={99}
                disabled={hayCambios}
              />
            }
            <NumberInput
              name="cigarrillosDia"
              label={t('cigarrillos_al_dia')}
              value={Number(toxicomania.tabaco.cigarrillosDia)}
              setValue={handleInputChange}
              isString
              min={0}
              max={99}
              disabled={hayCambios}
            />
            {!esPaciente &&
              <TextField
                name="ipa"
                label={t('indice_de_paquetes_al_año')}
                value={toxicomania.tabaco.ipa}
                disabled={hayCambios}
              />
            }
          </div>
          <TextArea
            name="comentarios"
            value={toxicomania.tabaco.comentarios}
            setValue={handleInputChange}
            disabled={hayCambios}
          />
        </>
      }
    </>
  );
}

export default Tabaco;

import { FC, useEffect, useState } from 'react';
import { DateInputProps } from './type';

export const DateInput: FC<DateInputProps> = ({ label, isRequired, name, setValue, value }) => {
  const currentDate = new Date();
  const [selectedDay, setSelectedDay] = useState<number>(
    value ? Number(value.split('-')[2]) : currentDate.getDate(),
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    value ? Number(value.split('-')[1]) : currentDate.getMonth() + 1,
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    value ? Number(value.split('-')[0]) : currentDate.getFullYear(),
  );

  const months = [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' },
  ];

  useEffect(() => {
    if (value) {
      try {
        const [year, month, day] = value.split('-').map(Number);
        if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
          setSelectedYear(year);
          setSelectedMonth(month);
          setSelectedDay(day);
        }
      } catch (error) {
        console.warn('Invalid date format:', value);
      }
    }
  }, [value]);

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const years = Array.from({ length: 101 }, (_, i) => currentDate.getFullYear() - i);

  const handleDateChange = (type: 'day' | 'month' | 'year', newValue: number) => {
    let updatedDay = selectedDay;
    let updatedMonth = selectedMonth;
    let updatedYear = selectedYear;

    switch (type) {
      case 'day':
        updatedDay = newValue;
        setSelectedDay(newValue);
        break;
      case 'month':
        updatedMonth = newValue;
        setSelectedMonth(newValue);
        break;
      case 'year':
        updatedYear = newValue;
        setSelectedYear(newValue);
        break;
    }

    // Formatear los valores sin padding en dia y mes
    const formattedDate = {
      dia: String(updatedDay),
      mes: String(updatedMonth),
      anio: String(updatedYear),
    };

    const syntheticEvent = {
      name,
      value: formattedDate,
      type: 'date',
    };

    setValue(syntheticEvent);
  };

  const CustomSelect = ({
    value,
    onChange,
    options,
    placeholder,
    selectName,
    isMonth = false,
  }: {
    value: number;
    onChange: (value: number) => void;
    options: number[] | typeof months;
    placeholder: string;
    selectName: 'day' | 'month' | 'year';
    isMonth?: boolean;
  }) => (
    <div className="w-full relative">
      <div className="absolute -top-3 left-1 bg-white px-1">
        <span className="text-gray-500">{placeholder}</span>
      </div>
      <select
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        className="w-full h-[48px] px-3 border border-gray-300 rounded-md bg-white cursor-pointer
                 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 appearance-none"
        name={`${name}-${selectName}`}
      >
        {isMonth
          ? (options as typeof months).map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))
          : (options as number[]).map((opt) => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
      </select>
      <div className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2">
        <svg
          className="fill-current h-4 w-4 text-gray-700"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
        </svg>
      </div>
    </div>
  );

  return (
    <div className="w-full h-auto px-4">
      <div className="w-full mb-6">
        {label && (
          <label className="block font-medium text-gray-700 mb-4">
            {label}
            {isRequired && <span className="text-red-500 ml-1">*</span>}
          </label>
        )}
        <div className="flex gap-2">
          <div className="w-32">
            <CustomSelect
              value={selectedDay}
              onChange={(value) => handleDateChange('day', value)}
              options={days}
              placeholder="Día"
              selectName="day"
            />
          </div>
          <div className="w-32">
            <CustomSelect
              value={selectedMonth}
              onChange={(value) => handleDateChange('month', value)}
              options={months}
              placeholder="Mes"
              selectName="month"
              isMonth={true}
            />
          </div>
          <div className="w-32">
            <CustomSelect
              value={selectedYear}
              onChange={(value) => handleDateChange('year', value)}
              options={years}
              placeholder="Año"
              selectName="year"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateInput;

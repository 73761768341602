import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Avatar,
  TextField,
  Button,
  InputAdornment,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { RootState } from 'src/store';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import {
  getCatalogoAgem,
  getCatalogoAgel,
  getCatalogoAgea,
  getPerfilMedicoOrganizacion,
} from '@utils/getCatalogos';
import obtenerDatosGeograficos from '@utils/geografia';
import { crearArchivo } from '@utils/sendFiles';
import { HtmlTooltip } from '@utils/ComponentesJSX';
import {
  rfcLongitudMax,
  validacionSoloNumeros,
  validacionEmail,
  validacionRfc,
} from '@constants/validaciones';
import getMoneda from '@constants/monedas';
import { ICatalogosEstandar, ICatStrEstandar } from '@common/types';
import { setRequest } from '@actions/request/types';
import { obtenerURLArchivo } from '@utils/getFiles';
import { setOrganizacion } from '@actions/organizaciones/actions';
import { IOrganizacionProps, ICatalogoCfdi } from './types';
import useStyles from './styles';
import SelectInput from '@components/FormElemntsLibrary/SelectInput2';

function Organizacion(props: IOrganizacionProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    objOrganizacion,
    setObjOrganizacion,
    catCfdi,
    catAGEE,
    catAGEM,
    catAGEL,
    catAGEA,
    urlLogo,
    setUrlLogo,
    setDisabledSave,
    setDisabledNext,
  } = props;
  const { secciones, idMedico, pais } = useSelector((state: RootState) => state.Me);
  const abortController = new AbortController();
  const { signal } = abortController;
  const [catAGEMTemp, setCatAGEMTemp] = useState<ICatStrEstandar[]>(catAGEM);
  const [catAGELTemp, setCatAGELTemp] = useState<ICatStrEstandar[]>(catAGEL);
  const [catAGEATemp, setCatAGEATemp] = useState<ICatStrEstandar[]>(catAGEA);
  const [filterCatCfdi, setFilterCatCfdi] = useState<ICatalogoCfdi[]>(
    objOrganizacion.personalJuridica === 'pm'
      ? catCfdi.filter((valCatCfdi) => valCatCfdi.personaMoral)
      : catCfdi,
  );
  const [errores, setErrores] = useState({
    email: { error: false, helperText: '' },
    rfc: { error: false, helperText: '' },
  });
  /* archivo */
  const [selObjArchivo, setSelObjArchivo] = useState<{ archivo: File; idLista: number } | null>(
    null,
  );
  const esDependiente = objOrganizacion.organizacionSuperior > 0;
  const geo = obtenerDatosGeograficos(pais);
  const handleGeoChange = (e: { name: string; value: any }) => {
    setObjOrganizacion((prevData) => ({ ...prevData, [e.name]: e.value }));
    if (e.name === 'agee') {
      setCatAGEMTemp([]);
      setCatAGELTemp([]);
      setCatAGEATemp([]);
    } else if (e.name === 'agem') {
      setCatAGELTemp([]);
      setCatAGEATemp([]);
    } else if (e.name === 'agel') {
      setCatAGEATemp([]);
    }
  }

  const handleChangeAgee = (event: any, newValue: ICatStrEstandar | null) => {
    setObjOrganizacion({ ...objOrganizacion, agee: newValue || null });
    setCatAGEMTemp([]);
    setCatAGELTemp([]);
    setCatAGEATemp([]);
  };
  const handleChangeAgem = (event: any, newValue: ICatStrEstandar | null) => {
    setObjOrganizacion({ ...objOrganizacion, agem: newValue || null });
    setCatAGELTemp([]);
    setCatAGEATemp([]);
  };
  const handleChangeAgel = (event: any, newValue: ICatStrEstandar | null) => {
    setObjOrganizacion({ ...objOrganizacion, agel: newValue || null });
    setCatAGEATemp([]);
  };
  const handleChangeAgea = (event: any, newValue: ICatStrEstandar | null) => {
    setObjOrganizacion({ ...objOrganizacion, agea: newValue || null });
  };
  const handleChangePersonaJuridica = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target as HTMLInputElement;
    if (val.value === 'pm') {
      setFilterCatCfdi(catCfdi.filter((valCatCfdi) => valCatCfdi.personaMoral));
    } else {
      setFilterCatCfdi(catCfdi);
    }
    setObjOrganizacion({
      ...objOrganizacion,
      personalJuridica: val.value,
      nombreOrganizacion: '',
      razonSocial: '',
      rfc: '',
      cfdi: { id: 0, label: '' },
      email: '',
      telefono: '',
      extension: '',
      agee: null,
      agem: null,
      agel: null,
      agea: null,
      calle: '',
      num1: '',
      num2: '',
      cp: '',
      beneficioBrutoDiario: '',
      diasLaboralesAnio: '',
      relCostoBeneficio: '',
      presentismoTabaquismo: '',
    });
  };
  const handleChangeNombre = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, nombreOrganizacion: val });
  };
  const handleChangeRazonSocial = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, razonSocial: val });
  };
  const handleChangeRfc = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, rfc: val.toUpperCase() });
  };
  const handleBlurRfc = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (validacionRfc(val.toUpperCase(), pais)) {
      setErrores((preV) => ({ ...preV, rfc: { helperText: '', error: false } }));
    } else {
      setErrores((preV) => ({
        ...preV,
        rfc: { helperText: t(`rfc_incorrecto_${pais}`), error: true },
      }));
    }
  };
  const handleChangeCfdi = (event: any, newValue: ICatalogosEstandar | null) => {
    setObjOrganizacion({
      ...objOrganizacion,
      cfdi: { id: newValue?.id || 0, label: newValue?.label || '' },
    });
  };
  const handleChangeCorreoElectronico = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, email: val.toLowerCase() });
    setErrores((preV) => ({ ...preV, email: { helperText: '', error: false } }));
  };
  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setErrores((preV) => ({ ...preV, email: { helperText: '', error: false } }));
    } else {
      setErrores((preV) => ({
        ...preV,
        email: { helperText: t('email_incorrecto'), error: true },
      }));
    }
  };
  const handleChangeTelefono1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjOrganizacion({ ...objOrganizacion, telefono: val });
    }
  };
  const handleChangeExtension1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjOrganizacion({ ...objOrganizacion, extension: val });
    }
  };
  const handleChangeCalle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, calle: val });
  };
  const handleChangeNum1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, num1: val });
  };
  const handleChangeNum2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, num2: val });
  };
  const handleChangeCP = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjOrganizacion({ ...objOrganizacion, cp: val });
    }
  };
  const handleChangeBeneficioBrutoDiario = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjOrganizacion({ ...objOrganizacion, beneficioBrutoDiario: val });
    }
  };
  const handleChangePresentismoTabaco = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjOrganizacion({ ...objOrganizacion, presentismoTabaquismo: val });
    }
  };
  const handleChangeRelCostoBeneficio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjOrganizacion({ ...objOrganizacion, relCostoBeneficio: val });
    }
  };
  const handleChangeDiasLaboralesAnio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val) && (!val || parseInt(val, 10) <= 365)) {
      setObjOrganizacion({ ...objOrganizacion, diasLaboralesAnio: val });
    }
  };
  const seleccionarArchivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      setSelObjArchivo({
        archivo: event.target.files[0],
        idLista: objOrganizacion.idOrganizacion || 0,
      });
    } else {
      setSelObjArchivo(null);
    }
  };

  useEffect(() => {
    let dSave = false;
    let dNext = objOrganizacion.idOrganizacion === -1;
    if (objOrganizacion.idOrganizacion === -1 || !esDependiente) {
      if (
        errores.email.error ||
        errores.rfc.error ||
        objOrganizacion.personalJuridica === '' ||
        (objOrganizacion.personalJuridica === 'pm' &&
          objOrganizacion.nombreOrganizacion.length === 0) ||
        objOrganizacion.razonSocial.length === 0 ||
        (geo.agee && geo.agee.required && !objOrganizacion.agee) ||
        (geo.agem && geo.agem.required && !objOrganizacion.agem) ||
        (geo.agel && geo.agel.required && !objOrganizacion.agel) ||
        (geo.calle && geo.calle.required && objOrganizacion.calle.length === 0)
      ) {
        dSave = true;
        dNext = true;
      }
    } else if (objOrganizacion.nombreOrganizacion.length === 0) {
      dSave = true;
      dNext = true;
    }
    setDisabledSave(dSave);
    setDisabledNext(dNext);
  }, [objOrganizacion]);
  useEffect(() => {
    if (!esDependiente && objOrganizacion.agee?.id && catAGEMTemp.length === 0) {
      setObjOrganizacion({
        ...objOrganizacion,
        agem: null,
        agel: null,
        agea: null,
      });
      dispatch(
        setRequest({
          type: 'get',
          requestFunction: getCatalogoAgem(objOrganizacion.agee?.id || '', signal),
          successFunction: (result: ICatStrEstandar[]) =>
            setCatAGEMTemp(result),
          toJson: false,
          loading: false,
          showMsgs: false,
        }),
      );
    }
    return () => abortController.abort();
  }, [objOrganizacion.agee]);
  useEffect(() => {
    if (!esDependiente && objOrganizacion.agee?.id && objOrganizacion.agem?.id && catAGELTemp.length === 0) {
      setObjOrganizacion({ ...objOrganizacion, agel: null, agea: null });
      dispatch(
        setRequest({
          type: 'get',
          requestFunction: getCatalogoAgel(
            objOrganizacion.agee?.id,
            objOrganizacion.agem?.id,
            signal,
          ),
          successFunction: (result: ICatStrEstandar[]) =>
            setCatAGELTemp(result),
          toJson: false,
          loading: false,
          showMsgs: false,
        }),
      );
    }
    return () => abortController.abort();
  }, [objOrganizacion.agem]);
  useEffect(() => {
    if (!esDependiente && objOrganizacion.agee?.id && objOrganizacion.agem?.id && objOrganizacion.agel?.id && catAGEATemp.length === 0) {
      setObjOrganizacion({ ...objOrganizacion, agea: null });
      dispatch(
        setRequest({
          type: 'get',
          requestFunction: getCatalogoAgea(
            objOrganizacion.agee?.id,
            objOrganizacion.agem?.id,
            objOrganizacion.agel?.id,
            signal,
          ),
          successFunction: (result: ICatStrEstandar[]) =>
            setCatAGEATemp(result),
          toJson: false,
          loading: false,
          showMsgs: false,
        }),
      );
    }
    return () => abortController.abort();
  }, [objOrganizacion.agel]);
  useEffect(() => {
    if (selObjArchivo && selObjArchivo.archivo.size > 1048576) {
      setSelObjArchivo(null);
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: t('el_archivo_selecionado_pesa_mas_de_1_mega'),
        }),
      );
      return;
    }
    if (selObjArchivo) {
      dispatch(setLoading(true));
      // despues en un futuro debemos crear un XMLHttpRequest en vez de un fetch para obtener el progreso de la carga
      // ese progreso se dejará maqueteado para usarlo en un futuro, asi que los comentarios de dejan a proposito
      // obtenemos un objeto form data que nos servira para enviar nuestra peticion como si se hubiera generado en
      // un formulario
      const data = new FormData();
      // añadimos la informacion importante y OJO el archivo debe ir siempre al último o el back no parseara correctamente
      // los parametros que se le adjunta
      data.append('idLista', String(selObjArchivo.idLista));
      data.append('idMedico', String(idMedico));
      data.append('nombre', String(selObjArchivo.archivo?.name));
      data.append('tipo', selObjArchivo.archivo?.type);
      data.append('peso', String(selObjArchivo.archivo?.size));
      data.append('registro', 'imagenOrganizacion');
      data.append('file', selObjArchivo?.archivo);
      crearArchivo(data)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            dispatch(
              setRequest({
                type: 'get',
                multiple: true,
                requestFunctions: [
                  obtenerURLArchivo(
                    idMedico,
                    null,
                    objOrganizacion.idOrganizacion,
                    null,
                    result.datos.url,
                    'imagenOrganizacion',
                  ),
                  getPerfilMedicoOrganizacion(idMedico, objOrganizacion.idOrganizacion),
                ],
                successFunctions: [
                  (res: any) => setUrlLogo(res.data.url),
                  (res: any) => dispatch(setOrganizacion(res.data)),
                ],
                showMsgSuccess: false,
              }),
            );
            setObjOrganizacion({ ...objOrganizacion, urlLogotipo: result.datos.url || '' });
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `Ocurrio un error al procesar el archivo: ${err}`,
            }),
          );
        });
    }
  }, [selObjArchivo]);

  return (
    <div className="p-2">
      <div className="flex flex-col justify-center">
        <h1 className="text-blue-500 font-normal m-2 text-center">
          {t('administrador_mi_organizacion')}
        </h1>
      </div>
      <div className="flex justify-center items-center flex-col">
        <Avatar className={classes.ninetyPx} src={urlLogo}>
          {urlLogo.length === 0 && <AddToPhotosIcon fontSize="large" />}
        </Avatar>
        <label htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            onChange={seleccionarArchivo}
            disabled={objOrganizacion.idOrganizacion <= 0}
          />
          <HtmlTooltip
            title={
              objOrganizacion.idOrganizacion <= 0
                ? `${t('administrador_alta_empresa_luego_logo')}`
                : ''
            }
          >
            <span>
              <Button
                component="span"
                color="primary"
                disabled={objOrganizacion.idOrganizacion <= 0}
              >
                {t('agregar_logotipo')}
              </Button>
            </span>
          </HtmlTooltip>
        </label>
      </div>
      {!esDependiente && (
        <div className="pt-4">
          <FormControl component="fieldset">
            <FormLabel>{`${t('administrador_mi_organizacion_personalidad')}*`}</FormLabel>
            <RadioGroup
              value={objOrganizacion.personalJuridica}
              onChange={handleChangePersonaJuridica}
              aria-label="personalidad_juridica"
              name="personalidad_juridica"
              row
            >
              <FormControlLabel
                value="pm"
                control={<Radio color="primary" />}
                label={t('administrador_mi_organizacion_moral')}
              />
              <FormControlLabel
                value="pf"
                control={<Radio color="primary" />}
                label={t('administrador_mi_organizacion_fisica')}
              />
            </RadioGroup>
          </FormControl>
        </div>
      )}
      {(esDependiente || objOrganizacion.personalJuridica === 'pm') && (
        <div className="w-full my-4">
          <h3 className="text-gray-500 font-normal m-0 mb-4">
            {`${t('administrador_mi_organizacion_nombre')}* (${t(
              'administrador_mi_organizacion_comercial',
            )})`}
          </h3>
          <TextField
            variant="outlined"
            fullWidth
            inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 250 }}
            onChange={handleChangeNombre}
            value={objOrganizacion.nombreOrganizacion}
          />
        </div>
      )}
      {!esDependiente && (
        <div>
          <div className="w-full my-4">
            <h3 className="text-gray-500 font-normal m-0 mb-4">
              {`${t('administrador_mi_organizacion_razon_social')}*`}
            </h3>
            <TextField
              variant="outlined"
              fullWidth
              inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 250 }}
              onChange={handleChangeRazonSocial}
              value={objOrganizacion.razonSocial}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <h3 className="text-gray-500 font-normal m-0 mb-4">
                {t(`administrador_mi_organizacion_rfc_${pais}`)}
              </h3>
              <TextField
                variant="outlined"
                fullWidth
                inputProps={{
                  autocomplete: 'off',
                  'aria-autocomplete': 'none',
                  maxLength: rfcLongitudMax(pais),
                }}
                onChange={handleChangeRfc}
                value={objOrganizacion.rfc}
                onBlur={handleBlurRfc}
                helperText={errores.rfc.helperText}
                error={errores.rfc.error}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
              />
            </div>
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 152) && (
              <div>
                <h3 className="font-normal m-0 text-gray-500 mb-4">
                  {t('administrador_mi_organizacion_cfdi')}
                </h3>
                <Autocomplete
                  options={filterCatCfdi}
                  getOptionLabel={(option) => option.label}
                  fullWidth
                  noOptionsText={t('sin_resultados')}
                  value={objOrganizacion.cfdi}
                  onChange={(e, nV) => handleChangeCfdi(e, nV)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              </div>
            )}
          </div>
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 152) && (
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <h3 className="text-gray-500 font-normal m-0 mb-4">
                  {t('administrador_mi_organizacion_email')}
                </h3>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  InputProps={{
                    inputProps: {
                      autocomplete: 'off',
                      'aria-autocomplete': 'none',
                      maxLength: 100,
                    },
                  }}
                  onChange={handleChangeCorreoElectronico}
                  value={objOrganizacion.email}
                  onBlur={handleBlurEmail}
                  helperText={errores.email.helperText}
                  error={errores.email.error}
                  FormHelperTextProps={{ className: classes.absoluteBottom }}
                />
              </div>
              <div className="grid grid-cols-6 gap-4 items-end">
                <div className="col-span-4">
                  <h3 className="text-gray-500 font-normal m-0 mb-4">
                    {t('administrador_mi_organizacion_telefono')}
                  </h3>
                  <TextField
                    variant="outlined"
                    fullWidth
                    inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                    onChange={handleChangeTelefono1}
                    value={objOrganizacion.telefono}
                  />
                </div>
                <div className="col-span-2">
                  <h3 className="text-gray-500 font-normal m-0 mb-4">{t('extension')}</h3>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder={t('numero')}
                    inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                    onChange={handleChangeExtension1}
                    value={objOrganizacion.extension}
                  />
                </div>
              </div>
            </div>
          )}
          {geo.agee && (
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <h3 className="font-normal m-0 text-gray-500 mb-4">
                  {`${t(`${geo.agee.label}`)}${geo.agee.required ? '*' : ''}`}
                </h3>
                {/* <SelectInput
                  label={`${t(`${geo.agee.label}`)}${geo.agee.required ? '*' : ''}`}
                  name="agee"
                  options={catAGEE}
                  value={objOrganizacion.agee?.id}
                  setValue={handleGeoChange}
                  // isNumeric={false}
                  isRequired={
                    geo.agee.required &&
                    String(datosUbicacionTemp.territorioOriginario) === gentilicio
                  }
                  isDisabled={
                    hayCambios || String(datosUbicacionTemp.territorioOriginario) !== gentilicio
                  }
                /> */}
                <Autocomplete
                  options={catAGEE}
                  getOptionLabel={(option) => option.label}
                  fullWidth
                  noOptionsText={t('sin_resultados')}
                  value={objOrganizacion.agee}
                  onChange={(e, nV) => handleChangeAgee(e, nV)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              </div>
              {geo.agem && (
                <div>
                  <h3 className="font-normal m-0 text-gray-500 mb-4">
                    {`${t(`${geo.agem.label}`)}${geo.agem.required ? '*' : ''}`}
                  </h3>
                  <Autocomplete
                    options={catAGEMTemp}
                    getOptionLabel={(option) => option.label}
                    fullWidth
                    noOptionsText={t('sin_resultados')}
                    value={objOrganizacion.agem}
                    onChange={(e, nV) => handleChangeAgem(e, nV)}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                    )}
                  />
                </div>
              )}
            </div>
          )}
          {geo.agel && (
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <h3 className="font-normal m-0 text-gray-500 mb-4">
                  {`${t(`${geo.agel.label}`)}${geo.agel.required ? '*' : ''}`}
                </h3>
                <Autocomplete
                  options={catAGELTemp}
                  getOptionLabel={(option) => option.label}
                  fullWidth
                  noOptionsText={t('sin_resultados')}
                  value={objOrganizacion.agel}
                  onChange={(e, nV) => handleChangeAgel(e, nV)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              </div>
              {geo.agea && (
                <div>
                  <h3 className="font-normal m-0 text-gray-500 mb-4">
                    {`${t(`${geo.agea.label}`)}${geo.agea.required ? '*' : ''}`}
                  </h3>
                  <Autocomplete
                    id="combo-box-agea-residente"
                    options={catAGEATemp}
                    getOptionLabel={(option) => option.label}
                    fullWidth
                    noOptionsText={t('sin_resultados')}
                    value={objOrganizacion.agea}
                    onChange={(e, nV) => handleChangeAgea(e, nV)}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                    )}
                  />
                </div>
              )}
            </div>
          )}
          <div className="grid grid-cols-12 gap-4 my-4">
            <div className="col-span-9">
              <h3 className="text-gray-500 font-normal m-0 mb-4">
                {`${t(`${geo.calle?.label}`)}${geo.calle?.required ? '*' : ''}`}
              </h3>
              <TextField
                variant="outlined"
                fullWidth
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 50 }}
                onChange={handleChangeCalle}
                value={objOrganizacion.calle}
              />
            </div>
            <div>
              <h3 className="text-gray-500 font-normal m-0 mb-4">
                {`${t(`${geo.num1?.label}`)}${geo.num1?.required ? '*' : ''}`}
              </h3>
              <TextField
                variant="outlined"
                fullWidth
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                onChange={handleChangeNum1}
                value={objOrganizacion.num1}
              />
            </div>
            {geo.num2 && (
              <div>
                <h3 className="text-gray-500 font-normal m-0 mb-4">
                  {`${t(`${geo.num2.label}`)}${geo.num2.required ? '*' : ''}`}
                </h3>
                <TextField
                  variant="outlined"
                  fullWidth
                  inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                  onChange={handleChangeNum2}
                  value={objOrganizacion.num2}
                />
              </div>
            )}
            <div>
              <h3 className="text-gray-500 font-normal m-0 mb-4">
                {`${t(`${geo.cp?.label}_av`)}${geo.cp?.required ? '*' : ''}`}
              </h3>
              <TextField
                variant="outlined"
                fullWidth
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 5 }}
                onChange={handleChangeCP}
                value={objOrganizacion.cp}
              />
            </div>
          </div>
        </div>
      )}
      <hr />
      <h2 className="text-gray-500 font-normal m-0 my-4">{t('administrador_empleados_titulo')}</h2>
      <div className="grid grid-cols-4 gap-4">
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-4">
            {t('administrador_empleados_beneficio_diario')}
          </h3>
          <TextField
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{getMoneda(pais).simbolo}</InputAdornment>
              ),
              inputProps: { autocomplete: 'off', 'aria-autocomplete': 'none' },
            }}
            onChange={handleChangeBeneficioBrutoDiario}
            value={objOrganizacion.beneficioBrutoDiario}
          />
        </div>
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-4">
            {t('administrador_empleados_presentismo_tabaco')}
          </h3>
          <TextField
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('minutos_abrev')}</InputAdornment>,
              inputProps: { autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 3 },
            }}
            onChange={handleChangePresentismoTabaco}
            value={objOrganizacion.presentismoTabaquismo}
          />
        </div>
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-4">
            {t('administrador_empleados_rel_costo_beneficio')}
          </h3>
          <TextField
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">1 :</InputAdornment>,
              inputProps: { autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 2 },
            }}
            onChange={handleChangeRelCostoBeneficio}
            value={objOrganizacion.relCostoBeneficio}
          />
        </div>
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-4">
            {t('administrador_empleados_dias_laborales_anio')}
          </h3>
          <TextField
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('dias_')}</InputAdornment>,
              inputProps: { autocomplete: 'off', 'aria-autocomplete': 'none' },
            }}
            onChange={handleChangeDiasLaboralesAnio}
            value={objOrganizacion.diasLaboralesAnio}
          />
        </div>
      </div>
      <div className="mt-4">
        <span className="text-gray-500">{`*${t('campos_obligatorios')}`}</span>
      </div>
    </div>
  );
}

export default Organizacion;

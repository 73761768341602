import { useEffect, useState } from 'react';

export const useBreakPoint = (threshold: number) => {
  const [isConditionMet, setIsConditionMet] = useState(window.innerWidth > threshold);

  useEffect(() => {
    const handleResize = () => {
      setIsConditionMet(window.innerWidth > threshold);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [threshold]);
  return isConditionMet;
};

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import { ICatalogosEstandar } from '@common/types';
import { getAnimales } from '@utils/getCatalogos';
import {
  antecedentesNoPatologicosGuardarVivienda,
  antecedentesNoPatologicosUsuarioPacienteGuardarVivienda,
  antecedentesNoPatologicosUsuarioGuardarVivienda,
} from '@utils/sendInfo';
import { setRequest } from '@actions/request/types';
import { setVivienda, setViviendaUsuario } from '@actions/paciente/actions';
import { setRutaAntNoPatologicos, setRutaPrincipal } from '@actions/rutasExpediente/actions';
// import MascotasComponente from './Mascotas/index';
import { ITipoMascota, IVivienda, viviendaInitial } from './types';
import { ModalForm } from '@components/modals/ModalForm';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import TextField from '@components/FormElemntsLibrary/TextField';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import AgregarMascotas from '@components/ModalDialogs/AgregarMascotas';
import { GLOBALS } from '@constants/prueba/globals';
import { useBreakPoint } from '@hooks/useBreakpoint';

function Vivienda() {
  const dispatch = useDispatch();
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { isOxxo } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    expediente: {
      antNoPatologicos: { vivienda },
    },
    usuario: {
      antNoPatologicos: { vivienda: viviendaUsuario },
    },
    cambios: {
      antNoPatologicos: { vivienda: cambios },
    },
  } = useSelector((state: RootState) => state.Paciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);
  const [viviendaTemp, setViviendaTemp] = useState<IVivienda>(viviendaInitial);
  const [txtHac, setTxtHac] = useState<string>('');
  /** Catalogos */
  const [catAnimales, setCatAnimales] = useState<Array<ICatalogosEstandar>>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const hayCambios: boolean = idUsuario > 0 && cambios.some((c: string) => c.length > 0);

  const tipoMascotaInitial: ITipoMascota = {
    idAnimal: { id: 0, label: '' },
    especifica: '',
    cantidad: '',
  };

  const handleInputChange = (e: { name: string; value: any }) => {
    if (e.name === 'animales') {
      if (e.value === 'T') {
        setOpenModal(true);
      }
      setViviendaTemp({
        ...viviendaTemp,
        animales: e.value,
        tipo: e.value === 'F' ? [] : [tipoMascotaInitial],
      });
    } else {
      setViviendaTemp((prevData) => ({ ...prevData, [e.name]: e.value }));
    }
  };

  const handleServicesChange = (eventOrValue: { name: string; value: string[] }) => {
    const { value } = eventOrValue;
    const newServiciosBasicos = {
      check1: false,
      check2: false,
      check3: false,
      check4: false,
    };

    if (value.length > 0 && value[value.length - 1] === 'todos') {
      // Si ya estaba seleccionado "todos", lo deseleccionamos
      if (viviendaTemp.serviciosBasicos.check1) {
        newServiciosBasicos.check1 = false;
      } else {
        // Si no estaba seleccionado, lo seleccionamos y desactivamos los demás
        newServiciosBasicos.check1 = true;
        newServiciosBasicos.check2 = false;
        newServiciosBasicos.check3 = false;
        newServiciosBasicos.check4 = false;
      }
    } else {
      // Manejo de servicios individuales
      newServiciosBasicos.check1 = false; // "todosServicios" siempre falso si hay individuales
      newServiciosBasicos.check2 = value.includes('sinAgua');
      newServiciosBasicos.check3 = value.includes('sinElectricidad');
      newServiciosBasicos.check4 = value.includes('sinDrenaje');
    }
    setViviendaTemp((prev) => ({
      ...prev,
      serviciosBasicos: newServiciosBasicos,
    }));
  };

  const getDatosVivienda = (datos: IVivienda) => ({
    serviciosBasicos: datos.serviciosBasicos || {
      check1: false,
      check2: false,
      check3: false,
      check4: false,
    },
    numeroHabitantes: datos.numeroHabitantes,
    numeroHabitaciones: datos.numeroHabitaciones,
    animales: datos.animales || '',
    trayectoTrabajo: datos.trayectoTrabajo,
    riesgoInundacion: datos.riesgoInundacion,
    zonaDeslaves: datos.zonaDeslaves,
    tipo: datos.tipo || [],
    comentarios: datos.comentarios || '',
  });
  const getDatosExpediente = (datos: IVivienda) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    vivienda: getDatosVivienda(datos),
  });
  const getDatosUsuario = (datos: IVivienda) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    vivienda: getDatosVivienda(datos),
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(viviendaTemp);
    const sendFunctions = [antecedentesNoPatologicosGuardarVivienda(datosToSend)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(antecedentesNoPatologicosUsuarioPacienteGuardarVivienda(datosToSend));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => dispatch(setVivienda({ ...viviendaTemp, loaded: true })),
          () => dispatch(setViviendaUsuario({ ...viviendaTemp, loaded: true })),
        ],
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioGuardarVivienda(
          getDatosUsuario(viviendaTemp),
        ),
        successFunction: () => {
          dispatch(setVivienda({ ...viviendaTemp, loaded: true }));
          dispatch(isOxxo
            ? setRutaAntNoPatologicos('alimentacion')
            : setRutaAntNoPatologicos('higiene')
          );
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosGuardarVivienda(
          getDatosExpediente(viviendaUsuario),
        ),
        successFunction: () => {
          dispatch(setVivienda(viviendaUsuario));
          setViviendaTemp(viviendaUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarVivienda(
          getDatosExpediente(vivienda),
        ),
        successFunction: () => dispatch(setViviendaUsuario(vivienda)),
      }),
    );
  };

  useEffect(() => {
    if (vivienda.loaded && !viviendaTemp.loaded) {
      setViviendaTemp(vivienda);
    }
  }, [vivienda]);
  useEffect(() => {
    if (viviendaTemp.loaded) {
      if (
        viviendaTemp.numeroHabitaciones &&
        viviendaTemp.numeroHabitaciones !== 0 &&
        viviendaTemp.numeroHabitantes &&
        viviendaTemp.numeroHabitantes !== 0
      ) {
        const indiceHac = viviendaTemp.numeroHabitantes / viviendaTemp.numeroHabitaciones;
        setViviendaTemp({
          ...viviendaTemp,
          indiceHac: indiceHac > 0 ? indiceHac.toFixed(1) : '',
        });
        if (indiceHac > 2.5) {
          setTxtHac(`${t('antecedentes-pnp-vivienda-conih')}*`);
        } else {
          setTxtHac(`${t('antecedentes-pnp-vivienda-sinih')}*`);
        }
      } else {
        setViviendaTemp({
          ...viviendaTemp,
          indiceHac: '',
        });
        setTxtHac('');
      }
    }
  }, [viviendaTemp.numeroHabitaciones, viviendaTemp.numeroHabitantes]);
  useEffect(() => {
    getAnimales().then((result: ICatalogosEstandar[]) => {
      setCatAnimales(result);
    });
  }, []);

  return (
    <div id="antecedentes-pnp-vivienda" aria-labelledby="antecedentes-pnp-vivienda">
      <ModalForm
        title={t('antecedentes-pnp-vivienda')}
        id="antecedentes-pnp-vivienda-form"
        aceptarCambios={guardarDatosDeUsuarioAExpediente}
        rechazarCambios={guardarDatosDeExpedienteAUsuario}
        submitForm={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
        hayCambios={hayCambios}
        cambios={cambios}
        backAction={() => dispatch(setRutaPrincipal('main'))}
      >
        <div className="flex flex-col">
          {!isOxxo &&
            <div className="grid grid-cols-1 md:grid-cols-4 md:gap-2">
              <CheckboxGroup
                name="serviciosVivienda"
                label={t('antecedentes-pnp-vivienda-sv')}
                options={[
                  { value: 'todos', label: t('antecedentes-pnp-vivienda-tt') },
                  { value: 'sinAgua', label: t('antecedentes-pnp-vivienda-sa') },
                  { value: 'sinElectricidad', label: t('antecedentes-pnp-vivienda-se') },
                  { value: 'sinDrenaje', label: t('antecedentes-pnp-vivienda-sd') },
                ]}
                setValue={(e) => handleServicesChange(e)}
                value={[
                  ...(viviendaTemp.serviciosBasicos.check1 ? ['todos'] : []),
                  ...(viviendaTemp.serviciosBasicos.check2 ? ['sinAgua'] : []),
                  ...(viviendaTemp.serviciosBasicos.check3 ? ['sinElectricidad'] : []),
                  ...(viviendaTemp.serviciosBasicos.check4 ? ['sinDrenaje'] : []),
                ]}
                allowMultiple
                disabled={hayCambios}
              />
              <NumberInput
                name="numeroHabitantes"
                label={t('antecedentes-pnp-vivienda-nhab')}
                value={viviendaTemp.numeroHabitantes || 0}
                setValue={handleInputChange}
                min={0}
                disabled={hayCambios}
              />
              <NumberInput
                name="numeroHabitaciones"
                label={t('antecedentes-pnp-vivienda-ncua')}
                value={viviendaTemp.numeroHabitaciones || 0}
                setValue={handleInputChange}
                min={0}
                disabled={hayCambios}
              />
              {!esPaciente &&
                <TextField
                  name="indiceHac"
                  label={t('antecedentes-pnp-vivienda-ihac')}
                  value={viviendaTemp.indiceHac}
                  adornment={{
                    text: txtHac,
                    position: 'end',
                    alert: parseFloat(viviendaTemp.indiceHac) > 2.5,
                  }}
                  helperText={txtHac.length > 0 ? t('antecedentes-pnp-vivienda-notaih') : ''}
                  disabled={hayCambios}
                />
              }
            </div>
          }
          <div className="grid grid-cols-1 md:grid-cols-1 md:mt-2">
            {!isOxxo &&
              <CheckboxGroup
                numberPosition={isOxxo && esPaciente ? 0 : undefined}
                containerClass="md:mt-2"
                name="animales"
                label={t('antecedentes-pnp-vivienda-mascotas')}
                options={[
                  { value: 'T', label: t('si') },
                  { value: 'F', label: t('no') },
                ]}
                edit={{
                  show: viviendaTemp.animales === 'T',
                  onClick: () => setOpenModal(prev => !prev),
                }}
                distribution={isWideScreen ? "horizontal-inline" : "vertical-flex"}
                value={viviendaTemp.animales}
                setValue={handleInputChange}
                disabled={hayCambios}
              />
            }
            {isOxxo && esPaciente &&
              <>
                <CheckboxGroup
                  numberPosition={0}
                  name="trayectoTrabajo"
                  label={t('antecedentes-pnp-vivienda-trayecto-trabajo')}
                  options={[
                    { value: 'si', label: t('si') },
                    { value: 'no', label: t('no') },
                  ]}
                  distribution={isWideScreen ? "horizontal-inline" : "vertical-flex"}
                  value={viviendaTemp.trayectoTrabajo}
                  setValue={handleInputChange}
                  disabled={hayCambios}
                />
                <CheckboxGroup
                  numberPosition={1}
                  name="riesgoInundacion"
                  label={t('antecedentes-pnp-vivienda-riesgo-inundacion')}
                  options={[
                    { value: 'si', label: t('si') },
                    { value: 'no', label: t('no') },
                  ]}
                  distribution={isWideScreen ? "horizontal-inline" : "vertical-flex"}
                  value={viviendaTemp.riesgoInundacion}
                  setValue={handleInputChange}
                  disabled={hayCambios}
                />
                <CheckboxGroup
                  numberPosition={2}
                  name="zonaDeslaves"
                  label={t('antecedentes-pnp-vivienda-zona-deslaves')}
                  options={[
                    { value: 'si', label: t('si') },
                    { value: 'no', label: t('no') },
                  ]}
                  distribution={isWideScreen ? "horizontal-inline" : "vertical-flex"}
                  value={viviendaTemp.zonaDeslaves}
                  setValue={handleInputChange}
                  disabled={hayCambios}
                />
              </>
            }
          </div>
          <TextArea
            containerClass="mt-4"
            name="comentarios"
            value={viviendaTemp.comentarios}
            setValue={handleInputChange}
            disabled={hayCambios}
          />
        </div>
        <AgregarMascotas
          open={openModal}
          hayCambios={hayCambios}
          viviendaTemp={viviendaTemp}
          setViviendaTemp={setViviendaTemp}
          catAnimales={catAnimales}
          callBackAceptar={() => setOpenModal(false)}
          callBackClose={() => setOpenModal(false)}
        />
      </ModalForm>
    </div>
  );
}

export default Vivienda;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { ICatalogosEstandar } from '@common/types';
import { IAnimalesProps } from './types';
import SelectInput from '@components/FormElemntsLibrary/SelectInput2';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

function MascotasComponente({
  hayCambios,
  eliminarMascota,
  handleChange,
  catAnimales,
  index,
  idAnimal,
  cantidad,
  especifica,
}: IAnimalesProps) {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');

  const handleMascotaChange = (e: {name: string, value: any}) => handleChange({ ...e, index });

  return (
    <div key={`div-${index}`} className="border-solid border border-gray-400 rounded p-4 mb-3 flex">
      <div className={`grid gap-4 grid-cols-1 md:grid-cols-${
        idAnimal && idAnimal.id && idAnimal.id === 28 ? '3' : '2'
      } w-full`}>
        <div>
          <SelectInput
            name="idAnimal"
            label={t('antecedentes-pnp-vivienda-tipo')}
            variant="search"
            options={catAnimales}
            value={idAnimal}
            setValue={handleMascotaChange}
            sendObject
          />
        </div>
        {idAnimal && idAnimal.id && idAnimal.id === 28
          ? <TextInput
            name="especifica"
            value={especifica}
            setValue={handleMascotaChange}
            disabled={hayCambios}
          /> : null
        }
        <div>
          <TextInput
            name="cantidad"
            value={cantidad}
            type="number"
            setValue={handleMascotaChange}
            disabled={hayCambios}
          />
          {/* <NumberInput
            name="cantidad"
            value={cantidad.length > 0 ? Number(cantidad) : null}
            setValue={handleMascotaChange}
            disabled={hayCambios}
          /> */}
        </div>
      </div>
      <IconButton
        aria-label="delete"
        onClick={() => eliminarMascota(index)} disabled={hayCambios}
        className="h-12 self-center"
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
}

export default MascotasComponente;

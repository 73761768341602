export const GENDER_FORM_CONFIG = {
  progressSteps: [
    {
      status: 'completed',
      title: 'Enfermedades del corazón y crónicas',
    },
    {
      status: 'completed',
      title: 'Problemas nerviosos y dolor',
    },
    {
      status: 'current',
      title: 'Problemas respiratorios y corporales',
    },
  ],
  checkboxGroups: {
    general: {
      type: 'Checkbox',
      distribution: 'vertical',
      allowMultiple: false,
      fields: [
        {
          id: 'convulsiones',
          name: 'convulsiones',
          label: 'Convulsiones, epilepsia, desmayos repentinos',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'ansiedad',
          name: 'ansiedad',
          label: 'Ansiedad o ataques de pánico',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'depresion',
          name: 'depresion',
          label: 'Depresión (diagnosticada)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'estres',
          name: 'estres',
          label: 'Estrés (diagnosticado)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'vertigo',
          name: 'vertigo',
          label: 'Vértigo (mareo)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'dolor_cuello',
          name: 'dolor_cuello',
          label: 'Dolor de cuello (últimos 3 meses)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'dolor_espalda',
          name: 'dolor_espalda',
          label: 'Dolor de espalda baja',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'dolor_articulaciones',
          name: 'dolor_articulaciones',
          label: 'Dolor de hombros, codos, rodillas, tobillos',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'problemas_munecas',
          name: 'problemas_munecas',
          label: 'Problemas en las muñecas (dolor/adormecimiento de dedos y manos)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'hernia_disco',
          name: 'hernia_disco',
          label: 'Hernia de disco (de espalda)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'faltan_extremidades',
          name: 'faltan_extremidades',
          label: '¿Te faltan dedos o extremidades?',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'pension',
          name: 'pension',
          label: '¿Has recibido una pensión por enfermedad, accidente o cesantía?',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'incapacidad',
          name: 'incapacidad',
          label:
            '¿Has tenido alguna incapacidad por más de 30 días? (que no sea de maternidad/paternidad)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
      ],
    },
    male: {
      type: 'Checkbox',
      distribution: 'vertical',
      allowMultiple: false,
      fields: [
        {
          id: 'asma',
          name: 'asma',
          label: 'Asma (en el último año)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'enfermedades_respiratorias',
          name: 'enfermedades_respiratorias',
          label:
            'Enfermedades respiratorias o del pulmón (dificultad para respirar, bronquitis, enfisema)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'piedras_rinones',
          name: 'piedras_rinones',
          label: 'Piedras en los riñones',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'problemas_vision',
          name: 'problemas_vision',
          label:
            'Problemas de visión (ceguera de colores o nocturna, visión borrosa, glaucoma, cataratas)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'usa_lentes',
          name: 'usa_lentes',
          label: '¿Usas lentes?',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'cirugia_abdominal',
          name: 'cirugia_abdominal',
          label: '¿Has tenido una cirugía abdominal en los últimos 6 meses?',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'cirugia_columna',
          name: 'cirugia_columna',
          label: '¿Has tenido una cirugía de columna en los últimos 6 meses?',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'hernia_abdominal',
          name: 'hernia_abdominal',
          label: '¿Tienes una hernia abdominal?',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'problemas_testiculos',
          name: 'problemas_testiculos',
          label: 'Has tenido algún problema en los testículos (cirugía, infección, lesión)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
      ],
    },
    female: {
      type: 'Checkbox',
      distribution: 'vertical',
      allowMultiple: false,
      fields: [
        {
          id: 'asma_crisis',
          name: 'asma_crisis',
          label: 'Asma o crisis asmática (en el último año)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'enfermedades_respiratorias',
          name: 'enfermedades_respiratorias',
          label:
            'Enfermedades respiratorias (dificultad para respirar, bronquitis, enfisema pulmonar)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'piedras_rinones',
          name: 'piedras_rinones',
          label: 'Piedras en los riñones',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'problemas_vision',
          name: 'problemas_vision',
          label:
            'Problemas de visión (ceguera de colores o nocturna, visión borrosa, glaucoma, cataratas, otros)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'usa_lentes',
          name: 'usa_lentes',
          label: '¿Usas lentes?',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'cirugia_abdominal',
          name: 'cirugia_abdominal',
          label: '¿Has tenido una cirugía abdominal en los últimos 6 meses?',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'cirugia_columna',
          name: 'cirugia_columna',
          label: '¿Has tenido una cirugía de columna en los últimos 6 meses?',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'hernia_abdominal',
          name: 'hernia_abdominal',
          label: '¿Tienes una hernia abdominal?',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          id: 'problemas_pechos',
          name: 'problemas_pechos',
          label:
            'Tienes o has tenido algún problema en los pechos (bultos, tumores, quistes, cirugías)',
          options: [
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ],
        },
      ],
    },
  },
};

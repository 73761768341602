import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { FC, useEffect } from 'react';
import { SmokingFormProps } from './types';

export const SmokingForm: FC<SmokingFormProps> = ({ isExSmoker, formData, onInputChange }) => {
  // Efecto para calcular el IPA y nivel de tabaquismo
  useEffect(() => {
    if (formData.tabaco.cigarrillosDia && formData.tabaco.edadInicio) {
      const cigarrillos = Number(formData.tabaco.cigarrillosDia);
      const edadInicio = Number(formData.tabaco.edadInicio);
      const edadActual = new Date().getFullYear();
      const edadTermino = isExSmoker ? Number(formData.tabaco.edadTermino) : edadActual;

      if (!isNaN(cigarrillos) && !isNaN(edadInicio)) {
        // Cálculo de años fumando
        const yearsSmoking = edadTermino - edadInicio;
        // Cálculo de paquetes por día (20 cigarrillos = 1 paquete)
        const packetsPerDay = cigarrillos / 20;
        // Cálculo del IPA (Índice Paquetes/Año)
        const ipa = (packetsPerDay * yearsSmoking).toFixed(1);

        // Actualizar el IPA en el formulario
        onInputChange({
          name: 'tabaco.ipa',
          value: ipa,
        });

        // Determinar nivel de alarma según el IPA
        let nivelAlarma = '';
        if (Number(ipa) < 5) nivelAlarma = 'leve';
        else if (Number(ipa) < 15) nivelAlarma = 'moderado';
        else nivelAlarma = 'severo';

        // Actualizar el nivel de alarma en el formulario
        onInputChange({
          name: 'tabaco.alarmaTabaquismo',
          value: nivelAlarma,
        });
      }
    }
  }, [
    formData.tabaco.cigarrillosDia,
    formData.tabaco.edadInicio,
    formData.tabaco.edadTermino,
    isExSmoker,
  ]);

  return (
    <div className="mt-4 space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <NumberInput
          name="smoking.edadInicio"
          label="Edad de inicio"
          fixedValue="Año(s)"
          value={Number(formData.tabaco.edadInicio) || 0}
          setValue={onInputChange}
        />

        {isExSmoker && (
          <NumberInput
            name="smoking.edadTermino"
            label="Edad de término"
            fixedValue="Año(s)"
            value={Number(formData.tabaco.edadTermino) || 0}
            setValue={onInputChange}
          />
        )}

        <NumberInput
          name="smoking.cigarrillosDia"
          label="Cigarrillos al día"
          value={Number(formData.tabaco.cigarrillosDia) || 0}
          setValue={onInputChange}
        />
      </div>

      <TextArea
        name="smoking.comentarios"
        label="Comentarios"
        value={formData.tabaco.comentarios}
        setValue={onInputChange}
      />

      {/* Mostrar IPA si existe */}
      {formData.tabaco.ipa && (
        <div className="mt-2 p-2 bg-gray-50 rounded">
          <p className="text-sm text-gray-700">
            Índice paquetes/año (IPA):
            <span className="font-medium ml-1">{formData.tabaco.ipa}</span>
          </p>
        </div>
      )}

      {/* Mostrar nivel de tabaquismo si existe */}
      {formData.tabaco.alarmaTabaquismo && (
        <div className="mt-2 p-2 bg-yellow-50 rounded border border-yellow-200">
          <p className="text-sm text-yellow-700">
            Nivel de tabaquismo:
            {' '}
            <span className="capitalize font-medium">{formData.tabaco.alarmaTabaquismo}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default SmokingForm;

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import { X } from 'lucide-react';
import { TransitionProps } from '@material-ui/core/transitions';
import IAlertDialogProps from './types';
import { Button } from '@components/FormElemntsLibrary/Button';

const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  )
);

function RegistroSinEmail(props: IAlertDialogProps) {
  const {
    open,
    callBackClose,
    organization,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={() => callBackClose(false)}
      aria-labelledby="registro-sin-email-titulo"
      aria-describedby="registro-sin-email-descripcion"
      maxWidth="xs"
    >
      <DialogTitle>
        <div className="relative flex items-center h-[68px] bg-white border-b border-[#C4C4C4]">
          <div className="flex-1 text-center">
            <h4 className="font-semibold text-base">{t('advertencia')}</h4>
          </div>
          <div className="absolute  right-0 top-2 cursor-pointer" onClick={() => callBackClose(false)}>
            <X size={20} className="text-gray-600" />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <form className="w-full h-full md:mt-0 flex flex-col justify-between bg-white border-[#C4C4C4] md:z-40 md:max-w-screen-xl">
          <div className="w-full h-auto ">
            <section className="w-full h-fit py-4">
              <span className="flex text-base text-center text-gray-700 ">
                {`${t('no_poseo_email_modal_advertencia')}${
                  organization.length > 0
                    ? `de ${organization}`
                    : ''
                }.`}
              </span>
            </section>
          </div>
          <div className="flex flex-row gap-x-4 py-2">
            <Button
              onClick={() => callBackClose(false)}
              label={t('si_poseo_correo')}
            />
            <Button
              onClick={() => callBackClose(true)}
              label={t('no_poseo_correo')}
              color='red'
            />
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default RegistroSinEmail;

interface HealthRisk {
  id: string;
  description: string;
  riskLevel: 'yellow-400' | 'orange-500' | 'red-500';
}

interface Treatment {
  id: string;
  description: string;
}

interface WorkRisk {
  id: string;
  description: string;
}

const healthRisks: HealthRisk[] = [
  {
    id: '1',
    description: 'Del corazón',
    riskLevel: 'red-500',
  },
  {
    id: '2',
    description: 'Riesgo de cáncer de Lorem ipsum Lorem ipsum Lorem ipsum',
    riskLevel: 'orange-500',
  },
  {
    id: '3',
    description: 'Riesgo de cáncer de Lorem ipsum Lorem ipsum Lorem ipsum',
    riskLevel: 'yellow-400',
  },
];

const treatments: Treatment[] = [
  {
    id: '1',
    description: 'Metformina, Lorem ipsum Lorem ipsum...',
  },
  {
    id: '2',
    description: 'Amoxicilina, Lorem ipsum Lorem ipsum...',
  },
  {
    id: '3',
    description: 'Naproseno, Lorem ipsum Lorem ipsum...',
  },
];

const workRisks: WorkRisk[] = [
  {
    id: '1',
    description: 'Ergonómico, Físico, Químico Lorem ipsum Lorem ipsum...',
  },
  {
    id: '2',
    description: 'Ergonómico, Físico, Químico Lorem ipsum Lorem ipsum...',
  },
  {
    id: '3',
    description: 'Ergonómico, Físico, Químico Lorem ipsum Lorem ipsum...',
  },
];

export { healthRisks, treatments, workRisks };

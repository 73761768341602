import { setActiveModal } from '@actions/modal/actions';
import { ModalForm } from '@components/modals/ModalForm';
import { healthRisks, treatments, workRisks } from '@constants/prueba/resumenDeSalud';
import { RootState } from '@reducer/index';
import { useDispatch, useSelector } from 'react-redux';

export const ResumenDeSalud = () => {
  const { exploracionFisica, diagnosticosGenerales } = useSelector(
    (state: RootState) => state.InfoUser,
  );

  const dispatch = useDispatch();

  const handleDetailClick = (section: 'riesgos' | 'tratamientos' | 'laborales') => {
    switch (section) {
      case 'riesgos':
        dispatch(setActiveModal('RiesgoSalud'));
        break;
      case 'tratamientos':
        dispatch(setActiveModal('Tratamientos'));
        break;
      case 'laborales':
        dispatch(setActiveModal('AntecedentesLaborales'));
        break;
    }
  };

  return (
    <ModalForm
      title="Resumen De Salud"
      id="ResumenDeSalud"
      displayButton={false}
      submitForm={() => { }}
    >
      <div className="w-full max-w-6xl mx-auto p-2 md:p-4 lg:p-6 bg-white">
        <div className="mb-6">
          {/* Risk Indicators */}
          <div className="flex justify-center gap-8 md:gap-16 lg:gap-24 w-full h-auto mb-6 lg:mb-8">
            <div className="text-center flex flex-col items-center">
              <div className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-yellow-400 mb-2" />
              <span className="text-xs md:text-sm whitespace-nowrap">Riesgo intermedio</span>
            </div>
            <div className="text-center flex flex-col items-center">
              <div className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-orange-500 mb-2" />
              <span className="text-xs md:text-sm whitespace-nowrap">Riesgo alto</span>
            </div>
            <div className="text-center flex flex-col items-center">
              <div className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-red-500 mb-2" />
              <span className="text-xs md:text-sm whitespace-nowrap">Riesgo muy alto</span>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-2 gap-6 lg:gap-8">
            {/* Health Risks Section */}
            <div className="w-full h-auto">
              <h2 className="bg-blue-500 text-white p-2 md:p-3 rounded-t-lg text-base md:text-lg">
                Riesgos a mi salud
              </h2>
              <div className="w-full h-fit border rounded-b-lg">
                {healthRisks.map((risk) => (
                  <div
                    key={risk.id}
                    className={`p-3 md:p-4 border-b flex justify-between items-center border-l-4 border-l-${risk.riskLevel}`}
                  >
                    <span className="w-[70%] md:text-base">{risk.description}</span>
                    <span
                      onClick={() => handleDetailClick('riesgos')}
                      className="text-blue-500 text-sm md:text-base hover:text-blue-600 cursor-pointer"
                    >
                      Ver detalle &gt;
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* Diagnostics Section */}
            <div>
              <h2 className="bg-blue-500 text-white p-2 md:p-3 rounded-t-lg text-base md:text-lg">
                Diagnósticos
              </h2>
              <div className="border rounded-b-lg">
                {diagnosticosGenerales.contenidoTabla.map((diagnostico, index) => (
                  <div key={diagnostico.nombre} className="p-3 md:p-4 border-b md:text-base">
                    <span>{diagnostico.nombre}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Treatments Section */}
            <div>
              <h2 className="bg-blue-500 text-white p-2 md:p-3 rounded-t-lg text-base md:text-lg">
                Tratamientos
              </h2>
              <div className="border rounded-b-lg">
                {treatments.map((treatment, index) => (
                  <div
                    key={index}
                    className="p-3 md:p-4 border-b flex justify-between items-center"
                  >
                    <span className="w-[70%] md:text-base">{treatment.description}</span>
                    <span
                      onClick={() => handleDetailClick('tratamientos')}
                      className="text-blue-500 text-sm md:text-base hover:text-blue-600 cursor-pointer"
                    >
                      Ver detalle &gt;
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* Work Risks Section */}
            <div>
              <h2 className="bg-blue-500 text-white p-2 md:p-3 rounded-t-lg text-base md:text-lg">
                Riesgos laborales
              </h2>
              <div className="border rounded-b-lg">
                {workRisks.map((risk, index) => (
                  <div
                    key={index}
                    className="p-3 md:p-4 border-b flex justify-between items-center"
                  >
                    <span className="w-[70%] md:text-base">{risk.description}</span>
                    <span
                      onClick={() => handleDetailClick('laborales')}
                      className="text-blue-500 text-sm md:text-base hover:text-blue-600 cursor-pointer"
                    >
                      Ver detalle &gt;
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Current Measurements and Goals Section */}
          <div className="grid grid-cols-2 gap-4 lg:gap-8 mt-6 lg:mt-8">
            <div className="border rounded-lg p-4 md:p-6">
              <h2 className="text-blue-600 font-semibold mb-3 text-lg md:text-xl">
                Medidas actuales
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <div className="text-sm md:text-base text-gray-500">Peso:</div>
                  <div className="font-medium md:text-lg">
                    {exploracionFisica.peso}
                    {' '}
                    kg
                  </div>
                </div>
                <div>
                  <div className="text-sm md:text-base text-gray-500">Medida de cintura:</div>
                  <div className="font-medium md:text-lg">
                    {exploracionFisica.perCintura}
                    {' '}
                    cm
                  </div>
                </div>
                <div>
                  <div className="text-sm md:text-base text-gray-500">Colesterol total:</div>
                  <div className="font-medium md:text-lg">180 mg/dl</div>
                </div>
                <div>
                  <div className="text-sm md:text-base text-gray-500">Presión Arterial:</div>
                  <div className="font-medium md:text-lg">
                    {exploracionFisica.fCardiacaRadio}
                    {' '}
                    mmHg
                  </div>
                </div>
              </div>
            </div>

            <div className="border rounded-lg p-4 md:p-6">
              <h2 className="text-blue-600 font-semibold mb-3 text-lg md:text-xl">Metas</h2>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <div className="text-sm md:text-base text-gray-500">Peso (ideal):</div>
                  <div className="font-medium md:text-lg">60 kg</div>
                </div>
                <div>
                  <div className="text-sm md:text-base text-gray-500">
                    Medida de cintura (ideal):
                  </div>
                  <div className="font-medium md:text-lg">90 cm</div>
                </div>
              </div>
            </div>
          </div>

          <div className="border rounded-lg p-4 md:p-6 mt-4 ">
            <div className="grid grid-cols-1 gap-4">
              <div>
                <div className="text-sm md:text-base text-gray-500">Colesterol total:</div>
                <div className="font-medium md:text-lg">100 mg/dl</div>
              </div>
              <div>
                <div className="text-sm md:text-base text-gray-500">Presión Arterial:</div>
                <div className="font-medium md:text-lg">150/130 mmHg</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default ResumenDeSalud;

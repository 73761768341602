import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import AdmonNavbar from '@containers/AdmonNavbar';
import Navbar from '@containers/Navbar';
import NavbarPaciente from '@containers/NavbarPaciente';
import { GLOBALS } from '@constants/prueba/globals';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { RootState } from 'src/store';
import { ILoggedInSubscribedRouteProps } from './types';

function LoggedInSubscribedRoute({ children, ...otrasProps }: ILoggedInSubscribedRouteProps) {
  const { suscrito, acreditado } = useSelector((state: RootState) => state.Auth);
  const { rol, esPaciente, secciones } = useSelector((state: RootState) => state.Me);
  const { rutaPrincipal } = useSelector((state: RootState) => state.RutasExpediente);
  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);

  // Ocultar el scroll vertical para las pantallas pequeñas
  // useEffect(() => {
  //   if (!isWideScreen) {
  //     document.body.style.overflow = 'hidden';
  //     return () => {
  //       document.body.style.removeProperty('overflow');
  //     };  
  //   }
  // }, [isWideScreen]);

  if (acreditado === false) {
    return (
      <Route
        {...otrasProps}
        render={({ location }) => (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )}
      />
    );
  }

  return (
    <Route
      {...otrasProps}
      render={({ location }) =>
        (rol.grupo !== 'paciente' && suscrito === false ? (
          <Redirect to={{ pathname: '/subscripcion', state: { from: location } }} />
        ) : (
          <div className="flex">
            {secciones.length > 0 &&
              secciones.find((valor) => valor.idSeccion >= 1 && valor.idSeccion <= 149) && (
                <>{esPaciente ? <NavbarPaciente /> : <Navbar />}</>
            )}
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion >= 150) && (
              <AdmonNavbar />
            )}
            <main
              className={`top-16 ${
                isWideScreen
                  ? 'relative w-full'
                  : `fixed bottom-0 left-0 right-0 ${
                    rutaPrincipal === 'main' ? '' : 'overflow-y-auto'
                  }`
              }`}
            >
              {children}
            </main>
          </div>
        ))}
    />
  );
}

export default LoggedInSubscribedRoute;

import { ChevronDown, Search } from 'lucide-react';
import { FC, useEffect, useRef, useState } from 'react';
import { SelectInputProps } from './type';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useTranslation } from 'react-i18next';

export const SelectInput: FC<SelectInputProps> = ({
  name,
  label,
  isRequired,
  options,
  placeholder = 'Selecciona',
  helperText,
  variant = 'normal',
  setValue,
  disableOption,
  value: externalValue,
  isDisabled: isDisabledExternal,
  position,
  errorMessage: externalErrorMessage
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const defaultOption = { id: '', label: placeholder };
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState<{
    id: string | number;
    label: string;
  }>(defaultOption);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [wasFocused, setWasFocused] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof isDisabledExternal === 'boolean') {
      setIsDisabled(isDisabledExternal);
    }
  }, [isDisabledExternal]);

  useEffect(() => {
    if (externalValue !== undefined) {
      const option =
        options.find((opt) => opt.id.toString() === externalValue.toString()) || defaultOption;
      setSelectedOption(option);
    }
  }, [externalValue, options]);

  useEffect(() => {
    if (typeof isDisabledExternal === 'boolean') {
      setIsDisabled(!isDisabledExternal);
    }
  }, [isDisabledExternal]);

  const validateSelect = () => {
    if (isRequired && wasFocused && (!selectedOption.id || selectedOption.id === '')) {
      setErrorMessage(t('campo_obligatorio'));
      return false;
    }
    setErrorMessage('');
    return true;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        if (isOpen) {
          validateSelect();
        }
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, wasFocused]);

  const filteredOptions: { id: string | number; label: string }[] = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleDisableChange = () => {
    setIsDisabled(!isDisabled);
    if (!isDisabled) {
      setSelectedOption(defaultOption);
      setErrorMessage('');
      setWasFocused(false);
      setValue({
        name,
        value: 'notiene',
        position,
      });
    }
  };

  const handleSelect = (option: { id: string | number; label: string }) => {
    if (!isDisabled) {
      setSelectedOption(option);
      setSearchTerm('');
      setIsOpen(false);
      setErrorMessage('');
      setValue({
        name,
        value: {
          id: option.id,
          label: option.label
        },
        position,
      });
    }
  };

  const handleToggleDropdown = () => {
    if (!isDisabled) {
      if (!wasFocused) {
        setWasFocused(true);
      }
      setIsOpen(!isOpen);
      if (isOpen) {
        validateSelect();
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isDisabled) {
      const value = e.target.value;
      setSearchTerm(value);
      if (!isOpen) {
        setIsOpen(true);
      }
      if (!wasFocused) {
        setWasFocused(true);
      }
    }
  };

  return (
    <div className="w-full h-auto px-4">
      <div className="w-full h-[66px] mb-8" ref={dropdownRef}>
        <div className="flex items-center justify-between mb-2">
          {label && (
            <label
              htmlFor={name}
              className={`block font-medium ${
                errorMessage ? 'text-red-500' : isDisabled ? 'text-gray-500' : 'text-gray-700'
              }`}
            >
              {label} {isRequired && <span className="text-red-500">*</span>}
            </label>
          )}
          {disableOption?.position === 'right' && (
            <label className="flex items-center text-sm text-gray-600 whitespace-nowrap ml-4">
              <input
                type="checkbox"
                className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
                checked={isDisabled}
                onChange={handleDisableChange}
              />
              {disableOption.label}
            </label>
          )}
        </div>

        <div className="relative">
          {variant === 'normal' ? (
            <div
              className={`w-full h-[48px] p-2 border rounded-md flex justify-between items-center 
               ${
                 isDisabled
                   ? 'bg-gray-50 border-gray-200 text-gray-400 cursor-not-allowed'
                   : errorMessage
                   ? 'bg-white border-red-500 ring-2 ring-red-200'
                   : 'bg-white border-gray-300 cursor-pointer'
               }`}
              onClick={handleToggleDropdown}
            >
              <span className={selectedOption.id === '' ? 'text-gray-400' : ''}>
                {selectedOption.label}
              </span>
              <ChevronDown
                size={20}
                className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
              />
            </div>
          ) : (
            <div
              className={`relative h-[48px] flex items-center border rounded-md
               ${
                 isDisabled
                   ? 'bg-gray-50 border-gray-200'
                   : errorMessage
                   ? 'bg-white border-red-500 ring-2 ring-red-200'
                   : 'bg-white border-gray-300'
               }`}
            >
              <Search className="absolute left-2 text-gray-400" size={18} />
              <input
                ref={inputRef}
                type="text"
                disabled={isDisabled}
                className={`w-full pl-9 pr-8 py-2 bg-transparent focus:outline-none rounded-md
                 ${
                   isDisabled
                     ? 'text-gray-400 cursor-not-allowed'
                     : 'focus:ring-2 focus:ring-blue-500'
                 }`}
                placeholder={placeholder}
                value={isOpen ? searchTerm : selectedOption.label}
                onChange={handleInputChange}
                onClick={() => !isDisabled && setIsOpen(true)}
              />
              <ChevronDown
                size={20}
                className={`absolute right-2 transition-transform duration-200 ${
                  isOpen ? 'rotate-180' : ''
                }`}
              />
            </div>
          )}

          {isOpen && !isDisabled && (
            <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <div
                    key={option.id}
                    className={`p-2 cursor-pointer hover:bg-gray-100 
                     ${
                       option.id.toString() === selectedOption.id.toString()
                         ? 'bg-blue-50 text-blue-600'
                         : ''
                     }`}
                    onClick={() => handleSelect(option)}
                  >
                    {option.label}
                  </div>
                ))
              ) : (
                <div className="p-2 text-gray-500 text-center">No hay opciones disponibles</div>
              )}
            </div>
          )}
        </div>

        {(errorMessage || helperText || externalErrorMessage) && (
          <p className={`mt-1 text-sm ${errorMessage || externalErrorMessage ? 'text-red-500' : 'text-gray-500'}`}>
            {errorMessage || helperText || externalErrorMessage}
          </p>
        )}
      </div>
      {disableOption?.position === 'after' && (
        <label className="flex items-center text-sm text-gray-600 whitespace-nowrap">
          <input
            type="checkbox"
            className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
            checked={isDisabled}
            onChange={handleDisableChange}
          />
          {disableOption.label}
        </label>
      )}
    </div>
  );
};

export default SelectInput;

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from 'lucide-react';
import NivelCafeinaAlerta from '@components/AlertasInformacion/NivelCafeina';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { ICafeinaProps } from './types';
import { toxicomaniaInitial } from '../types';
import ScaleInput from '@components/FormElemntsLibrary/ScaleInput';
import TextField from '@components/FormElemntsLibrary/TextField';
import TextArea from '@components/FormElemntsLibrary/TextArea';

function Cafeina(props: ICafeinaProps) {
  const {
    hayCambios, enGestacion, toxicomania, setToxicomania, getCafeina,
  } = props;
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [openInfo, setOpenInfo] = useState(false);
  const estatus = ['niega', 'activo'].map(es => ({ value: es, label: t(es) }));

  const handleInputChange = (e: { name: string; value: any }) => {
    setToxicomania({
      ...toxicomania,
      cafeina: {
        ...toxicomania.cafeina,
        [e.name]: e.value,
      }
    });
  };

  useEffect(() => {
    if (toxicomania.cafeina.consumoCafeina === 'niega') {
      setToxicomania({
        ...toxicomania,
        cafeina: {
          ...toxicomaniaInitial.cafeina,
          consumoCafeina: 'niega',
        },
      });  
    }
  }, [toxicomania.cafeina.consumoCafeina]);

  useEffect(() => {
    setToxicomania({
      ...toxicomania,
      cafeina: getCafeina(toxicomania.cafeina),
    });
  }, [toxicomania.cafeina.tazaDeCafe, toxicomania.cafeina.lataDeBebidaEnergetica, enGestacion]);

  return (
    <>
      <NivelCafeinaAlerta open={openInfo} setOpen={setOpenInfo} />
      <CheckboxGroup
        name="consumoCafeina"
        label={t('cafeina')}
        options={estatus}
        value={toxicomania.cafeina.consumoCafeina}
        setValue={handleInputChange}
        disabled={hayCambios}
        distribution="vertical-flex"
      />
      {toxicomania.cafeina.consumoCafeina === 'activo' && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-4 md:mt-2">
            <ScaleInput
              name="tazaDeCafe"
              label={t('taza_de_cafe')}
              options={[...Array(5)].map((x, i) => ({
                value: String(i + 1),
                label: `${i + 1}${i < 4 ? '' : '+'}`,
              }))}
              value={toxicomania.cafeina.tazaDeCafe || undefined}
              setValue={handleInputChange}
              disabled={hayCambios}
            />
            <ScaleInput
              name="lataDeBebidaEnergetica"
              label={t('lata_de_bebida_energetica')}
              options={[...Array(5)].map((x, i) => ({
                value: String(i + 1),
                label: `${i + 1}${i < 4 ? '' : '+'}`,
              }))}
              value={toxicomania.cafeina.lataDeBebidaEnergetica || undefined}
              setValue={handleInputChange}
              disabled={hayCambios}
            />
            {!esPaciente &&
              <>
                <TextField
                  name="cafeinaAlDia"
                  label={t('total_aproximado_de_cafeina_al_dia')}
                  value={toxicomania.cafeina.cafeinaAlDia}
                  adornment={{
                    text: t('mg_de_cafeina/dia'),
                    position: 'end',
                  }}
                  disabled={hayCambios}
                />
                <div className="flex flex-col justify-center md:gap-3 mb-4 md:mb-0 pl-4">
                  <button
                    type="button"
                    className="text-base text-blue-500 mb-2"
                    onClick={() => setOpenInfo(true)}
                    disabled={hayCambios}
                  >
                    <div className="flex flex-row text-sm">
                      <InfoIcon className="mr-2" size={15}/>
                      {t('estimacion_de_nivel_de_cafeina')}
                    </div>
                  </button>
                  {toxicomania.cafeina.alarmaConsumoExcesivo
                    ? <p className="text-red-500 col-span-2">
                      {enGestacion === 'si'
                        ? t('posible_consumo_excesivo_de_cafeina_durante_el_embarazo')
                        : t('posible_consumo_excesivo_de_cafeina')}
                    </p>
                    : <div className="md:mt-5"/>
                  }
                </div>
              </>
            }
          </div>
          <TextArea
            name="comentarios"
            value={toxicomania.cafeina.comentarios}
            setValue={handleInputChange}
            maxLength={300}
            disabled={hayCambios}
          />
        </>
      )}
    </>
  );
}

export default Cafeina;

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AppBar, Toolbar, CssBaseline, Button,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import loading from '@actions/loading/actions';
import { ReactComponent as LogoIcon } from '@assets/logo/Logotipo_Color.svg';
import { sendLoguot } from '@utils/sendInfo';
import { getToken, getUser, removeUserSession } from '@utils/commonStore';
import { limpiarReduxSesion } from '@utils/reduxCommon';
import { setSnackComplete } from '@actions/snackbar/types';

function BasicNav() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(loading(true));
    const obj = { token: getToken(), usuario: getUser() };
    sendLoguot(obj)
      .then((result) => result.json())
      .then((data) => {
        if (data.code === 200) {
          removeUserSession();
          limpiarReduxSesion();
          history.push('/login');
        }
        dispatch(loading(false));
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
        dispatch(loading(false));
      });
  };

  return (
    <>
      <CssBaseline />
      <div className="text-white bg-white">
        <AppBar color="inherit">
          <Toolbar>
            <div className="text-gray-900 w-100 flex-grow">
              <LogoIcon height="55" />
            </div>
            <Button
              color="primary"
              variant="contained"
              startIcon={<ExitToAppIcon />}
              disableElevation
              onClick={handleLogout}
            >
              {t('cerrar_sesion')}
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
}

export default BasicNav;

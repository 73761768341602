import { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { GLOBALS } from '@constants/prueba/globals';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { useTable, usePagination, Row } from 'react-table';
import {
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table as MaUTable,
  Grid,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { RootState } from 'src/store';
import {
  getTableroPacienteEvaluacionMedidas,
  getTableroPacienteEvaluacionMedidasUsuario,
  getColesterolPaciente,
  getColesterolUsuarioPaciente,
  getDiagnosticosTableroPaciente,
  getDiagnosticosTableroUsuarioPaciente,
  getMedicinaPreventivaTableroPaciente,
  getMedicinaPreventivaTableroUsuarioPaciente,
  getAntecedentesLaborales,
  getTratamientosTableroPaciente,
  getTratamientosTableroUsuarioPaciente,
  getAntecedenteLaboralEmpresa,
  getAntecedenteLaboralEmpresaUsuarioPaciente,
  getDataTablaGloborisk,
  getTratamientoTableroPaciente,
  getTratamientoTableroUsuarioPaciente,
  getDataTablaGloboriskUsuario,
  getAntecedentesLaboralesUsuarioPaciente,
} from '@utils/getCatalogos';
import Pagination from '@components/PaginationButtons';
import { setRequest } from '@actions/request/types';
import { fechaISOADiaMesAnioStr } from '@common/functions';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import EvaluacionMedidas from '../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/EvaluacionMedidas';
import VerDetalleMedicamento from '../TabsPaciente/DiagnosticoPlan/Plan/TerapiaFarmacologica/ListaMedicamentos/VerDetalleMedicamento';
import DeteccionTempranaCancer from '../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/DeteccionTempranaCancer';
import AneurismaAortaAbdominal from '../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/PrevencionCardiovascular/AneurismaAortaAbdominal';
import PrevencionCardiovascularDiv from '../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/PrevencionCardiovascular/PrevencionCardiovascular';
import DeteccionCancerCervicalDiv from '../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/DeteccionTempranaCancer/DeteccionCancerCervical';
import ListaAntecedentesLaborales from '../TabsPaciente/Antecedentes/Laborales/FormAntecedentesLaborales/ListaAntecedentesLaborales';
import {
  IObjEvaluacionMedidas,
  IObjPresionArterial,
  IObjExamenColesterol,
  IObjDiagnosticosPaciente,
  IObjMedicinaPreventiva,
  IObjAntecedentesLaborales,
  IObjTratamientos,
} from './types';
import useStyles from './styles';
import PrevencionAuditiva from '../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/PrevencionRiesgos/PrevencionAuditiva';
import ExamenMedicoPeriodico from '../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/ExamenMedicoPeriodico';
import {
  objEvaluacionMedidasInitial,
  objExamenColesterolInitial,
  objPresionArterialInitial,
} from './mockDatosInitial';
import { IAntecedenteLaboral } from '../TabsPaciente/Antecedentes/Laborales/types';
import {
  IMedicamento,
  medicamentoInitial,
} from '../TabsPaciente/DiagnosticoPlan/Plan/TerapiaFarmacologica/types';
import { BackButton } from '@components/BackButton';
import { setRutaPrincipal } from '@actions/rutasExpediente/actions';

dayjs.locale('es');

const TableroPaciente = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { idMedico, esPaciente, validar } = useSelector((state: RootState) => state.Me);
  const { idPaciente, idUsuario, docId } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [fuenteMedidas, setFuenteMedidas] = useState<'expediente' | 'usuario'>('expediente');
  const [medidasExpediente, setMedidasExpediente] = useState<boolean>(false);
  const [medidasUsuario, setMedidasUsuario] = useState<boolean>(false);
  const [fechaMedidas, setFechaMedidas] = useState<string>('');
  const [fechaMedidasExpediente, setFechaMedidasExpediente] = useState<string>('');
  const [fechaMedidasUsuario, setFechaMedidasUsuario] = useState<string>('');
  const [evaluacionMedidas, setEvaluacionMedidas] = useState<IObjEvaluacionMedidas>(
    objEvaluacionMedidasInitial,
  );
  const [evaluacionMedidasExpediente, setEvaluacionMedidasExpediente] =
    useState<IObjEvaluacionMedidas>(objEvaluacionMedidasInitial);
  const [evaluacionMedidasUsuario, setEvaluacionMedidasUsuario] = useState<IObjEvaluacionMedidas>(
    objEvaluacionMedidasInitial,
  );
  const [presionArterial, setPresionArterial] =
    useState<IObjPresionArterial>(objPresionArterialInitial);
  const [presionArterialExpediente, setPresionArterialExpediente] =
    useState<IObjPresionArterial>(objPresionArterialInitial);
  const [presionArterialUsuario, setPresionArterialUsuario] =
    useState<IObjPresionArterial>(objPresionArterialInitial);
  const [examenColesterol, setExamenColesterol] = useState<IObjExamenColesterol>(
    objExamenColesterolInitial,
  );
  const [arrayDiagnosticosPaciente, setArrayDiagnosticosPaciente] = useState<
  IObjDiagnosticosPaciente[]
  >([]);
  const [arrayMedicinaPreventiva, setArrayMedicinaPreventiva] = useState<IObjMedicinaPreventiva[]>(
    [],
  );
  const [arrayAntecedentesLaborales, setArrayAntecedentesLaborales] = useState<
  IObjAntecedentesLaborales[]
  >([]);
  const [arrayTratamientos, setArrayTratamientos] = useState<IObjTratamientos[]>([]);
  const [viewTabCardiovascular, setViewTabCardiovascular] = useState<boolean>(false);
  const [openDetalle, setOpenDetalle] = useState<boolean>(false);
  const [dataToSetMedicina, setDataToSetMedicina] = useState<IObjMedicinaPreventiva | undefined>(
    undefined,
  );
  const [numberDataToSetMedicina, setNumberDataToSetMedicina] = useState<number>(-1);
  const [tableGloborisk, setTableGloborisk] = useState<any[]>([]);
  const [valAntecedenteLaboral, setValAntecedenteLaboral] = useState<
  IAntecedenteLaboral | undefined
  >(undefined);
  const [medicamentoAVerDetalle, setMedicamentoAVerDetalle] =
    useState<IMedicamento>(medicamentoInitial);
  const [index, setIndex] = useState<number>(-1);
  const [tipoDatos, setTipoDatos] = useState<number>(0);
  const [pagina, setPagina] = useState<Row[]>([]);
  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);
  useEffect(() => {
    if (index > -1 && tipoDatos > 0 && pagina.length > 0) {
      if (tipoDatos === 2) {
        setDataToSetMedicina(arrayMedicinaPreventiva[pagina[index].index]);
        setNumberDataToSetMedicina(tipoDatos);
        setViewTabCardiovascular(true);
      } else if (tipoDatos === 3) {
        const getAntLabFunc = esPaciente
          ? getAntecedenteLaboralEmpresaUsuarioPaciente(
            idPaciente,
            arrayAntecedentesLaborales[pagina[index].index].idEmpresa,
          )
          : getAntecedenteLaboralEmpresa(
            idMedico,
            idPaciente,
            idConsultorio,
            arrayAntecedentesLaborales[pagina[index].index].idEmpresa,
          );
        setNumberDataToSetMedicina(tipoDatos);
        dispatch(
          setRequest({
            type: 'get',
            requestFunction: getAntLabFunc,
            successFunction: (result: any) => {
              setValAntecedenteLaboral(result.datos);
              setViewTabCardiovascular(true);
            },
            catchFunction: () => setValAntecedenteLaboral(undefined),
            showMsgs: false,
          }),
        );
      } else if (tipoDatos === 4) {
        const getTratamientoFunc = esPaciente
          ? getTratamientoTableroUsuarioPaciente(
            idPaciente,
            arrayTratamientos[pagina[index].index].id,
          )
          : getTratamientoTableroPaciente(
            idMedico,
            idPaciente,
            idConsultorio,
            arrayTratamientos[pagina[index].index].id,
          );
        setNumberDataToSetMedicina(tipoDatos);
        dispatch(
          setRequest({
            type: 'get',
            requestFunction: getTratamientoFunc,
            successFunction: (result: any) => {
              setMedicamentoAVerDetalle(result.datos);
              setOpenDetalle(true);
            },
            catchFunction: () => setMedicamentoAVerDetalle(medicamentoInitial),
            showMsgs: false,
          }),
        );
      }
      setTipoDatos(0);
      setPagina([]);
      setIndex(-1);
    }
  }, [index, tipoDatos, pagina]);

  const Table = (val: any) => {
    // Use the state and functions returned from useTable to build your UI
    const {
      columns, data, pageCount: controlledPageCount, tipo,
    } = val;
    const {
      getTableProps,
      headerGroups,
      page,
      prepareRow,
      canPreviousPage,
      canNextPage,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      state: { pageIndex },
    } = useTable(
      {
        columns,
        data,
        pageCount: controlledPageCount,
        initialState: { pageIndex: 0, pageSize: 5 },
      },
      usePagination,
    );
    // Render the UI for your table
    return (
      <>
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()} className={classes.header}>
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, inx) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const rowIndex = parseInt(cell.row.id, 10) || 0;
                    if (cell.column && cell.column.id === 'detalle') {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                        >
                          <Button
                            onClick={() => {
                              setTipoDatos(tipo);
                              setPagina(page);
                              setIndex(inx);
                            }}
                            color="primary"
                          >
                            <span>{t('ver_mas')}</span>
                          </Button>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      </>
    );
  };
  const columnasDiagnostico = esPaciente
    ? [
      {
        Header: t('diagnostico'),
        accessor: 'diagnostico',
      },
    ]
    : [
      {
        Header: t('diagnostico'),
        accessor: 'diagnostico',
      },
      {
        Header: t('estado'),
        accessor: 'estado',
      },
    ];
  const columnasMedicinaPreventiva = [
    {
      Header: t('tipo'),
      accessor: 'tipo',
    },
    {
      Header: t('oportunidad_riesgo'),
      accessor: 'oportunidad',
    },
    {
      Header: t('detalle'),
      accessor: 'detalle',
    },
  ];
  const columnasAntecedentesLaborales = [
    {
      Header: t('riesgo'),
      accessor: 'riesgo',
    },
    {
      Header: t('antecedentes_laborales_empresa'),
      accessor: 'empresa',
    },
    {
      Header: t('detalle'),
      accessor: 'detalle',
    },
  ];
  const columnasTratamientos = [
    {
      Header: t('medicamento'),
      accessor: 'medicamento',
    },
    {
      Header: t('detalle'),
      accessor: 'detalle',
    },
  ];
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  useEffect(() => {
    // Se evalúa si docId tiene valor para validar si ya se cargaron los datos del paciente
    // No se valida solo el idPaciente porque ese se asigna manualmente, pero el idUsuario viene de la consulta de los datos
    // Luego se valida si no es paciente o si es un paciente ya validado
    if (docId && (!esPaciente || !validar)) {
      dispatch(
        setRequest({
          type: 'get',
          multiple: true,
          requestFunctions: esPaciente
            ? [
              getTableroPacienteEvaluacionMedidasUsuario(idMedico, idUsuario), // 1. Medidas
              getDiagnosticosTableroUsuarioPaciente(idMedico, idUsuario), // 2. Diagnosticos
              getMedicinaPreventivaTableroUsuarioPaciente(idMedico, idUsuario), // 3. Medicina preventiva
              getColesterolUsuarioPaciente(idMedico), // 4. Colesterol
              getTratamientosTableroUsuarioPaciente(idMedico), // 5. Tratamientos
              getAntecedentesLaboralesUsuarioPaciente(idMedico), // 6. Antecedentes laborales
            ]
            : [
              getTableroPacienteEvaluacionMedidas(idMedico, idPaciente, idUsuario, idConsultorio), // 1. Medidas
              getDiagnosticosTableroPaciente(idMedico, idPaciente, idConsultorio), // 2. Diagnosticos
              getMedicinaPreventivaTableroPaciente(
                idMedico,
                idPaciente,
                idUsuario,
                idConsultorio,
              ), // 3. Medicina preventiva
              getColesterolPaciente(idMedico, idPaciente, idConsultorio), // 4. Colesterol
              getTratamientosTableroPaciente(idMedico, idPaciente, idConsultorio), // 5. Tratamientos
              getAntecedentesLaborales(idMedico, idPaciente, idConsultorio), // 6. Antecedentes laborales
            ],
          successFunctions: [
            (result: any) => {
              // 1. Medidas
              if (result.datos.expediente) {
                setMedidasExpediente(true);
                const evalMedidas = {
                  talla: result.datos.expediente.talla || undefined,
                  perCintura: result.datos.expediente.perCintura || undefined,
                  peso: result.datos.expediente.peso || undefined,
                  imc: result.datos.expediente.imc || undefined,
                };
                const presArterial = {
                  pSistolica: result.datos.expediente.pSistolica || undefined,
                  pDiastolica: result.datos.expediente.pDiastolica || undefined,
                };
                setFechaMedidas(result.datos.expediente.fecha);
                setEvaluacionMedidas(evalMedidas);
                setPresionArterial(presArterial);
                setFechaMedidasExpediente(result.datos.expediente.fecha);
                setEvaluacionMedidasExpediente(evalMedidas);
                setPresionArterialExpediente(presArterial);
              }
              if (result.datos.usuario) {
                setMedidasUsuario(true);
                const evalMedidas = {
                  talla: result.datos.usuario.talla || undefined,
                  perCintura: result.datos.usuario.perCintura || undefined,
                  peso: result.datos.usuario.peso || undefined,
                  imc: result.datos.usuario.imc || undefined,
                };
                const presArterial = {
                  pSistolica: result.datos.usuario.pSistolica || undefined,
                  pDiastolica: result.datos.usuario.pDiastolica || undefined,
                };
                if (!result.datos.expediente) {
                  setFuenteMedidas('usuario');
                  setFechaMedidas(result.datos.usuario.fecha);
                  setEvaluacionMedidas(evalMedidas);
                  setPresionArterial(presArterial);
                }
                setFechaMedidasUsuario(result.datos.usuario.fecha);
                setEvaluacionMedidasUsuario(evalMedidas);
                setPresionArterialUsuario(presArterial);
              }
            },
            (result: any) =>
              setArrayDiagnosticosPaciente(
                esPaciente // 2. Diagnosticos
                  ? result.datos.map((diagnostico: string) => ({
                    diagnostico: t(diagnostico),
                    estado: '',
                  }))
                  : result.datos.map((diagnostico: IObjDiagnosticosPaciente) => ({
                    diagnostico: diagnostico.diagnostico,
                    estado: t(diagnostico.estado),
                  })),
              ),
            (result: any) => setArrayMedicinaPreventiva(result.datos), // 3. Medicina preventiva
            (result: any) => {
              // 4. Colesterol
              setMedidasExpediente(true);
              setExamenColesterol({
                ...examenColesterol,
                anio: result.datos.anio || '',
                dia: result.datos.dia || '',
                mes: result.datos.mes || '',
                valor: result.datos.valor || '',
              });
            },
            (result: any) => setArrayTratamientos(result.datos), // 5. Tratamientos
            (result: any) => setArrayAntecedentesLaborales(result.datos), // 6. Antecedentes laborales
          ],
          showMsgError: false,
        }),
      );
    }
  }, [docId]);
  useEffect(() => {
    setFechaMedidas(fuenteMedidas === 'expediente' ? fechaMedidasExpediente : fechaMedidasUsuario);
    setEvaluacionMedidas(
      fuenteMedidas === 'expediente' ? evaluacionMedidasExpediente : evaluacionMedidasUsuario,
    );
    setPresionArterial(
      fuenteMedidas === 'expediente' ? presionArterialExpediente : presionArterialUsuario,
    );
  }, [fuenteMedidas]);
  useEffect(() => {
    if (
      numberDataToSetMedicina === 2 &&
      dataToSetMedicina &&
      [
        'riesgo_cardiovascular',
        'riesgo_cardiovascular_s_colesterol',
        'riesgo_cardiovascular_usuario',
      ].includes(dataToSetMedicina.tipo)
    ) {
      dispatch(
        setRequest({
          type: 'get',
          requestFunction: esPaciente
            ? getDataTablaGloboriskUsuario(
              dataToSetMedicina?.sexo || 0,
              dataToSetMedicina?.consumoTabaco || 0,
              dataToSetMedicina?.rangoEdad || 0,
              idPaciente,
            )
            : getDataTablaGloborisk(
              dataToSetMedicina?.sexo || 0,
              dataToSetMedicina?.esDiabetico || 0,
              dataToSetMedicina?.consumoTabaco || 0,
              dataToSetMedicina?.rangoEdad || 0,
              !['riesgo_cardiovascular_s_colesterol', 'riesgo_cardiovascular_usuario'].includes(
                dataToSetMedicina.tipo,
              ),
              idMedico,
              idPaciente,
              idConsultorio,
            ),
          successFunction: (result: any) => {
            if (result.datos && result.datos.length > 0) {
              setTableGloborisk(result.datos);
            }
          },
          catchFunction: () => setTableGloborisk([]),
          showMsgs: false,
        }),
      );
    }
  }, [dataToSetMedicina, numberDataToSetMedicina]);
  const handleClickMedicionesArrows = () =>
    setFuenteMedidas(fuenteMedidas === 'expediente' ? 'usuario' : 'expediente');
  return (
    <div className="p-4 bg-white w-full">
      {!isWideScreen &&
        <div className="px-3 mt-4">
          <BackButton onClick={() => dispatch(setRutaPrincipal('main'))} />
        </div>
      }
      <VerDetalleMedicamento
        open={openDetalle}
        handleDetalleClose={() => setOpenDetalle(false)}
        medicamentoAVerDetalle={medicamentoAVerDetalle}
      />
      {viewTabCardiovascular ? (
        <>
          <div className="py-4">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              onClick={() => {
                setNumberDataToSetMedicina(-1);
                setViewTabCardiovascular(false);
                setDataToSetMedicina(undefined);
              }}
              startIcon={<ArrowBackIcon />}
            >
              {t('regresar')}
            </Button>
          </div>
          {numberDataToSetMedicina === 2 && dataToSetMedicina?.tipo === 'riesgo_cardiovascular' && (
            <PrevencionCardiovascularDiv
              presion={dataToSetMedicina.rangoPa || 0}
              colesterol={dataToSetMedicina.rangoColesterol || 0}
              valor={dataToSetMedicina.valor?.toString() || ''}
              sexo={dataToSetMedicina.sexo ? 'M' : 'H'}
              toxi={dataToSetMedicina.consumoTabaco ? 'activo' : 'falso'}
              esDiabetico={dataToSetMedicina.esDiabetico || 0}
              colesterolResultado={dataToSetMedicina.valorColesterol || 0}
              fechaColesterol={dataToSetMedicina.fecha || ''}
              tableGloborisk={tableGloborisk}
              edad={dataToSetMedicina.edad || 0}
            />
          )}
          {numberDataToSetMedicina === 2 &&
            dataToSetMedicina &&
            ['riesgo_cardiovascular_s_colesterol', 'riesgo_cardiovascular_usuario'].includes(
              dataToSetMedicina.tipo,
            ) && (
              <PrevencionCardiovascularDiv
                conColesterol={false}
                presion={dataToSetMedicina.rangoPa || 0}
                imc={dataToSetMedicina.rangoImc || 0}
                valor={dataToSetMedicina.valor?.toString() || ''}
                sexo={dataToSetMedicina.sexo ? 'M' : 'H'}
                toxi={dataToSetMedicina.consumoTabaco ? 'activo' : 'falso'}
                imcResultado={dataToSetMedicina.imc || 0}
                tableGloborisk={tableGloborisk}
                edad={dataToSetMedicina.edad || 0}
              />
          )}
          {numberDataToSetMedicina === 2 && dataToSetMedicina?.tipo === 'cancer_pulmon' && (
            <DeteccionTempranaCancer selIdPaciente={idPaciente || 0} />
          )}
          {numberDataToSetMedicina === 2 && dataToSetMedicina?.tipo === 'cancer_cervical' && (
            <DeteccionCancerCervicalDiv
              edad={dataToSetMedicina.edad}
              sendFecha={dataToSetMedicina.fecha}
              sendShowCervicoUterino
            />
          )}
          {numberDataToSetMedicina === 2 && dataToSetMedicina?.tipo === 'aaa' && (
            <AneurismaAortaAbdominal txtToxicomania={dataToSetMedicina.toxicomania || ''} />
          )}
          {numberDataToSetMedicina === 2 && dataToSetMedicina?.tipo === 'audiometria' && (
            <PrevencionAuditiva
              nivel={dataToSetMedicina.nivel || 0}
              fechaEstudio={dataToSetMedicina.fechaEstudio || ''}
            />
          )}
          {numberDataToSetMedicina === 2 && dataToSetMedicina?.tipo === 'emp' && (
            <ExamenMedicoPeriodico fechaEstudio={dataToSetMedicina.fechaEstudio || ''} />
          )}
          {numberDataToSetMedicina === 3 && valAntecedenteLaboral && (
            <ListaAntecedentesLaborales
              objTabEmpresa={valAntecedenteLaboral.objTabEmpresa}
              objExposicionRiesgo={valAntecedenteLaboral.objExposicionRiesgo}
              objLaboral={valAntecedenteLaboral.objLaboral}
              idEmpresa={valAntecedenteLaboral.objTabEmpresa.id}
              inx={-1}
              actualizaListaValores={() => {}}
              eliminaListaValores={() => {}}
            />
          )}
        </>
      ) : (
        <>
          <h1 className="text-blue-800 font-normal m-0 text-center pb-4">
            {t('tablero_paciente')}
          </h1>
          <Grid container spacing={4} className="pb-4">
            <Grid item xs={12} md={6} className="pb-4">
              <h2 className="text-blue-800 font-normal m-0 text-center pb-2">
                {t('business_diagnosticos')}
              </h2>
              {arrayDiagnosticosPaciente.length > 0 ? (
                <Table
                  columns={columnasDiagnostico}
                  data={arrayDiagnosticosPaciente}
                  pageCount={arrayDiagnosticosPaciente.length / 5}
                  tipo={1}
                />
              ) : (
                <p>{t('message_without_data')}</p>
              )}
            </Grid>
            <Grid item xs={12} md={6} className="pb-4">
              <h2 className="text-blue-800 font-normal m-0 text-center pb-2">
                {t('medicina_preventiva')}
              </h2>
              {arrayMedicinaPreventiva.length > 0 ? (
                <Table
                  columns={columnasMedicinaPreventiva}
                  data={arrayMedicinaPreventiva.map((obj: IObjMedicinaPreventiva) => ({
                    ...obj,
                    tipo: t(obj.tipo),
                    oportunidad: t(obj.oportunidad),
                  }))}
                  pageCount={arrayMedicinaPreventiva.length / 5}
                  tipo={2}
                />
              ) : (
                <p>{t('message_without_data')}</p>
              )}
            </Grid>
            <Grid item xs={12} md={6} className="pb-4">
              <h2 className="text-blue-800 font-normal m-0 text-center pb-2">
                {t('antecedentes_laborales_ant')}
              </h2>
              {arrayAntecedentesLaborales.length > 0 ? (
                <Table
                  columns={columnasAntecedentesLaborales}
                  data={arrayAntecedentesLaborales}
                  pageCount={arrayAntecedentesLaborales.length / 5}
                  tipo={3}
                />
              ) : (
                <p>{t('message_without_data')}</p>
              )}
            </Grid>
            <Grid item xs={12} md={6} className="pb-4">
              <h2 className="text-blue-800 font-normal m-0 text-center pb-2">
                {t('tratamientos')}
              </h2>
              {arrayTratamientos.length > 0 ? (
                <Table
                  columns={columnasTratamientos}
                  data={arrayTratamientos}
                  pageCount={arrayTratamientos.length / 5}
                  tipo={4}
                />
              ) : (
                <p>{t('message_without_data')}</p>
              )}
            </Grid>
          </Grid>
          {!esPaciente && (medidasExpediente || medidasUsuario) && (
            <>
              <div className="bg-white border-solid border-0 border-b border-gray-600">
                <h2 className="text-blue-800 font-normal m-0 text-center">
                  {t('ultimas_mediciones')}
                </h2>
                <div className="flex justify-between">
                  <h4 className="mt-0 text-gray-600">
                    {t(
                      fuenteMedidas === 'expediente'
                        ? 'mediciones_expediente'
                        : 'mediciones_usuario_paciente',
                    )}
                  </h4>
                  {((fuenteMedidas === 'expediente' && fechaMedidas) ||
                    (fuenteMedidas === 'usuario' && fechaMedidasUsuario)) && (
                    <span className="text-gray-600">
                      {`${t('fecha')}: ${fechaISOADiaMesAnioStr(
                        fuenteMedidas === 'expediente' ? fechaMedidas : fechaMedidasUsuario,
                        { sep: '-', dia: 'DD', mes: 'MMM' },
                      )}`}
                    </span>
                  )}
                </div>
              </div>
              <div className="py-2" />
              <div className="w-full flex flex-row">
                {medidasExpediente && medidasUsuario && (
                  <Button onClick={handleClickMedicionesArrows}>
                    <ArrowBackIosIcon />
                  </Button>
                )}
                {evaluacionMedidas.talla &&
                  evaluacionMedidas.perCintura &&
                  evaluacionMedidas.peso &&
                  evaluacionMedidas.imc && (
                    <div className="pr-2 w-3/4">
                      <EvaluacionMedidas
                        talla={evaluacionMedidas.talla}
                        perCintura={evaluacionMedidas.perCintura}
                        peso={evaluacionMedidas.peso}
                        imc={evaluacionMedidas.imc}
                      />
                    </div>
                )}
                <div className="pl-2 w-1/4">
                  {fuenteMedidas === 'expediente' &&
                    examenColesterol.anio &&
                    examenColesterol.mes &&
                    examenColesterol.dia &&
                    examenColesterol.valor && (
                      <h3 className="font-normal m-0 text-left pb-4">
                        {`${t('p_e_cardio_er_colesterol')}: ${
                          examenColesterol.valor
                        } mg/dL (${dayjs(
                          `${examenColesterol.anio}-${examenColesterol.mes}-${examenColesterol.dia}`,
                        ).format('DD-MMM-YYYY')})`}
                      </h3>
                  )}
                  {presionArterial.pDiastolica && presionArterial.pSistolica && (
                    <h3 className="font-normal m-0 text-left pb-4">
                      {`${t('pa')}: ${presionArterial.pSistolica}/${
                        presionArterial.pDiastolica
                      } mmHg`}
                    </h3>
                  )}
                </div>
                {medidasExpediente && medidasUsuario && (
                  <Button onClick={handleClickMedicionesArrows}>
                    <ArrowForwardIosIcon />
                  </Button>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TableroPaciente;

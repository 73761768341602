import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { ITeProps } from './types';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import ScaleInput from '@components/FormElemntsLibrary/ScaleInput';

function Te(props: ITeProps) {
  const {
    hayCambios, toxicomania, setToxicomania,
  } = props;
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const estatus = ['si', 'no'].map(es => ({ value: es, label: t(es) }));

  const handleInputChange = (e: { name: string; value: any }) => {
    setToxicomania({
      ...toxicomania,
      te: {
        ...toxicomania.te,
        [e.name]: e.value,
      }
    });
  };

  useEffect(() => {
    if (toxicomania.te?.consumoTe === 'no') {
      setToxicomania({
        ...toxicomania,
        te: { consumoTe: 'no', tazaDeTe: '' },
      });  
    }
  }, [toxicomania.te?.consumoTe]);

  return (
    <>
      <CheckboxGroup
        name="consumoTe"
        label={t('te')}
        options={estatus}
        value={toxicomania.te?.consumoTe}
        setValue={handleInputChange}
        disabled={hayCambios}
        distribution="vertical-flex"
      />
      {toxicomania.te?.consumoTe === 'si' &&
        <div className="md:w-1/2">
          <ScaleInput
            name="tazaDeTe"
            label={t('taza_de_te')}
            options={[...Array(5)].map((x, i) => ({
              value: String(i + 1),
              label: `${i + 1}${i < 4 ? '' : '+'}`,
            }))}
            value={toxicomania.te?.tazaDeTe || undefined}
            setValue={handleInputChange}
            disabled={hayCambios}
          />
        </div>
      }
    </>
  );
}

export default Te;

import { AddButton } from '@components/AddButton';
import { NumberInput } from '@components/FormElemntsLibrary/NumberInput';
import { SelectInput } from '@components/FormElemntsLibrary/SelectInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { isOxxo } from '@constants/oxxo';
import { Trash2 } from 'lucide-react';
import { FC } from 'react';

import { AlcoholFormProps } from './types';

const frequencyOptions = [
  { id: 'diario', label: 'Diario' },
  { id: '1_vez_por_semanas', label: '1 vez por semana' },
  { id: '2_veces_por_semana', label: '2 veces por semana' },
  { id: '3_veces_por_semana', label: '3 veces por semana' },
  { id: '4_veces_por_semana', label: '4 veces por semana' },
  { id: '5_veces_por_semana', label: '5 veces por semana' },
  { id: '6_veces_por_semana', label: '6 veces por semana' },
  { id: 'mensual_o_menos', label: 'Mensual o menos' },
];

const alcoholTypeOptions = [
  { id: 'cerveza_al_5_lata', label: 'Cerveza (lata)', gramos: 13 },
  { id: 'cerveza_al_5_caguama', label: 'Cerveza (caguama)', gramos: 36.92 },
  { id: 'pulque_al_6_3_jarrito', label: 'Pulque (jarrito)', gramos: 13 },
  { id: 'ron_al_38_vaso', label: 'Ron (vaso)', gramos: 13 },
  { id: 'ron_al_40_botela_750', label: 'Ron (botella 750ml)', gramos: 235.5 },
  { id: 'ron_al_40_botella_980', label: 'Ron (botella 980ml)', gramos: 307.7 },
  { id: 'vino_al_12_copa', label: 'Vino (copa)', gramos: 13 },
  { id: 'vino_al_12_botella', label: 'Vino (botella)', gramos: 70.6 },
  { id: 'coctel_destilado_al_45', label: 'Coctel destilado', gramos: 13 },
  { id: 'tequila_caballito', label: 'Tequila (caballito)', gramos: 13 },
  { id: 'tequila_al_38_botella', label: 'Tequila (botella)', gramos: 283.4 },
  { id: 'whisky_al_40_vaso', label: 'Whisky (vaso)', gramos: 13 },
  { id: 'whisky_al_40_botella_750', label: 'Whisky (botella 750ml)', gramos: 235.5 },
  { id: 'vodka_al_40_vaso', label: 'Vodka (vaso)', gramos: 13 },
  { id: 'vodka_al_40_botella_450', label: 'Vodka (botella 450ml)', gramos: 141.31 },
  { id: 'vodka_al_40_botella_750', label: 'Vodka (botella 750ml)', gramos: 235.56 },
  { id: 'otro_(trago_estandar)', label: 'Otro (trago estándar)', gramos: 13 },
];

export const AlcoholForm: FC<AlcoholFormProps> = ({
  isActive,
  formData,
  onInputChange,
  onAlcoholTypesChange,
}) => {
  const handleAddAlcoholType = () => {
    const newIndex = formData.alcohol.arrTipoAlcohol.tipoAlcohol.length;
    onAlcoholTypesChange(
      newIndex,
      {
        label: alcoholTypeOptions[0].id,
        grUnidad: alcoholTypeOptions[0].gramos,
      },
      '',
    );
  };

  const handleDeleteAlcoholType = (index: number) => {
    if (index === 0) return;
    onAlcoholTypesChange(index, { label: '', grUnidad: 0 }, '');
  };

  const handleTipoAlcoholChange = (index: number, selectedValue: string) => {
    const selectedOption = alcoholTypeOptions.find((opt) => opt.id === selectedValue);
    if (!selectedOption) return;

    onAlcoholTypesChange(
      index,
      {
        label: selectedOption.id,
        grUnidad: selectedOption.gramos,
      },
      formData.alcohol.arrTipoAlcohol.cantidadAlcohol[index] || '',
    );
  };

  const handleCantidadChange = (index: number, value: string) => {
    onAlcoholTypesChange(index, formData.alcohol.arrTipoAlcohol.tipoAlcohol[index], value);
  };

  return (
    <div className="mt-4 space-y-4">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <NumberInput
          name="alcohol.edadInicio"
          label="Edad de inicio"
          fixedValue="Año(s)"
          value={Number(formData.alcohol.edadInicio) || 0}
          setValue={onInputChange}
        />

        <SelectInput
          name="alcohol.frecuencia"
          label="Frecuencia"
          placeholder="Selecciona"
          options={frequencyOptions}
          value={formData.alcohol.frecuencia}
          setValue={(e) => onInputChange({ name: 'alcohol.frecuencia', value: e.value })}
        />

        {!isActive && (
          <NumberInput
            name="alcohol.edadTermino"
            label="Edad de término"
            fixedValue="Año(s)"
            value={Number(formData.alcohol.edadTermino) || 0}
            setValue={onInputChange}
          />
        )}
      </div>

      {!isOxxo && (
        <>
          <div className="space-y-4">
            {formData.alcohol.arrTipoAlcohol.arrTabla.map((_, index) => (
              <div key={index} className="py-4 bg-gray-50 rounded-lg">
                <div className="grid grid-cols-2">
                  <SelectInput
                    name={`alcohol.arrTipoAlcohol.tipoAlcohol.${index}`}
                    label="Tipo de alcohol"
                    placeholder="Selecciona"
                    options={alcoholTypeOptions}
                    value={formData.alcohol.arrTipoAlcohol.tipoAlcohol[index].label}
                    setValue={(e) => handleTipoAlcoholChange(index, e.value)}
                  />

                  <div className="relative">
                    <NumberInput
                      name={`alcohol.arrTipoAlcohol.cantidadAlcohol.${index}`}
                      label="Cantidad"
                      value={Number(formData.alcohol.arrTipoAlcohol.cantidadAlcohol[index]) || 0}
                      setValue={(e) => handleCantidadChange(index, e.value.toString())}
                    />
                    {index > 0 && (
                      <button
                        onClick={() => handleDeleteAlcoholType(index)}
                        className="absolute right-2 top-1 text-gray-400 hover:text-red-500"
                        title="Eliminar"
                      >
                        <Trash2 size={18} />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* TODO: MEJORAR EL ADDBUTTOTN PARA PODER HACER QUE DINAMICAMENTE CON LA PROP DE DISABLED SE PUEDA HACER LA DESACTIVACION POR CONDICIONES */}
          <AddButton
            variant="enabled"
            label="Agregar otro tipo de alcohol"
            onClick={handleAddAlcoholType}
            // disabled={formData.alcohol.arrTipoAlcohol.arrTabla.length >= 10}
          />
        </>
      )}

      <TextArea
        name="alcohol.comentarios"
        label="Comentarios"
        value={formData.alcohol.comentarios}
        setValue={onInputChange}
      />
    </div>
  );
};

export default AlcoholForm;

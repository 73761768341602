import { ICatalogosEstandar } from '@common/types';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';
import { getEjercicios } from '@utils/getCatalogos';
import { Trash } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { ActividadProps } from './type';

export const Actividad: FC<ActividadProps> = ({
  index,
  activity,
  onDelete,
  onChange,
  showDelete = true,
  errors,
}) => {
  const [catEjercicios, setCatEjercicios] = useState<ICatalogosEstandar[]>([]);

  useEffect(() => {
    const fetchEjercicios = async () => {
      const result: ICatalogosEstandar[] = await getEjercicios();
      setCatEjercicios(result);
    };

    fetchEjercicios();
  }, []);

  const handleInputChange = (value: { name: string; value: any; numberPosition?: number }) => {
    onChange({
      ...value,
      position: index,
    });
  };

  const handleInputNumberChange = (value: { name: string; value: any; position?: number }) => {
    onChange({
      ...value,
      position: index,
    });
  };

  return (
    <div className="relative flex flex-col w-full h-auto bg-[#F3F4F6] pt-4 px-4 mb-4">
      {showDelete && (
        <button
          type="button"
          className="absolute right-8 top-4 ml-auto cursor-pointer text-gray-500 hover:text-red-500 mb-2"
          onClick={() => onDelete(index)}
        >
          <Trash size={18} />
        </button>
      )}

      <SelectInput
        label="Tipo de actividad"
        name="idTipo"
        placeholder="Selecciona"
        variant="search"
        options={catEjercicios}
        setValue={handleInputChange}
        value={String(activity.idTipo.id)}
        errorMessage={errors.idTipo?.message}
      />

      <NumberInput
        label="Frecuencia"
        name="frecuencia"
        fixedValue="veces por semana"
        value={Number(activity.frecuencia)}
        setValue={handleInputNumberChange}
        errorMessage={errors.frecuencia?.message}
      />

      <NumberInput
        label="Duración/sesión"
        name="duracion"
        fixedValue="min."
        value={Number(activity.duracion)}
        setValue={handleInputNumberChange}
        errorMessage={errors.duracion?.message}
      />
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { IAlimentosProps } from './types';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

function AlimentacionComponente({
  hayCambios,
  index,
  eliminar,
  handleChange,
  alimento,
  especifica,
}: IAlimentosProps) {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const handleAlimentoChange = (e: {name: string, value: any}) => handleChange({ ...e, index })
  return (
    <div key={`div-${index}`} className="border-solid border border-gray-400 rounded p-4 mb-3 flex">
      <div className="grid gap-4 grid-cols-1 md:grid-cols-3 w-full">
        <TextInput
          key={`restringido-${index}`}
          name="alimento"
          label=""
          placeholder={t('antecedentes-pnp-alimentacion-restringidos')}
          value={alimento}
          disabled={hayCambios}
          setValue={handleAlimentoChange}
          maxLength={50}
        />
        <TextInput
          containerClass="md:col-span-2"
          key={`especifica-${index}`}
          name="especifica"
          label=""
          placeholder={t('antecedentes-pnp-alimentacion-especifica-ejemplo')}
          value={especifica}
          setValue={handleAlimentoChange}
          disabled={hayCambios}
          maxLength={100}
        />
      </div>
      <IconButton
        aria-label="delete" onClick={() => eliminar(index)}
        disabled={hayCambios}
        className="h-12 self-center"
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
}

export default AlimentacionComponente;

/* eslint-disable */

import { SubModalForm } from '@components/modals/SubModalForm';

export const AntecedentesLaborales = () => (
  <SubModalForm
    title="Detalle de Planta"
    id="AntecedentesLaborales"
    displayButton={false}
    saveModal={() => { }}
  >
    <div className="p-4">
      <h2 className="text-lg font-medium text-blue-800 mb-4">Planta Sur 4</h2>

      <div className="">
        <div>
          <p className="text-sm text-gray-600">Ocupación:</p>
          <p>Abarrotero</p>
        </div>

        <div>
          <p className="text-sm text-gray-600">Trabajador sindicalizado:</p>
          <p>Si</p>
        </div>

        <div className="">
          <div>
            <p className="text-sm text-gray-600">Fecha estimada de ingreso:</p>
            <p>jul-2018</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Fecha estimada de egreso:</p>
            <p>Trabajo actual</p>
          </div>
        </div>

        <div>
          <h3 className="font-medium mb-2">Exposición a riesgo</h3>
          <ul className="">
            <li>- Físico</li>
            <li>- Ruido</li>
            <li className="pl-4">
              Nivel de exposición al ruido: 81 dB
              <br />
              Tiempo de exposición diario: 8 horas
            </li>
            <li className="pl-4">
              Nivel de exposición al ruido: 120 dB
              <br />
              Tiempo de exposición diario: 12 horas
            </li>
            <li className="pl-4">
              Nivel de exposición al ruido: 100 dB
              <br />
              Tiempo de exposición diario: 10 horas
            </li>
          </ul>
        </div>
      </div>
    </div>
  </SubModalForm>
);

import { resetModal } from '@actions/modal/actions';
import { ICatalogosEstandar } from '@common/types';
import { AddButton } from '@components/AddButton';
import { NumberInput } from '@components/FormElemntsLibrary/NumberInput';
import { SelectInput } from '@components/FormElemntsLibrary/SelectInput';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import { SubModalForm } from '@components/modals/SubModalForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { getAnimales } from '@utils/getCatalogos';
import { Trash } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import { initialMascotasForm, MascotasForm, MascotasProps } from './type';

const petSchema = z.object({
  id: z.string(),
  idAnimal: z.object({
    id: z.number().min(1, 'Seleccione un tipo de mascota'),
    label: z.string(),
  }),
  cantidad: z.string().min(1, 'Ingrese la cantidad'),
  especifica: z.string(),
});

const schema = z.object({
  pets: z.array(petSchema).min(1, 'Debe agregar al menos una mascota'),
});

export const Mascotas: FC<MascotasProps> = ({ setFormDataSubModals, formDataSubModals }) => {
  const [formData, setFormData] = useState<MascotasForm>(
    formDataSubModals.mascotas ?? initialMascotasForm,
  );

  const [catAnimales, setCatAnimales] = useState<Array<ICatalogosEstandar>>([]);

  const dispatch = useDispatch();
  const {
    formState: { errors },
    reset,
  } = useForm<MascotasForm>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });

  const handleDeletePet = (idToDelete: string) => {
    if (formData.pets.length > 1 && idToDelete !== 'pet-1') {
      setFormData((prev) => ({
        ...prev,
        pets: prev.pets.filter((pet) => pet.id !== idToDelete),
      }));
    }
  };

  const handleAddPet = () => {
    setFormData((prev) => ({
      ...prev,
      pets: [
        ...prev.pets,
        {
          id: `pet-${prev.pets.length + 1}`,
          idAnimal: { id: 0, label: '' },
          cantidad: '',
          especifica: '',
        },
      ],
    }));
  };

  const handleInputChange = (
    { name, value }: { name: string; value: any; position?: number },
    petId: string,
  ): void => {
    const fieldMap: Record<string, string> = {
      tipoMascota: 'idAnimal',
      cantidad: 'cantidad',
      detalles: 'especifica',
    };

    const [fieldName] = name.split('_');
    const targetField = fieldMap[fieldName] || '';

    setFormData((prev) => ({
      ...prev,
      pets: prev.pets.map((pet) => {
        if (pet.id !== petId) {
          return pet;
        }

        return {
          ...pet,
          [targetField]: fieldName === 'cantidad' ? value.toString() : value,
        };
      }),
    }));
  };

  const handleSubmitForm = async () => {
    try {
      const validatedData = await schema.parseAsync(formData);

      if (validatedData) {
        setFormDataSubModals((prev) => ({
          ...prev,
          mascotas: validatedData,
        }));
        dispatch(resetModal('Mascotas'));
      }
    } catch (error) {
      console.error('Error en el envío del formulario:', error);
    }
  };

  useEffect(() => {
    getAnimales().then((result: ICatalogosEstandar[]) => {
      setCatAnimales(result);
    });
  }, []);
  useEffect(() => {
    reset(formData);
  }, [formData]);

  console.log(errors);

  return (
    <SubModalForm title="Mascotas" id="Mascotas" saveModal={handleSubmitForm}>
      <div className="w-full flex flex-col gap-4">
        {formData.pets.map((pet, index) => (
          <div key={pet.id} className="flex flex-col w-full bg-gray-50 p-4 rounded-lg">
            <div className="w-full flex justify-end mb-2">
              {pet.id !== 'pet-1' && (
                <button
                  type="button"
                  className="text-gray-500 hover:text-red-500 transition-colors"
                  onClick={() => handleDeletePet(pet.id)}
                >
                  <Trash size={18} />
                </button>
              )}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <SelectInput
                name={`tipoMascota_${pet.id}`}
                label="Tipo de mascota"
                value={pet.idAnimal.id.toString()}
                setValue={(e) => {
                  const selectedOption = catAnimales.find((opt) => opt.id.toString() === e.value);
                  handleInputChange(
                    {
                      name: e.name,
                      value: selectedOption
                        ? { id: selectedOption.id, label: selectedOption.label }
                        : { id: 0, label: '' },
                    },
                    pet.id,
                  );
                }}
                options={catAnimales.map((animal) => ({
                  id: animal.id.toString(),
                  label: animal.label,
                }))}
                placeholder="Seleccione un tipo"
                errorMessage={errors.pets?.[index]?.idAnimal?.id?.message}
              />

              {pet.idAnimal.id === 28 && (
                <TextInput
                  name={`detalles_${pet.id}`}
                  label="Especifique"
                  value={pet.especifica}
                  setValue={(e) => handleInputChange(e, pet.id)}
                  placeholder="Especifique el tipo de mascota"
                  errorMessage={errors.pets?.[index]?.especifica?.message}
                />
              )}

              <NumberInput
                name={`cantidad_${pet.id}`}
                label="Cantidad"
                value={Number(pet.cantidad) || 0}
                setValue={(e) => handleInputChange(e, pet.id)}
                min={0}
                errorMessage={errors.pets?.[index]?.cantidad?.message}
              />
            </div>
          </div>
        ))}

        <AddButton variant="enabled" label="Agregar mascota" onClick={handleAddPet} />
      </div>
    </SubModalForm>
  );
};

export default Mascotas;

import { ThunkDispatch } from 'redux-thunk';
import { subscripcionPaymentMedico } from '@utils/common';
import { getMeMedico } from '../me/actions';
import {
  ACREDITADO,
  AuthActionTypes,
  DESACREDITADO,
  IAuth,
  RESET_AUTH,
  SET_AUTH,
  SET_ID,
  SINSUSCRIPCION,
  SUSCRITO,
} from './types';

export function acreditar(): AuthActionTypes {
  return {
    type: ACREDITADO,
  };
}

export function desacreditar(): AuthActionTypes {
  return { type: DESACREDITADO };
}

export function suscribir(): AuthActionTypes {
  return { type: SUSCRITO };
}

export function desuscribir(): AuthActionTypes {
  return { type: SINSUSCRIPCION };
}

export function resetAuth(): AuthActionTypes {
  return { type: RESET_AUTH };
}

export function setAuth(auth: IAuth): AuthActionTypes {
  return { type: SET_AUTH, payload: auth };
}

export function setID(id: string): AuthActionTypes {
  return { type: SET_ID, payload: id };
}

export function checkAuth() {
  return async (dispatch: ThunkDispatch<AuthActionTypes, object, any>) => {
    const token = sessionStorage.getItem('token');
    if (token) {
      const email = sessionStorage.getItem('emailMe') || '';
      if (email.length) {
        dispatch(getMeMedico(email));
      }
      const response = await fetch(subscripcionPaymentMedico(email), {
        headers: { Authorization: `Bearer ${token}` },
      });
      const result = await response.json();
      if (typeof result.data === 'boolean') {
        const susbs = result.data;
        dispatch(setID(email));
        dispatch(acreditar());
        dispatch(susbs === true ? suscribir() : desuscribir());
      } else {
        dispatch(desacreditar());
        dispatch(desuscribir());
      }
    } else {
      dispatch(desacreditar());
      dispatch(desuscribir());
    }
  };
}

import { actualizarCampo } from '@actions/infoUser/actions';
import { resetModal, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { ModalForm } from '@components/modals/ModalForm';
import {
  IHigiene,
  higieneInitial,
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Higiene/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { RootState } from '@reducer/index';
import { handleInputChange } from '@utils/handleInputChange';
import {
  antecedentesNoPatologicosGuardarHigiene,
  antecedentesNoPatologicosUsuarioGuardarHigiene,
  antecedentesNoPatologicosUsuarioPacienteGuardarHigiene,
} from '@utils/sendInfo';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

const schema = z.object({
  loaded: z.boolean(),
  banio: z.string(),
  banioComentario: z.string(),
  bucal: z.string(),
  ropa: z.string(),
  ropaComentario: z.string(),
  comentarios: z.string(),
});

type FormData = z.infer<typeof schema>;

export const Higiene = () => {
  const { higiene } = useSelector((state: RootState) => state.InfoUser);

  const [formData, setFormData] = useState<FormData>(higieneInitial);

  const dispatch = useDispatch();

  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);

  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);

  const { idConsulta } = useSelector((state: RootState) => state.Consulta);

  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);

  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IHigiene>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });

  const getDatosHigiene = (datos: IHigiene) => ({
    banio: datos.banio,
    banioComentario: datos.banioComentario,
    bucal: datos.bucal,
    ropa: datos.ropa,
    ropaComentario: datos.ropaComentario,
    comentarios: datos.comentarios,
  });

  const getDatosExpediente = (datos: IHigiene) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    higiene: getDatosHigiene(datos),
  });

  const getDatosUsuario = (datos: IHigiene) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    higiene: getDatosHigiene(datos),
  });

  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(formData);
    const sendFunctions = [antecedentesNoPatologicosGuardarHigiene(datosToSend)];

    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(antecedentesNoPatologicosUsuarioPacienteGuardarHigiene(datosToSend));
    }

    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => {
            dispatch(actualizarCampo('cambiosHigiene', { ...formData, loaded: true }));
            dispatch(actualizarCampo('higieneUsuario', { ...formData, loaded: true }));

            dispatch(resetModal('Higiene'));
            dispatch(setActiveModal('Ginecologia'));
          },
        ],
      }),
    );
  };

  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioGuardarHigiene(getDatosUsuario(formData)),
        successFunction: () => {
          dispatch(actualizarCampo('cambiosHigiene', { ...formData, loaded: true }));
          // setActiveSubStep('alimentacion');
          dispatch(resetModal('Higiene'));
          dispatch(setActiveModal('Ginecologia'));
        },
      }),
    );
  };

  const handleHigieneChange = (data: { name: string; value: any }) => {
    if (data.name in formData) {
      handleInputChange(setFormData, data);
    } else {
      console.error(`La clave ${data.name} no es válida para higiene.`);
    }
  };

  useEffect(() => {
    setFormData(higiene);
  }, [higiene]);
  useEffect(() => {
    reset(formData);
  }, [formData]);

  const handleSubmitForm = async () => {
    try {
      await schema.parseAsync(formData);
      if (esPaciente) {
        guardarDatosUsuario();
      } else {
        guardarDatosExpediente();
      }
    } catch (error) {
      console.error('Error en el envío del formulario:', error);
    }
  };

  return (
    <ModalForm
      currentStep={1}
      title="Higiene"
      id="Higiene"
      submitForm={handleSubmit(handleSubmitForm)}
    >
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
        {/* Baño */}
        <div className="mb-4">
          <h4 className="text-md font-semibold px-4 mb-2">Baño</h4>
          <CheckboxInput
            name="banio"
            options={[
              { value: 'D', label: 'Diario' },
              { value: '2D', label: 'Cada 2 días' },
              { value: '3D', label: 'Cada 3 días' },
              { value: 'OTRO', label: 'Otro' },
            ]}
            value={formData.banio}
            allowMultiple={false}
            distribution="vertical"
            setValue={handleHigieneChange}
            isRequired
            errorMessage={errors.banio?.message}
          />
          {formData.banio === 'OTRO' && (
            <TextArea
              name="banioComentario"
              label="Especifique"
              value={formData.banioComentario}
              setValue={handleHigieneChange}
              errorMessage={errors.banioComentario?.message}
            />
          )}
        </div>

        {/* Aseo bucal */}
        <fieldset className="mb-4">
          <h4 className="text-md font-semibold px-4 mb-2">Aseo bucal</h4>
          <CheckboxInput
            name="bucal"
            options={[
              { value: '1', label: '1 vez al día' },
              { value: '2', label: '2 veces al día' },
              { value: '3', label: '3 veces al día' },
              { value: 'MENOS', label: 'Menos de una vez al día' },
            ]}
            value={formData.bucal}
            allowMultiple={false}
            distribution="vertical"
            setValue={handleHigieneChange}
            isRequired
            errorMessage={errors.bucal?.message}
          />
        </fieldset>

        {/* Cambio de ropa */}
        <fieldset className="mb-4">
          <h4 className="text-md font-semibold px-4 mb-2">Cambio de ropa</h4>
          <CheckboxInput
            name="ropa"
            options={[
              { value: 'D', label: 'Diario' },
              { value: '2D', label: 'Cada 2 días' },
              { value: '3D', label: 'Cada 3 días' },
              { value: 'OTRO', label: 'Otro' },
            ]}
            value={formData.ropa}
            allowMultiple={false}
            distribution="vertical"
            setValue={handleHigieneChange}
            isRequired
            errorMessage={errors.ropa?.message}
          />
          {formData.ropa === 'OTRO' && (
            <TextArea
              name="ropaComentario"
              label="Especifique"
              value={formData.ropaComentario}
              setValue={handleHigieneChange}
              errorMessage={errors.ropaComentario?.message}
            />
          )}
        </fieldset>
      </div>

      {esPaciente && (
        <TextArea
          name="comentarios"
          label="Comentarios"
          value={formData.comentarios}
          isRequired={false}
          setValue={handleHigieneChange}
          errorMessage={errors.comentarios?.message}
        />
      )}
    </ModalForm>
  );
};

export default Higiene;

import { actualizarCampo } from '@actions/infoUser/actions';
import { resetModal, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import { NumberInput } from '@components/FormElemntsLibrary/NumberInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { ModalForm } from '@components/modals/ModalForm';
import { isOxxo } from '@constants/oxxo';
import {
  IVivienda,
  viviendaInitial,
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Vivienda/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { RootState } from '@reducer/index';
import {
  antecedentesNoPatologicosGuardarVivienda,
  antecedentesNoPatologicosUsuarioGuardarVivienda,
  antecedentesNoPatologicosUsuarioPacienteGuardarVivienda,
} from '@utils/sendInfo';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { EstiloDeVidaProps } from './type';

const schema = z.object({
  loaded: z.boolean().default(false), // Valor por defecto
  serviciosBasicos: z.object({
    check1: z.boolean(),
    check2: z.boolean(),
    check3: z.boolean(),
    check4: z.boolean(),
  }),
  numeroHabitantes: z.number().nullable(),
  numeroHabitaciones: z.number().nullable(),
  indiceHac: z.string().default(''), // Valor por defecto
  animales: z.string(),
  tipo: z.array(
    z.object({
      idAnimal: z.object({
        id: z.number(),
        label: z.string(),
      }),
      especifica: z.string(),
      cantidad: z.string(),
    }),
  ),
  comentarios: z.string(),
  trayectoTrabajo: z.enum(['si', 'no', '']),
  riesgoInundacion: z.enum(['si', 'no', '']),
  zonaDeslaves: z.enum(['si', 'no', '']),
});

type FormData = z.infer<typeof schema>;

export const EstiloDeVida: FC<EstiloDeVidaProps> = ({
  formDataSubModals,
  setFormDataSubModals,
}) => {
  const [formData, setFormData] = useState<FormData>(viviendaInitial);
  const [txtHac, setTxtHac] = useState<string>('');

  const { vivienda, cambiosVivienda, viviendaUsuario } = useSelector(
    (state: RootState) => state.InfoUser,
  );

  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);

  useEffect(() => {
    setFormData(vivienda);
  }, [vivienda]);

  useEffect(() => {
    if (formDataSubModals?.mascotas) {
      setFormData((prev) => ({
        ...prev,
        animales: formDataSubModals.mascotas.pets.length > 0 ? 'T' : 'F',
        tipo: formDataSubModals.mascotas.pets.map((pet) => ({
          idAnimal: pet.idAnimal,
          especifica: pet.especifica,
          cantidad: pet.cantidad,
        })),
      }));
    }
  }, [formDataSubModals]);
  useEffect(() => {
    if (formData.loaded) {
      const habitantes = formData.numeroHabitantes;
      const habitaciones = formData.numeroHabitaciones;

      if (habitantes && habitaciones && habitantes > 0 && habitaciones > 0) {
        const indiceHac = habitantes / habitaciones;
        setFormData((prev) => ({
          ...prev,
          indiceHac: indiceHac.toFixed(1),
        }));
        setTxtHac(indiceHac > 2.5 ? 'Hacinamiento crítico' : 'Sin hacinamiento');
      } else {
        setFormData((prev) => ({
          ...prev,
          indiceHac: '',
        }));
        setTxtHac('');
      }
    }
  }, [formData.numeroHabitaciones, formData.numeroHabitantes]);
  useEffect(() => {
    if (formDataSubModals?.mascotas?.pets) {
      const { pets } = formDataSubModals.mascotas;
      setFormData((prev) => ({
        ...prev,
        tieneMascotas: pets.length > 0 ? 'Si' : 'No',
      }));
    }
  }, [formDataSubModals.mascotas]);
  // useEffect para el cálculo del indiceHac
  useEffect(() => {
    if (formData.loaded) {
      const { numeroHabitantes, numeroHabitaciones } = formData;

      if (
        numeroHabitantes
        && numeroHabitaciones
        && numeroHabitantes !== 0
        && numeroHabitaciones !== 0
      ) {
        const indiceHac = numeroHabitantes / numeroHabitaciones;
        setFormData((prev) => ({
          ...prev,
          indiceHac: indiceHac > 0 ? indiceHac.toFixed(1) : '',
        }));
        setTxtHac(indiceHac > 2.5 ? 'Hacinamiento crítico' : 'Sin hacinamiento');
      } else {
        setFormData((prev) => ({
          ...prev,
          indiceHac: '',
        }));
        setTxtHac('');
      }
    }
  }, [formData.numeroHabitaciones, formData.numeroHabitantes]);
  useEffect(() => {
    reset(formData);
  }, [formData]);

  const dispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<IVivienda>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });
  const hayCambios: boolean = idUsuario > 0 && cambiosVivienda.filter((c: string) => c.length > 0).length > 0;

  const handleServicesChange = (eventOrValue: { name: string; value: string[] }) => {
    const { value } = eventOrValue;
    const newServiciosBasicos = {
      check1: false,
      check2: false,
      check3: false,
      check4: false,
    };

    if (value.includes('todosServicios')) {
      // Si ya estaba seleccionado "todosServicios", lo deseleccionamos
      if (formData.serviciosBasicos.check1) {
        newServiciosBasicos.check1 = false;
      } else {
        // Si no estaba seleccionado, lo seleccionamos y desactivamos los demás
        newServiciosBasicos.check1 = true;
        newServiciosBasicos.check2 = false;
        newServiciosBasicos.check3 = false;
        newServiciosBasicos.check4 = false;
      }
    } else {
      // Manejo de servicios individuales
      newServiciosBasicos.check1 = false; // "todosServicios" siempre falso si hay individuales
      newServiciosBasicos.check2 = value.includes('sinAgua');
      newServiciosBasicos.check3 = value.includes('sinElectricidad');
      newServiciosBasicos.check4 = value.includes('sinDrenaje');
    }

    setFormData((prev) => ({
      ...prev,
      serviciosBasicos: newServiciosBasicos,
    }));
  };

  const handleInputChange = (e: { name: string; value: any }) => {
    const { name, value } = e;

    if (name === 'numeroHabitantes' || name === 'numeroHabitaciones') {
      const numValue = typeof value === 'string' ? value.replace(/\D/g, '') : value;

      setFormData((prev) => ({
        ...prev,
        [name]: numValue ? parseInt(numValue, 10) : null,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleCheckboxClick = (value: string, name: string) => {
    if (name === 'animales') {
      const newFormData = {
        ...formData,
        animales: value === 'T' ? 'T' : 'F',
        tipo:
          value === 'T' ? [{ idAnimal: { id: 0, label: '' }, especifica: '', cantidad: '' }] : [],
      };
      setFormData(newFormData);

      if (value === 'T') {
        // Asegurarse de que haya al menos una mascota inicial
        const initialPets = newFormData.tipo.length > 0
          ? newFormData.tipo
          : [
            {
              id: 'pet-1',
              idAnimal: { id: 0, label: '' },
              especifica: '',
              cantidad: '',
            },
          ];

        setFormDataSubModals((prev) => ({
          ...prev,
          mascotas: {
            pets: initialPets.map((mascota: any, index: number) => ({
              id: `pet-${index + 1}`,
              idAnimal: mascota.idAnimal,
              especifica: mascota.especifica,
              cantidad: mascota.cantidad,
            })),
          },
        }));
        dispatch(setActiveModal('Mascotas'));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value as 'Si' | 'No' | '',
      }));
    }
  };

  const getDatosVivienda = (datos: IVivienda) => ({
    serviciosBasicos: datos.serviciosBasicos,
    numeroHabitantes: datos.numeroHabitantes,
    numeroHabitaciones: datos.numeroHabitaciones,
    animales: datos.animales,
    tipo: datos.tipo,
    comentarios: datos.comentarios,
  });

  const getDatosExpediente = (datos: IVivienda) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    vivienda: getDatosVivienda(datos),
  });

  const getDatosUsuario = (datos: IVivienda) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    vivienda: getDatosVivienda(datos),
  });

  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(formData);
    const sendFunctions = [antecedentesNoPatologicosGuardarVivienda(datosToSend)];

    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(antecedentesNoPatologicosUsuarioPacienteGuardarVivienda(datosToSend));
    }

    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => {
            dispatch(actualizarCampo('cambiosVivienda', { ...formData, loaded: true }));
            dispatch(actualizarCampo('viviendaUsuario', { ...formData, loaded: true }));

            dispatch(resetModal('EstiloDeVida'));
            dispatch(setActiveModal('Alimentacion'));
          },
        ],
      }),
    );
  };

  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioGuardarVivienda(getDatosUsuario(formData)),
        successFunction: () => {
          dispatch(actualizarCampo('cambiosVivienda', { ...formData, loaded: true }));
          dispatch(resetModal('EstiloDeVida'));
          dispatch(setActiveModal('Alimentacion'));
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosGuardarVivienda(
          getDatosExpediente(viviendaUsuario),
        ),
        successFunction: () => {
          dispatch(actualizarCampo('vivienda', viviendaUsuario));
          setFormData(viviendaUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarVivienda(
          getDatosExpediente(vivienda),
        ),
        successFunction: () => dispatch(actualizarCampo('viviendaUsuario', viviendaUsuario)),
      }),
    );
  };

  const handleSubmitForm = async () => {
    try {
      if (esPaciente) {
        await guardarDatosUsuario();
      } else {
        await guardarDatosExpediente();
      }
    } catch (error) {
      console.error('Error en el envío del formulario:', error);
    }
  };

  return (
    <ModalForm
      title="Vivienda"
      id="EstiloDeVida"
      submitForm={handleSubmit(handleSubmitForm)}
      hayCambios={hayCambios}
      aceptarCambios={guardarDatosDeUsuarioAExpediente}
      rechazarCambios={guardarDatosDeExpedienteAUsuario}
    >
      {!isOxxo && (
        <>
          <div className="mb-4">
            <CheckboxInput
              name="serviciosVivienda"
              options={[
                { value: 'todosServicios', label: 'Tiene todos los servicios' },
                { value: 'sinAgua', label: 'Sin agua' },
                { value: 'sinElectricidad', label: 'Sin electricidad' },
                { value: 'sinDrenaje', label: 'Sin drenaje' },
              ]}
              setValue={(e) => handleServicesChange(e)}
              value={[
                ...(formData.serviciosBasicos.check1 ? ['todosServicios'] : []),
                ...(formData.serviciosBasicos.check2 ? ['sinAgua'] : []),
                ...(formData.serviciosBasicos.check3 ? ['sinElectricidad'] : []),
                ...(formData.serviciosBasicos.check4 ? ['sinDrenaje'] : []),
              ]}
              allowMultiple
              errorMessage={errors.serviciosBasicos?.message}
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:gap-4 mb-2">
            <NumberInput
              name="numeroHabitantes"
              label="Número de habitantes"
              value={formData.numeroHabitantes || 0}
              setValue={handleInputChange}
              min={0}
              errorMessage={errors.numeroHabitantes?.message}
            />
            <NumberInput
              name="numeroHabitaciones"
              label="Número de habitaciones"
              value={formData.numeroHabitaciones || 0}
              setValue={handleInputChange}
              min={0}
              errorMessage={errors.numeroHabitaciones?.message}
            />

            {txtHac && (
              <div className="flex items-center">
                <span
                  className={`text-sm ${parseFloat(formData.indiceHac) > 2.5 ? 'text-red-500' : 'text-gray-500'
                    }`}
                >
                  {txtHac}
                </span>
              </div>
            )}
          </div>
        </>
      )}

      <div className="w-full">
        {!isOxxo && (
          <CheckboxInput
            name="animales"
            label="Mascotas/animales"
            distribution="horizontal-inline"
            numberPosition={0}
            options={[
              { value: 'T', label: 'Si' },
              { value: 'F', label: 'No' },
            ]}
            value={formData.animales}
            setValue={handleInputChange}
            onClick={(value) => handleCheckboxClick(value, 'animales')}
            errorMessage={errors.animales?.message}
          />
        )}

        <CheckboxInput
          name="trayectoTrabajo"
          label="¿Haces más de 1 hora de trayecto para llegar al trabajo?"
          distribution="horizontal-inline"
          numberPosition={1}
          options={[
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ]}
          value={formData.trayectoTrabajo}
          setValue={handleInputChange}
          onClick={(value) => handleCheckboxClick(value, 'trayectoTrabajo')}
          errorMessage={errors.trayectoTrabajo?.message}
        />

        <CheckboxInput
          name="riesgoInundacion"
          label="¿En el área en donde vives hay riesgo de inundación?"
          distribution="horizontal-inline"
          numberPosition={2}
          options={[
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ]}
          value={formData.riesgoInundacion}
          setValue={handleInputChange}
          onClick={(value) => handleCheckboxClick(value, 'riesgoInundacion')}
          errorMessage={errors.riesgoInundacion?.message}
        />

        <CheckboxInput
          name="zonaDeslaves"
          label="¿Tu casa se encuentra en zona de deslaves?"
          distribution="horizontal-inline"
          numberPosition={3}
          options={[
            { value: 'Si', label: 'Si' },
            { value: 'No', label: 'No' },
          ]}
          value={formData.zonaDeslaves}
          setValue={handleInputChange}
          onClick={(value) => handleCheckboxClick(value, 'zonaDeslaves')}
          errorMessage={errors.zonaDeslaves?.message}
        />
      </div>

      <div className="mt-5">
        {!esPaciente && (
          <TextArea
            name="comentarios"
            label="Comentarios"
            value={formData.comentarios}
            isRequired={false}
            setValue={handleInputChange}
            errorMessage={errors.comentarios?.message}
          />
        )}
      </div>
    </ModalForm>
  );
};

export default EstiloDeVida;

import {
  ModalActionTypes,
  RESET_ALL_MODALS,
  RESET_MODAL,
  SET_ACTIVE_MODAL,
} from '../actions/modal/types';

export interface IModal {
  name: string;
  isActive: boolean;
}

const initialState: IModal[] = [
  { name: 'Perfil', isActive: false },
  { name: 'Habitos', isActive: false },
  { name: 'Ubication', isActive: false },
  { name: 'Discapacidades', isActive: false },
  { name: 'InformacionEmergencia', isActive: false },
  { name: 'SegurosPlanes', isActive: false },
  { name: 'Higiene', isActive: false },
  { name: 'MiSalud', isActive: false },
  { name: 'ActividadFisica', isActive: false },
  { name: 'EstiloDeVida', isActive: false },
  { name: 'Alimentacion', isActive: false },
  { name: 'Diabetes', isActive: false },
  { name: 'MaleHealth', isActive: false },
  { name: 'FemaleHealth', isActive: false },
  { name: 'Hipertension', isActive: false },
  { name: 'Activo', isActive: false },
  { name: 'ExFumador', isActive: false },
  { name: 'Fumador', isActive: false },
  { name: 'Mascotas', isActive: false },
  { name: 'Ginecologia', isActive: false },
  { name: 'MisMedidas', isActive: false },
  { name: 'SubMenu', isActive: false },
  { name: 'ResumenDeSalud', isActive: false },
  { name: 'TipoDeAlcohol', isActive: false },
  { name: 'TiempoDeUso', isActive: false },
  { name: 'Anticonceptivos', isActive: false },
  { name: 'AntecedentesLaborales', isActive: false },
  { name: 'RiesgoSalud', isActive: false },
  { name: 'Tratamientos', isActive: false },
  { name: 'NerveProblemsAndPain', isActive: false },
];

function Modal(state = initialState, action: ModalActionTypes): IModal[] {
  switch (action.type) {
    case SET_ACTIVE_MODAL:
      return state.map((modal) =>
        modal.name === action.payload ? { ...modal, isActive: true } : modal,
      );
    case RESET_MODAL:
      return state.map((modal) =>
        modal.name === action.payload ? { ...modal, isActive: false } : modal,
      );
    case RESET_ALL_MODALS:
      return state.map((modal) => ({ ...modal, isActive: false }));
    default:
      return state;
  }
}

export default Modal;

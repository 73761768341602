import React, { useEffect, useState, useRef } from 'react';
import { RootState } from 'src/store';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import {
  Dialog, DialogContent, Slide, IconButton, TextField, Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import getPerfilNotas from '@utils/barraDatosPaciente';
import { sendNotasMedico, updateNotasMedico } from '@utils/sendInfo';
import { setSnackComplete } from '@actions/snackbar/types';
import { INotasPrivadasProps, ICreacionNotas } from './types';

// const Transition = React.forwardRef(
//   (
//     // eslint-disable-next-line react/require-default-props
//     props: TransitionProps & { children?: React.ReactElement<any, any> },
//     ref: React.Ref<unknown>,
//   ) => <Slide direction="up" ref={ref} {...props} />,
// );
const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  )
);

function NotasPrivadas(props: INotasPrivadasProps) {
  const {
    open, callBackClose, findList, setFindList,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const [notaNueva, setNotaNueva] = useState<ICreacionNotas>({
    id: -1,
    fecha: '',
    txt: '',
  });
  const [totalNotas, setTotalNotas] = useState<ICreacionNotas[]>([]);
  /** states de la snackbar y el alert */
  const [warBorrado, setWarBorrado] = useState<boolean>(false);
  const [warActualizar, setWarActualizar] = useState<boolean>(false);
  const [inxSeleccionado, setInxSeleccionado] = useState<number>(-1);
  const overlayEl = useRef<HTMLDivElement>(null);
  const getNotas = () => {
    getPerfilNotas(idMedico, idPaciente, idConsultorio).then((result: any) => {
      if (result) {
        setTotalNotas(result);
      }
    });
  };
  const sendToSave = () => {
    const dataToSend = {
      idPaciente,
      idMedico,
      idConsultorio,
      accion: 'c',
      notaNueva,
    };
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    if (dataToSend.notaNueva.id === -1) {
      sendNotasMedico(dataToSend)
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('message-success'),
              }),
            );
            getNotas();
            setNotaNueva({
              id: -1,
              fecha: '',
              txt: '',
            });
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('message-error'),
              }),
            );
          }
        })
        .catch((error) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${error.toString()}`,
            }),
          );
        });
    } else if (dataToSend.notaNueva.id >= 0) {
      dataToSend.accion = 'a';
      updateNotasMedico(dataToSend)
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('message-success'),
              }),
            );
            getNotas();
            setNotaNueva({
              id: -1,
              fecha: '',
              txt: '',
            });
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('message-error'),
              }),
            );
          }
        })
        .catch((error) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${error.toString()}`,
            }),
          );
        });
    }
  };
  useEffect(() => {
    if (findList) {
      setFindList(false);
    }
  }, [findList]);
  useEffect(() => {
    if (idPaciente > 0 && idMedico > 0 && idConsultorio > 0) {
      getNotas();
    }
  }, [idPaciente, idMedico, idConsultorio]);
  const handleBack = () => {
    if (overlayEl.current) {
      overlayEl.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleClose = (e: any) => {
    setNotaNueva({
      id: -1,
      fecha: '',
      txt: '',
    });
    setInxSeleccionado(-1);
    setWarActualizar(false);
    setWarBorrado(false);
    callBackClose(e);
  };
  const handleUpdate = () => {
    setNotaNueva(totalNotas[inxSeleccionado]);
    setWarActualizar(false);
  };
  const handleDelete = () => {
    const dataToSend = {
      idPaciente,
      idMedico,
      accion: 'd',
      idNota: inxSeleccionado.toString(),
    };
    updateNotasMedico(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          setWarBorrado(false);
          getNotas();
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('message-error'),
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${error.toString()}`,
          }),
        );
      });
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="delete-dialog-titulo"
      aria-describedby="delete-dialog-descripcion"
      fullWidth
      maxWidth="lg"
    >
      <div className="flex justify-between items-center pl-4 bg-blue-800 text-white">
        <h2 className="font-normal">{t('notas_privadas')}</h2>
        <IconButton aria-label="cerrar" color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <div className="py-2 px-0" ref={overlayEl}>
          <Collapse in={warActualizar}>
            <Alert
              action={(
                <>
                  <Button color="inherit" size="small" onClick={() => handleUpdate()}>
                    {t('aceptar')}
                  </Button>
                  <Button color="inherit" size="small" onClick={() => setWarActualizar(false)}>
                    {t('cancelar')}
                  </Button>
                </>
              )}
              severity="info"
            >
              {t('editar_nota')}
            </Alert>
          </Collapse>
          <Collapse in={warBorrado}>
            <Alert
              action={(
                <>
                  <Button color="inherit" size="small" onClick={() => handleDelete()}>
                    {t('aceptar')}
                  </Button>
                  <Button color="inherit" size="small" onClick={() => setWarBorrado(false)}>
                    {t('cancelar')}
                  </Button>
                </>
              )}
              severity="error"
            >
              {t('eliminar_nota')}
            </Alert>
          </Collapse>
          <div>
            <h3 className="text-blue-500 font-normal m-0 mb-2 text-left">{t('nueva_nota')}</h3>
          </div>
          <TextField
            id="especifica"
            placeholder={t('escribe_nota')}
            value={notaNueva.txt}
            onChange={(e) => {
              const upNota = { ...notaNueva };
              upNota.fecha = dayjs().format();
              upNota.txt = e.target.value;
              setNotaNueva(upNota);
              setFindList(true);
            }}
            inputProps={{
              maxLength: 500,
              autoComplete: 'off',
            }}
            variant="outlined"
            fullWidth
          />
          <div className="text-right pt-2">
            {notaNueva && notaNueva.id === -1 ? (
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                onClick={() => {
                  sendToSave();
                }}
                disabled={!notaNueva.txt}
              >
                {t('guardar')}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                onClick={() => {
                  sendToSave();
                }}
                disabled={!notaNueva.txt}
              >
                {t('actualizar')}
              </Button>
            )}
          </div>
        </div>
        {totalNotas && totalNotas.length > 0 ? (
          <div className="py-2 px-0">
            <div>
              <h3 className="text-blue-500 font-normal m-0 mb-2 text-left">{t('lista_notas')}</h3>
            </div>
            {totalNotas.map((element, inx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="py-2" key={inx}>
                <div className="shadow-md border-0 border-solid border-blue-500 rounded border-l-8 w-full flex justify-between">
                  <div className="p-4 overflow-ellipsis overflow-hidden">
                    <h4 className="font-normal text-gray-500 m-0 mr-4 text-justify">
                      {`Fecha: ${dayjs(element.fecha)
                        .add(1, 'day')
                        .locale('es')
                        .format('DD-MMM-YYYY')}`}
                    </h4>
                    <h3 className="font-normal text-gray-500 m-0 mr.-4 text-justify">
                      {element.txt}
                    </h3>
                  </div>
                  <div className="min-w-max self-center">
                    <IconButton
                      aria-label="edit"
                      color="primary"
                      onClick={() => {
                        handleBack();
                        setInxSeleccionado(inx);
                        if (notaNueva.txt) {
                          setWarActualizar(true);
                          setWarBorrado(false);
                        } else {
                          setNotaNueva(totalNotas[inx]);
                        }
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => {
                        handleBack();
                        setInxSeleccionado(element.id);
                        setWarActualizar(false);
                        setWarBorrado(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}

export default NotasPrivadas;

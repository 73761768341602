import React, { FC, FormEvent, useEffect, useRef } from 'react';
import { resetModal } from '@actions/modal/actions';
import { BackButton } from '@components/BackButton';
import { SubmitButton } from '@components/FormElemntsLibrary/SubmitButton';
import { FormProgressSteps } from '@components/FormProgressSteps';
// import { MediHero } from '@components/MediHero';
import { StepHeader } from '@components/StepHeader';
import { GLOBALS } from '@constants/prueba/globals';
import { useBreakPoint } from '@hooks/useBreakpoint';
import ResumenCambios from '@components/ResumenCambios';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { ModalFormProps } from './type';

// eslint-disable-next-line import/prefer-default-export
export const ModalForm: FC<ModalFormProps> = ({
  currentStep,
  totalSteps,
  title,
  children,
  progressSteps,
  id,
  submitButtonLabel,
  aceptarCambios,
  rechazarCambios,
  submitForm,
  backAction,
  displayButton,
  hayCambios = false,
  cambios = [],
  camposObligatorios,
  noBorder = false,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const modals = useSelector((state: RootState) => state.Modal);

  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);
  const form = useRef<HTMLFormElement | null>(null);

  const dispatch = useDispatch();

  // const closeModal = () => {
  //   if (form.current) {
  //     form.current.classList.add('animate-popRight');
  //     document.body.style.removeProperty('overflow');
  //     setTimeout(() => dispatch(resetModal(id)), 300);
  //   }
  // };
  const closeModal = () => {
    form.current?.classList.remove('animate-popLeft');
    form.current?.classList.add('animate-popRight');
    if (backAction) {
      setTimeout(backAction, 300);
      // backAction();
    }
  };

  const handleDynamicHeight = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  };

  const handleSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await submitForm();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    // Animación de entrada
    setTimeout(() => form.current?.classList.remove('animate-popLeft'), 300);

    // Cierre con deslizar en móviles
    const handleTouchMove = (e: TouchEvent) => {
      if (e.touches[0].clientX < 40) closeModal();
    };
    document.addEventListener('touchmove', handleTouchMove);

    // Manejar cierre al recargar página
    window.onbeforeunload = () => closeModal();

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    // Ajustar altura del viewport en móviles
    if (!isWideScreen) {
      handleDynamicHeight();
      window.addEventListener('resize', handleDynamicHeight);
    }
    return () => window.removeEventListener('resize', handleDynamicHeight);
  }, [isWideScreen]);

  const minHeight = isWideScreen
    ? `calc(100vh - ${
        modals.some((modal) => modal.isActive && modal.name === 'SubMenu') ? '300px' : '252px'
      })`
    : undefined;

  return (
    <form
      onSubmit={handleSubmitForm}
      ref={form}
      className={`min-h-screen ${
        isWideScreen ? '' : ''
      } md:container ${noBorder
        ? ''
        : 'md:p-4 bg-white mx-auto shadow rounded-b-md border-solid border border-gray-100 border-t-0'
      }`}
    >
      <div
        className="flex-1 md:w-full md:normal md:h-fit"
        style={{ WebkitOverflowScrolling: 'touch' }}
      >
        <div
          className="w-full h-[100%] md:h-auto flex flex-col justify-between"
          style={{ minHeight }}
        >
          <div className="w-full min-h-fit relative">
            {/* {!isWideScreen && <MediHero />} */}
            {/* {!isWideScreen &&
              <div className='px-3 py-4'>
                <BackButton onClick={closeModal} />
              </div>
            } */}
            {hayCambios && <ResumenCambios cambios={cambios} />}
            <h1
              className={`w-full font-normal md:text-center px-4 ${
                (totalSteps && currentStep) || progressSteps ? '' : 'mb-4'
              } md:text-blue-800 md:mb-3`}
            >
              {title}
            </h1>
            {esPaciente && !isWideScreen && totalSteps && currentStep &&
              <StepHeader currentStep={currentStep} totalSteps={totalSteps}/>
            }
            {progressSteps && !isWideScreen && <FormProgressSteps steps={progressSteps} />}
            <div className="px-2 md:px-0">
              {camposObligatorios && esPaciente && !isWideScreen && (
                <div className="text-right">
                  <span className="text-red-500 mt-2">
                    *&nbsp;
                    {t('campos_obligatorios')}
                  </span>
                </div>
              )}
              {children}
              {camposObligatorios && (!esPaciente || isWideScreen) && (
                <div className="text-right">
                  <span className="text-red-500 mt-2">
                    *&nbsp;
                    {t('campos_obligatorios')}
                  </span>
                </div>
              )}
              <SubmitButton
                label={submitButtonLabel}
                acceptCallback={aceptarCambios}
                rejectCallback={rechazarCambios}
                saveCallback={submitForm}
                disabled={displayButton}
                hayCambios={hayCambios}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

import { FC } from 'react';
import { TriangleAlert } from 'lucide-react';
import { NavigateButtonProps } from './type';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export const NavigateButton: FC<NavigateButtonProps> = ({
  title,
  onClick,
  icon,
  alert = false,
  disabled = false,
  isActive,
  position = '',
  asTabs = false,
  completed,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  return <button
    onClick={onClick}
    disabled={disabled}
    className={`
          w-[156px] h-[56px]
          hover:bg-blue-600
          disabled:bg-gray-300 disabled:cursor-not-allowed
          text-white font-medium
          rounded-xl
          transition-colors duration-200
          flex items-center justify-center gap-2
          md:w-fit md:h-[44px] md:rounded-none md:gap-1 md:px-4
          ${isActive ? 'bg-blue-900' : 'bg-blue-500'}
          ${position === 'first' ? `md:overflow-hidden ${asTabs ? 'md:rounded-tl-lg' : 'md:rounded-s-lg'}` : ''}
          ${
            position === 'last'
              ? `md:overflow-hidden ${asTabs ? 'md:rounded-tr-lg' : 'md:rounded-e-lg'}`
              : 'md:border-r-[2px]'
          }
          ${esPaciente && completed === false ? 'border-2 border-solid border-orange-600 mr-[2px]' : ''}
        `}
  >
    {alert ? <TriangleAlert color="orange" size={20} /> : icon}
    <span>{title}</span>
  </button>
};

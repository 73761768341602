import { BackButton } from '@components/BackButton';
import { MediHero } from '@components/MediHero';
import { NavigateButton } from '@components/NavigateButton';
import { NavigationSection } from '@components/NavigationSection';
import { GLOBALS } from '@constants/prueba/globals';
import { subMenu } from '@constants/prueba/menu';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { FC, useEffect, useRef } from 'react';

import { resetModal, setActiveModal } from '@actions/modal/actions';
import { isOxxo } from '@constants/oxxo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';

const whiteList = [
  'Perfil',
  'Discapacidades',
  'Ubication',
  'InformacionEmergencia',
  'SegurosPlanes',
];

export const SubMenu: FC = () => {
  const modals = useSelector((state: RootState) => state.Modal);
  const dispatch = useDispatch();

  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);
  const form = useRef<HTMLDivElement | null>(null);
  const body = document.querySelector('body');

  // useEffect(() => {
  //   if (!isWideScreen) {
  //     document.body.style.overflow = 'hidden';

  //     setTimeout(() => {
  //       if (form.current) {
  //         form.current.classList.remove('animate-popLeft');
  //       }
  //     }, 300);
  //   }
  // }, [isWideScreen]);

  const handleResetModal = () => {
    if (body && form.current) {
      form.current.classList.add('animate-popRight');
      body.style.removeProperty('overflow');
      setTimeout(() => {
        dispatch(resetModal('SubMenu'));
      }, 300);
    }
  };

  const handleOpenModal = (modalName: string) => {
    if (isWideScreen) {
      if (whiteList.includes(modalName)) {
        whiteList.forEach((modal) => {
          dispatch(resetModal(modal));
        });
        dispatch(setActiveModal(modalName));
      }
    }
    dispatch(setActiveModal(modalName));
  };

  useEffect(() => {
    setTimeout(() => {
      if (form.current) {
        form.current.classList.remove('animate-popLeft');
      }
    }, 300);
  }, []);

  return (
    <div
      ref={form}
      className={`${isWideScreen ? '' : 'animate-popLeft'
        } fixed top-0 z-10 w-full h-screen bg-white flex flex-col md:static overflow-y-scroll md:w-[98%] md:h-fit md:bg-transparent md:overflow-y-visible md:pt-2`}
    >
      {!isWideScreen && <MediHero />}
      {!isWideScreen && <BackButton onClick={handleResetModal} />}
      <section className="w-full h-auto">
        {!isWideScreen && <h1 className="w-full text-2xl font-semibold px-4">Perfil</h1>}
        <nav className="w-full h-auto px-4 md:h-fit md:px-0">
          {subMenu.map((section) => (
            <NavigationSection key="SubMenu">
              {section.buttons
                .filter((button) => button.isOxxo !== false || !isOxxo)
                .map(({ title, id }, index, filteredButtons) => {
                  const isActive = modals.some((modal) => modal.name === id && modal.isActive);
                  return (
                    <NavigateButton
                      key={title}
                      title={title}
                      onClick={() => handleOpenModal(id)}
                      isActive={isActive}
                      position={index === 0 ? 'first' : (index === (filteredButtons.length - 1) ? 'last' : '')}
                    />
                  );
                })}
            </NavigationSection>
          ))}
        </nav>
      </section>
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { edadCalculada } from '@common/functions';
import { setExploracionFisica } from '@actions/paciente/actions';
import { setRutaPrincipal } from '@actions/rutasExpediente/actions';
import { sendExploracionFisica, sendExploracionFisicaPaciente } from '@utils/sendInfo';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { RootState } from 'src/store';
import { getSitioMedicion } from '@utils/getCatalogos';
import { edadInitial, ICatalogosEstandar, IEdad } from '@common/types';
import { IControlErrores, IExploracionFisica, exploracionFisicaInitial } from './types';
import TablaExploracion from './TablaExploracion';
import { ModalForm } from '@components/modals/ModalForm';
import SelectInput from '@components/FormElemntsLibrary/SelectInput2';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import TextField from '@components/FormElemntsLibrary/TextField';

function ExploracionFisica() {
  const dispatch = useDispatch();
  const { idMedico, idUsuario, esPaciente } = useSelector((state: RootState) => state.Me);
  const { isOxxo } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    perfil: { datosPersonales },
    exploracionFisica,
  } = useSelector((state: RootState) => state.Paciente.expediente);
  const { idPaciente, sexo } = useSelector((state: RootState) => state.BasicosPaciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [catSitioMedicion, setCatSitioMedicion] = useState<ICatalogosEstandar[]>([]);
  const [exploracionFisicaTemp, setExploracionFisicaTemp] =
    useState<IExploracionFisica>(exploracionFisicaInitial);
  const arrAreas = [
    'antecedentes-ef-ag',
    'antecedentes-ef-pa',
    'antecedentes-ef-craneo',
    'antecedentes-ef-cara',
    'antecedentes-ef-ojos',
    'antecedentes-ef-oidos',
    'antecedentes-ef-nariz',
    'antecedentes-ef-bg',
    'antecedentes-ef-cuello',
    'antecedentes-ef-torax',
    'antecedentes-ef-rp',
    'antecedentes-ef-gm',
    'antecedentes-ef-axila',
    'antecedentes-ef-abdomen',
    'antecedentes-ef-ric',
    'antecedentes-ef-gen',
    'antecedentes-ef-recto',
    'antecedentes-ef-extremidades',
    'antecedentes-ef-col',
    'antecedentes-ef-neurologico',
    'antecedentes-ef-otro',
  ];

  const edad =
    datosPersonales.fechaNac.dia && datosPersonales.fechaNac.mes && datosPersonales.fechaNac.anio
      ? edadCalculada(
        datosPersonales.fechaNac.anio,
        datosPersonales.fechaNac.mes,
        datosPersonales.fechaNac.dia,
      )
      : edadInitial;

  useEffect(() => {
    if (catSitioMedicion.length === 0) {
      getSitioMedicion().then((result: ICatalogosEstandar[]) => {
        setCatSitioMedicion(result);
      });
    }
    if (exploracionFisica.loaded) {
      if (!exploracionFisicaTemp.loaded) {
        setExploracionFisicaTemp(exploracionFisica);
      }
    } else {
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        contenidoTabla: arrAreas.map((aA) => ({
          comentarioSintoma: '',
          nombreSintoma: aA,
          normal: '',
        })),
      });
    }
  }, [exploracionFisica]);
  // calculo de presiones
  useEffect(() => {
    if (
      exploracionFisicaTemp.sistolica?.length !== 0 &&
      exploracionFisicaTemp.diastolica?.length !== 0
    ) {
      const pMedia =
        (parseInt(exploracionFisicaTemp.sistolica, 10) +
          parseInt(exploracionFisicaTemp.diastolica, 10) * 2) /
        3;
      const pPulso =
        parseInt(exploracionFisicaTemp.sistolica, 10) -
        parseInt(exploracionFisicaTemp.diastolica, 10);
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        pMedia: pMedia.toFixed(1),
        pPulso: pPulso > 0 ? pPulso.toFixed(1) : '',
      });
    } else if (exploracionFisicaTemp.loaded) {
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        pMedia: '',
        pPulso: '',
      });
    }
  }, [exploracionFisicaTemp.sistolica, exploracionFisicaTemp.diastolica]);
  // calculo de IMC
  useEffect(() => {
    if (
      exploracionFisicaTemp.peso.length !== 0 &&
      exploracionFisicaTemp.altura.length !== 0 &&
      edad.edad >= 5 &&
      edad.periodo === 'anios'
    ) {
      let peso = parseFloat(exploracionFisicaTemp.peso);
      let altura = parseFloat(exploracionFisicaTemp.altura);
      const imc = esPaciente
        ? peso / (altura * altura)
        : peso / ((altura / 100) * (altura / 100));
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        imc: imc.toFixed(1),
      });
    } else if (exploracionFisicaTemp.loaded) {
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        imc: '',
      });
    }
  }, [exploracionFisicaTemp.peso, exploracionFisicaTemp.altura]);

  const handleInputChange = (e: { name: string; value: any }) =>
    setExploracionFisicaTemp((prevData) => ({ ...prevData, [e.name]: e.value }));

  const handleSubmitForm = () => {
    const datosAEnviar = {
      PESO:
        exploracionFisicaTemp.peso && exploracionFisicaTemp.peso.length === 0
          ? 999
          : exploracionFisicaTemp.peso,
      ALTURA:
        exploracionFisicaTemp.altura && exploracionFisicaTemp.altura.length === 0
          ? 999
          : exploracionFisicaTemp.altura,
      IMC:
        exploracionFisicaTemp.imc && exploracionFisicaTemp.imc.length === 0
          ? '-1'
          : exploracionFisicaTemp.imc,
      PARTERIAL_SISTOLICA:
        exploracionFisicaTemp.sistolica && exploracionFisicaTemp.sistolica.length === 0
          ? 0
          : exploracionFisicaTemp.sistolica,
      PARTERIAL_MEDIA: exploracionFisicaTemp.pMedia,
      PARTERIAL_PULSO: exploracionFisicaTemp.pPulso,
      PARTERIAL_SEL: exploracionFisicaTemp.pArterialRadio,
      PARTERIAL_DIASTOLICA:
        exploracionFisicaTemp.diastolica && exploracionFisicaTemp.diastolica.length === 0
          ? 0
          : exploracionFisicaTemp.diastolica,
      PER_CINTURA:
        exploracionFisicaTemp.perCintura && exploracionFisicaTemp.perCintura.length === 0
          ? 999
          : exploracionFisicaTemp.perCintura,
    };
    dispatch(setLoading(true));
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    const funcSendExploracionFisica = esPaciente
      ? sendExploracionFisicaPaciente({ ...datosAEnviar, ID_USUARIO: idUsuario })
      : sendExploracionFisica({
        ...datosAEnviar,
        ID_PACIENTE: idPaciente,
        ID_MEDICO: idMedico,
        ID_CONSULTA: idConsulta,
        F_CARDIACA:
            exploracionFisicaTemp.fCardiaca && exploracionFisicaTemp.fCardiaca.length === 0
              ? 0
              : exploracionFisicaTemp.fCardiaca,
        F_CARDIACASEL: exploracionFisicaTemp.fCardiacaRadio,
        F_RESPIRATORIA:
            exploracionFisicaTemp.fRespiratoria && exploracionFisicaTemp.fRespiratoria.length === 0
              ? 0
              : exploracionFisicaTemp.fRespiratoria,
        SAT_OXIGENO:
            exploracionFisicaTemp.satOxigeno && exploracionFisicaTemp.satOxigeno.length === 0
              ? 0
              : exploracionFisicaTemp.satOxigeno,
        TEMPERATURA:
            exploracionFisicaTemp.temperatura && exploracionFisicaTemp.temperatura.length === 0
              ? 0
              : exploracionFisicaTemp.temperatura,
        TEMPERATURASEL_ID: exploracionFisicaTemp.sitioMedicion.id,
        CONTENIDO_TABLA: exploracionFisicaTemp.contenidoTabla,
        idMedico,
        idConsultorio,
      });
    funcSendExploracionFisica
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setExploracionFisica({
              ...exploracionFisicaTemp,
              loaded: true,
            }),
          );
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          if (esPaciente) {
            dispatch(setRutaPrincipal('resumen-de-salud'));
          }
        } else {
          let mensaje = '';
          if (/UNIQUE KEY/i.test(result.msg)) {
            mensaje = 'Campo Repetido';
          } else {
            mensaje = result.msg;
          }
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${mensaje}`,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${error.toString()}`,
          }),
        );
        dispatch(setLoading(false));
      });
  };

  const componentsSV: {
    title: string;
    hide?: boolean;
    component: JSX.Element;
  }[] = [
    {
      title: t('antecedentes-ef-temperatura'),
      hide: esPaciente,
      component:
        <div className="grid grid-cols-1 md:grid-cols-3 md:w-1/2 pt-2">
          <SelectInput
            containerClass="md:col-span-2"
            name="sitioMedicion"
            label=""
            floatingLabel={t('antecedentes-ef-sitio')}
            options={catSitioMedicion}
            value={exploracionFisicaTemp.sitioMedicion.id}
            setValue={handleInputChange}
            isNumeric={false}
            sendObject
          />
          <NumberInput
            name="temperatura"
            label=""
            floatingLabel={esPaciente ? t('antecedentes-ef-temperatura') : "T"}
            value={exploracionFisicaTemp.temperatura
              ? Number(exploracionFisicaTemp.temperatura)
              : null
            }
            fixedValue={t('antecedentes-ef-gc')}
            helperText="Entre 30 y 44"
            isString
            min={30}
            max={44}
            decimals={2}
            setValue={handleInputChange}
          />
        </div>
    },
    {
      title: t('antecedentes-ef-frecuenciac'),
      hide: esPaciente,
      component:
        <div className="grid grid-cols-1 md:grid-cols-3 md:w-1/2 pt-2">
          <CheckboxGroup
            containerClass="md:col-span-2"
            name="fCardiacaRadio"
            label=""
            options={[
              { value: 'rRegular', label: t('antecedentes-ef-ritmor') },
              { value: 'rIrregular', label: t('antecedentes-ef-ritmoi') },
            ]}
            distribution="vertical-flex"
            value={exploracionFisicaTemp.fCardiacaRadio}
            setValue={handleInputChange}
          />
          <NumberInput
            name="fCardiaca"
            label=""
            value={exploracionFisicaTemp.fCardiaca
              ? Number(exploracionFisicaTemp.fCardiaca)
              : null
            }
            isInteger
            isString
            fixedValue={t('antecedentes-ef-l_m')}
            helperText={t('antecedentes-ef-frecuenciac-err')}
            min={40}
            max={220}
            setValue={handleInputChange}
          />
        </div>
    },
    {
      title: t('antecedentes-ef-presion'),
      component:
        <div className="grid grid-cols-1 md:grid-cols-6 pt-2">
          <CheckboxGroup
            containerClass="md:col-span-2"
            name="pArterialRadio"
            label=""
            options={['sentado', 'decubito', 'pie'].map(op => ({
              value: op,
              label: t(`antecedentes-ef-${op}`),
            }))}
            distribution="vertical-flex"
            value={exploracionFisicaTemp.pArterialRadio}
            setValue={handleInputChange}
          />
          <NumberInput
            name="sistolica"
            label=""
            value={exploracionFisicaTemp.sistolica
              ? Number(exploracionFisicaTemp.sistolica)
              : null
            }
            placeholder={t('antecedentes-ef-sistolica')}
            isInteger
            isString
            fixedValue={t('antecedentes-ef-mmhg')}
            helperText={t('antecedentes-ef-sistolica-err')}
            min={50}
            max={300}
            setValue={handleInputChange}
          />
          <NumberInput
            name="diastolica"
            label=""
            value={exploracionFisicaTemp.diastolica
              ? Number(exploracionFisicaTemp.diastolica)
              : null
            }
            floatingLabel={t('antecedentes-ef-diastolica')}
            isInteger
            isString
            fixedValue={t('antecedentes-ef-mmhg')}
            helperText={t('antecedentes-ef-diastolica-err')}
            min={20}
            max={200}
            setValue={handleInputChange}
          />
          {!esPaciente &&
            <>
              <TextField
                containerClass="pt-2"
                name="pMedia"
                label=""
                floatingLabel={t('antecedentes-ef-presiona')}
                value={exploracionFisicaTemp.pMedia}
                adornment={{
                  text: t('antecedentes-ef-mmhg'),
                  position: 'end',
                }}
              />
              <TextField
                containerClass="pt-2"
                name="pMedia"
                label=""
                floatingLabel={t('antecedentes-ef-presionp')}
                value={exploracionFisicaTemp.pPulso}
                adornment={{
                  text: t('antecedentes-ef-mmhg'),
                  position: 'end',
                }}
              />
            </>
          }
        </div>
    },
    {
      title: t('antecedentes-ef-frecuenciar'),
      hide: esPaciente,
      component:
        <div className="md:w-2/6 pt-2">
          <NumberInput
            name="fRespiratoria"
            label=""
            value={exploracionFisicaTemp.fRespiratoria
              ? Number(exploracionFisicaTemp.fRespiratoria)
              : null
            }
            isInteger
            isString
            fixedValue={t('antecedentes-ef-respiraciones')}
            helperText={t('antecedentes-ef-frecuenciar-err')}
            min={10}
            max={99}
            setValue={handleInputChange}
          />
        </div>
    },
    {
      title: t('antecedentes-ef-sp02-label'),
      hide: esPaciente,
      component:
        <div className="md:w-2/6 pt-2">
          <NumberInput
            name="satOxigeno"
            label=""
            value={exploracionFisicaTemp.satOxigeno
              ? Number(exploracionFisicaTemp.satOxigeno)
              : null
            }
            placeholder={t('antecedentes-ef-sp02')}
            isInteger
            isString
            fixedValue={t('antecedentes-ef-%')}
            helperText={t('antecedentes-ef-sp02-err')}
            min={10}
            max={100}
            setValue={handleInputChange}
          />
        </div>
    },
  ].filter(component => !component.hide);

  return (
    <div className="md:px-4 md:py-4 bg-white md:container mx-auto shadow">
      <ModalForm
        title={t('exploracion_fisica')}
        id="exploracion-fisica-form"
        submitForm={handleSubmitForm}
        backAction={() => dispatch(setRutaPrincipal('main'))}
        // displayButton={esPaciente || !!arrAreas.length}
        // disable={Object.values(controlError).find((element) => element === true)}
        noBorder
      >
        {!isOxxo &&
          <>
            <h2 className="text-blue-800 font-normal mb-2">{t('antecedentes-ef-signos')}</h2>
            {componentsSV.map((component, index) =>
              <div className={`px-4 pt-2 pb-0 border-[#D1D5DB] border-b-[1px] ${index % 2 === 0 ? 'bg-[#F3F4F6]' : ''}`}>
                <h3 className="text-gray-600 font-medium">{component.title}</h3>
                {component.component}
              </div>
            )}
          </>
        }
        <h2 className="text-blue-800 font-normal mt-4 mb-2">{t('antecedentes-ef-soma')}</h2>
        <div className="grid grid-cols-1 md:grid-cols-6">
          <NumberInput
            name="peso"
            label=""
            value={exploracionFisicaTemp.peso
              ? Number(exploracionFisicaTemp.peso)
              : null
            }
            floatingLabel={t('antecedentes-ef-peso')}
            fixedValue={t('antecedentes-ef-kg')}
            helperText={t('antecedentes-ef-peso-err')}
            isString
            min={1}
            max={400}
            decimals={3}
            setValue={handleInputChange}
          />
          <NumberInput
            name="altura"
            label=""
            value={exploracionFisicaTemp.altura
              ? Number(exploracionFisicaTemp.altura)
              : null
            }
            floatingLabel={t('antecedentes-ef-altura')}
            isInteger={!esPaciente}
            isString
            fixedValue={t('antecedentes-ef-cm')}
            helperText={t('antecedentes-ef-altura-err')}
            min={esPaciente ? 0.3 : 30}
            max={esPaciente ? 2.2 : 220}
            decimals={esPaciente ? 2 : undefined}
            setValue={handleInputChange}
          />
          {!esPaciente &&
            <TextField
              containerClass="pt-2"
              name="imc"
              label=""
              floatingLabel={t('antecedentes-ef-imc')}
              value={exploracionFisicaTemp.imc}
              adornment={{
                text: t('antecedentes-ef-km'),
                position: 'end',
              }}
            />        
          }
          {!isOxxo &&
            <NumberInput
              name="perCintura"
              label=""
              value={exploracionFisicaTemp.perCintura
                ? Number(exploracionFisicaTemp.perCintura)
                : null
              }
              floatingLabel={t('antecedentes-ef-perimetro')}
              isInteger
              isString
              fixedValue={t('antecedentes-ef-cm')}
              helperText={t('antecedentes-ef-perimetro-err')}
              min={20}
              max={300}
              setValue={handleInputChange}
            />
          }
        </div>
        {!esPaciente && (
          <div>
            {!!arrAreas.length && (
              <>
                <div className="text-left">
                  <h2 className="text-blue-800 font-normal m-0 py-4">
                    {t('antecedentes-ef-detalle')}
                  </h2>
                </div>
                <TablaExploracion
                  exploracionFisica={exploracionFisicaTemp}
                  setExploracionFisica={setExploracionFisicaTemp}
                  sexo={sexo}
                />
              </>
            )}
          </div>
        )}
      </ModalForm>
    </div>
  );
}

export default ExploracionFisica;

import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import { SubModalForm } from '@components/modals/SubModalForm';
import { FC, useState } from 'react';

import { resetModal } from '@actions/modal/actions';
import { useDispatch } from 'react-redux';

interface AnticonceptivosProps {
  currentValue?: string;
  onUpdate: (type: string) => void;
}

export const Anticonceptivos: FC<AnticonceptivosProps> = ({ currentValue, onUpdate }) => {
  const dispatch = useDispatch();

  const [selectedMethod, setSelectedMethod] = useState<string>(currentValue || '');

  const handleMethodSelect = (e: any) => {
    const { value } = e.target;
    setSelectedMethod(value);
  };

  const handleSubmit = () => {
    onUpdate(selectedMethod);
    dispatch(resetModal('Anticonceptivos'));
  };

  return (
    <SubModalForm title="Anticonceptivos" id="Anticonceptivos" saveModal={handleSubmit}>
      <CheckboxInput
        name="metodo_anticonceptivo"
        options={[
          { value: 'ninguno', label: 'Ninguno' },
          { value: 'anillo_vaginal', label: 'Anillo vaginal' },
          {
            value: 'anticonceptivo_emergencia',
            label: 'Anticonceptivo de emergencia',
          },
          { value: 'anticonceptivo_oral', label: 'Anticonceptivo oral' },
          { value: 'condon', label: 'Condón' },
          {
            value: 'diafragma_cupula_vaginal',
            label: 'Diafragma o cúpula vaginal',
          },
          {
            value: 'dispositivo_intrauterino',
            label: 'Dispositivo intrauterino',
          },
          { value: 'esponja', label: 'Esponja' },
          {
            value: 'esterilizacion_femenina',
            label: 'Esterilización femenina',
          },
          {
            value: 'esterilizacion_masculina',
            label: 'Esterilización masculina',
          },
          { value: 'implante', label: 'Implante' },
          { value: 'inyectable', label: 'Inyectable' },
          {
            value: 'metodos_basados_fertilidad',
            label: 'Métodos basados en el conocimiento de la fertilidad',
          },
        ]}
        allowMultiple={false}
        distribution="vertical"
        setValue={handleMethodSelect}
      />
    </SubModalForm>
  );
};

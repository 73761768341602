/* eslint-disable */

import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import { SelectInput } from '@components/FormElemntsLibrary/SelectInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import { ModalForm } from '@components/modals/ModalForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { actualizarCampo } from '@actions/infoUser/actions';
import { resetModal, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import {
  datosUbicacionInitial,
  IDatosUbicacion,
} from '@containers/TabsPaciente/PerfilPaciente/DatosDeUbicacion/types';
import { RootState } from '@reducer/index';
import {
  getCatalogoAgea,
  getCatalogoAgee,
  getCatalogoAgel,
  getCatalogoAgem,
} from '@utils/getCatalogos';
import {
  updateDatosUbicacion,
  updateDatosUbicacionPaciente,
  updateDatosUbicacionUsuario,
} from '@utils/sendInfo';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CatalogosState, initialCatalogosState, initialShowInput } from './type';
import { ICatStrEstandar } from '@common/types';

const schema = z.object({
  loaded: z.boolean(),
  territorioOriginario: z.string(),
  ageeOriginario: z.string(),
  agemOriginario: z.string(),
  agelOriginario: z.string(),
  territorioResidente: z.string(),
  ageeResidente: z.string(),
  agemResidente: z.string(),
  agelResidente: z.string(),
  ageaResidente: z.string(),
  calle: z.string(),
  num1: z.string(),
  num2: z.string(),
  cp: z.string(),
  referencias: z.string(),
  comentario: z.string(),
  error: z.boolean(),
});

type FormData = z.infer<typeof schema>;

export const Ubication = () => {
  const [formData, setFormData] = useState<FormData>(datosUbicacionInitial);
  const [catalogosState, setCatalogosState] = useState<CatalogosState>(initialCatalogosState);
  const [showInput, setShowInput] = useState(initialShowInput);

  const {
    idMedico,
    idUsuario: idMiUsuario,
    pais,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { datosUbicacion, cambiosDatosUbicacion, datosUbicacionUsuario } = useSelector(
    (state: RootState) => state.InfoUser,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (datosUbicacion.territorioOriginario === t(`gentilicio_${pais}`)) {
        try {
          const ageeResult: ICatStrEstandar[] = await getCatalogoAgee(idMedico);
          setCatalogosState((prev) => ({
            ...prev,
            catAgeeOriginario: ageeResult,
          }));
          setFormData({ ...datosUbicacion, ageeOriginario: datosUbicacion.ageeOriginario });

          const agemResult: ICatStrEstandar[] = await getCatalogoAgem(datosUbicacion.ageeOriginario);
          setCatalogosState((prev) => ({
            ...prev,
            catAgemOriginario: agemResult,
          }));
          setFormData({ ...datosUbicacion, agemOriginario: datosUbicacion.agemOriginario });

          const agelResult: ICatStrEstandar[] = await getCatalogoAgel(
            datosUbicacion.ageeOriginario,
            datosUbicacion.agemOriginario,
          );
          setCatalogosState((prev) => ({
            ...prev,
            catAgelOriginario: agelResult,
          }));
          setFormData({ ...datosUbicacion, agelOriginario: datosUbicacion.agelOriginario });

          setShowInput((prev) => ({
            ...prev,
            ageeOrigen: true,
            agemOrigen: true,
            agelOrigen: true,
          }));
        } catch (error) {
          console.error('Error fetching data for origin territory', error);
        }
      }

      if (datosUbicacion.territorioResidente === t(`gentilicio_${pais}`)) {
        try {
          const ageeResult: ICatStrEstandar[] = await getCatalogoAgee(idMedico);
          setCatalogosState((prev) => ({
            ...prev,
            catAgeeResidente: ageeResult,
          }));
          setFormData({ ...datosUbicacion, ageeResidente: datosUbicacion.ageeResidente });

          const agemResult: ICatStrEstandar[] = await getCatalogoAgem(datosUbicacion.ageeResidente);
          setCatalogosState((prev) => ({
            ...prev,
            catAgemResidente: agemResult,
          }));
          setFormData({ ...datosUbicacion, agemResidente: datosUbicacion.agemResidente });

          const agelResult: ICatStrEstandar[] = await getCatalogoAgel(
            datosUbicacion.ageeResidente,
            datosUbicacion.agemResidente,
          );
          setCatalogosState((prev) => ({
            ...prev,
            catAgelResidente: agelResult,
          }));
          setFormData({ ...datosUbicacion, agelResidente: datosUbicacion.agelResidente });

          const ageaResult: ICatStrEstandar[] = await getCatalogoAgea(
            datosUbicacion.ageeResidente,
            datosUbicacion.agemResidente,
            datosUbicacion.agelResidente,
          );
          setCatalogosState((prev) => ({
            ...prev,
            catAgeaResidente: ageaResult,
          }));
          setFormData({ ...datosUbicacion, ageaResidente: datosUbicacion.ageaResidente });

          setShowInput((prev) => ({
            ...prev,
            ageeResidente: true,
            agemResidente: true,
            agelResidente: true,
            ageaResidente: true,
          }));
        } catch (error) {
          console.error('Error fetching data for resident territory', error);
        }
      }

      setFormData({ ...datosUbicacion, error: false });
    };

    fetchData();
  }, [datosUbicacion]);

  useEffect(() => {
    reset(formData);
  }, [formData]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });
  const hayCambios: boolean =
    idUsuario > 0 && cambiosDatosUbicacion.filter((c: string) => c.length > 0).length > 0;

  const handleInputChange = (e: { name: string; value: any }) => {
    const { name, value } = e;
    let newValue = value;
    if (value.id) {
      newValue = value.id;
    }

    if (name == 'territorioOriginario') {
      if (value == t(`gentilicio_${pais}`)) {
        getCatalogoAgee(idMedico).then((result: ICatStrEstandar[]) => {
          setCatalogosState((prev) => ({
            ...prev,
            catAgeeOriginario: result,
          }));
        });
        setShowInput((prev) => ({ ...prev, ageeOrigen: true }));
      } else {
        setFormData((prev) => ({
          ...prev,
          ageeOriginario: '',
          agelOriginario: '',
          agemOriginario: '',
        }));
        setShowInput((prev) => ({
          ...prev,
          ageeOrigen: false,
          agemOrigen: false,
          agelOrigen: false,
        }));
      }
    }
    if (name == 'ageeOriginario') {
      getCatalogoAgem(newValue).then((result: ICatStrEstandar[]) => {
        setCatalogosState((prev) => ({
          ...prev,
          catAgemOriginario: result,
        }));
      });
      setShowInput((prev) => ({ ...prev, agemOrigen: true }));
    }
    if (name == 'agemOriginario') {
      getCatalogoAgel(formData.ageeOriginario, newValue).then((result: ICatStrEstandar[]) => {
        setCatalogosState((prev) => ({
          ...prev,
          catAgelOriginario: result,
        }));
      });
      setShowInput((prev) => ({ ...prev, agelOrigen: true }));
    }

    if (name == 'territorioResidente') {
      if (value == t(`gentilicio_${pais}`)) {
        getCatalogoAgee(idMedico).then((result: ICatStrEstandar[]) => {
          setCatalogosState((prev) => ({
            ...prev,
            catAgeeResidente: result,
          }));
        });
        setShowInput((prev) => ({ ...prev, ageeResidente: true }));
      } else {
        setFormData((prev) => ({
          ...prev,
          ageeResidente: '',
          agelResidente: '',
          agemResidente: '',
          ageaResidente: '',
        }));
        setShowInput((prev) => ({
          ...prev,
          ageeResidente: false,
          agemResidente: false,
          agelResidente: false,
          ageaResidente: false,
        }));
      }
    }
    if (name == 'ageeResidente') {
      getCatalogoAgem(newValue).then((result: ICatStrEstandar[]) => {
        setCatalogosState((prev) => ({
          ...prev,
          catAgemResidente: result,
        }));
      });
      setShowInput((prev) => ({ ...prev, agemResidente: true }));
    }
    if (name == 'agemResidente') {
      getCatalogoAgel(formData.ageeResidente, newValue).then((result: ICatStrEstandar[]) => {
        setCatalogosState((prev) => ({
          ...prev,
          catAgelResidente: result,
        }));
      });
      setShowInput((prev) => ({ ...prev, agelResidente: true }));
    }
    if (name == 'agelResidente') {
      getCatalogoAgea(formData.ageeResidente, formData.agemResidente, newValue).then(
        (result: ICatStrEstandar[]) => {
          setCatalogosState((prev) => ({
            ...prev,
            catAgeaResidente: result,
          }));
        },
      );
      setShowInput((prev) => ({ ...prev, ageaResidente: true }));
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const getData = (datos: IDatosUbicacion, idUser?: number) => ({
    idMedico,
    idPaciente,
    idUsuario: idUser,
    idConsultorio,
    territorioOriginario: datos.territorioOriginario,
    ageeOriginario: datos.ageeOriginario ? datos.ageeOriginario : '',
    agemOriginario: datos.agemOriginario ? datos.agemOriginario : '',
    agelOriginario: datos.agelOriginario ? datos.agelOriginario : '',
    territorioResidente: datos.territorioResidente,
    ageeResidente: datos.ageeResidente ? datos.ageeResidente : '',
    agemResidente: datos.agemResidente ? datos.agemResidente : '',
    agelResidente: datos.agelResidente ? datos.agelResidente : '',
    ageaResidente: datos.ageaResidente ? datos.ageaResidente : '',
    calle: datos.calle,
    num1: datos.num1,
    num2: datos.num2,
    cp: datos.cp,
    referencias: datos.referencias,
    comentario: datos.comentario,
  });

  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosUbicacionUsuario(getData(formData, idMiUsuario)),
        successFunction: () => {
          dispatch(actualizarCampo('datosUbicacion', { ...formData, loaded: true }));
          dispatch(resetModal('Ubication'));
          dispatch(setActiveModal('Discapacidades'));
        },
      }),
    );
  };

  const guardarDatosExpediente = () => {
    const dataUbicacion = getData(formData, idUsuario);
    const sendFunctions = [updateDatosUbicacion(dataUbicacion)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(updateDatosUbicacionPaciente(dataUbicacion));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => dispatch(actualizarCampo('datosUbicacion', { ...formData, loaded: true })),
          () => dispatch(actualizarCampo('datosUbicacionUsuario', { ...formData, loaded: true })),
          () => dispatch(resetModal('Ubication')),
          () => dispatch(setActiveModal('Discapacidades')),
        ],
      }),
    );
  };

  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosUbicacion(getData(datosUbicacionUsuario)),
        successFunction: () => {
          dispatch(actualizarCampo('datosUbicacion', { ...formData, loaded: true })),
            setFormData(datosUbicacionUsuario);
        },
      }),
    );
  };

  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosUbicacionPaciente(getData(datosUbicacion, idUsuario)),
        successFunction: () => dispatch(actualizarCampo('datosUbicacionUsuario', datosUbicacion)),
      }),
    );
  };

  const handleSubmitForm = () => {
    try {
      if (esPaciente) {
        guardarDatosUsuario();
      } else {
        guardarDatosExpediente();
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  return (
    <ModalForm
      currentStep={2}
      title="Ubicación"
      id="Ubication"
      submitForm={handleSubmit(handleSubmitForm)}
      hayCambios={hayCambios}
      aceptarCambios={guardarDatosDeUsuarioAExpediente}
      rechazarCambios={guardarDatosDeExpedienteAUsuario}
    >
      <div className="w-full ">
        {/* Sección Lugar de Origen */}
        <div>
          <h2 className="px-4 text-lg font-medium mb-2">Originario de:*</h2>

          <div className="">
            <div className="w-full">
              <CheckboxInput
                name="territorioOriginario"
                label="Territorio"
                distribution="vertical-flex"
                options={[
                  { value: t(`gentilicio_${pais}`), label: 'Mexicano' },
                  { value: 'extranjero', label: 'Extranjero' },
                  { value: 'desconocido', label: 'Desconocido' },
                ]}
                isRequired
                value={formData.territorioOriginario}
                setValue={handleInputChange}
                errorMessage={errors.territorioOriginario?.message}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-0">
              <SelectInput
                label="Entidad federativa"
                name="ageeOriginario"
                isRequired
                options={catalogosState.catAgeeOriginario}
                value={formData.ageeOriginario}
                setValue={handleInputChange}
                isDisabled={showInput.ageeOrigen}
                errorMessage={errors.ageeOriginario?.message}
              />
              <SelectInput
                label="Municipio/Delegación"
                name="agemOriginario"
                options={catalogosState.catAgemOriginario}
                value={formData.agemOriginario}
                setValue={handleInputChange}
                isDisabled={showInput.agemOrigen}
                errorMessage={errors.agemOriginario?.message}
              />
              <SelectInput
                label="Localidad"
                name="agelOriginario"
                options={catalogosState.catAgelOriginario}
                value={formData.agelOriginario}
                setValue={handleInputChange}
                isDisabled={showInput.agelOrigen}
                errorMessage={errors.agelOriginario?.message}
              />
            </div>
          </div>
        </div>

        {/* Sección Residencia Actual */}
        <div>
          <h2 className="px-4 text-lg font-medium mb-2">Reside en:*</h2>

          <div className="">
            <div className="w-full">
              <CheckboxInput
                name="territorioResidente"
                label="Territorio"
                distribution="vertical-flex"
                options={[
                  { value: t(`gentilicio_${pais}`), label: 'Mexicano' },
                  { value: 'extranjero', label: 'Extranjero' },
                  { value: 'desconocido', label: 'Desconocido' },
                ]}
                isRequired
                value={formData.territorioResidente}
                setValue={handleInputChange}
                errorMessage={errors.territorioResidente?.message}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-0">
              <SelectInput
                label="Entidad federativa"
                name="ageeResidente"
                isRequired
                options={catalogosState.catAgeeResidente}
                value={formData.ageeResidente}
                setValue={handleInputChange}
                isDisabled={showInput.ageeResidente}
                errorMessage={errors.ageeResidente?.message}
              />
              <SelectInput
                label="Municipio/Delegación"
                name="agemResidente"
                options={catalogosState.catAgemResidente}
                value={formData.agemResidente}
                setValue={handleInputChange}
                isDisabled={showInput.agemResidente}
                errorMessage={errors.agemResidente?.message}
              />
              <SelectInput
                label="Localidad"
                name="agelResidente"
                options={catalogosState.catAgelResidente}
                value={formData.agelResidente}
                setValue={handleInputChange}
                isDisabled={showInput.agelResidente}
                errorMessage={errors.agelResidente?.message}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-0">
              <SelectInput
                label="Colonia"
                name="ageaResidente"
                options={catalogosState.catAgeaResidente}
                value={formData.ageaResidente}
                setValue={handleInputChange}
                isDisabled={showInput.ageaResidente}
                errorMessage={errors.ageaResidente?.message}
              />
              <TextInput
                label="Calle"
                name="calle"
                value={formData.calle}
                setValue={handleInputChange}
                errorMessage={errors.calle?.message}
              />
            </div>

            <div className="grid grid-cols-3">
              <TextInput
                label="Interior"
                name="num2"
                value={formData.num2}
                setValue={handleInputChange}
                labelAligmet="border"
                errorMessage={errors.num2?.message}
              />
              <TextInput
                label="Exterior"
                name="num1"
                value={formData.num1}
                setValue={handleInputChange}
                labelAligmet="border"
                errorMessage={errors.num1?.message}
              />
              <TextInput
                label="C.P"
                name="cp"
                value={formData.cp}
                setValue={handleInputChange}
                labelAligmet="border"
                errorMessage={errors.cp?.message}
              />
            </div>
          </div>
        </div>

        {/* Sección Información Adicional */}
        <div>
          <div className="flex flex-col">
            <TextArea
              label="Referencias"
              name="referencias"
              maxLength={100}
              value={formData.referencias}
              setValue={handleInputChange}
              errorMessage={errors.referencias?.message}
            />
            <TextArea
              label="Comentario"
              name="comentario"
              maxLength={300}
              value={formData.comentario}
              setValue={handleInputChange}
              errorMessage={errors.comentario?.message}
            />
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default Ubication;

import { ICatalogosEstandar } from '@common/types';

export interface Discapacidad {
  tipoId: string;
  tipo: string;
  gradoId: string;
  grado: string;
  origenId: string;
  origen: string;
}
export interface Discapacidades {
  loaded: boolean;
  arrayDiscapacidades: Discapacidad[];
  sinDiscapacidad: boolean;
  certificado: '' | 'si' | 'no';
  comentarios: string;
}

export interface CatDiscapacidades {
  tipo: ICatalogosEstandar[];
  grado: ICatalogosEstandar[];
  origen: ICatalogosEstandar[];
}

export const catDiscapacidadesInitial: CatDiscapacidades = {
  tipo: [],
  grado: [],
  origen: [],
};

export const initialShowInput = [
  {
    tipo: true,
    grado: false,
    origen: false,
  },
];

/* eslint-disable */

import { resetModal, setActiveModal } from '@actions/modal/actions';
import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import { Step } from '@components/FormProgressSteps/type';
import { ModalForm } from '@components/modals/ModalForm';
import { GENDER_FORM_CONFIG } from '@constants/prueba/genderFormConfig';
import { initialMaleForm } from '@constants/prueba/initialForms';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import { MaleHealthForm } from './type';

const optionValueSchema = z.enum(['Si', 'No', '']);

const schema = z.object({
  asma: optionValueSchema,
  enfermedades_respiratorias: optionValueSchema,
  piedras_rinones: optionValueSchema,
  problemas_vision: optionValueSchema,
  usa_lentes: optionValueSchema,
  cirugia_abdominal: optionValueSchema,
  cirugia_columna: optionValueSchema,
  hernia_abdominal: optionValueSchema,
  problemas_testiculos: optionValueSchema,
});

type FormData = z.infer<typeof schema>;

export const MaleHealth: FC = () => {
  const [formData, setFormData] = useState<FormData>(initialMaleForm);
  const dispatch = useDispatch();

  const { checkboxGroups } = GENDER_FORM_CONFIG;

  useEffect(() => {
    reset(formData);
  }, [formData]);

  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });

  const steps: Step[] = [
    {
      title: 'Enfermedades del corazón y crónicas',
      status: 'completed',
    },
    {
      title: 'Problemas nerviosos y dolor',
      status: 'completed',
    },
    {
      title: 'Problemas respiratorios y corporales',
      status: 'current',
    },
  ];

  const handleInputChange = (name: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const guardarDatosUsuario = () => { };

  const handleSubmitForm = async () => {
    try {
      guardarDatosUsuario();

      dispatch(resetModal('MaleHealth'));
      dispatch(setActiveModal('ActividadFisica'));
    } catch (error) {
      console.error('Error en el envío del formulario:', error);
    }
  };

  return (
    <ModalForm
      title="Mi Salud"
      progressSteps={steps}
      id="Gender"
      submitForm={handleSubmit(handleSubmitForm)}
      hayCambios={false}
      aceptarCambios={() => { }}
      rechazarCambios={() => { }}
    >
      {checkboxGroups.male.fields.map((field, index) => (
        <CheckboxInput
          key={field.id}
          name={field.name}
          label={field.label}
          options={field.options}
          distribution="horizontal-inline"
          numberPosition={index}
          value={formData[field.name as keyof MaleHealthForm]}
          setValue={(e) => handleInputChange(field.name, e.value)}
          errorMessage={errors[field.name as keyof typeof errors]?.message}
        />
      ))}
    </ModalForm>
  );
};

/* eslint-disable */

import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { SubModalForm } from '@components/modals/SubModalForm';
import { hipertensionFormData } from '@constants/prueba/hipertensionFormData';
import { initialHipertensionForm } from '@constants/prueba/initialForms';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { resetModal } from '@actions/modal/actions';
import { useDispatch } from 'react-redux';
import { HipertensionField, HipertensionProps } from './type';

const schema = z.object({
  seguimientoTratamiento: z.enum(['siempre', 'casiSiempre', 'aVeces', 'raraVez', 'nunca', '']),
  seguimientoConsultas: z.enum(['siempre', 'casiSiempre', 'aVeces', 'raraVez', 'nunca', '']),
  planAlimentacion: z.enum(['siempre', 'casiSiempre', 'aVeces', 'raraVez', 'nunca', '']),
  mediPresion: z
    .string()
    .or(z.number())
    .transform((val) => val.toString()),
  medicamentosHipertension: z.string(),
});

type FormData = z.infer<typeof schema>;

export const Hipertension: FC<HipertensionProps> = ({
  formDataSubModals,
  setFormDataSubModals,
}) => {
  const [formData, setFormData] = useState<FormData>(
    formDataSubModals.hipertension ?? initialHipertensionForm,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    reset(formData);
  }, [formData]);

  const {
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });

  const handleInputChange = (e: { name: string; value: any }) => {
    const { name, value } = e;

    if (name === 'mediPresion') {
      setFormData((prev) => ({
        ...prev,
        [name]: value.toString(),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const saveModal = async () => {
    try {
      const validatedData = schema.parse(formData);

      setFormDataSubModals({
        ...formDataSubModals,
        hipertension: validatedData,
      });
      dispatch(resetModal('Hipertension'));
    } catch (error) {
      console.error('Error en el envío del formulario:', error);
    }
  };

  const renderField = (field: HipertensionField) => {
    switch (field.type) {
      case 'checkbox':
        return (
          <CheckboxInput
            name={field.name}
            label={field.label}
            options={field.options}
            distribution="vertical"
            value={formData[field.name]}
            setValue={handleInputChange}
            errorMessage={errors[field.name]?.message}
          />
        );

      case 'number':
        return (
          <NumberInput
            name={field.name}
            label={field.label}
            fixedValue={field.fixedValue}
            value={Number(formData[field.name])}
            setValue={handleInputChange}
            errorMessage={errors[field.name]?.message}
          />
        );

      case 'textarea':
        return (
          <TextArea
            name={field.name}
            label={field.label}
            placeHolder={field.placeholder}
            isRequired={field.required}
            maxLength={field.maxLength}
            value={formData[field.name]}
            setValue={handleInputChange}
            errorMessage={errors[field.name]?.message}
          />
        );
      default:
        return null;
    }
  };

  return (
    <SubModalForm title={hipertensionFormData.title} id="Hipertension" saveModal={saveModal}>
      {hipertensionFormData.fields.map((field, i) => (
        <div key={`hipertension-field-${field.name}-${i}`} className="flex flex-col">
          {renderField(field as HipertensionField)}
        </div>
      ))}
    </SubModalForm>
  );
};

export default Hipertension;


export interface IDatosUbicacionNombres {
  ageeOriginario: string;
  agemOriginario: string;
  agelOriginario: string;
  ageeResidente: string;
  agemResidente: string;
  agelResidente: string;
  ageaResidente: string;
}

export const datosUbicacionNombresInitial: IDatosUbicacionNombres = {
  ageeOriginario: '',
  agemOriginario: '',
  agelOriginario: '',
  ageeResidente: '',
  agemResidente: '',
  agelResidente: '',
  ageaResidente: '',
};

export interface IDatosUbicacion {
  loaded: boolean;
  territorioOriginario: string;
  ageeOriginario: string;
  agemOriginario: string;
  agelOriginario: string;
  territorioResidente: string;
  ageeResidente: string;
  agemResidente: string;
  agelResidente: string;
  ageaResidente: string;
  calle: string;
  num1: string;
  num2: string;
  cp: string;
  referencias: string;
  comentario: string;
  error: boolean;
}

export const datosUbicacionInitial: IDatosUbicacion = {
  loaded: false,
  territorioOriginario: '',
  ageeOriginario: '',
  agemOriginario: '',
  agelOriginario: '',
  territorioResidente: '',
  ageeResidente: '',
  agemResidente: '',
  agelResidente: '',
  ageaResidente: '',
  calle: '',
  num1: '',
  num2: '',
  cp: '',
  referencias: '',
  comentario: '',
  error: false,
};

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  TextField,
  FormControlLabel,
  Radio,
  InputAdornment,
  RadioGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
/** importancion de las fechas para la fecha de nacimiento */
import {
  constDia, constMes, constAnio, constAnioGestacion,
} from '@constants/fechas';
import { validarFecha, validarFechaEsAnterior, calcularSemanasGestacion } from '@common/functions';
import { IFechaField } from '@common/types';
import { validacionSoloNumeros } from '@constants/validaciones';
import useStyles from './styles';
import { IGeneralidadesProps } from './types';

function Generalidades(props: IGeneralidadesProps) {
  const {
    hayCambios, ginecoObstetrico, setGinecoObstetrico,
  } = props;
  const classes = useStyles();
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [anyoFur, setAnyoFur] = useState<number[]>(constAnio);
  const [semanasGestacion, setSemanasGestacion] = useState<number>(0);
  const [diasGestacion, setDiasGestacion] = useState<number>(0);
  const [fechaField, setFechaField] = useState<IFechaField>({
    error: false,
    helperText: '',
  });
  const [mostrarEdadGestacional, setMostrarEdadGestacional] = useState<boolean>(false);
  const [errorEdadGestacional, setErrorEdadGestacional] = useState<boolean>(false);
  const handleChangeMenarquia = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        generalidades: {
          ...ginecoObstetrico.generalidades,
          menarquia: val.toString(),
        },
      });
    }
  };
  const handleChangeMenarquiaNA = (event: React.ChangeEvent<HTMLInputElement>) => {
    const check = event.target.checked as boolean;
    if (check) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        generalidades: {
          ...ginecoObstetrico.generalidades,
          menarquia: '',
          menarquiaNA: check,
        },
      });
    } else {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        generalidades: {
          ...ginecoObstetrico.generalidades,
          menarquiaNA: check,
        },
      });
    }
  };
  const handleChangeEnGestacion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as 'si' | 'no';
    setGinecoObstetrico(prev => ({
      ...prev,
      generalidades: {
        ...prev.generalidades,
        enGestacion: val,
        menopausiaNA: val === 'si' ? true : prev.generalidades.menopausiaNA,
      }
    }))
    if (val === 'no') {
      setMostrarEdadGestacional(false);
      setAnyoFur(constAnio);
    } else {
      setAnyoFur(constAnioGestacion);
    }
  };
  const handleChangeDia = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      generalidades: {
        ...ginecoObstetrico.generalidades,
        fur: {
          ...ginecoObstetrico.generalidades.fur,
          dia: event.target.value as string,
        },
      },
    });
  };
  const handleChangeMes = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      generalidades: {
        ...ginecoObstetrico.generalidades,
        fur: {
          ...ginecoObstetrico.generalidades.fur,
          mes: event.target.value as string,
        },
      },
    });
  };
  const handleChangeAnio = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      generalidades: {
        ...ginecoObstetrico.generalidades,
        fur: {
          ...ginecoObstetrico.generalidades.fur,
          anio: event.target.value as string,
        },
      },
    });
  };
  const handleChangeLactancia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (val === 'si') {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        generalidades: {
          ...ginecoObstetrico.generalidades,
          lactancia: val,
          menopausiaNA: true,
        },
      });
    } else {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        generalidades: {
          ...ginecoObstetrico.generalidades,
          lactancia: val,
        },
      });
    }
  };
  const handleChangeMenopausia = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        generalidades: {
          ...ginecoObstetrico.generalidades,
          menopausia: val.toString(),
        },
      });
    }
  };
  const handleChangeMenopausiaNA = (event: React.ChangeEvent<HTMLInputElement>) => {
    const check = event.target.checked as boolean;
    if (check) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        generalidades: {
          ...ginecoObstetrico.generalidades,
          menopausia: '',
          menopausiaNA: check,
        },
      });
    } else {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        generalidades: {
          ...ginecoObstetrico.generalidades,
          menopausiaNA: check,
        },
      });
    }
  };
  const handleChangeComentario = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      generalidades: {
        ...ginecoObstetrico.generalidades,
        comentarios: event.target.value as string,
      },
    });
  };
  useEffect(() => {
    if (
      ginecoObstetrico.generalidades.fur.dia !== '' &&
      ginecoObstetrico.generalidades.fur.mes !== '' &&
      ginecoObstetrico.generalidades.fur.anio !== ''
    ) {
      if (
        !validarFecha(
          ginecoObstetrico.generalidades.fur.anio,
          ginecoObstetrico.generalidades.fur.mes,
          ginecoObstetrico.generalidades.fur.dia,
        )
      ) {
        setFechaField({
          error: true,
          helperText: t('fecha_invalida'),
        });
      } else if (
        !validarFechaEsAnterior(
          ginecoObstetrico.generalidades.fur.anio,
          ginecoObstetrico.generalidades.fur.mes,
          ginecoObstetrico.generalidades.fur.dia,
        )
      ) {
        setFechaField({
          error: true,
          helperText: t('fecha_invalida'),
        });
      } else {
        setFechaField({
          error: false,
          helperText: '',
        });
      }
    }
  }, [ginecoObstetrico.generalidades.fur]);
  useEffect(() => {
    if (
      ginecoObstetrico.generalidades.enGestacion === 'si' &&
      ginecoObstetrico.generalidades.fur.anio &&
      ginecoObstetrico.generalidades.fur.mes &&
      ginecoObstetrico.generalidades.fur.dia
    ) {
      const objSemanasGestacion = calcularSemanasGestacion(
        ginecoObstetrico.generalidades.fur.anio,
        ginecoObstetrico.generalidades.fur.mes,
        ginecoObstetrico.generalidades.fur.dia,
      );
      if (!objSemanasGestacion.error) {
        setSemanasGestacion(objSemanasGestacion.semanas || 0);
        setDiasGestacion(objSemanasGestacion.dias || 0);
        setMostrarEdadGestacional(true);
        if ((objSemanasGestacion.semanas || 0) > 43) {
          setErrorEdadGestacional(true);
        } else {
          setErrorEdadGestacional(false);
        }
        setAnyoFur(constAnioGestacion);
        return;
      }
    }
    setMostrarEdadGestacional(false);
    setErrorEdadGestacional(false);
  }, [ginecoObstetrico.generalidades.enGestacion, ginecoObstetrico.generalidades.fur]);

  return (
    <>
      <h2 className="text-blue-800 font-normal m-0">{t('generalidades')}</h2>
      <div className="grid grid-cols-4 gap-x-6 mb-4">
        <h3 className="mt-4 mb-2 text-gray-600 font-medium">
          {t('menarquia(edad)')}
        </h3>
        <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('en_gestacion')}</h3>
        <h3 className="mt-4 mb-2 text-gray-600 font-medium">
          {t('fur')}
        </h3>
        <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('lactancia')}</h3>
        <div className="flex items-center">
          <TextField
            variant="outlined"
            value={ginecoObstetrico.generalidades.menarquia}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('anios')}</InputAdornment>,
              inputProps: {
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                maxLength: '2',
              },
            }}
            onChange={handleChangeMenarquia}
            disabled={hayCambios || ginecoObstetrico.generalidades.menarquiaNA}
          />
          <div className="ml-4">
            <FormControlLabel
              control={(
                <Checkbox
                  color="primary"
                  checked={ginecoObstetrico.generalidades.menarquiaNA}
                  onChange={handleChangeMenarquiaNA}
                />
              )}
              label={t('n/a')}
              disabled={hayCambios}
            />
          </div>
        </div>
        <RadioGroup
          row
          name="en_gestacion"
          value={ginecoObstetrico.generalidades.enGestacion}
          onChange={handleChangeEnGestacion}
        >
          <FormControlLabel
            value="si"
            control={<Radio color="primary" />}
            label={t('si')}
            labelPlacement="end"
            disabled={hayCambios}
          />
          <FormControlLabel
            value="no"
            control={<Radio color="primary" />}
            label={t('no')}
            labelPlacement="end"
            disabled={hayCambios}
          />
        </RadioGroup>
        <div className="w-full grid grid-cols-3 gap-2">
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label-dia">{t('dia')}</InputLabel>
            <Select
              labelId="label-dia"
              id="select-dia"
              value={ginecoObstetrico.generalidades.fur.dia}
              onChange={handleChangeDia}
              label={t('dia')}
              error={fechaField.error}
              disabled={hayCambios}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 375,
                  },
                },
              }}
            >
              {constDia.map((diaSingle) => (
                <MenuItem value={diaSingle.toString()} key={diaSingle}>
                  {diaSingle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label-mes">{t('mes')}</InputLabel>
            <Select
              labelId="label-mes"
              id="select-mes"
              value={ginecoObstetrico.generalidades.fur.mes}
              onChange={handleChangeMes}
              label={t('mes')}
              error={fechaField.error}
              disabled={hayCambios}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 375,
                  },
                },
              }}
            >
              {constMes.map((mesSingle, indx) => (
                <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                  {mesSingle.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className={classes.absoluteBottom}>
              {fechaField.helperText}
            </FormHelperText>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label-año">{t('anio')}</InputLabel>
            <Select
              labelId="label-año"
              id="select-año"
              value={ginecoObstetrico.generalidades.fur.anio}
              onChange={handleChangeAnio}
              label={t('anio')}
              error={fechaField.error}
              disabled={hayCambios}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 375,
                  },
                },
              }}
            >
              {anyoFur.map((anioSingle) => (
                <MenuItem key={anioSingle} value={anioSingle.toString()}>
                  {anioSingle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <RadioGroup
          row
          name="lactancia"
          value={ginecoObstetrico.generalidades.lactancia}
          onChange={handleChangeLactancia}
        >
          <FormControlLabel
            value="si"
            control={<Radio color="primary" />}
            label={t('si')}
            labelPlacement="end"
            disabled={hayCambios}
          />
          <FormControlLabel
            value="no"
            control={<Radio color="primary" />}
            label={t('no')}
            labelPlacement="end"
            disabled={hayCambios}
          />
        </RadioGroup>
        {mostrarEdadGestacional && (
          <>
            <div />
            <div />
            <div className="col-span-2 mt-2 font-bold">
              {`${t('edad_gestacional')}: ${semanasGestacion} ${t(
                'semanas_y_',
              )} ${diasGestacion} ${t('dias_')}.`}
            </div>
            {errorEdadGestacional && (
              <>
                <div />
                <div />
                <div className="col-span-2 text-red-500">
                  {t('la_edad_gestacional_parece_superar_la_duracion_esperada')}
                </div>
              </>
            )}
          </>
        )}
        <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('menopausia(edad)')}</h3>
        <div />
        <div />
        <div />
        <div className="flex items-center">
          <TextField
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('anios')}</InputAdornment>,
              inputProps: {
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                maxLength: '2',
              },
            }}
            value={ginecoObstetrico.generalidades.menopausia}
            onChange={handleChangeMenopausia}
            disabled={hayCambios || ginecoObstetrico.generalidades.menopausiaNA}
          />
          <div className="ml-4">
            <FormControlLabel
              control={(
                <Checkbox
                  color="primary"
                  checked={ginecoObstetrico.generalidades.menopausiaNA}
                  onChange={handleChangeMenopausiaNA}
                  disabled={hayCambios}
                />
              )}
              label={t('n/a')}
            />
          </div>
        </div>
      </div>
      {!esPaciente && (
        <div className="mb-4">
          <TextField
            variant="outlined"
            rows={4}
            multiline
            fullWidth
            label={t('comentarios')}
            inputProps={{
              maxLength: 500,
              autoComplete: 'off',
              'aria-autocomplete': 'none',
            }}
            value={ginecoObstetrico.generalidades.comentarios}
            onChange={handleChangeComentario}
            disabled={hayCambios}
          />
        </div>
      )}
    </>
  );
}

export default Generalidades;

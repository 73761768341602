import { ICatStrEstandar } from "@common/types";

export interface UbicationForm {
  territorio: 'mexicano' | 'extranjero' | 'desconocido';
  entidadFederativa: string;
  municipioDelegacion?: string;
  localidad?: string;
  resideen: 'mexicano' | 'extranjero' | 'desconocido';
  colonia?: string;
  calle?: string;
  interior?: string;
  exterior?: string;
  cp?: string;
  referencias?: string;
  comentario?: string;
}

export interface CatalogosState {
  catAgeeOriginario: ICatStrEstandar[];
  catAgemOriginario: ICatStrEstandar[];
  catAgelOriginario: ICatStrEstandar[];
  catAgeeResidente: ICatStrEstandar[];
  catAgemResidente: ICatStrEstandar[];
  catAgelResidente: ICatStrEstandar[];
  catAgeaResidente: ICatStrEstandar[];
}

export const initialCatalogosState: CatalogosState = {
  catAgeeOriginario: [],
  catAgemOriginario: [],
  catAgelOriginario: [],
  catAgeeResidente: [],
  catAgemResidente: [],
  catAgelResidente: [],
  catAgeaResidente: [],
};

export const initialShowInput = {
  ageeOrigen: false,
  agemOrigen: false,
  agelOrigen: false,
  ageeResidente: false,
  agemResidente: false,
  agelResidente: false,
  ageaResidente: false,
};

export const handleInputChange = <T extends Record<string, any>>(
  setState: React.Dispatch<React.SetStateAction<T>>,
  data: { name: string; value: any },
) => {
  const { name, value } = data;

  setState((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};

// const handleHigieneChange = (data: { name: string; value: any }) => {
//   if (data.name in higieneTemp) {
//     handleInputChange(setHigieneTemp, data);
//   } else {
//     console.error(`La clave ${data.name} no es válida para higiene.`);
//   }
// };

import store from 'src/store';
import { resetPaciente } from '@actions/basicosPaciente/actions';
import { resetDatosPaciente } from '@actions/paciente/actions';
import { resetConsulta } from '@actions/consulta/actions';
import { resetRutas } from '@actions/rutasExpediente/actions';
import { resetOrganizacion, resetOrganizaciones } from '@actions/organizaciones/actions';
import { resetConsultorio, resetConsultorios } from '@actions/consultorios/actions';
import { resetMe } from '@actions/me/actions';
import { setAuth } from '@actions/auth/actions';

export function limpiarReduxSesion() {
  store.dispatch(resetOrganizaciones());
  store.dispatch(resetOrganizacion());
  store.dispatch(resetConsultorios());
  store.dispatch(resetConsultorio());
  store.dispatch(resetMe());
  store.dispatch(resetPaciente());
  store.dispatch(resetConsulta());
  store.dispatch(resetDatosPaciente());
  store.dispatch(resetRutas());
  store.dispatch(setAuth({ id: '', suscrito: false, acreditado: false }),
  );

}

export function limpiarReduxPaciente() {
  store.dispatch(resetConsulta());
  store.dispatch(resetPaciente());
  store.dispatch(resetDatosPaciente());
  store.dispatch(resetRutas());
}

import { FC } from 'react';
import { ProgresBarProps } from './type';
import { useTranslation } from 'react-i18next';

export const ProgressBar: FC<ProgresBarProps> = ({ percentage }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto px-4 py-2 font-semibold bg-[#EEF6FF]">
      <p className="text-md font-light text-[#6B7280]">{t('avance_llenado')}</p>
  
      <div className="w-full h-2 my-2 rounded-lg bg-[#D5D5D5]">
        <div
          style={{ width: `${percentage}%`, overflow: 'hidden' }}
          className="h-2 mt-2 mb-2 rounded-lg bg-[#1E40AF]"
        />
      </div>
      <div className="flex justify-between">
        <p className="text-md font-light text-[#6B7280]">{percentage === '100' ? '' : `${percentage}%`}</p>
        <p className="text-md font-light text-[#6B7280] left-2">100%</p>
      </div>
    </div>
  );
};

/* eslint-disable */

import { actualizarCampo } from '@actions/infoUser/actions';
import { resetModal, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { Step } from '@components/FormProgressSteps/type';
import { ModalForm } from '@components/modals/ModalForm';
import { HEALTH_FORM_CONFIG } from '@constants/prueba/healthFormConfig';
import { initialMiSaludForm } from '@constants/prueba/initialForms';
import { IDiagnosticoGeneral } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/DiagnosticosGenerales/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { RootState } from '@reducer/index';
import { enfermedadesGuardarDiagnosticosUsuario } from '@utils/sendInfo';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { DiabetesForm, FrequencyOption } from './Diabetes/type';
import { HipertensionForm } from './Hipertension/type';
import { HealthFormField, MiSaludProps, OptionValue } from './type';

const OptionValueSchema = z.union([
  z.literal('Si'),
  z.literal('No'),
  z.literal('siempre'),
  z.literal('casiSiempre'),
  z.literal('aVeces'),
  z.literal('raraVez'),
  z.literal('nunca'),
  z.literal(''),
]);

const schema = z.object({
  problemasCorazon: OptionValueSchema,
  embolia: OptionValueSchema,
  hipertension: OptionValueSchema,
  diabetes: OptionValueSchema,
  problemasRinones: OptionValueSchema,
  hepatitis: OptionValueSchema,
  tumores: OptionValueSchema,
  trastornosInmunes: OptionValueSchema,
  problemasCirculacion: OptionValueSchema,
  enfermedadesRaras: OptionValueSchema,

  contenidoTabla: z.array(z.object({
    id: z.number(),
    idDiagnostico: z.number(),
    categoria: z.enum(['corazon-cronico', 'nervioso-dolor', 'respiratorio-corporal']),
    nombre: z.string(),
    posee: z.enum(['', 'si', 'no']),
    medicamentos: z.array(z.string()),
    noMedicamento: z.boolean(),
    sexo: z.enum(['H', 'M', 'A']),
    control: z.object({
      frecSigueIndicaciones: z.string(),
      frecAcudeConsultas: z.string(),
      frecSigueDieta: z.string(),
      diasUltimaMedicion: z.number().nullable(),
    }).nullable(),
    validado: z.boolean(),
  })),

  // medicamentosProblemasCorazon: z.string().optional(),
  // medicamentosEmbolia: z.string().optional(),
  // medicamentosProblemasRinones: z.string().optional(),
  // medicamentosHepatitis: z.string().optional(),
  // medicamentosTumores: z.string().optional(),
  // medicamentosTrastornosInmunes: z.string().optional(),
  // medicamentosProblemasCirculacion: z.string().optional(),
  // medicamentosEnfermedadesRaras: z.string().optional(),

  // Campos de Hipertensión
  seguimientoTratamiento: OptionValueSchema.optional(),
  seguimientoConsultas: OptionValueSchema.optional(),
  planAlimentacion: OptionValueSchema.optional(),
  mediPresion: z.string(),
  medicamentosHipertension: z.string(),

  // Campos de Diabetes
  seguimientoTratamientoDiabetes: OptionValueSchema.optional(),
  seguimientoConsultasDiabetes: OptionValueSchema.optional(),
  planAlimentacionDiabetes: OptionValueSchema.optional(),
  mediGlucosa: z.string(),
  medicamentosDiabetes: z.string(),
});

type FormData = z.infer<typeof schema>;

export const MiSalud: FC<MiSaludProps> = ({ formDataSubModals, setFormDataSubModals }) => {
  const { checkboxGroups } = HEALTH_FORM_CONFIG;

  const [formData, setFormData] = useState<FormData>(initialMiSaludForm);
  const [visibleSections, setVisibleSections] = useState<Record<string, boolean>>({});

  const { diagnosticosGenerales } = useSelector((state: RootState) => state.InfoUser);
  const { idUsuario } = useSelector((state: RootState) => state.Me);

  useEffect(() => {
    if (formDataSubModals) {
      setFormData((prev) => ({
        ...prev,
        // Datos de hipertensión
        seguimientoTratamiento: formDataSubModals.hipertension.seguimientoTratamiento || '',
        seguimientoConsultas: formDataSubModals.hipertension.seguimientoConsultas || '',
        planAlimentacion: formDataSubModals.hipertension.planAlimentacion || '',
        mediPresion: formDataSubModals.hipertension.mediPresion || '',
        medicamentosHipertension: formDataSubModals.hipertension.medicamentosHipertension || '',
        // Datos de diabetes
        seguimientoTratamientoDiabetes:
          formDataSubModals.diabetes.seguimientoTratamientoDiabetes || '',
        seguimientoConsultasDiabetes: formDataSubModals.diabetes.seguimientoConsultasDiabetes || '',
        planAlimentacionDiabetes: formDataSubModals.diabetes.planAlimentacionDiabetes || '',
        medicamentosDiabetes: formDataSubModals.diabetes.medicamentosDiabetes || '',
      }));
    }
  }, [formDataSubModals]);
  useEffect(() => {
    reset(formData);
  }, [formData]);

  const dispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });

  const handleCheckboxClick = (value: OptionValue, _: any, field: HealthFormField) => {
    setFormData((prev) => ({
      ...prev,
      [field.name]: value,
    }));

    if (field.options?.some((opt) => opt.modal) && value === 'Si') {
      const modalOption = field.options.find((opt) => opt.modal);
      if (modalOption?.modal) {
        if (modalOption.modal === 'Hipertension') {
          const updatedHipertension: HipertensionForm = {
            seguimientoTratamiento: (formData.seguimientoTratamiento as FrequencyOption) || '',
            seguimientoConsultas: (formData.seguimientoConsultas as FrequencyOption) || '',
            planAlimentacion: (formData.planAlimentacion as FrequencyOption) || '',
            mediPresion: formData.mediPresion || '',
            medicamentosHipertension: formData.medicamentosHipertension || '',
          };

          setFormDataSubModals((prev) => ({
            ...prev,
            hipertension: updatedHipertension,
          }));
        } else if (modalOption.modal === 'Diabetes') {
          const updatedDiabetes: DiabetesForm = {
            seguimientoTratamientoDiabetes:
              (formData.seguimientoTratamientoDiabetes as FrequencyOption) || '',
            seguimientoConsultasDiabetes:
              (formData.seguimientoConsultasDiabetes as FrequencyOption) || '',
            planAlimentacionDiabetes: (formData.planAlimentacionDiabetes as FrequencyOption) || '',
            mediPresion: formData.mediPresion || '',
            medicamentosDiabetes: formData.medicamentosDiabetes || '',
          };

          setFormDataSubModals((prev) => ({
            ...prev,
            diabetes: updatedDiabetes,
          }));
        }
        dispatch(setActiveModal(modalOption.modal));
      }
    }

    setVisibleSections((prev) => ({
      ...prev,
      [field.id]: value === 'Si',
    }));
  };

  const steps: Step[] = [
    {
      title: 'Enfermedades del corazón y crónicas',
      status: 'current',
    },
    {
      title: 'Problemas nerviosos y dolor',
      status: 'default',
    },
    {
      title: 'Problemas respiratorios y corporales',
      status: 'default',
    },
  ];

  const handleInputChange = (e: { name: string; value: any }) => {
    const { name, value } = e;

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const guardarDatosUsuario = () => {
    const contenidoTabla = formData.contenidoTabla.map(
      (diagnostico: IDiagnosticoGeneral, index: number) => {
        if (
          diagnostico.posee !== diagnosticosGenerales.contenidoTabla[index].posee ||
          diagnostico.medicamentos !== diagnosticosGenerales.contenidoTabla[index].medicamentos ||
          diagnostico.noMedicamento !== diagnosticosGenerales.contenidoTabla[index].noMedicamento
        ) {
          return {
            ...diagnostico,
            validado: false,
          };
        }
        return diagnostico;
      },
    );
    const newDiagnosticos = { ...formData, contenidoTabla };
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: enfermedadesGuardarDiagnosticosUsuario({
          idUsuario,
          contenidoTabla,
        }),
        successFunction: () => {
          setFormData(newDiagnosticos);
          dispatch(actualizarCampo('newDiagnosticos', newDiagnosticos));

          dispatch(resetModal('MiSalud'));
          dispatch(setActiveModal('NerveProblemsAndPain'));
        },
      }),
    );
  };

  const handleSubmitForm = async () => {
    try {
      const validatedData = await schema.parseAsync(formData);

      guardarDatosUsuario();
      dispatch(resetModal('MiSalud'));
      dispatch(setActiveModal('NerveProblemsAndPain'));
    } catch (error) {
      console.error('Error en el envío del formulario:', error);
    }
  };

  const renderConditionalInputs = (field: HealthFormField) => {
    if (!visibleSections[field.id]) return null;

    if (['pension_enfermedad', 'falta_extremidades', 'incapacidad_prolongada'].includes(field.id)) {
      return null;
    }

    if (field.options.some((opt) => opt.modal)) {
      return null;
    }

    const medicamentosFieldName = `medicamentos_${field.id}`;

    return (
      <div className="mt-4">
        <TextArea
          name={medicamentosFieldName}
          label="¿Qué medicamento usas?"
          placeHolder="Escribe los medicamentos que tomas"
          isRequired={false}
          maxLength={500}
          setValue={handleInputChange}
          disableOption={{
            label: 'No tiene',
            position: 'right',
          }}
          helperText="Por favor solo agregar la lista de medicamentos"
        />
      </div>
    );
  };

  return (
    <ModalForm
      title="Mi salud"
      progressSteps={steps}
      id="MiSalud"
      submitForm={handleSubmit(handleSubmitForm)}
      hayCambios={false}
      aceptarCambios={() => { }}
      rechazarCambios={() => { }}
    >
      {checkboxGroups.conditions.fields.map((field, index) => {
        const typedField: HealthFormField = {
          ...field,
          options: field.options.map((opt) => ({
            ...opt,
            value: opt.value as OptionValue,
          })),
        };

        return (
          <div key={typedField.id}>
            <CheckboxInput
              name={typedField.name}
              label={typedField.label}
              options={typedField.options}
              distribution="horizontal-inline"
              numberPosition={index}
              value={formData[typedField.name as keyof FormData] as OptionValue}
              setValue={handleInputChange}
              onClick={(value) => handleCheckboxClick(value as OptionValue, null, typedField)}
              errorMessage={errors[field.name as keyof typeof errors]?.message}
            />
            {renderConditionalInputs(typedField)}
          </div>
        );
      })}
    </ModalForm>
  );
};

export default MiSalud;

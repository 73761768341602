import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import { ModalForm } from '@components/modals/ModalForm';

const ALCOHOL_CONFIG = [
  { value: 'cerveza_al_5_lata', label: 'Cerveza al 5% (lata/tarro)' },
  { value: 'cerveza_al_5_caguama', label: 'Cerveza al 5% (caguama)' },
  { value: 'pulque_al_6_3_garito', label: 'Pulque al 6.3 (garito)' },
  { value: 'ron_al_30_vaso_47ml', label: 'Ron al 30% (vaso de 47 ml)' },
  { value: 'ron_al_40_botella_750ml', label: 'Ron al 40% (botella de 750 ml)' },
  { value: 'ron_al_40_botella_980ml', label: 'Ron al 40% (botella de 980 ml)' },
  { value: 'vino_al_12_copa', label: 'Vino al 12% (copa)' },
  {
    value: 'vino_al_12_botella_750ml',
    label: 'Vino al 12% (botella de 750 ml)',
  },
  {
    value: 'coctel_destilado_al_45_47ml',
    label: 'Coctel con destilado al 45% (47 ml)',
  },
  {
    value: 'mezcal_tequila_caballito_43_5ml',
    label: 'Mezcal/tequila (caballito de 43.5 ml)',
  },
  {
    value: 'mezcal_tequila_al_30_botella_950ml',
    label: 'Mezcal/tequila al 30% (botella de 950 ml)',
  },
  { value: 'whisky_al_40_vaso_47ml', label: 'Whisky al 40% (vaso de 47 ml)' },
  {
    value: 'whisky_al_40_botella_750ml',
    label: 'Whisky al 40% (botella 750 ml)',
  },
  { value: 'vodka_al_40_vaso_47ml', label: 'Vodka al 40% (vaso de 47 ml)' },
  {
    value: 'vodka_al_40_botella_150ml',
    label: 'Vodka al 40% (botella 150 ml)',
  },
  {
    value: 'vodka_al_40_botella_750ml',
    label: 'Vodka al 40% (botella 750 ml)',
  },
  { value: 'otro_trago_estandar', label: 'Otro (trago estándar)' },
];

export const TipoDeAlcohol = () => (
  <ModalForm title="Tipo de alcohol" id="TipoDeAlcohol" submitForm={() => { }}>
    <CheckboxInput
      name="tipo_alcohol"
      options={ALCOHOL_CONFIG}
      setValue={(value) => console.log('tipo seleccionado ->', value)}
      distribution="vertical"
    />
  </ModalForm>
);

/* eslint-disable */

import { ICatalogosEstandar } from '@common/types';
import DateInput from '@components/FormElemntsLibrary/DateInput2';
import { NumberInput } from '@components/FormElemntsLibrary/NumberInput';
import { SelectInput } from '@components/FormElemntsLibrary/SelectInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { ModalForm } from '@components/modals/ModalForm';
import {
  ISegurosPlanes,
  segurosPlanesInitial,
} from '@containers/TabsPaciente/PerfilPaciente/SegurosPlanes/types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actualizarCampo } from '@actions/infoUser/actions';
import { resetModal, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import { zodResolver } from '@hookform/resolvers/zod';
import { RootState } from '@reducer/index';
import { getCatalogoAseguradoras } from '@utils/getCatalogos';
import {
  updateSegurosPlanes,
  updateSegurosPlanesPaciente,
  updateSegurosPlanesUsuario,
} from '@utils/sendInfo';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const schema = z.object({
  loaded: z.boolean(),
  noTieneAseguradora: z.boolean(),
  aseguradora: z.object({
    id: z.number(),
    label: z.string(),
  }),
  cualAseguradora: z.string(),
  numPoliza: z.string(),
  vigencia: z.object({
    dia: z.string(),
    mes: z.string(),
    anio: z.string(),
  }),
  sumaAsegurada: z.string(),
  comentarios: z.string(),
});

type FormData = z.infer<typeof schema>;

export const SegurosPlanes = () => {
  const [formData, setFormData] = useState<FormData>(segurosPlanesInitial);
  const [catAseguradoras, setCatAseguradoras] = useState<ICatalogosEstandar[]>([]);

  const { segurosPlanes, segurosPlanesUsuario, cambiosSegurosPlanes } = useSelector(
    (state: RootState) => state.InfoUser,
  );
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
    pais,
  } = useSelector((state: RootState) => state.Me);
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);

  useEffect(() => {
    setFormData(segurosPlanes);
  }, [segurosPlanes]);
  useEffect(() => {
    reset(formData);
  }, [formData]);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });
  const hayCambios: boolean =
    idUsuario > 0 && cambiosSegurosPlanes.filter((c: string) => c.length > 0).length > 0;

  const getData = (datos: ISegurosPlanes, idUser?: number) => ({
    idMedico,
    idPaciente,
    idUsuario: idUser,
    idConsultorio,
    noTieneAseguradora: datos.noTieneAseguradora,
    aseguradora: datos.aseguradora ? datos.aseguradora.id : '',
    cualAseguradora: datos.cualAseguradora,
    numPoliza: datos.numPoliza,
    vigDia: datos.vigencia.dia.toString(),
    vigMes: datos.vigencia.mes.toString(),
    vigAnio: datos.vigencia.anio.toString(),
    sumaAsegurada: datos.sumaAsegurada,
    comentarios: datos.comentarios,
  });

  const guardarDatosExpediente = () => {
    const datosSegurosPlanes = getData(formData, idUsuario);
    const sendFunctions = [updateSegurosPlanes(datosSegurosPlanes)];

    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(updateSegurosPlanesPaciente(datosSegurosPlanes));
    }

    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => dispatch(actualizarCampo('segurosPlanes', { ...formData, loaded: true })),
          () => dispatch(actualizarCampo('segurosPlanesUsuario', { ...formData, loaded: true })),
          () => dispatch(resetModal('SegurosPlanes')),
          () => dispatch(resetModal('SubMenu')),
          () => dispatch(setActiveModal('MiSalud')),
        ],
      }),
    );
  };

  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateSegurosPlanesUsuario(getData(formData, idMiUsuario)),
        successFunction: () => {
          dispatch(actualizarCampo('segurosPlanes', { ...formData, loaded: true }));
          dispatch(resetModal('SegurosPlanes'));
          dispatch(resetModal('SubMenu'));
          dispatch(setActiveModal('MiSalud'));
        },
      }),
    );
  };

  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateSegurosPlanes(getData(segurosPlanesUsuario)),
        successFunction: () => {
          dispatch(actualizarCampo('segurosPlanes', segurosPlanesUsuario));
          setFormData(segurosPlanesUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateSegurosPlanesPaciente(getData(segurosPlanes, idUsuario)),
        successFunction: () =>
          dispatch(actualizarCampo('segurosPlanesUsuario', segurosPlanesUsuario)),
      }),
    );
  };

  const handleSubmitForm = () => {
    if (esPaciente) {
      guardarDatosUsuario();
    } else {
      guardarDatosExpediente();
    }
  };

  const handleInputChange = (e: { name: string; value: any }, field?: string) => {
    const { name, value } = e;
    const fieldName = field || name;
    console.log( { name, value });

    let newValue = value
    if (fieldName === 'sumaAsegurada') {
      newValue = String(value)
    }

    setFormData((prev) => ({
      ...prev,
      [fieldName]: newValue,
    }));
  };

  const handleChangeAseguradora = (aseguradora: ICatalogosEstandar | null) => {
    setFormData((prev) => ({
      ...prev,
      aseguradora: aseguradora || { id: 0, label: '' },
      cualAseguradora: aseguradora?.label === 'OTRA' ? prev.cualAseguradora : '',
    }));
  };

  useEffect(() => {
    getCatalogoAseguradoras(pais).then((result: ICatalogosEstandar[]) => {
      setCatAseguradoras(result);
    });
  }, []);

  return (
    <ModalForm
      currentStep={5}
      title="Seguros y Planes"
      id="SegurosPlanes"
      submitForm={handleSubmit(handleSubmitForm)}
      hayCambios={hayCambios}
      aceptarCambios={guardarDatosDeUsuarioAExpediente}
      rechazarCambios={guardarDatosDeExpedienteAUsuario}
    >
      <div className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <SelectInput
            label={t('aseguradora')}
            name="aseguradora"
            options={catAseguradoras}
            value={String(formData.aseguradora.id)}
            setValue={handleInputChange}
            errorMessage={errors.aseguradora?.message}
          />

          <TextInput
            label="# Poliza"
            name="numPoliza"
            value={formData.numPoliza}
            setValue={handleInputChange}
            errorMessage={errors.numPoliza?.message}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <DateInput
            label="Vigencia"
            name="vigencia"
            value={formData.vigencia}
            setValue={handleInputChange}
            errorMessage={errors.vigencia?.message}
          />

          <NumberInput
            label="Suma asegurada"
            name="sumaAsegurada"
            value={Number(formData.sumaAsegurada)}
            setValue={handleInputChange}
            errorMessage={errors.sumaAsegurada?.message}
          />
        </div>

        <TextArea
          label="Comentarios"
          name="comentarios"
          value={formData.comentarios}
          setValue={handleInputChange}
          maxLength={300}
          errorMessage={errors.comentarios?.message}
        />
      </div>
    </ModalForm>
  );
};

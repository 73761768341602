import { FC } from 'react';
import { StepHeaderProps } from './type';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/prefer-default-export
export const StepHeader: FC<StepHeaderProps> = ({ currentStep, totalSteps }) => {
  const { t } = useTranslation();
  const step = Math.min(Math.max(1, currentStep), totalSteps);

  return (
    <div className="w-full bg-gray-100 my-4 px-4 py-3 shadow-[0_2px_4px_-1px_rgba(0,0,0,0.5)]">
      <h2 className="text-gray-800 text-lg font-medium">
        {`${t('paso_paso')} ${step} ${t('paso_de')} ${totalSteps}`}
      </h2>
    </div>
  );
};

import { actualizarCampo } from '@actions/infoUser/actions';
import { resetModal, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import { AddButton } from '@components/AddButton';
import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { ModalForm } from '@components/modals/ModalForm';
import {
  actividadFisicaInitial,
  IActividadFisica,
  ITipoActividad,
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/ActividadFisica/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { RootState } from '@reducer/index';
import {
  antecedentesNoPatologicosGuardarActividadFisica,
  antecedentesNoPatologicosUsuarioGuardarActividadFisica,
  antecedentesNoPatologicosUsuarioPacienteGuardarActividadFisica,
} from '@utils/sendInfo';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { Actividad } from './Actividad';

const MAX_ACTIVITIES = 4;

const schema = z.object({
  loaded: z.boolean(),
  realiza: z.string(),
  tipo: z.array(
    z.object({
      idTipo: z.object({
        id: z.number(),
        label: z.string(),
      }),
      especifica: z.string(),
      frecuencia: z.string(),
      duracion: z.string(),
    }),
  ),
  comentarios: z.string().default(''),
});

type FormData = z.infer<typeof schema>;

export const ActividadFisica = () => {
  const [formData, setFormData] = useState<FormData>(actividadFisicaInitial);

  const dispatch = useDispatch();
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { actividadFisica, cambiosActividadFisica, actividadFisicaUsuario } = useSelector(
    (state: RootState) => state.InfoUser,
  );

  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });

  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);

  const tipoActividadInitial: ITipoActividad = {
    idTipo: { id: 0, label: '' },
    especifica: '',
    frecuencia: '',
    duracion: '',
  };

  // Funciones originales de manejo de datos
  const getDatosActividadFisica = (datos: IActividadFisica) => ({
    realiza: datos.realiza,
    tipo: datos.tipo,
    comentarios: datos.comentarios,
  });

  const getDatosExpediente = (datos: IActividadFisica) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    actividadFisica: getDatosActividadFisica(datos),
  });

  const getDatosUsuario = (datos: IActividadFisica) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    actividadFisica: getDatosActividadFisica(datos),
  });

  // Funciones de guardado originales
  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(formData);
    const sendFunctions = [antecedentesNoPatologicosGuardarActividadFisica(datosToSend)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(
        antecedentesNoPatologicosUsuarioPacienteGuardarActividadFisica(datosToSend),
      );
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => dispatch(actualizarCampo('actividadFisica', { ...formData, loaded: true })),
          () => dispatch(actualizarCampo('actividadFisicaUsuario', { ...formData, loaded: true })),
        ],
      }),
    );
  };

  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioGuardarActividadFisica(
          getDatosUsuario(formData),
        ),
        successFunction: () => {
          dispatch(actualizarCampo('actividadFisica', { ...formData, loaded: true }));
          dispatch(resetModal('ActividadFisica'));
          dispatch(setActiveModal('MisMedidas'));
        },
      }),
    );
  };

  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosGuardarActividadFisica(
          getDatosExpediente(actividadFisicaUsuario),
        ),
        successFunction: () => {
          // setActividadFisica(actividadFisicaUsuario);
          setFormData(actividadFisicaUsuario);
        },
      }),
    );
  };

  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarActividadFisica(
          getDatosExpediente(actividadFisica),
        ),
        // successFunction: () => setActividadFisicaUsuario(actividadFisica),
      }),
    );
  };

  // Manejadores de eventos adaptados
  const handleExerciseChange = (e: { name: string; value: any; position?: number }) => {
    const realiza = e.value === 'si' ? 'T' : 'F';

    setFormData({
      ...formData,
      realiza,
    });
  };

  const handleActivityChange = (e: { name: string; value: any; position?: number }) => {
    const { name, value, position } = e;
    let newValue = value

    if (name === 'duracion' || name === 'frecuencia') {
      newValue = String(value)
    }

    setFormData({
      ...formData,
      tipo: formData.tipo.map((item, i) => (i === position
        ? {
          ...item,
          [name]: newValue,
        }
        : item)),
    });
  };

  const handleAddActivity = () => {
    if (formData.tipo.length < MAX_ACTIVITIES) {
      setFormData({
        ...formData,
        tipo: [...formData.tipo, tipoActividadInitial],
      });
    }
  };

  const handleDeleteActivity = (indexDelete: number) => {
    setFormData({
      ...formData,
      tipo: formData.tipo.filter((_, i) => i !== indexDelete),
    });
  };

  useEffect(() => {
    const newtipos = actividadFisica.tipo.map((tipo) => ({
      ...tipo,
      duracion: String(tipo.duracion),
      frecuencia: String(tipo.frecuencia),
    }));
    setFormData({ ...actividadFisica, tipo: newtipos });
  }, [actividadFisica]); 

  const handleSubmitForm = async () => {
    try {
      if (esPaciente) {
        await guardarDatosUsuario();
      } else {
        await guardarDatosExpediente();
      }
    } catch (error) {
      console.error('Error en el envío del formulario:', error);
    }
  };

  useEffect(() => {
    reset(formData);
  }, [formData]);

  return (
    <ModalForm
      title="Actividad Física"
      id="ActividadFisica"
      submitForm={handleSubmit(handleSubmitForm)}
    >
      <div className="w-full flex flex-col gap-4">
        <CheckboxInput
          label="¿Realiza actividad física?"
          name="realizaActividad"
          options={[
            { value: 'no', label: 'No' },
            { value: 'si', label: 'Sí' },
          ]}
          value={formData.realiza === 'T' ? 'si' : 'no'}
          setValue={handleExerciseChange}
          distribution="horizontal-inline"
        />
        <div className="flex flex-col gap-4">
          {formData.realiza === 'T'
            && formData.tipo.map((activity, index) => (
              <Actividad
                key={`${activity.idTipo.label}_${index}`}
                index={index}
                activity={activity}
                onDelete={handleDeleteActivity}
                onChange={handleActivityChange}
                showDelete={index !== 0}
                errors={errors}
              />
            ))}

          {formData.realiza === 'T' && formData.tipo.length < MAX_ACTIVITIES && (
            <AddButton
              variant="enabled"
              label="Agregar otra actividad"
              onClick={handleAddActivity}
            />
          )}
        </div>

        <TextArea
          name="comentarios"
          label="Comentarios"
          value={formData.comentarios}
          setValue={(e) => setFormData((prev) => ({
            ...prev,
            comentarios: e.value,
          }))}
          errorMessage={errors.comentarios?.message}
        />
      </div>
    </ModalForm>
  );
};

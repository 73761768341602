export const ACTUALIZAR_CAMPO = 'ACTUALIZAR_CAMPO';
export const LLENAR_DATOS_PACIENTE = 'LLENAR_DATOS_PACIENTE';

interface ActualizarCampoAction {
  type: typeof ACTUALIZAR_CAMPO;
  payload: {
    campo: string; // Ajustado al estado que desees actualizar
    valor: any;
  };
}

interface LlenarDatosPacienteAction {
  type: typeof LLENAR_DATOS_PACIENTE;
  payload: any;
}

export type PacienteActionTypes = ActualizarCampoAction | LlenarDatosPacienteAction;

import { FC, useEffect, useRef, useState } from 'react';
import CountryInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { PhoneInputProps } from './type';
import { GLOBALS } from '@constants/prueba/globals';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { validacionLongTelefono } from '@constants/validaciones';

export const PhoneInput: FC<PhoneInputProps> = ({
  index,
  name,
  containerClass = '',
  label: externalLabel,
  isRequired,
  floatingLabel,
  placeholder,
  disableOption,
  setValue,
  disabled = false,
  value,
  setStatus,
}) => {
  const { pais, esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [label, setLabel] = useState('');
  const [isDisabled, setIsDisabled] = useState(disabled);
  const [wasFocused, setWasFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef<HTMLDivElement>(null);
  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);

  useEffect(() => {
    setStatus?.({
      listName: 'missing',
      name,
      value: value !== undefined || value !== null || value !== '',
      index,
    });
  }, [isRequired]);

  useEffect(() => {
    setStatus?.({
      listName: 'error',
      name,
      value: errorMessage.length > 0,
      index,
    });
  }, [errorMessage]);

  useEffect(() => {
    if (floatingLabel) {
      setLabel('');
    } else {
      setLabel(externalLabel === undefined ? t(name) : externalLabel);
    }
  }, [floatingLabel, externalLabel]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  const handleClickOutside = (event: MouseEvent) => {
    setWasFocused(!!inputRef.current?.contains(event.target as Node));
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [wasFocused]);

  const handlePhoneChange = (value: string, data: any) => {
    setErrorMessage('');

    // Crear evento sintético
    const syntheticEvent = {
      name,
      value: { numero: value.slice(data.dialCode.length), codigo: data.dialCode },
      type: 'tel',
    };

    setValue(syntheticEvent);
  };

  const handleBlur = () => {
    const longTel = validacionLongTelefono(pais);
    if (isRequired && value.numero.length === 0) {
      setErrorMessage(t('error_campo_en_blanco'));
    } else if (value.numero.length > 0 && value.numero.length < longTel) {
      setErrorMessage(`${t('error_n_digitos_1')} ${longTel} ${t('error_n_digitos_2')}`);
    } else {
      setErrorMessage('');
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!isWideScreen) {
      setTimeout(() => {
        event.target.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);  
    }
  };

  const handleDisableChange = () => {
    const newState = !isDisabled;
    setIsDisabled(newState);
    setErrorMessage('');

    if (disableOption?.name) {
      setValue({ name: disableOption.name, value: newState });
    }
    if (newState) {
      setValue({ name, value: { numero: '', codigo: '' } });
    }
  };

  const renderDisableCheckbox = () => (
    <label className="flex items-center text-gray-600 whitespace-nowrap ml-4">
      <input
        type="checkbox"
        className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
        checked={!!disableOption?.value}
        onChange={handleDisableChange}
      />
      {disableOption?.label}
    </label>
  );

  const customStyles = {
    containerStyle: {
      width: '100%',
    },
    inputStyle: {
      width: '100%',
      height: '48px',
      fontSize: '0.875rem',
      borderRadius: '0.300rem',
      border: errorMessage ? '1px solid rgb(239 68 68)' : '1px solid rgb(209 213 219)',
      backgroundColor: isDisabled ? '#F9FAFB' : 'white',
      color: isDisabled ? '#9CA3AF' : 'black',
      padding: '8px 8px 8px 58px',
      boxShadow: errorMessage ? '0 0 0 2px rgb(254 202 202)' : 'none',
    },
    buttonStyle: {
      border: errorMessage ? '1px solid rgb(239 68 68)' : '1px solid rgb(209 213 219)',
      // borderRight: 'none',
      borderRadius: '0.300rem 0 0 0.300rem',
      backgroundColor: isDisabled ? '#F9FAFB' : 'white',
    },
  };

  return (
    <div className={`w-full h-fit my-[10px] ${containerClass}`}>
      <div className="px-4">
        <div className="">
          <div className="flex items-center justify-between mb-2">
            {label && (
              <label
                htmlFor={name}
                className={`block font-medium text-base ${
                  errorMessage ? 'text-red-500' : 'text-gray-700'
                } ${isDisabled ? 'text-gray-500' : ''}`}
              >
                {label} {isRequired && <span className="text-red-500">*</span>}
              </label>
            )}
            {disableOption?.position === 'right' && renderDisableCheckbox()}
          </div>

          <div className="flex items-center gap-4">
            <div className="flex-1">
              <div ref={inputRef}>
                <CountryInput
                  country={pais}
                  preferredCountries={[pais, 'us']}
                  localization={es}
                  enableSearch
                  searchPlaceholder={t('buscar')}
                  searchNotFound={t('sin_resultados')}
                  value={`${value.codigo}${value.numero}`}
                  onChange={(e, newPhone) => handlePhoneChange(e, newPhone)}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  disabled={isDisabled}
                  inputProps={{
                    name,
                    placeholder: placeholder || t('ingresa_telefono'),
                  }}
                  specialLabel={`${floatingLabel ? floatingLabel : ''}${floatingLabel && isRequired ? '*' : ''}`}
                  containerStyle={customStyles.containerStyle}
                  inputStyle={customStyles.inputStyle}
                  buttonStyle={customStyles.buttonStyle}
                  containerClass={`phone-input-container rounded-md ${
                    wasFocused ? 'ring-2 ring-blue-500' : ''
                  }
                  `}
                  disableDropdown={isDisabled}
                />
              </div>
            </div>
            {disableOption?.position === 'after' && renderDisableCheckbox()}
          </div>

          {errorMessage && <p className="text-sm text-red-500 mt-1">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default PhoneInput;

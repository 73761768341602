/* eslint-disable */

import { SubModalForm } from '@components/modals/SubModalForm';
import { AlertTriangle } from 'lucide-react';

export const RiesgoSalud = () => (
  <SubModalForm
    title="Riesgo a mi salud"
    id="RiesgoSalud"
    displayButton={false}
    saveModal={() => { }}
  >
    <div className="mb-6">
      <h2 className="text-xl font-semibold mb-2">Detección de cáncer de pulmón</h2>

      <div className="bg-yellow-50 p-4 rounded-lg text-sm">
        <div className="flex gap-2 items-start">
          <AlertTriangle className="text-yellow-500 w-5 h-5 mt-0.5 flex-shrink-0" />
          <div>
            <p className="mb-2">
              De acuerdo a tus hábitos de vida, presentas un "alto riesgo" de desarrollar cáncer de
              pulmón.
            </p>
            <p>
              La recomendación es que te acerques a la unidad médica de atención para recibir
              asesoría acerca de las medidas preventivas
            </p>
          </div>
        </div>
      </div>
    </div>
  </SubModalForm>
);

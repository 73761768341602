import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IAlertDialogProps from './types';
import { ITipoMascota } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Vivienda/types';
import MascotasComponente from './Mascotas/index';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

// const Transition = React.forwardRef(
//   (
//     // eslint-disable-next-line react/require-default-props
//     props: TransitionProps & { children?: React.ReactElement<any, any> },
//     ref: React.Ref<unknown>,
//     // eslint-disable-next-line react/jsx-props-no-spreading
//   ) => <Slide direction="up" ref={ref} {...props} />,
// );
const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  )
);

function AgregarMascotas(props: IAlertDialogProps) {
  const {
    open,
    hayCambios = false,
    viviendaTemp,
    setViviendaTemp,
    catAnimales,
    callBackAceptar,
    callBackClose,
  } = props;
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');

  const tipoMascotaInitial: ITipoMascota = {
    idAnimal: { id: 0, label: '' },
    especifica: '',
    cantidad: '',
  };

  // const handleChangeAnimal = (event: any, newValue: ICatalogosEstandar | null) => {
  //   const valUpdate = [...viviendaTemp.tipo];
  //   if (newValue) {
  //     if (newValue.id !== 28) {
  //       valUpdate[index].especifica = '';
  //       valUpdate[index].idAnimal = newValue;
  //     } else {
  //       valUpdate[index].idAnimal = newValue;
  //     }
  //   } else {
  //     valUpdate[index].especifica = '';
  //     valUpdate[index].idAnimal = { id: 0, label: '' };
  //   }
  //   setViviendaTemp({
  //     ...viviendaTemp,
  //     tipo: valUpdate,
  //   });
  // };

  const handleMascotaChange = (e: { name: string; value: any, index: number }) => {
    let val = e.value;
    if (e.name === 'cantidad') {
      val = String(val);
    }
    setViviendaTemp((prevData) => ({
      ...prevData,
      tipo: prevData.tipo.map((mascota, indx) => indx === e.index
        ? { ...mascota, [e.name]: val }
        : mascota
      ),
    }));
  };

  const addAnimal = () => {
    setViviendaTemp({
      ...viviendaTemp,
      tipo: [...viviendaTemp.tipo, tipoMascotaInitial],
    });
  };
  const deleteAnimal = (index: number) => {
    if (viviendaTemp.tipo.length === 1) {
      setViviendaTemp({
        ...viviendaTemp,
        tipo: [tipoMascotaInitial],
      });
    } else {
      const newTipo = [...viviendaTemp.tipo];
      newTipo.splice(index, 1);
      setViviendaTemp({
        ...viviendaTemp,
        tipo: newTipo,
      });
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="mascotas-titulo"
      aria-describedby="mascotas-descripcion"
      maxWidth="lg"
      fullWidth
    >
      <div className="flex justify-between items-center bg-blue-500 text-white pl-4">
        <span className="text-lg">{t('antecedentes-pnp-vivienda-mascotas')}</span>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <DialogContentText>
          {viviendaTemp.tipo.map((val, index) => (
            <MascotasComponente
              {...val}
              hayCambios={hayCambios}
              catAnimales={catAnimales}
              eliminarMascota={(index) => deleteAnimal(index)}
              handleChange={handleMascotaChange}
              index={index}
              // eslint-disable-next-line react/no-array-index-key
              key={String(index)}
            />
          ))}
          <div className="flex justify-end mb-4">
            <Button
              disabled={
                hayCambios ||
                viviendaTemp.tipo.some(
                  (mascota) => mascota.idAnimal.id === 0 && !mascota.especifica.length,
                )
              }
              color="primary"
              size="small"
              onClick={addAnimal}
              // className={classes.noTextTranform}
              startIcon={<AddCircleOutlineIcon />}
            >
              {t('antecedentes-pnp-vivienda-amascota')}
            </Button>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="w-full flex justify-center mb-2">
          <div>
            <Button
              onClick={() => callBackAceptar()}
              color="primary"
              variant="contained"
            >
              {t('aceptar')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default AgregarMascotas;

type AnyObject = Record<string, any>;

export const countProps = (obj: AnyObject, exclude: string[] = []): { total: number; filled: number } => {
  let totalProps = 0;
  let filledProps = 0;

  function traverse(value: any, parentKey: string | null): void {
    if (typeof value === "object" && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((item) => traverse(item, parentKey));
      } else {
        for (const key in value) {
          if (value.hasOwnProperty(key) && !exclude.includes(key)) {
            totalProps++;
            if (
              value[key] !== null &&
              value[key] !== "" &&
              value[key] !== false &&
              !(Array.isArray(value[key]) && value[key].length === 0) &&
              !(typeof value[key] === "object" && Object.keys(value[key]).length === 0)
            ) {
              filledProps++;
            }
            traverse(value[key], key);
          }
        }
      }
    }
  }

  traverse(obj, null);
  return { total: totalProps, filled: filledProps };
}

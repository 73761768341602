import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, IconButton, CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ModalComponent from '@components/ModalComponent';
import {
  getListaVariablesBuilder,
  getListaVariablesTopMedico,
  getVariableBuilderMedico,
  getVariableBuilderMedipraxi,
} from '@utils/getCatalogos';
import { RootState } from 'src/store';
import { ICatalogoVariable } from '@common/types';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { removePaciente, removeUserSession } from '@utils/commonStore';
import { limpiarReduxSesion, limpiarReduxPaciente } from '@utils/reduxCommon';
import { ICompUnico, ITodasLasVariables } from './types';
import Variable from '../Variable';

function TodasLasVariables(props: ITodasLasVariables) {
  const history = useHistory();
  const { setArrComponentes } = props;
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation();
  /** States del functional component */
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [singleComp, setSingleComp] = useState<ICompUnico>({
    titulo: '',
    id: 0,
    formato: 0,
    tipo: false,
    constructorComponente: {
      componente: '',
      datosComponente: {
        titulo: '',
        descripcion: '',
      },
      constructor: {
        titulo: '',
        descripcion: '',
        comentarios: '',
        valor: '',
      },
      valores: {
        textoLibre: '',
        valor: '',
      },
    },
  });

  const [loadingAutocomplete, setLoadingAutocomplete] = useState<boolean>(false);
  const [catAutocomplete, setCatAutocomplete] = useState<ICatalogoVariable[]>([]);
  /** states auxiliares */
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  /** Catalogos */
  const [catComponentesMedico, setCatComponentesMedico] = useState<ICatalogoVariable[]>([]);
  /** Hooks */
  useEffect(() => {
    dispatch(setLoading(true));
    getListaVariablesTopMedico(idMedico)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setCatComponentesMedico(result.data);
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'info',
              mensaje: t('message-get-error'),
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-get-error')} ${err.toString()}`,
          }),
        );
      });
  }, []);
  useEffect(() => {
    if (singleComp.titulo) {
      setModalOpen(true);
    }
  }, [singleComp]);

  useEffect(() => {
    if (searchTerm && searchTerm.length >= 3) {
      setLoadingAutocomplete(true);
      getListaVariablesBuilder(idMedico, searchTerm)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatAutocomplete(result.datos);
          } else if (result.code === 300) {
            // el token y el idMedico no son el mismo
            /* Cerrar sesión */
            removeUserSession();
            limpiarReduxSesion();
            history.push('/login');
          } else if (result.code === 301) {
            // el medico no tiene subscripcion activa
            /* Resetear y redirigir al checkout */
            removePaciente();
            limpiarReduxSesion();
            history.push('/subscripcion');
          } else if (result.code === 302) {
            // el paciente y el medico no tienen relacion
            /* Resetear redux paciente y datos paciente y redirigir a paciente */
            removePaciente();
            limpiarReduxPaciente();
            history.push('/pacientes');
          } else {
            setCatAutocomplete([]);
          }
          setLoadingAutocomplete(false);
        })
        .catch(() => {
          setCatAutocomplete([]);
          setLoadingAutocomplete(false);
        });
    }
  }, [searchTerm]);
  /** Handlers del componente */

  const handleChangeBusqueda = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setSearchTerm(val);
    if (!val.length) {
      setCatAutocomplete([]);
    }
  };

  const verVariableMedipraxi = (idBD: number) => {
    dispatch(setLoading(false));
    getVariableBuilderMedipraxi(idMedico, idBD)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setSingleComp(result.data);
        } else {
          setLoading(false);
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'info',
              mensaje: t('message-get-error'),
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-general')} ${err.toString()}`,
          }),
        );
      });
  };

  const verVariableMedico = (idBD: number) => {
    dispatch(setLoading(false));
    getVariableBuilderMedico(idMedico, idBD)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setSingleComp(result.data);
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          limpiarReduxSesion();
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarReduxSesion();
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarReduxPaciente();
          history.push('/pacientes');
        } else if (result.code === 305) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-get-error')} ${t(result.msg)}`,
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-general')} ${err.toString()}`,
          }),
        );
      });
  };

  const agregarVariableMedipraxi = (idBD: number) => {
    dispatch(setLoading(true));
    getVariableBuilderMedipraxi(idMedico, idBD)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setArrComponentes((preArr) => {
            const existe = preArr.some((el) => el.id === result.data.id);
            if (!existe) {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'success',
                  mensaje: t('message-add-success'),
                }),
              );
              return preArr.concat(result.data);
            }
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'info',
                mensaje: t('message-add-same-variable-formato'),
              }),
            );
            return preArr;
          });
        }
        if (result.code === 300) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-get-error')} ${t(result.msg)}`,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-general')} ${err.toString()}`,
          }),
        );
      });
  };

  const agregarVariableMedico = (idBD: number) => {
    dispatch(setLoading(true));
    getVariableBuilderMedico(idMedico, idBD)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setArrComponentes((preArr) => {
            const existe = preArr.some((el) => el.id === result.data.id);
            if (!existe) {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'success',
                  mensaje: t('message-add-success'),
                }),
              );
              return preArr.concat(result.data);
            }
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'info',
                mensaje: t('message-add-same-variable-formato'),
              }),
            );
            return preArr;
          });
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          limpiarReduxSesion();
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarReduxSesion();
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarReduxPaciente();
          history.push('/pacientes');
        } else if (result.code === 305) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-get-error')} ${t(result.msg)}`,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-general')} ${err.toString()}`,
          }),
        );
      });
  };

  const handleCloseButton = () => {
    setSearchTerm('');
    setCatAutocomplete([]);
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setModalOpen(false);
  };

  return (
    <>
      <ModalComponent
        open={modalOpen}
        constructorComponente={singleComp.constructorComponente}
        id={singleComp.id}
        titulo={singleComp.titulo}
        formato={singleComp.formato}
        callBackClose={handleAlertClose}
      />
      <div className="text-center">
        <h3 className="font-medium text-blue-600">{t('todas_las_variables')}</h3>
        <div className="shadow-lg bg-white rounded-md px-2 pb-2 border border-t-0 border-solid border-gray-200">
          <p className="pt-2 text-gray-600">
            {t('busca_o_selecciona_las_variables_a_incluir_en_el_formato_que_estas_diseñando')}
          </p>
          <div className="flex items-center">
            <SearchIcon />
            <div className="pl-4 w-full">
              <TextField
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={handleChangeBusqueda}
                placeholder={t('introduce_al_menos_3_caracteres')}
                InputProps={{
                  endAdornment: searchTerm && (
                    <IconButton size="small">
                      <CloseIcon onClick={handleCloseButton} />
                    </IconButton>
                  ),
                }}
              />
            </div>
          </div>
          <div className="p-2 min-h-full h-60 overflow-y-auto">
            {loadingAutocomplete ? (
              <div className="flex items-center justify-center h-full">
                <CircularProgress color="inherit" size={45} />
              </div>
            ) : (
              catAutocomplete.map((comp) => (
                // si el tipo es true significa que es una variable medipraxi
                <Variable
                  idBD={comp.id}
                  texto={comp.nombre}
                  tipo={comp.tipo}
                  agregarVariable={comp.tipo ? agregarVariableMedipraxi : agregarVariableMedico}
                  verVariable={comp.tipo ? verVariableMedipraxi : verVariableMedico}
                />
              ))
            )}
            {searchTerm.length < 2
              ? catComponentesMedico.map((comp) => (
                <Variable
                  idBD={comp.id}
                  texto={comp.nombre}
                  tipo={comp.tipo}
                  agregarVariable={comp.tipo ? agregarVariableMedipraxi : agregarVariableMedico}
                  verVariable={comp.tipo ? verVariableMedipraxi : verVariableMedico}
                />
              ))
              : !catAutocomplete.length && !loadingAutocomplete && <div>Sin Resultados</div>}
          </div>
        </div>
      </div>
    </>
  );
}

export default TodasLasVariables;

export function validacionIp(ip: string) {
  return /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/i.test(ip);
}

export function validacionNombres(nombre: string) {
  // eslint-disable-next-line no-useless-escape
  return /^[a-zñ'áéíóúäëïöü-]*(?:\s?[a-zñ'áéíóúäëïöü-]+)+\s?$/i.test(nombre);
}

export function validacionApellidos(apellido: string) {
  // eslint-disable-next-line no-useless-escape
  return /^[a-zñ'áéíóúäëïöü]*(?:\s?[a-zñ'áéíóúäëïöü]+)+\s?$/i.test(apellido);
}

export function validacionCaracterNoNumerico(string: string) {
  return /^\D*$/i.test(string);
}

export function validacionCualquierLetraSinNumero(texto: string) {
  return /[0-9]{1,}/i.test(texto);
}

export function validacionSoloLetras(string: string) {
  return /^([a-zñ]|\s)*$/i.test(string);
}

export function validacionLetrasConAcentos(apellido: string) {
  // eslint-disable-next-line no-useless-escape
  return /^[a-zñ'áéíóúäëïöü]*(?:\s?[a-zñ'áéíóúäëïöü]+)+$/i.test(apellido);
}

export function validacionSoloNumeros(string: string) {
  return /^\d*$/i.test(string);
}

export function validacionSoloNumerosUnDecimal(string: string) {
  return /^(\d{1,3})(\.\d{1})?\d{0}$/i.test(string);
}

export function validacionSoloNumerosDosDecimal(string: string) {
  return /^(\d{1,3})(\.\d\d?)?\d{0}$/i.test(string);
}

export function validacionSoloNumerosTresDecimales(string: string) {
  return /^(\d{1,3})(\.\d{1,3})?\d{0}$/i.test(string);
}

export function validacionDiezNumerosCuatroDecimales(string: string) {
  return /^(?!0\d|$)(\d{1,10})(\.\d{1,4})?\d{0}$/i.test(string);
}

export function validacionHoraDoce(string: string) {
  return /^(?:0?[0-9]|1[0-2])[:][0-5][0-9]$/i.test(string);
}

export function validacionIngresoSoloUnPunto(string: string) {
  const j = /[.]$/i.test(string);
  const k = /\.\d+\./i.test(string);
  const l = /\.\./i.test(string);
  let valTrue = false;
  if (j && !k && !l) {
    valTrue = true;
  }
  return valTrue;
}

export function noDosPuntos(string: string) {
  return /[.]$/i.test(string);
}

const getParametrosPais = (pais: string) => {
  switch (pais) {
    case 'mx':
      return {
        numSSRequired: true, // Obligatoriedad del IMSS
        docIdLongMax: 18, // Tamaño máximo del CURP
        telLongMax: 10, // Tamaño máximo del número de teléfono
        rfcLongMax: 13, // Tamaño máximo del RFC
        numSSLongMax: 11, // Tamaño máximo del IMSS
        validacionDocId: (docId: string) => /^[a-z]{4}\d{6}(h|m)[a-z]{5}(\d{2}|\w\d|\w{2})/i.test(docId), // Validación del CURP
        validacionDocIdOnChange: (docId: string) => /^[a-z]{0,4}\d{0,6}(h|m)?[a-z]{0,5}(\d{0,2}|\w\d|\w{0,2})$/i.test(docId), // Validación del CURP
        validacionTelefono: (tel: string) => /^\d{10}$/.test(tel), // Validación del número de teléfono
        validacionRFC: (rfc: string) => /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/.test(
          rfc,
        ), // Validación del RFC
        validacionCP: (cp: string) => /^\d{5}$/.test(cp), // Validación del código postal
        validacionNumSS: (nss: string) => /^(\d{2})(\d{2})(\d{2})\d{5}$/.test(nss), // Validación del IMSS
        validacionNumEmpleado: (num: string) => /^[A-Za-z0-9]*$/.test(num), // Validación del número de empleado
      };
    case 'uy':
      return {
        numSSRequired: false, // Obligatoriedad del número de seguro social
        docIdLongMax: 7, // Tamaño máximo de la Cédula de Identidad
        telLongMax: 8, // Tamaño máximo del número de teléfono
        rfcLongMax: 12, // Tamaño máximo del RUT
        numSSLongMax: 6, // No utilizan número de seguro social sino la misma Cédula de Identidad
        validacionDocId: (docId: string) => /^[1-9][0-9]{5,6}$/i.test(docId), // Validación de la Cédula de Identidad
        validacionDocIdOnChange: (docId: string) => /^([1-9][0-9]{0,6})?$/i.test(docId), // Validación de la Cédula de Identidad
        validacionTelefono: (tel: string) => /^\d{8}$/.test(tel), // Validación del número de teléfono
        validacionRFC: (rfc: string) => /^[1-9]{12}$/.test(rfc), // Validación del RUT
        validacionCP: (cp: string) => /^\d{5}$/.test(cp), // Validación del código postal
        validacionNumSS: (nss: string) => /^[1-9][0-9]{5,6}$/i.test(nss), // No utilizan número de seguro social o usan la cédula
        validacionNumEmpleado: (num: string) => /^[A-Za-z0-9]*$/.test(num), // Validación del número de empleado
      };
    default:
      return {
        numSSRequired: false,
        docIdLongMax: 0,
        telLongMax: 0,
        rfcLongMax: 0,
        numSSLongMax: 0,
        validacionDocId: () => false,
        validacionDocIdOnChange: () => false,
        validacionTelefono: () => false,
        validacionRFC: () => false,
        validacionCP: () => false,
        validacionNumSS: () => false,
        validacionNumEmpleado: () => false,
      };
  }
};

export function docIdLongitudMax(pais: string) {
  return getParametrosPais(pais).docIdLongMax;
}

export function rfcLongitudMax(pais: string) {
  return getParametrosPais(pais).rfcLongMax;
}

export function validacionDocId(docId: string, pais: string) {
  return getParametrosPais(pais).validacionDocId(docId);
}

export function validacionDocIdOnChange(docId: string, pais: string) {
  return (
    !docId.length
    || docId.length < getParametrosPais(pais).docIdLongMax
    || getParametrosPais(pais).validacionDocIdOnChange(docId)
  );
}

export function validacionLongTelefono(pais: string) {
  return getParametrosPais(pais).telLongMax;
}

export function validacionTelefono(tel: string, pais: string) {
  return getParametrosPais(pais).validacionTelefono(tel);
}

export function validacionRfc(rfc: string, pais: string) {
  return rfc.length && getParametrosPais(pais).validacionRFC(rfc);
}

export function validacionCp(cp: string, pais: string) {
  return cp.length && getParametrosPais(pais).validacionCP(cp);
}

export function validacionNumSS(nss: string, pais: string) {
  return (
    (nss.length === 0 && !getParametrosPais(pais).numSSRequired)
    || getParametrosPais(pais).validacionNumSS(nss)
  );
}

export function validacionNumeroEmpleado(num: string, pais: string) {
  return getParametrosPais(pais).validacionNumEmpleado(num);
}

export function validacionEmail(email: string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );
}

export function validacionDiezNCuatroD(numero: string) {
  return /^(?!0\d|$)\d{1,10}(\.\d{1,3})?$/.test(numero);
}

export function validacionTipoSangre(tipo: string) {
  return /^(A|B|AB|O|a|b|ab|o)$/.test(tipo);
}

export function validacionFactorRH(factor: string) {
  return /^(positivo|negativo|POSITIVO|NEGATIVO|p|n|P|N)$/.test(factor);
}

import { ACTUALIZAR_CAMPO, LLENAR_DATOS_PACIENTE, PacienteActionTypes } from './types';

export function actualizarCampo(campo: string, valor: any): PacienteActionTypes {
  return {
    type: ACTUALIZAR_CAMPO,
    payload: { campo, valor },
  };
}

export function llenarDatosPaciente(datos: any): PacienteActionTypes {
  return {
    type: LLENAR_DATOS_PACIENTE,
    payload: datos,
  };
}

import { ActividadFisicaForm } from '@containers/PatientForm/ActividadFisica/type';
import { AlimentacionForm } from '@containers/PatientForm/Alimentacion/types';
import { Discapacidad } from '@containers/PatientForm/Discapacidades/type';
import { EstiloVidaForm } from '@containers/PatientForm/EstiloDeVida/type';
import { GinecologiaForm } from '@containers/PatientForm/Ginecologia/type';
import { HabitosForm } from '@containers/PatientForm/Habitos/type';
import { HigieneForm } from '@containers/PatientForm/Higiene/type';
import { InformacionEmergenciaForm } from '@containers/PatientForm/InfoEmergencia/type';
import { DiabetesForm } from '@containers/PatientForm/MiSalud/Diabetes/type';
import { FemaleHealthForm } from '@containers/PatientForm/MiSalud/Gender/FemaleHealth/type';
import { MaleHealthForm } from '@containers/PatientForm/MiSalud/Gender/MaleHealth/type';
import { HipertensionForm } from '@containers/PatientForm/MiSalud/Hipertension/type';
import { NerveProblemsAndPainForm } from '@containers/PatientForm/MiSalud/NerveProblemsAndPain/type';
import { MiSaludForm } from '@containers/PatientForm/MiSalud/type';
import { MisMedidasForm } from '@containers/PatientForm/MisMedidas/type';
import { SegurosPlanesForm } from '@containers/PatientForm/SegurosPlanes/type';
import { UbicationForm } from '@containers/PatientForm/Ubicacion/type';

export const initialUbicationForm: UbicationForm = {
  territorio: 'mexicano',
  entidadFederativa: '',
  municipioDelegacion: '',
  localidad: '',
  resideen: 'mexicano',
  colonia: '',
  calle: '',
  interior: '',
  exterior: '',
  cp: '',
  referencias: '',
  comentario: '',
};

export const initialDisabilityData: Discapacidad = {
  tipoId: '',
  tipo: '',
  gradoId: '',
  grado: '',
  origenId: '',
  origen: '',
};

export const initialInformacionEmergenciaForm: InformacionEmergenciaForm = {
  grupoSanguineoTipo: '',
  factorRh: '',
  nombreContacto: '',
  apellidoContacto: '',
  parentescoContacto: '',
  emailContacto: '',
  celular: '',
  telefonoFijoContacto: '',
};

export const initialSegurosPlanesForm: SegurosPlanesForm = {
  aseguradora: null,
  poliza: undefined,
  fecha: undefined,
  sumaAsegurada: undefined,
  comentarios: '',
};

export const initialMiSaludForm: MiSaludForm = {
  problemasCorazon: '',
  embolia: '',
  hipertension: '',
  diabetes: '',
  problemasRinones: '',
  hepatitis: '',
  tumores: '',
  trastornosInmunes: '',
  problemasCirculacion: '',
  enfermedadesRaras: '',
  contenidoTabla: [],
  seguimientoTratamientoDiabetes: '',
  seguimientoConsultasDiabetes: '',
  planAlimentacionDiabetes: '',
  mediPresion: '',
  medicamentosDiabetes: '',
  seguimientoTratamiento: '',
  seguimientoConsultas: '',
  planAlimentacion: '',
  mediGlucosa: '',
  medicamentosHipertension: '',
};

export const initialHipertensionForm: HipertensionForm = {
  seguimientoTratamiento: '',
  seguimientoConsultas: '',
  planAlimentacion: '',
  mediPresion: '',
  medicamentosHipertension: '',
};

export const initialDiabetesForm: DiabetesForm = {
  seguimientoTratamientoDiabetes: '',
  seguimientoConsultasDiabetes: '',
  planAlimentacionDiabetes: '',
  mediPresion: '',
  medicamentosDiabetes: '',
};

export const initialNerveProblemsAndPainForm: NerveProblemsAndPainForm = {
  convulsiones: '',
  ansiedad: '',
  depresion: '',
  estres: '',
  vertigo: '',
  espalda: '',
  articulaciones: '',
  munecas: '',
  hernia: '',
  extremidades: '',
  pension: '',
  incapacidad: '',
};

export const initialMaleForm: MaleHealthForm = {
  asma: '',
  enfermedades_respiratorias: '',
  piedras_rinones: '',
  problemas_vision: '',
  usa_lentes: '',
  cirugia_abdominal: '',
  cirugia_columna: '',
  hernia_abdominal: '',
  problemas_testiculos: '',
};

export const initialFemaleForm: FemaleHealthForm = {
  asma_crisis: '',
  enfermedades_respiratorias: '',
  piedras_rinones: '',
  problemas_vision: '',
  usa_lentes: '',
  cirugia_abdominal: '',
  cirugia_columna: '',
  hernia_abdominal: '',
  problemas_pechos: '',
};

export const initialEstiloVidaForm: EstiloVidaForm = {
  serviciosVivienda: [],
  numeroHabitantes: '',
  numeroHabitaciones: '',
  tieneMascotas: '',
  trayectoTrabajo: '',
  riesgoInundacion: '',
  zonaDeslaves: '',
};

export const initialAlimentacionForm: AlimentacionForm = {
  restriccion: '',
  foods: [],
  comidasDiarias: '',
  consumoFrutas: '',
  consumoVegetales: '',
  comidasFuera: '',
  carneRoja: '',
  pollo: '',
  pescado: '',
  refrescos: '',
  postres: '',
  comidas: '',
  dulces: '',
  arrTorFriPas: '',
  dulPapPast: '',
  comentario_higiene: '',
};

export const initialHabitosForm: HabitosForm = {
  estas_embarazada: '',
  cafe_bebidas: '',
  tazas_cafe: '',
  lata_energia: '',
  comentarios_cafe: '',
  te: '',
  tazas_te: '',
  comentarios_te: '',
  cigarro: '',
  cigarro_edad_inicio: '',
  cigarro_edad_termino: '',
  cigarrillos_dia: '',
  comentarios_cigarro: '',
  alcohol: '',
  alcohol_edad_inicio: '',
  alcohol_edad_termino: '',
  alcohol_frecuencia: '',
  alcohol_tipos: [{ id: 'alcohol-1', type: '', quantity: '' }],
  comentarios_alcohol: '',
  otras_sustancias: '',
  sustancias: [
    {
      id: 'substance-1',
      type: '',
      quantity: '',
      startAge: '',
      frequency: '',
      status: 'activo',
      endAge: '',
    },
  ],
  comentarios_sustancias: '',
};

export const initialHigieneForm: HigieneForm = {
  bano: '',
  aseoBucal: '',
  cambioRopa: '',
  comentario_higiene: '',
};

export const initialActivityForm: ActividadFisicaForm = {
  realizaActividad: '',
  activities: [],
  comentario_actividad: '',
};

export const initialGinecologiaForm: GinecologiaForm = {
  fecha_ultima_revision: '',
  tiene_papanicolau: '',
  fecha_papanicolau: '',
  tiene_mastografia: '',
  fecha_mastografia: '',
  edad_primera_menstruacion: '',
  duracion_menstruacion: '',
  dias_entre_periodos: '',
  dolor_menstrual: '',
  sangrado_abundante: '',
  fecha_ultima_menstruacion: '',
  metodos_anticonceptivos: [
    {
      id: 'method-1',
      type: '',
    },
  ],
  esta_embarazada: '',
  numero_embarazos: '',
  numero_partos: '',
  numero_cesareas: '',
  numero_abortos: '',
  tiene_complicaciones: '',
  complicaciones_descripcion: '',
  examina_senos: '',
  problemas_senos: '',
};

export const initialMisMedidasForm: MisMedidasForm = {
  presion_arterial: '',
  num_superior: '',
  num_inferior: '',
  peso: '',
  altura: '',
  medida_cintura: '',
};

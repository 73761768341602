/* eslint-disable */

import { resetModal, setActiveModal } from '@actions/modal/actions';
import CheckboxInput from '@components/FormElemntsLibrary/CheckboxInput';
import { Step } from '@components/FormProgressSteps/type';
import { ModalForm } from '@components/modals/ModalForm';
import { HEALTH_FORM_CONFIG } from '@constants/prueba/healthFormConfig';
import { initialNerveProblemsAndPainForm } from '@constants/prueba/initialForms';
import { zodResolver } from '@hookform/resolvers/zod';
import { RootState } from '@reducer/index';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { NerveProblemsAndPainForm } from './type';

const optionValueSchema = z.enum(['Si', 'No', '']);

const schema = z.object({
  convulsiones: optionValueSchema,
  ansiedad: optionValueSchema,
  depresion: optionValueSchema,
  estres: optionValueSchema,
  vertigo: optionValueSchema,
  espalda: optionValueSchema,
  articulaciones: optionValueSchema,
  munecas: optionValueSchema,
  hernia: optionValueSchema,
  extremidades: optionValueSchema,
  pension: optionValueSchema,
  incapacidad: optionValueSchema,
});

type FormData = z.infer<typeof schema>;

export const NerveProblemsAndPain = () => {
  const [formData, setFormData] = useState<FormData>(initialNerveProblemsAndPainForm);

  const { sexo } = useSelector((state: RootState) => state.Me);

  useEffect(() => {
    reset(formData);
  }, [formData]);

  const dispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });

  const { nerveProblemsAndPain } = HEALTH_FORM_CONFIG;
  const steps: Step[] = [
    {
      title: 'Enfermedades del corazón y crónicas',
      status: 'completed',
    },
    {
      title: 'Problemas nerviosos y dolor',
      status: 'current',
    },
    {
      title: 'Problemas respiratorios y corporales',
      status: 'default',
    },
  ];

  const handleInputChange = (name: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const guardarDatosUsuario = () => { };

  const handleSubmitForm = async () => {
    try {
      const validatedData = await schema.parseAsync(formData);

      guardarDatosUsuario();
      dispatch(resetModal('NerveProblemsAndPain'));

      if (sexo === 'H') {
        dispatch(setActiveModal('MaleHealth'));
      } else {
        dispatch(setActiveModal('FemaleHealth'));
      }
      console.log('Datos enviados:', validatedData);
    } catch (error) {
      console.error('Error en el envío del formulario:', error);
    }
  };

  return (
    <ModalForm
      title="Mi Salud"
      progressSteps={steps}
      id="MiSalud"
      submitForm={handleSubmit(handleSubmitForm)}
      hayCambios={false}
      aceptarCambios={() => { }}
      rechazarCambios={() => { }}
    >
      {nerveProblemsAndPain.fields.map((field, index) => (
        <CheckboxInput
          key={field.id}
          name={field.name}
          label={field.label}
          options={field.options}
          distribution="horizontal-inline"
          numberPosition={index}
          value={formData[field.name as keyof NerveProblemsAndPainForm]}
          setValue={(e) => handleInputChange(field.name, e.value)}
          errorMessage={errors[field.name as keyof typeof errors]?.message}
        />
      ))}
    </ModalForm>
  );
};

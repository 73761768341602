/* eslint-disable */

import {
  ACTUALIZAR_CAMPO,
  LLENAR_DATOS_PACIENTE,
  PacienteActionTypes,
} from '@actions/infoUser/types';
import { edadInitial, ICatalogosEstandar, IEdad } from '@common/types';
import { datosPersonalesInitial } from '@containers/PatientForm/Perfil/type';
import { IFamiliar } from '@containers/TabsPaciente/Antecedentes/Familiares/types';
import { IAntecedenteLaboral, IObjLaboral, laboralInitial } from '@containers/TabsPaciente/Antecedentes/Laborales/types';
import {
  actividadFisicaInitial,
  IActividadFisica,
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/ActividadFisica/types';
import {
  alimentacionInitial,
  IAlimentacion,
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Alimentacion/types';
import {
  ginecoObstetricoInitial,
  IGinecoObstetrico
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/GinecoObstetricos/types';
import {
  higieneInitial,
  IHigiene,
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Higiene/types';
import {
  historiaSexualInitial,
  IHistoriaSexual,
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/HistoriaSexual/types';
import { IInmunizacion } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Inmunizaciones/types';
import {
  IToxicomania,
  toxicomaniaInitial,
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Toxicomania/types';

import {
  IVivienda,
  viviendaInitial,
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Vivienda/types';
import { IAlergia } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/Alergias/types';

import {
  diagnosticosGeneralesInitial,
  IDiagnosticosGenerales,
} from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/DiagnosticosGenerales/types';
import { grupoEnfermedadesNegadasInitial, IEnfermedad, IEnfermedadNegada, IGrupoEnfermedadesNegadas } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/EnfermedadesTratamientos/Enfermedades/types';
import { IHospitalizacion } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/Hospitalizacion/types';
import { IProcedimiento } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/Procedimiento/types';
import { ITransfusion } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/Transfusiones/types';
import {
  aparatosYSistemasInitial,
  IAparatosYSistemas,
} from '@containers/TabsPaciente/AparatosYSistemas/types';
import { ILaboratorioSolicitud, laboratorioSolicitudInitial } from '@containers/TabsPaciente/DiagnosticoPlan/Plan/Laboratorio/types';
import { ISolicitudProcedimientos } from '@containers/TabsPaciente/DiagnosticoPlan/Plan/Procedimientos/types';
import { IPronostico } from '@containers/TabsPaciente/DiagnosticoPlan/Plan/Pronostico/types';
import { IListaRecetas, IMedicamento } from '@containers/TabsPaciente/DiagnosticoPlan/Plan/TerapiaFarmacologica/types';
import {
  exploracionFisicaInitial,
  IExploracionFisica,
} from '@containers/TabsPaciente/ExploracionFisica/types';
import { IDatosIncapacidad } from '@containers/TabsPaciente/Incapacidades/types';
import { IMcpa, mcpaInitial } from '@containers/TabsPaciente/MCPA/types';
import {
  datosUbicacionInitial,
  datosUbicacionNombresInitial,
  IDatosUbicacion,
  IDatosUbicacionNombres,
} from '@containers/TabsPaciente/PerfilPaciente/DatosDeUbicacion/types';
import { IDatosPersonales } from '@containers/TabsPaciente/PerfilPaciente/DatosPersonales/types';
import {
  discapacidadesInitial,
  IDiscapacidades,
} from '@containers/TabsPaciente/PerfilPaciente/Discapacidades/types';
import {
  IInfoEmergencia,
  infoEmergenciaInitial,
} from '@containers/TabsPaciente/PerfilPaciente/InfoEmergencia/types';
import {
  ISegurosPlanes,
  segurosPlanesInitial,
} from '@containers/TabsPaciente/PerfilPaciente/SegurosPlanes/types';

export interface InitialState {
  datosPersonales: IDatosPersonales;
  edad: IEdad;
  datosUbicacion: IDatosUbicacion;
  discapacidades: IDiscapacidades;
  infoEmergencia: IInfoEmergencia;
  segurosPlanes: ISegurosPlanes;
  MCPACompleto: IMcpa;
  enfReferidas: IEnfermedad[];
  enfNegadas: IEnfermedadNegada[];
  grupoEnfNegadas: IGrupoEnfermedadesNegadas;
  catDiagnostico: ICatalogosEstandar[];
  diagnosticosGenerales: IDiagnosticosGenerales;
  tratamientos: IMedicamento[];
  indiceReceta: number;
  recetas: IListaRecetas[];
  sinHospitalizaciones: boolean;
  hospitalizaciones: IHospitalizacion[];
  sinAlergias: boolean;
  alergias: IAlergia[];
  sinTransfusiones: boolean;
  transfusiones: ITransfusion[];
  vivienda: IVivienda;
  higiene: IHigiene;
  alimentacion: IAlimentacion;
  actividadFisica: IActividadFisica;
  inmunizaciones: IInmunizacion[];
  toxicomania: IToxicomania;
  ginecoObstetrico: IGinecoObstetrico;
  historiaSexual: IHistoriaSexual;
  sinProcQuirurgicos: boolean;
  procDiagnosticos: IProcedimiento[];
  procTerapeuticos: IProcedimiento[];
  procQuirurgicos: IProcedimiento[];
  solProcDiagnosticos: ISolicitudProcedimientos[];
  solProcTerapeuticos: ISolicitudProcedimientos[];
  solProcQuirurgicos: ISolicitudProcedimientos[];
  familiares: IFamiliar[];
  objLaboral: IObjLaboral;
  antecedentesLaborales: IAntecedenteLaboral[];
  accidentesLaborales: ICatalogosEstandar[];
  aparatosYSistemas: IAparatosYSistemas;
  exploracionFisica: IExploracionFisica;
  laboratorioSolicitud: ILaboratorioSolicitud[];
  pronosticos: IPronostico[];
  incapacidades: IDatosIncapacidad[];
  datosPersonalesUsuario: IDatosPersonales;
  datosUbicacionUsuario: IDatosUbicacion;
  datosUbicacionNombresUsuario: IDatosUbicacionNombres;
  discapacidadesUsuario: IDiscapacidades;
  infoEmergenciaUsuario: IInfoEmergencia;
  segurosPlanesUsuario: ISegurosPlanes;
  viviendaUsuario: IVivienda;
  higieneUsuario: IHigiene;
  alimentacionUsuario: IAlimentacion;
  actividadFisicaUsuario: IActividadFisica;
  toxicomaniaUsuario: IToxicomania;
  ginecoObstetricoUsuario: IGinecoObstetrico;
  cambiosDatosPersonales: string[];
  cambiosDatosUbicacion: string[];
  cambiosDiscapacidades: string[];
  cambiosInfoEmergencia: string[];
  cambiosSegurosPlanes: string[];
  cambiosVivienda: string[];
  cambiosHigiene: string[];
  cambiosAlimentacion: string[];
  cambiosActividadFisica: string[];
  cambiosToxicomania: string[];
  cambiosGinecoObstetrico: string[];
  tipoPaciente: null | 'directo' | 'candidato' | 'contratista' | 'otro';
}

const initialState: InitialState = {
  datosPersonales: datosPersonalesInitial,
  edad: edadInitial,
  datosUbicacion: datosUbicacionInitial,
  discapacidades: discapacidadesInitial,
  infoEmergencia: infoEmergenciaInitial,
  segurosPlanes: segurosPlanesInitial,
  MCPACompleto: mcpaInitial,
  enfReferidas: [],
  enfNegadas: [],
  grupoEnfNegadas: grupoEnfermedadesNegadasInitial,
  catDiagnostico: [],
  diagnosticosGenerales: diagnosticosGeneralesInitial,
  tratamientos: [],
  indiceReceta: 0,
  recetas: [{ idBD: 0, actualizado: true }],
  sinHospitalizaciones: false,
  hospitalizaciones: [],
  sinAlergias: false,
  alergias: [],
  sinTransfusiones: false,
  transfusiones: [],
  vivienda: viviendaInitial,
  higiene: higieneInitial,
  alimentacion: alimentacionInitial,
  actividadFisica: actividadFisicaInitial,
  inmunizaciones: [],
  toxicomania: toxicomaniaInitial,
  ginecoObstetrico: ginecoObstetricoInitial,
  historiaSexual: historiaSexualInitial,
  sinProcQuirurgicos: false,
  procDiagnosticos: [],
  procTerapeuticos: [],
  procQuirurgicos: [],
  solProcDiagnosticos: [],
  solProcTerapeuticos: [],
  solProcQuirurgicos: [],
  familiares: [],
  objLaboral: laboralInitial,
  antecedentesLaborales: [],
  accidentesLaborales: [],
  aparatosYSistemas: aparatosYSistemasInitial,
  exploracionFisica: exploracionFisicaInitial,
  laboratorioSolicitud: [laboratorioSolicitudInitial],
  pronosticos: [],
  incapacidades: [],
  datosPersonalesUsuario: datosPersonalesInitial,
  datosUbicacionUsuario: datosUbicacionInitial,
  datosUbicacionNombresUsuario: datosUbicacionNombresInitial,
  discapacidadesUsuario: discapacidadesInitial,
  infoEmergenciaUsuario: infoEmergenciaInitial,
  segurosPlanesUsuario: segurosPlanesInitial,
  viviendaUsuario: viviendaInitial,
  higieneUsuario: higieneInitial,
  alimentacionUsuario: alimentacionInitial,
  actividadFisicaUsuario: actividadFisicaInitial,
  toxicomaniaUsuario: toxicomaniaInitial,
  ginecoObstetricoUsuario: ginecoObstetricoInitial,
  cambiosDatosPersonales: [],
  cambiosDatosUbicacion: [],
  cambiosDiscapacidades: [],
  cambiosInfoEmergencia: [],
  cambiosSegurosPlanes: [],
  cambiosVivienda: [],
  cambiosHigiene: [],
  cambiosAlimentacion: [],
  cambiosActividadFisica: [],
  cambiosToxicomania: [],
  cambiosGinecoObstetrico: [],
  tipoPaciente: null,
};

export function InfoUser(state = initialState, action: PacienteActionTypes) {
  switch (action.type) {
    case ACTUALIZAR_CAMPO:
      return {
        ...state,
        [action.payload.campo]: action.payload.valor,
      };
    case LLENAR_DATOS_PACIENTE:
      const datos = action.payload;
      return {
        ...state,
        datosPersonales: { ...datos.perfil.datosPersonales, loaded: true },
        datosUbicacion: datos.perfil.datosUbicacion
          ? { ...datos.perfil.datosUbicacion, loaded: true }
          : state.datosUbicacion,
        discapacidades: datos.perfil.discapacidades
          ? { ...datos.perfil.discapacidades, loaded: true }
          : state.discapacidades,
        infoEmergencia: datos.perfil.infoEmergencia
          ? { ...datos.perfil.infoEmergencia, loaded: true }
          : state.infoEmergencia,
        segurosPlanes: datos.perfil.segurosPlanes
          ? { ...datos.perfil.segurosPlanes, loaded: true }
          : state.segurosPlanes,
        MCPACompleto: datos.motivoConsulta || state.MCPACompleto,
      };
    default:
      return state;
  }
}

import loading from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { ReactComponent as LogoIcon } from '@assets/logo/Logotipo_Color.svg';
import MenuPaciente from '@containers/NavbarPaciente/MenuPaciente';
import { Avatar, Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { RootState } from '@reducer/index';
import { getToken, getUser, removeUserSession } from '@utils/common';
import { limpiarReduxSesion } from '@utils/reduxCommon';
import { sendLoguot } from '@utils/sendInfo';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line import/prefer-default-export
export const MediHero: FC = () => {
  const [pacienteName, setPacienteName] = useState('');
  const [pacienteEl, setPacienteEl] = useState<null | HTMLButtonElement>(null);

  const { nombre, primerApellido, segundoApellido } = useSelector((state: RootState) => state.Me);

  useEffect(() => {
    setPacienteName(
      `${nombre ? nombre[0] : ''}${primerApellido ? primerApellido[0] : ''}${
        segundoApellido ? segundoApellido[0] : ''
      }`,
    );
  }, [nombre, primerApellido, segundoApellido]);

  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openPacienteMenu = Boolean(pacienteEl);

  const handleMenuPaciente = (e: React.MouseEvent<HTMLButtonElement>) => {
    setPacienteEl(e.currentTarget);
  };
  const handleCloseDoctor = () => {
    setPacienteEl(null);
  };
  const cerrarSesion = () => {
    dispatch(loading(true));
    const obj = { token: getToken(), usuario: getUser() };
    sendLoguot(obj)
      .then((result) => result.json())
      .then((data) => {
        if (data.code === 200) {
          removeUserSession();
          limpiarReduxSesion();
          history.push('/login-patient');
        }
        dispatch(loading(false));
      })
      .catch((err) => {
        if (err.toString() === 'SyntaxError: Unexpected token E in JSON at position 0') {
          removeUserSession();
          limpiarReduxSesion();
          history.push('/login-patient');
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        }
        dispatch(loading(false));
      });
  };
  return (
    <div className="w-full h-auto border-b-[1px] bt-[#C4C4C4] p-2 flex justify-between">
      <LogoIcon height="55" />

      <Button
        color="default"
        startIcon={<PersonIcon />}
        endIcon={
          <Avatar variant="rounded" style={{ backgroundColor: '#1D4ED8' }} alt={pacienteName}>
            {pacienteName}
          </Avatar>
        }
        onClick={handleMenuPaciente}
        disableElevation
      >
        {`${nombre} ${primerApellido} ${segundoApellido}`}
      </Button>
      <MenuPaciente
        anchorEl={pacienteEl}
        handleClose={handleCloseDoctor}
        open={openPacienteMenu}
        handleLogout={cerrarSesion}
      />
    </div>
  );
};

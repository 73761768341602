import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from '@containers/PatientForm/Menu';
import { NavigationSection } from '@components/NavigationSection';
import { NavigateButton } from '@components/NavigateButton';
import dayjs from 'dayjs';
import {
  setRutaPrincipal,
  setRutaAntecedentes,
  setRutaAntNoPatologicos,
  setRutaAntPatologicos,
  setRutaPerfil,
  setRutaPlan,
} from '@actions/rutasExpediente/actions';
import {
  setDatosExpediente,
  setDatosPersonales,
  setInmunizaciones,
  setCambiosDatosPaciente,
  setDatosExpedienteUsuario,
  setDatosUsuario,
} from '@actions/paciente/actions';
import { RootState } from 'src/store';
import {
  getPacienteDatosUsuario,
  getDatosUsuarioPaciente,
  getDatosPaciente,
  getPacienteDatosPersonales,
} from '@utils/getCatalogos';
import { setSnackComplete } from '@actions/snackbar/types';
import { setLoading } from '@actions/loading/actions';
import {
  getPaciente,
  setPacienteID,
  setPacienteIdUsuario,
  setPacienteTipoEmpleado,
} from '@actions/basicosPaciente/actions';
import { setRequest } from '@actions/request/types';
import ValidarUsuarioCorreo from '@components/ModalDialogs/ValidarUsuarioCorreo';
import { validarEmailUsuario, guardarTipoEmpleado } from '@utils/sendInfo';
import SeleccionarTipoPaciente from '@components/ModalDialogs/SelecionarTipoPaciente';
import { GLOBALS } from '@constants/prueba/globals';
import { useBreakPoint } from '@hooks/useBreakpoint';
/** Importacion de estilos */
/** Importaion de componentes de las tabs */
import PerfilPaciente from './PerfilPaciente';
import MCPA from './MCPA/index';
import Antecedentes from './Antecedentes/index';
import AparatosYSistemas from './AparatosYSistemas/index';
import ExploracionFisica from './ExploracionFisica/index';
import DiagnosticoPlan from './DiagnosticoPlan/index';
import Incapacidades from './Incapacidades';
import ResumenDeSalud from './ResumenDeSalud';
import { IDiagnosticoGeneral } from './Antecedentes/PersonalesPatologicos/DiagnosticosGenerales/types';
import { getCambiosDatosPaciente, getCambiosDatosPersonales } from './functionsCambios';
import obtenerDatosGeograficos from '@utils/geografia';
import ThankYouModal from '@components/ThankYouModal';
import { BackButton } from '@components/BackButton';
// import { Button } from '@material-ui/core';

dayjs.locale('es');

function TabsPaciente() {
  const dispatch = useDispatch();
  const {
    rutaPrincipal,
    rutaPerfil,
    rutaAntecedentes,
    rutaAntPatologicos,
    rutaAntNoPatologicos,
  } = useSelector((state: RootState) => state.RutasExpediente);
  const {
    idPaciente, idUsuario, sexo, tipoEmpleado,
  } = useSelector(
    (state: RootState) => state.BasicosPaciente,
  );
  const {
    secciones,
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
    enOrganizacion,
    pais,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { idConsulta, idPaciente: idPacienteConsulta } = useSelector(
    (state: RootState) => state.Consulta,
  );
  const { idOrganizacion, isOxxo } = useSelector(
    (state: RootState) => state.Organizaciones.organizacion
  );
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const { expediente, usuario, cambios } = useSelector((state: RootState) => state.Paciente);
  const [validacionPendiente, setValidacionPendiente] = useState<boolean>(true);
  const [showModalValidacionCorreo, setShowModalValidacionCorreo] = useState<boolean>(false);
  const [errorCorreoInvalido, setErrorCorreoInvalido] = useState<boolean>(false);
  const [showModalTipoPaciente, setShowModalTipoPaciente] = useState<boolean>(false);
  const [tipoPaciente, setTipoPaciente] = useState<
  null | 'directo' | 'candidato' | 'contratista' | 'otro'
  >(null);
  const [emailPaciente, setEmailPaciente] = useState<string>('');
  const [camposExcluidos, setCamposExcluidos] = useState<string[]>([]);
  const [camposCompletados, setCamposCompletados] = useState<string[]>([]);
  const [percentageBar, setPercentageBar] = useState<string>('0');
  const [showThankYouModal, setShowThankYouModal] = useState<boolean>(false);
  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);
  const geo: Record<string, any> = obtenerDatosGeograficos(pais);
  const mainMenuRef = useRef<HTMLDivElement | null>(null);
  const mainSecRef = useRef<HTMLDivElement | null>(null);
  const profMenuRef = useRef<HTMLDivElement | null>(null);
  const profSecRef = useRef<HTMLDivElement | null>(null);

  const handleValidarUsuarioCorreo = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: validarEmailUsuario({
          idMedico,
          idPaciente,
          idConsultorio,
          emailPaciente,
        }),
        successFunction: (result: any) => {
          dispatch(setPacienteIdUsuario(result.data));
          setShowModalValidacionCorreo(false);
          getPacienteDatosUsuario(idMedico, idPaciente, result.data, idConsultorio)
            .then((response) => response.json())
            .then((result2) => {
              if (result2.code === 200) {
                dispatch(setDatosExpedienteUsuario(result2.data));
              }
            });
          setValidacionPendiente(false);
        },
        errorFunction: (result: any) => {
          if (result.code === 201) {
            setErrorCorreoInvalido(true);
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('error')} ${result.msg}`,
              }),
            );
          }
        },
        showMsgError: false,
      }),
    );
  };

  const guardarTipo = () => {
    if (tipoPaciente) {
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: guardarTipoEmpleado({
            idMedico,
            idPaciente,
            idConsultorio,
            idOrganizacion,
            tipoEmpleado: tipoPaciente,
          }),
          successFunction: () => {
            setShowModalTipoPaciente(false);
            dispatch(setPacienteTipoEmpleado(tipoPaciente));
          },
        }),
      );
    }
  };

  const handleSeleccionarTipoPaciente = () => {
    if (idPaciente > 0) {
      guardarTipo();
    } else {
      setShowModalTipoPaciente(false);
    }
  };

  const obtenerDatosUsuarioPaciente = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getDatosUsuarioPaciente(idMiUsuario),
        successFunction: (result: any) => {
          dispatch(setDatosUsuario(result.data));
        },
      }),
    );
  };

  const obtenerDatosExpedientePaciente = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getDatosPaciente(idMedico, idPaciente, idConsultorio, idConsulta),
        successFunction: (result: any) => {
          const { datosExpediente, datosUsuario, usuariosPorValidar } = result.data;
          dispatch(setDatosExpediente(datosExpediente));
          if (datosUsuario) {
            dispatch(setDatosExpedienteUsuario(datosUsuario));
            dispatch(
              setCambiosDatosPaciente(
                getCambiosDatosPaciente(
                  datosExpediente.perfil.datosPersonales,
                  datosUsuario.perfil.datosPersonales,
                  pais,
                  t,
                ),
              ),
            );
          }
          if (usuariosPorValidar) {
            setShowModalValidacionCorreo(true);
          } else {
            setValidacionPendiente(false);
          }
        },
        errorFunction: () => {
          // Error 300: Si el paciente no tiene snapshot (nunca ha tenido una consulta)
          // se consultan los datos del expediente directamente de las tablas
          // Por alguna razón no funciona con "setRequest" (al parecer no funcionan si están anidados)
          dispatch(setLoading(true));
          getPacienteDatosPersonales(idPaciente, idMedico, idConsultorio)
            .then((response) => response.json())
            .then((result) => {
              if (result.code === 200) {
                dispatch(setDatosPersonales({ ...result.data.datosPersonales, loaded: true }));
                dispatch(setInmunizaciones(result.data.inmunizaciones));
                if (result.data.datosUsuario) {
                  dispatch(setDatosExpedienteUsuario(result.data.datosUsuario));
                  dispatch(
                    setCambiosDatosPaciente({
                      perfil: {
                        datosPersonales: getCambiosDatosPersonales(
                          result.data.datosPersonales,
                          result.data.datosUsuario.perfil.datosPersonales,
                          t,
                        ),
                        datosUbicacion: [],
                        discapacidades: [],
                        infoEmergencia: [],
                        segurosPlanes: [],
                      },
                      antNoPatologicos: {
                        vivienda: [],
                        higiene: [],
                        alimentacion: [],
                        actividadFisica: [],
                        toxicomania: [],
                        ginecoObstetrico: [],
                      },
                    }),
                  );
                }
                if (result.data.usuariosPorValidar) {
                  setShowModalValidacionCorreo(true);
                } else {
                  setValidacionPendiente(false);
                }
              }
              dispatch(setLoading(false));
            })
            .catch(() => {
              dispatch(setLoading(false));
            });
        },
        showMsgError: false,
      }),
    );
  };

  useEffect(() => {
    // Esta acción se ejecuta al hacer clic en el botón de volver a la consulta, pero se valida aquí por si se accede a través de la URL
    if (!esPaciente && idPacienteConsulta > 0 && idPaciente !== idPacienteConsulta) {
      dispatch(setPacienteID(idPacienteConsulta));
      dispatch(getPaciente(idPacienteConsulta, idMedico, idOrganizacion, idConsultorio));
    }
  }, [esPaciente, idPaciente, idPacienteConsulta]);

  useEffect(() => {
    if (!validacionPendiente && enOrganizacion && (!tipoEmpleado || tipoEmpleado === 'externo')) {
      setShowModalTipoPaciente(true);
    }
  }, [validacionPendiente]);

  useEffect(() => {
    if (tipoEmpleado) {
      setShowModalTipoPaciente(false);
    }
  }, [tipoEmpleado]);

  function hasFilledKeys(obj: Record<string, any>, keysToCheck: string[]): boolean {
    return keysToCheck.every((key) => {
        const value = obj[key];
        return value !== null && value !== undefined && value !== "";
    });
  }

  function evaluateGeoKeys(key: string): boolean {
    const value = geo[key];
    if (value === null) {
      return false; // Si es null, no cumple la condición
    }
    if (typeof value === "object" && "required" in value) {
      return value.required === true; // Evalúa el campo "required"
    }
    return false; // Si no es ni null ni un objeto con "required", no cumple la condición
  }

  useEffect(() => {
    // 1. Datos personales
    // 2. Datos de ubicación
    // 3. Discapacidades
    // 4. Información de emergencia
    // 5. Seguros y planes
    // 6. Diagnósticos generales
    // 7. Vivienda
    // 8. Higiene
    // 9. Alimentación
    // 10. Actividad física
    // 11. Toxicomanía
    // 12. Gineco-obstétricos
    // 13. Exploración física
    let exclude = sexo === 'H' ? ['gineco-obstetricos'] : [];
    if (isOxxo) {
      exclude = [ ...exclude, ...['seguros-planes', 'higiene', 'actividad-fisica']]
    }
    const total = 13 - exclude.length;
    let filled = ['datos-personales']; // El hecho de que exista implica que tiene datos personales, por lo que de entrada ya tiene una parte completada
    const camposUbicacion = [
      'territorioOriginario', 'territorioResidente'
    ].concat([
        'ageeOriginario', 'agemOriginario', 'agelOriginario',
        'ageeResidente', 'agemResidente', 'agelResidente',
        'ageaResidente', 'calle', 'num1', 'num2',
        'cp', 'referencias'
      ].filter((item: string) => evaluateGeoKeys(item))
    );
    if (!exclude.includes('datos-ubicacion') && hasFilledKeys(expediente.perfil.datosUbicacion, camposUbicacion)) {
      filled.push('datos-ubicacion')
    }
    if (!exclude.includes('discapacidades') && (
      expediente.perfil.discapacidades.sinDiscapacidad
      || (expediente.perfil.discapacidades.arrayDiscapacidades.length > 0
        && expediente.perfil.discapacidades.arrayDiscapacidades[0].tipoId.length > 0
      )
      || expediente.perfil.discapacidades.certificado.length > 0
    )) {
      filled.push('discapacidades');
    }
    if (!exclude.includes('info-emergencia') && (
      expediente.perfil.infoEmergencia.desconoceGSanguineo
      || expediente.perfil.infoEmergencia.tipoSanguineo
      || expediente.perfil.infoEmergencia.factorSanguineo
      || expediente.perfil.infoEmergencia.nombreContacto
      || expediente.perfil.infoEmergencia.apellidosContacto
      || expediente.perfil.infoEmergencia.parentescoContacto
      || expediente.perfil.infoEmergencia.emailContacto
      || expediente.perfil.infoEmergencia.ladaTel
      || expediente.perfil.infoEmergencia.telefonoContacto
      || expediente.perfil.infoEmergencia.tipoTelefonoContacto
    )) {
      filled.push('info-emergencia')
    }
    if (!exclude.includes('seguros-planes') && (
      expediente.perfil.segurosPlanes.noTieneAseguradora
      || expediente.perfil.segurosPlanes.aseguradora.id > 0
      || expediente.perfil.segurosPlanes.numPoliza
      || expediente.perfil.segurosPlanes.vigencia.dia
      || expediente.perfil.segurosPlanes.vigencia.mes
      || expediente.perfil.segurosPlanes.vigencia.anio
      || expediente.perfil.segurosPlanes.sumaAsegurada
    )) {
      filled.push('seguros-planes')
    }
    if (!exclude.includes('diagnosticos-generales')
      && expediente.antPatologicos.diagnosticosGenerales.contenidoTabla.some(diagnostico =>
        diagnostico.posee.length > 0
      )
    ) {
      filled.push('diagnosticos-generales')
    }
    if (!exclude.includes('vivienda') && (
      expediente.antNoPatologicos.vivienda.serviciosBasicos.check1
      || expediente.antNoPatologicos.vivienda.serviciosBasicos.check2
      || expediente.antNoPatologicos.vivienda.serviciosBasicos.check3
      || expediente.antNoPatologicos.vivienda.serviciosBasicos.check4
      || expediente.antNoPatologicos.vivienda.numeroHabitaciones
      || expediente.antNoPatologicos.vivienda.numeroHabitantes
      || expediente.antNoPatologicos.vivienda.animales.length > 0
      || expediente.antNoPatologicos.vivienda.trayectoTrabajo.length > 0
      || expediente.antNoPatologicos.vivienda.riesgoInundacion.length > 0
      || expediente.antNoPatologicos.vivienda.zonaDeslaves.length > 0
    )) {
      filled.push('vivienda')
    }
    if (!exclude.includes('higiene')
      && hasFilledKeys(expediente.antNoPatologicos.higiene, ['banio', 'bucal', 'ropa'])
    ) {
      filled.push('higiene')
    }
    if (!exclude.includes('alimentacion') && (
      expediente.antNoPatologicos.alimentacion.dieta
      || expediente.antNoPatologicos.alimentacion.botonComidas
      || expediente.antNoPatologicos.alimentacion.botonFruta
      || expediente.antNoPatologicos.alimentacion.botonVegetales
      || expediente.antNoPatologicos.alimentacion.comidas
      || expediente.antNoPatologicos.alimentacion.carne
      || expediente.antNoPatologicos.alimentacion.carneAlt
      || expediente.antNoPatologicos.alimentacion.pollo
      || expediente.antNoPatologicos.alimentacion.pescado
      || expediente.antNoPatologicos.alimentacion.refrescos
      || expediente.antNoPatologicos.alimentacion.postres
      || expediente.antNoPatologicos.alimentacion.arrTorFriPas
      || expediente.antNoPatologicos.alimentacion.dulPapPast
      || expediente.antNoPatologicos.alimentacion.comidasFuera
      || expediente.antNoPatologicos.alimentacion.dulces
    )) {
      filled.push('alimentacion')
    }
    if (!exclude.includes('actividad-fisica') &&  (
      expediente.antNoPatologicos.actividadFisica.realiza.length > 0
      || expediente.antNoPatologicos.actividadFisica.tipo.length > 0
    )) {
      filled.push('actividad-fisica')
    }
    if (!exclude.includes('toxicomania') && (
      expediente.antNoPatologicos.toxicomania.cafeina.consumoCafeina.length > 0
      || expediente.antNoPatologicos.toxicomania.te?.consumoTe.length > 0
      || expediente.antNoPatologicos.toxicomania.tabaco.consumoTabaco.length > 0
      || expediente.antNoPatologicos.toxicomania.alcohol.consumoAlcohol.length > 0
      || expediente.antNoPatologicos.toxicomania.otrasDrogas.consumoOtrasDrogas.length > 0
    )) {
      filled.push('toxicomania')
    }
    if (!exclude.includes('gineco-obstetricos') && (
      expediente.antNoPatologicos.ginecoObstetrico.generalidades.menarquia.length > 0
      || expediente.antNoPatologicos.ginecoObstetrico.generalidades.menarquiaNA
      || expediente.antNoPatologicos.ginecoObstetrico.generalidades.fur.dia.length > 0
      || expediente.antNoPatologicos.ginecoObstetrico.generalidades.lactancia.length > 0
      || expediente.antNoPatologicos.ginecoObstetrico.generalidades.menopausia.length > 0
      || expediente.antNoPatologicos.ginecoObstetrico.generalidades.menopausiaNA
      || expediente.antNoPatologicos.ginecoObstetrico.generalidades.sintomasDelClimaterio.length > 0
      || hasFilledKeys(
        expediente.antNoPatologicos.ginecoObstetrico.menstruacionHabitual,
        ['ritmoCada', 'ritmoDuracion', 'cantidad', 'dolor'],
      )
      || (expediente.antNoPatologicos.ginecoObstetrico.anticonceptivo.metodoEnUso.length > 0
        && expediente.antNoPatologicos.ginecoObstetrico.anticonceptivo.metodoEnUso[0].length > 0
      )
      || hasFilledKeys(
        expediente.antNoPatologicos.ginecoObstetrico.embarazos,
        ['embarazos', 'gestas', 'para', 'cesareas',
          'abortos', 'ectopicos', 'nacidosVivo'])
      || expediente.antNoPatologicos.ginecoObstetrico.citologiaRadio.length > 0
      || expediente.antNoPatologicos.ginecoObstetrico.consultaRadio?.length > 0
      || expediente.antNoPatologicos.ginecoObstetrico.estudioRadio?.length > 0
      || expediente.antNoPatologicos.ginecoObstetrico.mastografia.radio.length > 0
      || expediente.antNoPatologicos.ginecoObstetrico.ultrasonido.radio.length > 0
      || expediente.antNoPatologicos.ginecoObstetrico.sintomasClimaterio.vasomotores.radiosValues.some(v => v.length > 0)
      || expediente.antNoPatologicos.ginecoObstetrico.sintomasClimaterio.tractoGenital.radiosValues.some(v => v.length > 0)
      || expediente.antNoPatologicos.ginecoObstetrico.sintomasClimaterio.somaticos.radiosValues.some(v => v.length > 0)
      || expediente.antNoPatologicos.ginecoObstetrico.sintomasClimaterio.psicologicos.radiosValues.some(v => v.length > 0)
    )) {
      filled.push('gineco-obstetricos')
    }
    if (!exclude.includes('exploracion-fisica') && (
      expediente.exploracionFisica.diastolica.length > 0
      || expediente.exploracionFisica.sistolica.length > 0
      || expediente.exploracionFisica.pMedia.length > 0
      || expediente.exploracionFisica.pPulso.length > 0
      || expediente.exploracionFisica.pArterialRadio.length > 0
      || expediente.exploracionFisica.peso.length > 0
      || expediente.exploracionFisica.altura.length > 0
      || expediente.exploracionFisica.perCintura.length > 0
      || expediente.exploracionFisica.sitioMedicion.id > 0
    )) {
      filled.push('exploracion-fisica')
    }
    setCamposExcluidos(exclude);
    setCamposCompletados(filled);
    const percentage = String((filled.length * 100 / total).toFixed(2));
    const splited = percentage.split('.')
    setPercentageBar(splited[1] === '00' ? splited[0] : percentage);
  }, [expediente]);

  useEffect(() => {
    if (percentageBar === '100' && !['main', 'datos-personales'].includes(rutaPerfil)) {
      setShowThankYouModal(true);
    }
  }, [percentageBar]);

  useEffect(() => {
    if (idMedico) {
      if (esPaciente) {
        // Si el usuario es el paciente se consultan sus datos de usuario
        obtenerDatosUsuarioPaciente();
      } else if (
        idPaciente > 0 &&
        idConsultorio > 0 &&
        idConsulta > 0 &&
        !expediente.perfil.datosPersonales.loaded
      ) {
        // Se consultan los datos del expediente y los datos del usuario del paciente
        obtenerDatosExpedientePaciente();
      } else {
        setValidacionPendiente(false);
      }
    }
  }, [idMedico, idPaciente, idConsultorio, idConsulta]);

  useEffect(() => {
    if (rutaPrincipal === 'main') {
      guardarTipo();
    }
  }, [rutaPrincipal]);

  // Si el paciente es nuevo, se selecciona el tipo de empleado al principio, pero se guarda en el
  // back luego de que se guarda el registro del paciente, es decir, cuando idPaciente cambia a
  // mayor a 0
  useEffect(() => {
    if (idPaciente > 0 && !tipoEmpleado && tipoPaciente) {
      guardarTipo();
    }
  }, [idPaciente]);

  useEffect(() => {
    if (esPaciente) {
      dispatch(setRutaPrincipal(isWideScreen ? 'perfil' : 'main'));
      dispatch(setRutaPerfil(isWideScreen ? 'datos-personales' : 'main'));
    }
  }, [esPaciente, isWideScreen]);

  const groups = esPaciente
    ? [
      'datos_personales',
      'estilo_vida',
      'hist_ginecologica',
      'medidas_actuales',
      'mi_tablero'
    ].filter(g => sexo === 'M' || g !== 'hist_ginecologica')
    : [
      'datos_personales',
      'mcpa_antecedentes_aparatosysistemas',
      'medidas_actuales',
      'diagnosticoyplan_incapacidades'
    ];

  const toggleComponents = (show = 'mainSections') => {
    if (!isWideScreen) {
      if (show === 'mainSections') {
        mainSecRef.current?.classList.remove('animate-popRight');
        mainSecRef.current?.classList.add('animate-popLeft');
        mainSecRef.current?.classList.remove('hidden');
        setTimeout(() => {
          mainMenuRef.current?.classList.add('hidden');
          mainMenuRef.current?.classList.remove('overflow-y-auto');
        }, 300);
      } else if (show === 'mainMenu') {
        mainSecRef.current?.classList.remove('animate-popLeft');
        mainSecRef.current?.classList.add('animate-popRight');
        mainMenuRef.current?.classList.remove('hidden');
        setTimeout(() => {
          dispatch(setRutaPrincipal('main'));
          mainMenuRef.current?.classList.add('overflow-y-auto');
          mainSecRef.current?.classList.add('hidden');
        }, 300);  
      } else if (show === 'profileSections') {
        profSecRef.current?.classList.remove('animate-popRight');
        profSecRef.current?.classList.add('animate-popLeft');
        profSecRef.current?.classList.remove('hidden');
        setTimeout(() => {
          profMenuRef.current?.classList.add('hidden');
        }, 300);
      } else if (show === 'profileMenu') {
        profSecRef.current?.classList.remove('animate-popLeft');
        profSecRef.current?.classList.add('animate-popRight');
        profMenuRef.current?.classList.remove('hidden');
        setTimeout(() => {
          dispatch(setRutaPerfil('main'));
          profSecRef.current?.classList.add('hidden');
        }, 300);  
      }
    }
  };

  const screens: {
    group: string;
    name: string;
    title?: string;
    position?: string,
    isActive: boolean;
    sections: { min: number, max?: number };
    onClick: () => void;
    changes?: boolean;
    completed?: boolean;
    disabled?: boolean;
    hide?: boolean;
  }[] = [
    {
      group: 'datos_personales',
      name: 'perfil',
      position: 'first',
      isActive: rutaPrincipal === 'perfil',
      sections: { min: 7, max: 11 },
      onClick: () => {
        dispatch(setRutaPrincipal('perfil'));
        dispatch(setRutaPerfil(isWideScreen ? 'datos-personales' : 'main'));
        toggleComponents();
      },
      completed: [
        'datos-personales', 'datos-ubicacion', 'discapacidades','info-emergencia', 'seguros-planes'
      ].every(campo => camposCompletados.includes(campo) || camposExcluidos.includes(campo)),
      changes: [
        cambios.perfil.datosPersonales,
        cambios.perfil.datosUbicacion,
        cambios.perfil.discapacidades,
        cambios.perfil.infoEmergencia,
        cambios.perfil.segurosPlanes,
      ].some(cambio => cambio.some((c: string) => c.length > 0)),
    },
    {
      group: 'datos_personales',
      name: 'diagnosticos-generales',
      title: t('mi_salud'),
      isActive: rutaPrincipal === 'antecedentes'
        && rutaAntecedentes === 'personales-patologicos'
        && rutaAntPatologicos === 'diagnosticos-generales',
      sections: { min: 14 },
      onClick: () => {
        dispatch(setRutaPrincipal('antecedentes'));
        dispatch(setRutaAntecedentes('personales-patologicos'));
        dispatch(setRutaAntPatologicos('diagnosticos-generales'));
        toggleComponents();
      },
      completed:
        !esPaciente
        ||camposCompletados.includes('diagnosticos-generales')
        || camposExcluidos.includes('diagnosticos-generales'),
      changes: usuario.antPatologicos.diagnosticosGenerales.contenidoTabla?.some(
        (diagnostico: IDiagnosticoGeneral) => diagnostico.posee === 'si' && !diagnostico.validado
      ),
      hide: !esPaciente,
    },
    {
      group: 'mcpa_antecedentes_aparatosysistemas',
      name: 'mcpa',
      isActive: rutaPrincipal === 'mcpa',
      sections: { min: 12, max: 13 },
      onClick: () => dispatch(setRutaPrincipal('mcpa')),
    },
    {
      group: 'mcpa_antecedentes_aparatosysistemas',
      name: 'antecedentes',
      isActive: rutaPrincipal === 'antecedentes',
      sections: { min: 14, max: 32 },
      onClick: () => {
        dispatch(setRutaAntPatologicos('enfermedades-tratamientos'));
        dispatch(setRutaAntNoPatologicos('vivienda'));
        dispatch(setRutaAntecedentes('personales-patologicos'));
        dispatch(setRutaPlan('laboratorio'));
        dispatch(setRutaPrincipal('antecedentes'));
      },
      changes: usuario.antPatologicos.diagnosticosGenerales.contenidoTabla?.some(
        (diagnostico: IDiagnosticoGeneral) => diagnostico.posee === 'si' && !diagnostico.validado
      ) || [
        cambios.antNoPatologicos.vivienda,
        cambios.antNoPatologicos.higiene,
        cambios.antNoPatologicos.alimentacion,
        cambios.antNoPatologicos.actividadFisica,
        cambios.antNoPatologicos.toxicomania,
        cambios.antNoPatologicos.ginecoObstetrico,
      ].some(cambio => cambio.some((c: string) => c.length > 0)),
    },
    {
      group: 'estilo_vida',
      name: 'vivienda',
      title: t('antecedentes-pnp-vivienda'),
      isActive: rutaPrincipal === 'antecedentes'
        && rutaAntecedentes === 'personales-no-patologicos'
        && rutaAntNoPatologicos === 'vivienda',
      sections: { min: 23 },
      onClick: () => {
        dispatch(setRutaPrincipal('antecedentes'));
        dispatch(setRutaAntecedentes('personales-no-patologicos'));
        dispatch(setRutaAntNoPatologicos('vivienda'));
        toggleComponents();
      },
      completed:
        !esPaciente
        ||camposCompletados.includes('vivienda')
        || camposExcluidos.includes('vivienda'),
      changes: cambios.antNoPatologicos.vivienda.some((c: string) => c.length > 0),
    },
    {
      group: 'estilo_vida',
      name: 'higiene',
      title: t('antecedentes-pnp-higiene'),
      isActive: rutaPrincipal === 'antecedentes'
        && rutaAntecedentes === 'personales-no-patologicos'
        && rutaAntNoPatologicos === 'higiene',
      sections: { min: 24 },
      onClick: () => {
        dispatch(setRutaPrincipal('antecedentes'));
        dispatch(setRutaAntecedentes('personales-no-patologicos'));
        dispatch(setRutaAntNoPatologicos('higiene'));
        toggleComponents();
      },
      completed:
        !esPaciente
        ||camposCompletados.includes('higiene')
        || camposExcluidos.includes('higiene'),
      changes: cambios.antNoPatologicos.higiene.some((c: string) => c.length > 0),
      hide: isOxxo,
    },
    {
      group: 'estilo_vida',
      name: 'alimentacion',
      title: t('antecedentes-pnp-alimentacion'),
      isActive: rutaPrincipal === 'antecedentes'
        && rutaAntecedentes === 'personales-no-patologicos'
        && rutaAntNoPatologicos === 'alimentacion',
      sections: { min: 25 },
      onClick: () => {
        dispatch(setRutaPrincipal('antecedentes'));
        dispatch(setRutaAntecedentes('personales-no-patologicos'));
        dispatch(setRutaAntNoPatologicos('alimentacion'));
        toggleComponents();
      },
      completed:
        !esPaciente
        ||camposCompletados.includes('alimentacion')
        || camposExcluidos.includes('alimentacion'),
      changes: cambios.antNoPatologicos.alimentacion.some((c: string) => c.length > 0),
    },
    {
      group: 'estilo_vida',
      name: 'actividad',
      title: t('antecedentes-pnp-actividad'),
      isActive: rutaPrincipal === 'antecedentes'
        && rutaAntecedentes === 'personales-no-patologicos'
        && rutaAntNoPatologicos === 'actividad-fisica',
      sections: { min: 26 },
      onClick: () => {
        dispatch(setRutaPrincipal('antecedentes'));
        dispatch(setRutaAntecedentes('personales-no-patologicos'));
        dispatch(setRutaAntNoPatologicos('actividad-fisica'));
        toggleComponents();
      },
      completed:
        !esPaciente
        ||camposCompletados.includes('actividad-fisica')
        || camposExcluidos.includes('actividad-fisica'),
      changes: cambios.antNoPatologicos.actividadFisica.some((c: string) => c.length > 0),
      hide: isOxxo,
    },
    {
      group: 'estilo_vida',
      name: 'antecedentes-pnp-toxicomania',
      title: t('antecedentes-pnp-toxicomania'),
      isActive: rutaPrincipal === 'antecedentes'
        && rutaAntecedentes === 'personales-no-patologicos'
        && rutaAntNoPatologicos === 'toxicomania',
      sections: { min: 28 },
      onClick: () => {
        dispatch(setRutaPrincipal('antecedentes'));
        dispatch(setRutaAntecedentes('personales-no-patologicos'));
        dispatch(setRutaAntNoPatologicos('toxicomania'));
        toggleComponents();
      },
      completed:
        !esPaciente
        ||camposCompletados.includes('toxicomania')
        || camposExcluidos.includes('toxicomania'),
      changes: cambios.antNoPatologicos.toxicomania.some((c: string) => c.length > 0),
    },
    {
      group: 'hist_ginecologica',
      name: 'gineco-obstetricos',
      title: t('antecedentes-pnp-gineco'),
      isActive: rutaPrincipal === 'antecedentes'
        && rutaAntecedentes === 'personales-no-patologicos'
        && rutaAntNoPatologicos === 'gineco-obstetricos',
      sections: { min: 29 },
      onClick: () => {
        dispatch(setRutaPrincipal('antecedentes'));
        dispatch(setRutaAntecedentes('personales-no-patologicos'));
        dispatch(setRutaAntNoPatologicos('gineco-obstetricos'));
        toggleComponents();
      },
      completed:
        !esPaciente
        ||camposCompletados.includes('gineco-obstetricos')
        || camposExcluidos.includes('gineco-obstetricos'),
      changes: cambios.antNoPatologicos.ginecoObstetrico.some((c: string) => c.length > 0),
    },
    {
      group: 'mcpa_antecedentes_aparatosysistemas',
      name: 'aparatos-y-sistemas',
      isActive: rutaPrincipal === 'aparatos-y-sistemas',
      sections: { min: 33, max: 45 },
      onClick: () => dispatch(setRutaPrincipal('aparatos-y-sistemas')),
    },
    {
      group: 'medidas_actuales',
      name: 'exploracion-fisica',
      title: t('exploracion_fisica'),
      isActive: rutaPrincipal === 'exploracion-fisica',
      sections: { min: 46 },
      onClick: () => {
        dispatch(setRutaPrincipal('exploracion-fisica'));
        toggleComponents();
      },
      completed:
        !esPaciente
        ||camposCompletados.includes('exploracion-fisica')
        || camposExcluidos.includes('exploracion-fisica'),
    },
    {
      group: 'diagnosticoyplan_incapacidades',
      name: 'diagnostico-y-plan',
      isActive: rutaPrincipal === 'diagnostico-y-plan',
      sections: { min: 47, max: 58 },
      onClick: () => dispatch(setRutaPrincipal('diagnostico-y-plan')),
    },
    {
      group: 'diagnosticoyplan_incapacidades',
      name: 'incapacidades',
      position: 'last',
      isActive: rutaPrincipal === 'incapacidades',
      sections: { min: 80 },
      onClick: () => dispatch(setRutaPrincipal('incapacidades')),
    },
    {
      group: 'mi_tablero',
      name: 'resumen_de_salud',
      position: 'last',
      isActive: rutaPrincipal === 'resumen-de-salud',
      sections: { min: 74 },
      onClick: () => {
        dispatch(setRutaPrincipal('resumen-de-salud'));
        toggleComponents();
      },
      hide: !esPaciente,
    },
  ].filter(boton =>
    !boton.hide && !!secciones.find((valor) => boton.sections.max
      ? valor.idSeccion >= boton.sections.min && valor.idSeccion <= boton.sections.max
      : valor.idSeccion === boton.sections.min
    )
  );

  return (
    <div
      className={`w-full min-h-screen h-auto md:pt-4 z-10 ${
        esPaciente ? 'md:w-[100%]' : 'md:w-[calc(100vw-80px)]'
      } md:right-0 md:flex md:flex-col md:items-center overflow-hidden md:mt-4 md:min-h-[73dvh] md:h-fit`}
    >
      <div
        className={`${isWideScreen ? '' : 'absolute'} w-full md:px-4 inset-0`}
        ref={mainMenuRef}
      >
        <Menu title={t('mi_expediente')} percentageBar={percentageBar}>
          {groups.map((group) => (
            <NavigationSection key={group} title={t(group)}>
              {screens.filter(btn => btn.group === group).map(btn => (
                <NavigateButton
                  key={btn.name}
                  title={btn.title || t(btn.name)}
                  onClick={btn.onClick}
                  position={btn.position}
                  isActive={btn.isActive}
                  disabled={btn.name !== 'perfil' && idPaciente <= 0}
                  alert={!esPaciente && idUsuario > 0 && btn.changes}
                  completed={btn.completed}
                />
              ))}
            </NavigationSection>
          ))}
        </Menu>
      </div>
      <div
        className="w-full rounded-lg md:my-4"
        ref={mainSecRef}
      >
        {!isWideScreen &&
          <div className='bg-white px-3 py-4'>
            <BackButton onClick={() => {
              toggleComponents(rutaPerfil !== 'main' ? 'profileMenu' : 'mainMenu');
            }} />
          </div>
        }
        {rutaPrincipal === 'perfil' &&
          <PerfilPaciente
            camposCompletados={camposCompletados}
            toggleComponents={toggleComponents}
            menuRef={profMenuRef}
            sectionsRef={profSecRef}
          />
        }
        {idPaciente > 0 && (
          <>
            {rutaPrincipal === 'mcpa' && <MCPA />}
            {rutaPrincipal === 'antecedentes' && <Antecedentes />}
            {rutaPrincipal === 'aparatos-y-sistemas' && <AparatosYSistemas />}
            {rutaPrincipal === 'exploracion-fisica' && <ExploracionFisica />}
            {rutaPrincipal === 'diagnostico-y-plan' && <DiagnosticoPlan />}
            {rutaPrincipal === 'incapacidades' && <Incapacidades />}
            {rutaPrincipal === 'resumen-de-salud' && esPaciente && <ResumenDeSalud />}
          </>
        )}
      </div>
      <ValidarUsuarioCorreo
        open={showModalValidacionCorreo}
        email={emailPaciente}
        setEmail={setEmailPaciente}
        emailError={errorCorreoInvalido}
        setEmailError={setErrorCorreoInvalido}
        callBackAceptar={handleValidarUsuarioCorreo}
        callBackClose={() => {
          setShowModalValidacionCorreo(false);
          setValidacionPendiente(false);
        }}
      />
      <SeleccionarTipoPaciente
        open={showModalTipoPaciente}
        esExterno={tipoEmpleado === 'externo'}
        tipoPaciente={tipoPaciente}
        setTipoPaciente={setTipoPaciente}
        callBackAceptar={handleSeleccionarTipoPaciente}
        callBackClose={() => setShowModalTipoPaciente(false)}
      />
      {showThankYouModal &&
        <ThankYouModal
          onClose={() => {
            dispatch(setRutaPrincipal('resumen-de-salud'));
            setShowThankYouModal(false);
          }}
        />
      }
    </div>
  );
}

export default TabsPaciente;

import { GLOBALS } from '@constants/prueba/globals';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { FC } from 'react';
import { NavigationSectionProps } from './type';

export const NavigationSection: FC<NavigationSectionProps> = ({ title, children }) => {
  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);
  return (
    <section className="flex flex-col items-center align-middle w-full h-auto mb-4 md:w-fit md:mb-0 md:flex-row">
      {!isWideScreen && (
        <h2 className="w-full max-w-[410px] text-lg font-semibold text-gray-800">{title}</h2>
      )}
      <div className="w-full max-w-[410px] my-5 flex align-middle flex-wrap gap-[12px] justify-center xs:justify-between md:w-fit md:max-w-none md:flex-row md:justify-start md:h-[100%] md:my-0 md:gap-[0px]">
        {children}
      </div>
      {!isWideScreen && <hr className="w-full h-[1px] bg-[#C4C4C4]" />}
    </section>
  );
};

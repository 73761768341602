import { SubModalForm } from '@components/modals/SubModalForm';

export const Tratamientos = () => (
  <SubModalForm
    title="Detalle de Medicamento"
    id="Tratamientos"
    displayButton={false}
    saveModal={() => { }}
  >
    <div className="p-4">
      <h2 className="text-lg font-medium text-blue-800 mb-4">
        NAPROXENO, PARACETAMOL - Suspensión - (naproxeno 125 mg, paracetamol 100 mg)/5 mL
      </h2>

      <div className="">
        <div>
          <p className="font-medium">Tomar: </p>
          <p>1 Barra(s) Cada 3 horas por 1 Mes(es)</p>
        </div>

        <div className="">
          <div>
            <p className="text-sm text-gray-600">Fecha del inicio del tratamiento:</p>
            <p>2024-oct-10</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Fecha estimada del fin del tratamiento:</p>
            <p>2024-nov-10</p>
          </div>
        </div>

        <div>
          <h3 className="font-medium mb-2">Análisis de la prescripción</h3>
          <p>Alergia: Naproxeno Grado 1</p>
        </div>
      </div>
    </div>
  </SubModalForm>
);

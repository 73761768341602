import { resetModal } from '@actions/modal/actions';
import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import { SubModalForm } from '@components/modals/SubModalForm';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TiempoDeUsoOption, TiempoDeUsoProps, TiempoUsoValue } from './type';

export const TiempoDeUso: FC<TiempoDeUsoProps> = ({ currentValue, onUpdate }) => {
  const [selectedTime, setSelectedTime] = useState<TiempoUsoValue>(
    (currentValue as TiempoUsoValue) || '',
  );

  const dispatch = useDispatch();

  const options: TiempoDeUsoOption[] = [
    { value: '1_year', label: '≤ 1 año' },
    { value: '2_years', label: '2 años' },
    { value: '3_years', label: '3 años' },
    { value: '4_years', label: '4 años' },
    { value: '5_years', label: '5 años' },
    { value: '6_years', label: '6 años' },
    { value: '7_years', label: '7 años' },
    { value: '8_years', label: '8 años' },
    { value: '9_years', label: '9 años' },
    { value: '10_plus_years', label: '≥ 10 años' },
  ];

  const handleTimeSelect = (e: { name: string; value: any }) => {
    const { value } = e;

    setSelectedTime(value);
  };

  const handleSubmit = () => {
    onUpdate(selectedTime);
    dispatch(resetModal('TiempoDeUso'));
  };

  return (
    <SubModalForm title="Tiempo de uso" id="TiempoDeUso" saveModal={handleSubmit}>
      <CheckboxInput
        name="tiempo_uso"
        options={options}
        allowMultiple={false}
        distribution="vertical"
        setValue={handleTimeSelect}
        value={selectedTime}
      />
    </SubModalForm>
  );
};

export default TiempoDeUso;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from './type';

// eslint-disable-next-line import/prefer-default-export
export const Button: FC<ButtonProps> = ({
  type = 'button',
  name = 'button',
  label: externalLabel,
  color = 'blue',
  onClick,
  // startIcon,
  disable = false,
}) => {
  const { t } = useTranslation();
  const label = externalLabel === undefined ? t(name) : externalLabel;

  const getStyle = () => {
    switch(color) {
      case 'green':
        return 'bg-[#0AE639] hover:bg-green-700';
      case 'red':
        return 'bg-[#F63B44] hover:bg-red-700';
      case 'orange':
        return 'bg-[#F6B43B] hover:bg-orange-700';
      default:
        return 'bg-[#3B82F6] hover:bg-blue-700';
    }
  };

  return (
    <button
      type={type}
      className={`w-full h-11 px-2 rounded-md text-base text-white shadow-gray-500 shadow-sm ${
        getStyle()
      } cursor-pointer transition-all duration-200 disabled:opacity-75 disabled:cursor-not-allowed`}
      disabled={disable}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl, Select, MenuItem, IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from '@components/DeleteDialog';
import { IMetodoAnticonceptivoProps } from './types';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';

function MetodoAnticonceptivo(props: IMetodoAnticonceptivoProps) {
  const {
    index, ginecoObstetrico, setGinecoObstetrico, handleDeleteRow,
  } = props;
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');

  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);

  const metodos = [
    'ninguno',
    'anillo_vaginal',
    'anticonceptivo_de_emergencia',
    'anticonceptivo_oral',
    'condon',
    'diafragma_o_cupula_vaginal',
    'dispositivo_intrauterino',
    'espermicida',
    'esponja',
    'esterilizacion_femenina',
    'esterilizacion_masculina',
    'implante',
    'inyectable',
    'metodos_basados_en_el_conocimiento_de_la_fertilidad',
  ];
  const tiempos = ['≤1a', '2a', '3a', '4a', '5a', '6a', '7a', '8a', '9a', '≥10a'];

  const handleChangeMetodoEnUso = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const arr = ginecoObstetrico.anticonceptivo.metodoEnUso.map((item, j) => {
      if (j === index) {
        return val;
      }
      return item;
    });
    setGinecoObstetrico({
      ...ginecoObstetrico,
      anticonceptivo: {
        ...ginecoObstetrico.anticonceptivo,
        metodoEnUso: arr,
      },
    });
  };

  const handleChangeTiempoEnUso = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const arr = ginecoObstetrico.anticonceptivo.tiempoEnUso.map((item, j) => {
      if (j === index) {
        return val;
      }
      return item;
    });
    setGinecoObstetrico({
      ...ginecoObstetrico,
      anticonceptivo: {
        ...ginecoObstetrico.anticonceptivo,
        tiempoEnUso: arr,
      },
    });
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };

  return (
    <>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={`${t('_esta_seguro_que_desea_eliminar_el_metodo_anticonceptivo')}${
          ginecoObstetrico.anticonceptivo.metodoEnUso[index]
            ? ` ${t(ginecoObstetrico.anticonceptivo.metodoEnUso[index])}`
            : ''
        }${t('_?')}`}
        callBackAceptar={() => handleDeleteRow(index)}
        callBackClose={handleAlertClose}
      />
      <div className="grid grid-cols-7 px-4 pb-4 mt-4 border border-solid border-gray-300 rounded gap-x-4">
        <h3 className="col-span-3 text-gray-600 font-medium">
          {t('metodo_anticonceptivo_en_uso')}
        </h3>
        <h3 className="col-span-3 text-gray-600 font-medium">{t('tiempo_en_uso')}</h3>
        <div className="row-span-2 flex items-center justify-center">
          <IconButton color="primary" onClick={() => setDeleteAlert(true)}>
            <DeleteIcon />
          </IconButton>
        </div>
        <div className="col-span-3">
          <FormControl variant="outlined" fullWidth>
            <Select
              displayEmpty
              value={ginecoObstetrico.anticonceptivo.metodoEnUso[index]}
              onChange={handleChangeMetodoEnUso}
            >
              <MenuItem value="" disabled>
                <span className="text-gray-400">{t('selecciona')}</span>
              </MenuItem>
              {metodos.map((metodo: string) => (
                <MenuItem value={metodo}>{t(metodo)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-span-3">
          <FormControl
            variant="outlined"
            fullWidth
            disabled={
              ginecoObstetrico.anticonceptivo.metodoEnUso[index] === '' ||
              ginecoObstetrico.anticonceptivo.metodoEnUso[index] === 'ninguno'
            }
          >
            <Select
              displayEmpty
              value={ginecoObstetrico.anticonceptivo.tiempoEnUso[index]}
              onChange={handleChangeTiempoEnUso}
            >
              <MenuItem value="" disabled>
                <span className="text-gray-400">Selecciona</span>
              </MenuItem>
              {tiempos.map((tiempo: string) => (
                <MenuItem value={tiempo}>{t(tiempo)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
}

export default MetodoAnticonceptivo;

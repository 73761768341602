import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { setDiagnosticosGeneralesUsuario } from '@actions/paciente/actions';
import { RootState } from 'src/store';
import { enfermedadesGuardarDiagnosticosUsuario } from '@utils/sendInfo';
import { setRequest } from '@actions/request/types';
import { IListaDiagnosticosProps } from './types';
import { IDiagnosticoGeneral } from '../../../../DiagnosticosGenerales/types';

function ListaDiagnosticos({ diagnostico, diagnosticosGenerales }: IListaDiagnosticosProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);

  const handleValidateDiagnostico = () => {
    const index = diagnosticosGenerales.contenidoTabla.findIndex(
      (d: IDiagnosticoGeneral) => d.nombre === diagnostico.nombre,
    );
    const contenidoTabla = [...diagnosticosGenerales.contenidoTabla];
    contenidoTabla[index].validado = true;
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: enfermedadesGuardarDiagnosticosUsuario({
          idUsuario,
          contenidoTabla,
        }),
        successFunction: () =>
          dispatch(setDiagnosticosGeneralesUsuario({ ...diagnosticosGenerales, contenidoTabla })),
      }),
    );
  };

  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
      <div
        className={`border-0 border-solid border-${
          diagnostico.posee === 'si' ? 'blue' : 'red'
        }-500 rounded border-l-8 p-4 w-full`}
      >
        <h2 className="m-0 overflow-ellipsis overflow-hidden font-normal">
          {t(diagnostico.nombre)}
        </h2>
        {(diagnostico.medicamentos.length || diagnostico.noMedicamento) && (
          <div className="max-h-24 overflow-y-auto mt-2 w-full">
            <h4 className="font-normal text-gray-500 m-0 mr-4">
              {diagnostico.noMedicamento
                ? t('no_toma_medicamentos')
                : `${t('medicamentos')}: ${diagnostico.medicamentos}`}
            </h4>
          </div>
        )}
      </div>
      <div className="min-w-max self-center">
        <IconButton aria-label="edit" color="primary" onClick={handleValidateDiagnostico}>
          <CheckIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default ListaDiagnosticos;

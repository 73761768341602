import { TFunction } from 'react-i18next';
import obtenerDatosGeograficos from '@utils/geografia';
import { IExpediente, IUsuarioPaciente } from '@actions/paciente/types';
import { IDatosPersonales } from './PerfilPaciente/DatosPersonales/types';
import { IDatosUbicacion, IDatosUbicacionNombres } from './PerfilPaciente/DatosDeUbicacion/types';
import { IDiscapacidad, IDiscapacidades } from './PerfilPaciente/Discapacidades/types';
import { IInfoEmergencia } from './PerfilPaciente/InfoEmergencia/types';
import { ISegurosPlanes } from './PerfilPaciente/SegurosPlanes/types';
import { ITipoMascota, IVivienda } from './Antecedentes/PersonalesNoPatologicos/Vivienda/types';
import { IHigiene } from './Antecedentes/PersonalesNoPatologicos/Higiene/types';
import {
  IAlimentacion,
  IAlimentos,
} from './Antecedentes/PersonalesNoPatologicos/Alimentacion/types';
import {
  IActividadFisica,
  ITipoActividad,
} from './Antecedentes/PersonalesNoPatologicos/ActividadFisica/types';
import {
  ITipoAlcohol,
  IToxicomania,
} from './Antecedentes/PersonalesNoPatologicos/Toxicomania/types';
import { IGinecoObstetrico } from './Antecedentes/PersonalesNoPatologicos/GinecoObstetricos/types';

export function getCambiosDatosPersonales(
  datosPersonales: IDatosPersonales,
  datosPersonalesUsuario: IDatosPersonales,
  t: TFunction,
) {
  const vacio = `(${t('vacio')})`;
  const cambios = [];
  if (datosPersonalesUsuario.nombre !== datosPersonales.nombre) {
    cambios.push(`${t('nombre(s)')}: ${datosPersonalesUsuario.nombre}`);
  }
  if (datosPersonalesUsuario.primerApellido !== datosPersonales.primerApellido) {
    cambios.push(`${t('primer_apellido')}: ${datosPersonalesUsuario.primerApellido}`);
  }
  if (datosPersonalesUsuario.segundoApellido !== datosPersonales.segundoApellido) {
    cambios.push(`${t('segundo_apellido')}: ${datosPersonalesUsuario.segundoApellido || vacio}`);
  }
  if (datosPersonalesUsuario.sexo !== datosPersonales.sexo) {
    cambios.push(`${t('sexo')}: ${datosPersonalesUsuario.sexo === 'H' ? t('hombre') : t('mujer')}`);
  }
  if (
    JSON.stringify(datosPersonales.fechaNac) !== JSON.stringify(datosPersonalesUsuario.fechaNac)
  ) {
    cambios.push(
      `${t('fecha_nacimiento')}: ${datosPersonalesUsuario.fechaNac.dia}/${
        datosPersonalesUsuario.fechaNac.mes
      }/${datosPersonalesUsuario.fechaNac.anio}`,
    );
  }
  if (datosPersonalesUsuario.nacionalidad.id !== datosPersonales.nacionalidad.id) {
    cambios.push(
      `${t('nacionalidad')}: ${
        datosPersonalesUsuario.nacionalidad.id ? datosPersonalesUsuario.nacionalidad.label : vacio
      }`,
    );
  }
  if (datosPersonalesUsuario.grupoEtnico.id !== datosPersonales.grupoEtnico.id) {
    cambios.push(
      `${t('grupo_etnolinguistico')}: ${
        datosPersonalesUsuario.grupoEtnico.id ? datosPersonalesUsuario.grupoEtnico.label : vacio
      }`,
    );
  }
  if (datosPersonalesUsuario.estadoCivil.id !== datosPersonales.estadoCivil.id) {
    cambios.push(
      `${t('estado_civil')}: ${
        datosPersonalesUsuario.estadoCivil.id ? datosPersonalesUsuario.estadoCivil.label : vacio
      }`,
    );
  }
  if (datosPersonalesUsuario.escolaridad.id !== datosPersonales.escolaridad.id) {
    cambios.push(
      `${t('escolaridad')}: ${
        datosPersonalesUsuario.escolaridad.id ? datosPersonalesUsuario.escolaridad.label : vacio
      }`,
    );
  }
  if (datosPersonalesUsuario.religion.id !== datosPersonales.religion.id) {
    cambios.push(
      `${t('religion')}: ${
        datosPersonalesUsuario.religion.id ? datosPersonalesUsuario.religion.label : vacio
      }`,
    );
  }
  if (datosPersonalesUsuario.tipoIdentificacion.id !== datosPersonales.tipoIdentificacion.id) {
    cambios.push(
      `${t('tipo_identificacion')}: ${
        datosPersonalesUsuario.tipoIdentificacion.id
          ? datosPersonalesUsuario.tipoIdentificacion.label
          : vacio
      }`,
    );
  }
  if (datosPersonalesUsuario.numeroDocumento !== datosPersonales.numeroDocumento) {
    cambios.push(`${t('numero_documento')}: ${datosPersonalesUsuario.numeroDocumento || vacio}`);
  }
  if (datosPersonalesUsuario.ocupacion !== datosPersonales.ocupacion) {
    cambios.push(`${t('ocupacion')}: ${datosPersonalesUsuario.ocupacion || vacio}`);
  }
  if (datosPersonalesUsuario.tipoOcupacion.id !== datosPersonales.tipoOcupacion.id) {
    cambios.push(
      `${t('tipo_ocupacion')}: ${
        datosPersonalesUsuario.tipoOcupacion.id ? datosPersonalesUsuario.tipoOcupacion.label : vacio
      }`,
    );
  }
  if (datosPersonalesUsuario.tiempoOcupacion.id !== datosPersonales.tiempoOcupacion.id) {
    cambios.push(
      `${t('tiempo_ocupacion')}: ${
        datosPersonalesUsuario.tiempoOcupacion.id
          ? datosPersonalesUsuario.tiempoOcupacion.label
          : vacio
      }`,
    );
  }
  if (JSON.stringify(datosPersonalesUsuario.celular) !== JSON.stringify(datosPersonales.celular)) {
    cambios.push(
      `${t('telefono_celular')}: ${
        datosPersonalesUsuario.noTieneCelular
          ? t('no_tiene')
          : `${datosPersonalesUsuario.celular.codigo} ${datosPersonalesUsuario.celular.numero}`
      }`,
    );
  }
  if (JSON.stringify(datosPersonalesUsuario.fijo) !== JSON.stringify(datosPersonales.fijo)) {
    cambios.push(
      `${t('telefono_fijo')}: ${
        datosPersonalesUsuario.fijo
          ? `${datosPersonalesUsuario.fijo.codigo} ${datosPersonalesUsuario.fijo.numero}`
          : vacio
      }`,
    );
  }
  if (datosPersonalesUsuario.email !== datosPersonales.email) {
    cambios.push(
      `${t('email')}: ${
        datosPersonalesUsuario.noTieneEmail ? t('no_tiene') : datosPersonalesUsuario.email
      }`,
    );
  }
  return [cambios.join(' | ')];
}

export function getCambiosDatosUbicacion(
  datosUbicacion: IDatosUbicacion,
  datosUbicacionUsuario: IDatosUbicacion,
  datosUbicacionNombresUsuario: IDatosUbicacionNombres,
  pais: string,
  t: TFunction,
) {
  if (
    datosUbicacionUsuario.territorioOriginario.length > 0 ||
    datosUbicacionUsuario.ageeOriginario.length > 0 ||
    datosUbicacionUsuario.agemOriginario.length > 0 ||
    datosUbicacionUsuario.agelOriginario.length > 0 ||
    datosUbicacionUsuario.territorioResidente.length > 0 ||
    datosUbicacionUsuario.ageeResidente.length > 0 ||
    datosUbicacionUsuario.agemResidente.length > 0 ||
    datosUbicacionUsuario.agelResidente.length > 0 ||
    datosUbicacionUsuario.ageaResidente.length > 0 ||
    datosUbicacionUsuario.calle.length > 0 ||
    datosUbicacionUsuario.num1.length > 0 ||
    datosUbicacionUsuario.num2.length > 0 ||
    datosUbicacionUsuario.cp.length > 0 ||
    datosUbicacionUsuario.referencias.length > 0 ||
    datosUbicacionUsuario.comentario.length > 0
  ) {
    const vacio = `(${t('vacio')})`;
    const nA = t('n_a');
    const geo = obtenerDatosGeograficos(pais);
    const cambiosOriginario = [];
    const cambiosResidente = [];
    let cambiosComentarios = '';
    const originario = datosUbicacionUsuario.territorioOriginario === t(`gentilicio_${pais}`);
    const residente = datosUbicacionUsuario.territorioResidente === t(`gentilicio_${pais}`);
    if (datosUbicacion.territorioOriginario !== datosUbicacionUsuario.territorioOriginario) {
      cambiosOriginario.push(
        `${t('originario_de_territorio')}: ${datosUbicacionUsuario.territorioOriginario || vacio}`,
      );
    }
    if (geo.agee && datosUbicacion.ageeOriginario !== datosUbicacionUsuario.ageeOriginario) {
      let agee = '';
      if (!originario) {
        agee = nA;
      } else if (datosUbicacionUsuario.ageeOriginario) {
        agee = datosUbicacionNombresUsuario.ageeOriginario;
      } else {
        agee = vacio;
      }
      cambiosOriginario.push(`${t(geo.agee.label)}: ${agee}`);
    }
    if (geo.agem && datosUbicacion.agemOriginario !== datosUbicacionUsuario.agemOriginario) {
      let agem = '';
      if (!originario) {
        agem = nA;
      } else if (datosUbicacionUsuario.agemOriginario) {
        agem = datosUbicacionNombresUsuario.agemOriginario;
      } else {
        agem = vacio;
      }
      cambiosOriginario.push(`${t(geo.agem.label)}: ${agem}`);
    }
    if (geo.agel && datosUbicacion.agelOriginario !== datosUbicacionUsuario.agelOriginario) {
      let agel = '';
      if (!originario) {
        agel = nA;
      } else if (datosUbicacionUsuario.agelOriginario) {
        agel = datosUbicacionNombresUsuario.agelOriginario;
      } else {
        agel = vacio;
      }
      cambiosOriginario.push(`${t(geo.agel.label)}: ${agel}`);
    }
    if (datosUbicacion.territorioResidente !== datosUbicacionUsuario.territorioResidente) {
      cambiosResidente.push(
        `${t('residente_en_territorio')}: ${datosUbicacionUsuario.territorioResidente || vacio}`,
      );
    }
    if (geo.agee && datosUbicacion.ageeResidente !== datosUbicacionUsuario.ageeResidente) {
      let agee = '';
      if (!residente) {
        agee = nA;
      } else if (datosUbicacionUsuario.ageeResidente) {
        agee = datosUbicacionNombresUsuario.ageeResidente;
      } else {
        agee = vacio;
      }
      cambiosResidente.push(`${t(geo.agee.label)}: ${agee}`);
    }
    if (geo.agem && datosUbicacion.agemResidente !== datosUbicacionUsuario.agemResidente) {
      let agem = '';
      if (!residente) {
        agem = nA;
      } else if (datosUbicacionUsuario.agemResidente) {
        agem = datosUbicacionNombresUsuario.agemResidente;
      } else {
        agem = vacio;
      }
      cambiosResidente.push(`${t(geo.agem.label)}: ${agem}`);
    }
    if (geo.agel && datosUbicacion.agelResidente !== datosUbicacionUsuario.agelResidente) {
      let agel = '';
      if (!residente) {
        agel = nA;
      } else if (datosUbicacionUsuario.agelResidente) {
        agel = datosUbicacionNombresUsuario.agelResidente;
      } else {
        agel = vacio;
      }
      cambiosResidente.push(`${t(geo.agel.label)}: ${agel}`);
    }
    if (geo.agea && datosUbicacion.ageaResidente !== datosUbicacionUsuario.ageaResidente) {
      let agea = '';
      if (!residente) {
        agea = nA;
      } else if (datosUbicacionUsuario.ageaResidente) {
        agea = datosUbicacionNombresUsuario.ageaResidente;
      } else {
        agea = vacio;
      }
      cambiosResidente.push(`${t(geo.agea.label)}: ${agea}`);
    }
    if (geo.calle && datosUbicacion.calle !== datosUbicacionUsuario.calle) {
      cambiosResidente.push(`${t(geo.calle.label)}: ${datosUbicacionUsuario.calle || vacio}`);
    }
    if (geo.num1 && datosUbicacion.num1 !== datosUbicacionUsuario.num1) {
      cambiosResidente.push(`${t(geo.num1.label)}: ${datosUbicacionUsuario.num1 || vacio}`);
    }
    if (geo.num2 && datosUbicacion.num2 !== datosUbicacionUsuario.num2) {
      cambiosResidente.push(`${t(geo.num2.label)}: ${datosUbicacionUsuario.num2 || vacio}`);
    }
    if (geo.cp && datosUbicacion.cp !== datosUbicacionUsuario.cp) {
      cambiosResidente.push(`${t(geo.cp.label)}: ${datosUbicacionUsuario.cp || vacio}`);
    }
    if (geo.referencias && datosUbicacion.referencias !== datosUbicacionUsuario.referencias) {
      cambiosResidente.push(
        `${t(geo.referencias.label)}: ${datosUbicacionUsuario.referencias || vacio}`,
      );
    }
    if (datosUbicacion.comentario !== datosUbicacionUsuario.comentario) {
      cambiosComentarios = `${t('comentario')}: ${datosUbicacionUsuario.comentario || vacio}`;
    }
    return [cambiosOriginario.join(' | '), cambiosResidente.join(' | '), cambiosComentarios];
  }
  return [];
}

export function getCambiosDiscapacidades(
  discapacidades: IDiscapacidades,
  discapacidadesUsuario: IDiscapacidades,
  t: TFunction,
) {
  if (
    discapacidadesUsuario.sinDiscapacidad ||
    discapacidadesUsuario.arrayDiscapacidades.length > 0 ||
    discapacidadesUsuario.certificado.length > 0 ||
    discapacidadesUsuario.comentarios.length > 0
  ) {
    const vacio = `(${t('vacio')})`;
    const cambios = [];
    let listaDiscapacidades: string[] = [];
    if (!discapacidadesUsuario.sinDiscapacidad) {
      const addDiscapacidades: IDiscapacidad[] = discapacidadesUsuario.arrayDiscapacidades.filter(
        (d1: IDiscapacidad) =>
          d1.tipoId &&
          !discapacidades.arrayDiscapacidades.find(
            (d2: IDiscapacidad) =>
              d1.tipoId === d2.tipoId && d1.gradoId === d2.gradoId && d1.origenId === d2.origenId,
          ),
      );
      const remDiscapacidades: IDiscapacidad[] = discapacidades.arrayDiscapacidades.filter(
        (d1: IDiscapacidad) =>
          d1.tipoId &&
          !discapacidadesUsuario.arrayDiscapacidades.find(
            (d2: IDiscapacidad) =>
              d1.tipoId === d2.tipoId && d1.gradoId === d2.gradoId && d1.origenId === d2.origenId,
          ),
      );
      listaDiscapacidades = [
        ...addDiscapacidades.map(
          (d: IDiscapacidad) =>
            `+ ${t('tipo')}: ${d.tipo}, ${t('grado')}: ${d.grado || vacio}, ${t('origen')}:  ${
              d.origen || vacio
            }`,
        ),
        ...remDiscapacidades.map(
          (d: IDiscapacidad) =>
            `- ${t('tipo')}: ${d.tipo}, ${t('grado')}: ${d.grado || vacio}, ${t('origen')}:  ${
              d.origen || vacio
            }`,
        ),
      ];
    }
    if (
      discapacidades.sinDiscapacidad !== discapacidadesUsuario.sinDiscapacidad ||
      listaDiscapacidades.length
    ) {
      if (discapacidadesUsuario.sinDiscapacidad) {
        cambios.push(`${t('discapacidades')}: ${t('sin_discapacidades')}`);
      } else {
        cambios.push(
          `${t('discapacidades')}: ${
            listaDiscapacidades.length ? listaDiscapacidades.join(', ') : vacio
          }`,
        );
      }
    }
    if (discapacidades.certificado !== discapacidadesUsuario.certificado) {
      cambios.push(
        `${t('tiene_certificado_')}: ${
          discapacidadesUsuario.certificado ? discapacidadesUsuario.certificado : vacio
        }`,
      );
    }
    if (discapacidades.comentarios !== discapacidadesUsuario.comentarios) {
      cambios.push(
        `${t('comentario')}: ${
          discapacidadesUsuario.comentarios ? discapacidadesUsuario.comentarios : vacio
        }`,
      );
    }
    return [cambios.join(' | ')];
  }
  return [];
}

export function getCambiosInfoEmergencia(
  infoEmergencia: IInfoEmergencia,
  infoEmergenciaUsuario: IInfoEmergencia,
  t: TFunction,
) {
  if (
    infoEmergenciaUsuario.desconoceGSanguineo ||
    infoEmergenciaUsuario.tipoSanguineo.length > 0 ||
    infoEmergenciaUsuario.factorSanguineo.length > 0 ||
    infoEmergenciaUsuario.nombreContacto.length > 0 ||
    infoEmergenciaUsuario.apellidosContacto.length > 0 ||
    infoEmergenciaUsuario.parentescoContacto.length > 0 ||
    infoEmergenciaUsuario.emailContacto.length > 0 ||
    infoEmergenciaUsuario.telefonoContacto.length > 0 ||
    infoEmergenciaUsuario.tipoTelefonoContacto.length > 0
  ) {
    const vacio = `(${t('vacio')})`;
    let cambiosGSanguineo = '';
    const cambiosContacto = [];
    if (
      infoEmergencia.desconoceGSanguineo !== infoEmergenciaUsuario.desconoceGSanguineo &&
      infoEmergenciaUsuario.desconoceGSanguineo
    ) {
      cambiosGSanguineo = t('desconoce_grupo_sanguineo');
    } else if (
      infoEmergencia.tipoSanguineo !== infoEmergenciaUsuario.tipoSanguineo ||
      infoEmergencia.factorSanguineo !== infoEmergenciaUsuario.factorSanguineo
    ) {
      if (infoEmergenciaUsuario.tipoSanguineo || infoEmergenciaUsuario.factorSanguineo) {
        cambiosGSanguineo = `${infoEmergenciaUsuario.tipoSanguineo} ${infoEmergenciaUsuario.factorSanguineo}`;
      } else {
        cambiosGSanguineo = vacio;
      }
    }
    if (infoEmergencia.nombreContacto !== infoEmergenciaUsuario.nombreContacto) {
      cambiosContacto.push(`${t('nombre')}: ${infoEmergenciaUsuario.nombreContacto || vacio}`);
    }
    if (infoEmergencia.apellidosContacto !== infoEmergenciaUsuario.apellidosContacto) {
      cambiosContacto.push(
        `${t('apellidos')}: ${infoEmergenciaUsuario.apellidosContacto || vacio}`,
      );
    }
    if (infoEmergencia.parentescoContacto !== infoEmergenciaUsuario.parentescoContacto) {
      cambiosContacto.push(
        `${t('parentesco')}: ${infoEmergenciaUsuario.parentescoContacto || vacio}`,
      );
    }
    if (infoEmergencia.emailContacto !== infoEmergenciaUsuario.emailContacto) {
      cambiosContacto.push(`${t('email')}: ${infoEmergenciaUsuario.emailContacto || vacio}`);
    }
    if (
      infoEmergencia.telefonoContacto !== infoEmergenciaUsuario.telefonoContacto ||
      infoEmergencia.tipoTelefonoContacto !== infoEmergenciaUsuario.tipoTelefonoContacto
    ) {
      let tipoTelefono = '';
      switch (infoEmergenciaUsuario.tipoTelefonoContacto) {
        case '1':
          tipoTelefono = t('celular');
          break;
        case '2':
          tipoTelefono = t('fijo');
          break;
        default:
          break;
      }
      cambiosContacto.push(
        `${t('telefono')}: ${infoEmergenciaUsuario.telefonoContacto || vacio} (${tipoTelefono})`,
      );
    }
    return [
      cambiosGSanguineo ? `${t('grupo_sanguineo')}: ${cambiosGSanguineo}` : '',
      cambiosContacto.join(' | '),
    ];
  }
  return [];
}

export function getCambiosSegurosPlanes(
  segurosPlanes: ISegurosPlanes,
  segurosPlanesUsuario: ISegurosPlanes,
  t: TFunction,
) {
  if (
    segurosPlanesUsuario.noTieneAseguradora ||
    segurosPlanesUsuario.aseguradora.id > 0 ||
    segurosPlanesUsuario.numPoliza.length > 0 ||
    segurosPlanesUsuario.vigencia.dia !== null ||
    segurosPlanesUsuario.vigencia.mes !== null ||
    segurosPlanesUsuario.vigencia.anio !== null ||
    segurosPlanesUsuario.sumaAsegurada.length > 0 ||
    segurosPlanesUsuario.comentarios.length > 0
  ) {
    const vacio = `(${t('vacio')})`;
    const nA = t('n_a');
    const cambios = [];
    if (
      segurosPlanes.noTieneAseguradora !== segurosPlanesUsuario.noTieneAseguradora &&
      segurosPlanesUsuario.noTieneAseguradora
    ) {
      cambios.push(`${t('aseguradora')}: ${t('no_tiene')}`);
    } else if (segurosPlanes.aseguradora.id !== segurosPlanesUsuario.aseguradora.id) {
      cambios.push(`${t('aseguradora')}: ${segurosPlanesUsuario.aseguradora.label || vacio}`);
    }
    if (segurosPlanes.numPoliza !== segurosPlanesUsuario.numPoliza) {
      cambios.push(
        `${t('num_poliza')}: ${
          segurosPlanesUsuario.noTieneAseguradora ? nA : segurosPlanesUsuario.aseguradora || vacio
        }`,
      );
    }
    if (JSON.stringify(segurosPlanes.vigencia) !== JSON.stringify(segurosPlanesUsuario.vigencia)) {
      cambios.push(
        `${t('vigencia')}: ${
          segurosPlanesUsuario.noTieneAseguradora
            ? nA
            : `${segurosPlanesUsuario.vigencia.dia || '-'}/${
              segurosPlanesUsuario.vigencia.mes || '-'
            }/${segurosPlanesUsuario.vigencia.anio || '-'}`
        }`,
      );
    }
    if (segurosPlanes.sumaAsegurada !== segurosPlanesUsuario.sumaAsegurada) {
      cambios.push(
        `${t('suma_asegurada')}: ${
          segurosPlanesUsuario.noTieneAseguradora ? nA : segurosPlanesUsuario.sumaAsegurada || vacio
        }`,
      );
    }
    if (segurosPlanes.comentarios !== segurosPlanesUsuario.comentarios) {
      cambios.push(
        `${t('comentarios')}: ${
          segurosPlanesUsuario.noTieneAseguradora ? nA : segurosPlanesUsuario.comentarios || vacio
        }`,
      );
    }
    return [cambios.join(' | ')];
  }
  return [];
}

export function getCambiosVivienda(vivienda: IVivienda, viviendaUsuario: IVivienda, t: TFunction) {
  if (
    viviendaUsuario.serviciosBasicos.check1 ||
    viviendaUsuario.serviciosBasicos.check2 ||
    viviendaUsuario.serviciosBasicos.check3 ||
    viviendaUsuario.serviciosBasicos.check4 ||
    viviendaUsuario.numeroHabitantes ||
    viviendaUsuario.numeroHabitaciones ||
    viviendaUsuario.animales.length > 0 ||
    viviendaUsuario.tipo.length > 0
  ) {
    const vacio = `(${t('vacio')})`;
    const cambios = [];
    if (vivienda.serviciosBasicos.check1 !== viviendaUsuario.serviciosBasicos.check1) {
      cambios.push(
        `${t('antecedentes-pnp-vivienda-sv')}: ${
          viviendaUsuario.serviciosBasicos.check1 ? t('antecedentes-pnp-vivienda-tt') : vacio
        }`,
      );
    } else {
      const cambiosServiciosBasicos = [];
      if (vivienda.serviciosBasicos.check2 !== viviendaUsuario.serviciosBasicos.check2) {
        cambiosServiciosBasicos.push(
          `${t('antecedentes-pnp-vivienda-sa')}(${
            viviendaUsuario.serviciosBasicos.check2 ? 'X' : '-'
          })`,
        );
      }
      if (vivienda.serviciosBasicos.check3 !== viviendaUsuario.serviciosBasicos.check3) {
        cambiosServiciosBasicos.push(
          `${t('antecedentes-pnp-vivienda-se')}(${
            viviendaUsuario.serviciosBasicos.check3 ? 'X' : '-'
          })`,
        );
      }
      if (vivienda.serviciosBasicos.check4 !== viviendaUsuario.serviciosBasicos.check4) {
        cambiosServiciosBasicos.push(
          `${t('antecedentes-pnp-vivienda-sd')}(${
            viviendaUsuario.serviciosBasicos.check4 ? 'X' : '-'
          })`,
        );
      }
      if (cambiosServiciosBasicos.length > 0) {
        cambios.push(`${t('antecedentes-pnp-vivienda-sv')}: ${cambiosServiciosBasicos.join(', ')}`);
      }
    }
    if (vivienda.numeroHabitantes !== viviendaUsuario.numeroHabitantes) {
      cambios.push(
        `${t('antecedentes-pnp-vivienda-nhab')}: ${viviendaUsuario.numeroHabitantes || vacio}`,
      );
    }
    if (vivienda.numeroHabitaciones !== viviendaUsuario.numeroHabitaciones) {
      cambios.push(
        `${t('antecedentes-pnp-vivienda-ncua')}: ${viviendaUsuario.numeroHabitaciones || vacio}`,
      );
    }
    let mascotas: string[] = [];
    if (viviendaUsuario.animales === 'T') {
      const addMascotas: ITipoMascota[] = viviendaUsuario.tipo.filter(
        (m1: ITipoMascota) =>
          !vivienda.tipo.find(
            (m2: ITipoMascota) =>
              (m1.idAnimal.id === m2.idAnimal.id ||
                (m1.idAnimal.id === 0 && m1.especifica === m2.especifica)) &&
              m1.cantidad === m2.cantidad,
          ),
      );
      const remMascotas: ITipoMascota[] = vivienda.tipo.filter(
        (m1: ITipoMascota) =>
          !viviendaUsuario.tipo.find(
            (m2: ITipoMascota) =>
              (m1.idAnimal.id === m2.idAnimal.id ||
                (m1.idAnimal.id === 0 && m1.especifica === m2.especifica)) &&
              m1.cantidad === m2.cantidad,
          ),
      );
      mascotas = [
        ...addMascotas.map(
          (m: ITipoMascota) =>
            `+ ${m.idAnimal.id > 0 ? m.idAnimal.label : m.especifica} (${m.cantidad})`,
        ),
        ...remMascotas.map(
          (m: ITipoMascota) =>
            `- ${m.idAnimal.id > 0 ? m.idAnimal.label : m.especifica} (${m.cantidad})`,
        ),
      ];
    }
    if (vivienda.animales !== viviendaUsuario.animales || mascotas.length) {
      if (viviendaUsuario.animales === 'T') {
        cambios.push(
          `${t('antecedentes-pnp-vivienda-mascotas')} (${t('si')}): ${mascotas.join(', ')}`,
        );
      } else {
        cambios.push(
          `${t('antecedentes-pnp-vivienda-mascotas')}: ${
            viviendaUsuario.animales.length ? t('no') : vacio
          }`,
        );
      }
    }
    return [cambios.join(' | ')];
  }
  return [];
}

export function getCambiosHigiene(higiene: IHigiene, higieneUsuario: IHigiene, t: TFunction) {
  if (
    higieneUsuario.banio.length > 0 ||
    higieneUsuario.banioComentario.length > 0 ||
    higieneUsuario.bucal.length > 0 ||
    higieneUsuario.ropa.length > 0 ||
    higieneUsuario.ropaComentario.length > 0 ||
    higieneUsuario.comentarios.length > 0
  ) {
    const vacio = `(${t('vacio')})`;
    const cambios = [];
    const getBanioRopaTrad = (label: string) => {
      switch (label) {
        case 'D':
          return t('antecedentes-pnp-higiene-diario');
        case '2D':
          return t('antecedentes-pnp-higiene-dos-dias');
        case '3D':
          return t('antecedentes-pnp-higiene-tres-dias');
        case 'OTRO':
          return t('antecedentes-pnp-higiene-otro');
        default:
          return '';
      }
    };
    const getBucalTrad = (label: string) => {
      switch (label) {
        case '1':
          return t('antecedentes-pnp-higiene-una');
        case '2':
          return t('antecedentes-pnp-higiene-dos');
        case '3':
          return t('antecedentes-pnp-higiene-tres');
        case 'MENOS':
          return t('antecedentes-pnp-higiene-menos');
        default:
          return '';
      }
    };
    if (higiene.banio !== higieneUsuario.banio) {
      cambios.push(
        `${t('antecedentes-pnp-higiene-banio')}: ${
          higieneUsuario.banio === 'OTRO'
            ? higieneUsuario.banioComentario
            : getBanioRopaTrad(higieneUsuario.banio) || vacio
        }`,
      );
    }
    if (higiene.bucal !== higieneUsuario.bucal) {
      cambios.push(
        `${t('antecedentes-pnp-higiene-bucal')}: ${getBucalTrad(higieneUsuario.bucal) || vacio}`,
      );
    }
    if (higiene.ropa !== higieneUsuario.ropa) {
      cambios.push(
        `${t('antecedentes-pnp-higiene-ropa')}: ${
          higieneUsuario.ropa === 'OTRO'
            ? higieneUsuario.ropaComentario
            : getBanioRopaTrad(higieneUsuario.ropa) || vacio
        }`,
      );
    }
    if (higiene.comentarios !== higieneUsuario.comentarios) {
      cambios.push(`${t('comentarios')}: ${higieneUsuario.comentarios || vacio}`);
    }
    return [cambios.join(' | ')];
  }
  return [];
}

export function getCambiosAlimentacion(
  alimentacion: IAlimentacion,
  alimentacionUsuario: IAlimentacion,
  t: TFunction,
) {
  if (
    alimentacionUsuario.dieta.length ||
    alimentacionUsuario.alimentos.length > 0 ||
    alimentacionUsuario.dieta.length ||
    alimentacionUsuario.botonComidas ||
    alimentacionUsuario.botonFruta ||
    alimentacionUsuario.botonVegetales ||
    alimentacionUsuario.comidas ||
    alimentacionUsuario.carne ||
    alimentacionUsuario.pollo ||
    alimentacionUsuario.pescado ||
    alimentacionUsuario.refrescos ||
    alimentacionUsuario.postres ||
    alimentacionUsuario.comentarios.length > 0
  ) {
    const vacio = `(${t('vacio')})`;
    const cambios = [];
    let alimentos: string[] = [];
    if (alimentacionUsuario.dieta === 'T') {
      const addAlimentos: IAlimentos[] = alimentacionUsuario.alimentos.filter(
        (a1: IAlimentos) =>
          a1.alimento &&
          !alimentacion.alimentos.find(
            (a2: IAlimentos) => a1.alimento === a2.alimento && a1.especifica === a2.especifica,
          ),
      );
      const remAlimentos: IAlimentos[] = alimentacion.alimentos.filter(
        (a1: IAlimentos) =>
          a1.alimento &&
          !alimentacionUsuario.alimentos.find(
            (a2: IAlimentos) => a1.alimento === a2.alimento && a1.especifica === a2.especifica,
          ),
      );
      alimentos = [
        ...addAlimentos.map((a: IAlimentos) => `+ ${a.alimento} (${a.especifica})`),
        ...remAlimentos.map((a: IAlimentos) => `- ${a.alimento} (${a.especifica})`),
      ];
    }
    if (alimentacion.dieta !== alimentacionUsuario.dieta || alimentos.length) {
      if (alimentacionUsuario.dieta === 'T') {
        cambios.push(
          `${t('antecedentes-pnp-alimentacion-restriccion')} (${t('si')}): ${
            alimentos.length ? alimentos.join(', ') : vacio
          }`,
        );
      } else {
        cambios.push(
          `${t('antecedentes-pnp-alimentacion-restriccion')}: ${
            alimentacionUsuario.dieta.length ? t('no') : vacio
          }`,
        );
      }
    }
    if (alimentacion.botonComidas !== alimentacionUsuario.botonComidas) {
      cambios.push(
        `${t('antecedentes-pnp-alimentacion-comidas-d')}: ${
          alimentacionUsuario.botonComidas
            ? t(`antecedentes-pnp-alimentacion-${alimentacionUsuario.botonComidas}`)
            : vacio
        }`,
      );
    }
    if (alimentacion.botonFruta !== alimentacionUsuario.botonFruta) {
      let fruta = vacio;
      if (alimentacionUsuario.botonFruta) {
        fruta =
          alimentacionUsuario.botonFruta === 1 || alimentacionUsuario.botonFruta === 5
            ? t(`antecedentes-pnp-alimentacion-m${alimentacionUsuario.botonFruta}`)
            : t(`antecedentes-pnp-alimentacion-${alimentacionUsuario.botonFruta}`);
      }
      cambios.push(`${t('antecedentes-pnp-alimentacion-fruta')}: ${fruta}`);
    }
    if (alimentacion.botonVegetales !== alimentacionUsuario.botonVegetales) {
      let vegetales = vacio;
      if (alimentacionUsuario.botonVegetales) {
        vegetales =
          alimentacionUsuario.botonVegetales === 1 || alimentacionUsuario.botonVegetales === 5
            ? t(`antecedentes-pnp-alimentacion-m${alimentacionUsuario.botonVegetales}`)
            : t(`antecedentes-pnp-alimentacion-${alimentacionUsuario.botonVegetales}`);
      }
      cambios.push(`${t('antecedentes-pnp-alimentacion-vegetales')}: ${vegetales}`);
    }
    if (alimentacion.comidas !== alimentacionUsuario.comidas) {
      cambios.push(
        `${t('antecedentes-pnp-alimentacion-comidas-f')}: ${alimentacionUsuario.comidas || vacio}`,
      );
    }
    if (alimentacion.carne !== alimentacionUsuario.carne) {
      cambios.push(
        `${t('antecedentes-pnp-alimentacion-carne')}: ${alimentacionUsuario.carne || vacio}`,
      );
    }
    if (alimentacion.pollo !== alimentacionUsuario.pollo) {
      cambios.push(
        `${t('antecedentes-pnp-alimentacion-pollo')}: ${alimentacionUsuario.pollo || vacio}`,
      );
    }
    if (alimentacion.pescado !== alimentacionUsuario.pescado) {
      cambios.push(
        `${t('antecedentes-pnp-alimentacion-pescado')}: ${alimentacionUsuario.pescado || vacio}`,
      );
    }
    if (alimentacion.refrescos !== alimentacionUsuario.refrescos) {
      cambios.push(
        `${t('antecedentes-pnp-alimentacion-refrescos')}: ${
          alimentacionUsuario.refrescos || vacio
        }`,
      );
    }
    if (alimentacion.postres !== alimentacionUsuario.postres) {
      cambios.push(
        `${t('antecedentes-pnp-alimentacion-postres')}: ${alimentacionUsuario.postres || vacio}`,
      );
    }
    if (alimentacion.comentarios !== alimentacionUsuario.comentarios) {
      cambios.push(`${t('comentarios')}: ${alimentacionUsuario.comentarios || vacio}`);
    }
    return [cambios.join(' | ')];
  }
  return [];
}

export function getCambiosActividadFisica(
  actividadFisica: IActividadFisica,
  actividadFisicaUsuario: IActividadFisica,
  t: TFunction,
) {
  if (
    actividadFisicaUsuario.tipo.length > 0 ||
    actividadFisicaUsuario.realiza.length > 0 ||
    actividadFisicaUsuario.comentarios.length > 0
  ) {
    const vacio = `(${t('vacio')})`;
    const cambios = [];
    let actividades: string[] = [];
    if (actividadFisicaUsuario.realiza === 'T') {
      const addActividades: ITipoActividad[] = actividadFisicaUsuario.tipo.filter(
        (a1: ITipoActividad) =>
          !actividadFisica.tipo.find(
            (a2: ITipoActividad) =>
              (a1.idTipo.id === a2.idTipo.id ||
                (a1.idTipo.id === 0 && a1.especifica === a2.especifica)) &&
              a1.frecuencia === a2.frecuencia &&
              a1.duracion === a2.duracion,
          ),
      );
      const remActividades: ITipoActividad[] = actividadFisica.tipo.filter(
        (a1: ITipoActividad) =>
          !actividadFisicaUsuario.tipo.find(
            (a2: ITipoActividad) =>
              (a1.idTipo.id === a2.idTipo.id ||
                (a1.idTipo.id === 0 && a1.especifica === a2.especifica)) &&
              a1.frecuencia === a2.frecuencia &&
              a1.duracion === a2.duracion,
          ),
      );
      actividades = [
        ...addActividades.map(
          (a: ITipoActividad) => `
            + ${a.idTipo.id > 0 ? a.idTipo.label : a.especifica} (${a.frecuencia} ${t(
  'antecedentes-pnp-actividad-frecuencia-v',
)} x ${a.duracion} ${t('antecedentes-pnp-actividad-duracion-m')})
          `,
        ),
        ...remActividades.map(
          (a: ITipoActividad) => `
            - ${a.idTipo.id > 0 ? a.idTipo.label : a.especifica} (${a.frecuencia} ${t(
  'antecedentes-pnp-actividad-frecuencia-v',
)} x ${a.duracion} ${t('antecedentes-pnp-actividad-duracion-m')})
          `,
        ),
      ];
    }
    if (actividadFisica.realiza !== actividadFisicaUsuario.realiza || actividades.length) {
      if (actividadFisicaUsuario.realiza === 'T') {
        cambios.push(
          `${t('antecedentes-pnp-actividad-realiza_')} (${t('si')}): ${
            actividades.length ? actividades.join(', ') : vacio
          }`,
        );
      } else {
        cambios.push(
          `${t('antecedentes-pnp-actividad-realiza_')}: ${
            actividadFisicaUsuario.realiza.length ? t('no') : vacio
          }`,
        );
      }
    }
    if (actividadFisica.comentarios !== actividadFisicaUsuario.comentarios) {
      cambios.push(`${t('comentarios')}: ${actividadFisicaUsuario.comentarios || vacio}`);
    }
    return [cambios.join(' | ')];
  }
  return [];
}

export function getCambiosToxicomania(
  toxicomania: IToxicomania,
  toxicomaniaUsuario: IToxicomania,
  t: TFunction,
) {
  if (
    toxicomaniaUsuario.cafeina.consumoCafeina.length > 0 ||
    toxicomaniaUsuario.cafeina.tazaDeCafe.length > 0 ||
    toxicomaniaUsuario.cafeina.lataDeBebidaEnergetica.length > 0 ||
    toxicomaniaUsuario.cafeina.comentarios.length > 0 ||
    toxicomaniaUsuario.tabaco.consumoTabaco.length > 0 ||
    toxicomaniaUsuario.tabaco.edadInicio.length > 0 ||
    toxicomaniaUsuario.tabaco.edadTermino.length > 0 ||
    toxicomaniaUsuario.tabaco.cigarrillosDia.length > 0 ||
    toxicomaniaUsuario.tabaco.comentarios.length > 0 ||
    toxicomaniaUsuario.alcohol.consumoAlcohol.length > 0 ||
    toxicomaniaUsuario.alcohol.edadInicio.length > 0 ||
    toxicomaniaUsuario.alcohol.edadTermino.length > 0 ||
    toxicomaniaUsuario.alcohol.frecuencia.length > 0 ||
    toxicomaniaUsuario.alcohol.arrTipoAlcohol.arrTabla.length > 0 ||
    toxicomaniaUsuario.alcohol.comentarios.length > 0 ||
    toxicomaniaUsuario.otrasDrogas.arrTipoDrogas.arrTabla.length > 0 ||
    toxicomaniaUsuario.otrasDrogas.comentarios.length > 0
  ) {
    const vacio = `(${t('vacio')})`;
    const nA = t('n_a');
    const cambiosToxicomaniaCafeina: string[] = [];
    const cambiosToxicomaniaTabaco: string[] = [];
    const cambiosToxicomaniaAlcohol: string[] = [];
    const cambiosToxicomaniaOtras: string[] = [];
    // Cafeína
    if (toxicomania.cafeina.consumoCafeina !== toxicomaniaUsuario.cafeina.consumoCafeina) {
      cambiosToxicomaniaCafeina.push(
        `${t('cafeina')}: ${
          toxicomaniaUsuario.cafeina.consumoCafeina
            ? t(toxicomaniaUsuario.cafeina.consumoCafeina)
            : vacio
        }`,
      );
    }
    if (toxicomania.cafeina.tazaDeCafe !== toxicomaniaUsuario.cafeina.tazaDeCafe) {
      cambiosToxicomaniaCafeina.push(
        `${t('taza_de_cafe')}: ${
          toxicomaniaUsuario.cafeina.consumoCafeina === 'niega'
            ? nA
            : toxicomaniaUsuario.cafeina.tazaDeCafe || vacio
        }`,
      );
    }
    if (
      toxicomania.cafeina.lataDeBebidaEnergetica !==
      toxicomaniaUsuario.cafeina.lataDeBebidaEnergetica
    ) {
      cambiosToxicomaniaCafeina.push(
        `${t('lata_de_bebida_energetica')}: ${
          toxicomaniaUsuario.cafeina.consumoCafeina === 'niega'
            ? nA
            : toxicomaniaUsuario.cafeina.lataDeBebidaEnergetica || vacio
        }`,
      );
    }
    if (toxicomania.cafeina.comentarios !== toxicomaniaUsuario.cafeina.comentarios) {
      cambiosToxicomaniaCafeina.push(
        `${t('comentarios')}: ${
          toxicomaniaUsuario.cafeina.consumoCafeina === 'niega'
            ? nA
            : toxicomaniaUsuario.cafeina.comentarios || vacio
        }`,
      );
    }
    // Tabaco
    if (toxicomania.tabaco.consumoTabaco !== toxicomaniaUsuario.tabaco.consumoTabaco) {
      cambiosToxicomaniaTabaco.push(
        `${t('tabaco')}: ${
          toxicomaniaUsuario.tabaco.consumoTabaco
            ? t(`${toxicomaniaUsuario.tabaco.consumoTabaco}`)
            : vacio
        }`,
      );
    }
    if (toxicomania.tabaco.edadInicio !== toxicomaniaUsuario.tabaco.edadInicio) {
      cambiosToxicomaniaTabaco.push(
        `${t('edad_de_inicio')}: ${
          toxicomaniaUsuario.tabaco.consumoTabaco === 'niega'
            ? nA
            : `${toxicomaniaUsuario.tabaco.edadInicio} (${t('anios')})` || vacio
        }`,
      );
    }
    if (toxicomania.tabaco.edadTermino !== toxicomaniaUsuario.tabaco.edadTermino) {
      cambiosToxicomaniaTabaco.push(
        `${t('edad_de_termino')}: ${
          toxicomaniaUsuario.tabaco.consumoTabaco === 'niega'
            ? nA
            : `${toxicomaniaUsuario.tabaco.edadTermino} (${t('anios')})` || vacio
        }`,
      );
    }
    if (toxicomania.tabaco.cigarrillosDia !== toxicomaniaUsuario.tabaco.cigarrillosDia) {
      cambiosToxicomaniaTabaco.push(
        `${t('cigarrillos_al_dia')}: ${
          toxicomaniaUsuario.tabaco.consumoTabaco === 'niega'
            ? nA
            : toxicomaniaUsuario.tabaco.cigarrillosDia || vacio
        }`,
      );
    }
    if (toxicomania.tabaco.comentarios !== toxicomaniaUsuario.tabaco.comentarios) {
      cambiosToxicomaniaTabaco.push(
        `${t('comentarios')}: ${
          toxicomaniaUsuario.tabaco.consumoTabaco === 'niega'
            ? nA
            : toxicomaniaUsuario.tabaco.comentarios || vacio
        }`,
      );
    }
    // Alcohol
    if (toxicomania.alcohol.consumoAlcohol !== toxicomaniaUsuario.alcohol.consumoAlcohol) {
      cambiosToxicomaniaAlcohol.push(
        `${t('alcohol')}: ${
          toxicomaniaUsuario.alcohol.consumoAlcohol
            ? t(toxicomaniaUsuario.alcohol.consumoAlcohol)
            : vacio
        }`,
      );
    }
    if (toxicomania.alcohol.edadInicio !== toxicomaniaUsuario.alcohol.edadInicio) {
      cambiosToxicomaniaAlcohol.push(
        `${t('edad_de_inicio')}: ${
          toxicomaniaUsuario.alcohol.consumoAlcohol === 'niega'
            ? nA
            : `${toxicomaniaUsuario.alcohol.edadInicio} (${t('anios')})` || vacio
        }`,
      );
    }
    if (toxicomania.alcohol.edadTermino !== toxicomaniaUsuario.alcohol.edadTermino) {
      cambiosToxicomaniaAlcohol.push(
        `${t('edad_de_termino')}: ${
          toxicomaniaUsuario.alcohol.consumoAlcohol === 'niega'
            ? nA
            : `${toxicomaniaUsuario.alcohol.edadTermino} (${t('anios')})` || vacio
        }`,
      );
    }
    if (toxicomania.alcohol.frecuencia !== toxicomaniaUsuario.alcohol.frecuencia) {
      cambiosToxicomaniaAlcohol.push(
        `${t('frecuencia')}: ${
          toxicomaniaUsuario.alcohol.consumoAlcohol === 'niega'
            ? nA
            : t(toxicomaniaUsuario.alcohol.frecuencia) || vacio
        }`,
      );
    }
    let listaAlcohol: string[] = [];
    if (toxicomaniaUsuario.alcohol.consumoAlcohol === 'activo') {
      const alcohol: string[] = toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol
        .filter((a: ITipoAlcohol) => a.label)
        .map(
          (a: ITipoAlcohol, inx) =>
            `${a.label} (${toxicomania.alcohol.arrTipoAlcohol.cantidadAlcohol[inx]} ${a.grUnidad})`,
        );
      const alcoholUsuario: string[] = toxicomaniaUsuario.alcohol.arrTipoAlcohol.tipoAlcohol
        .filter((a: ITipoAlcohol) => a.label)
        .map(
          (a: ITipoAlcohol, inx) =>
            `${a.label} (${toxicomaniaUsuario.alcohol.arrTipoAlcohol.cantidadAlcohol[inx]} ${a.grUnidad})`,
        );
      const addAlcohol: string[] = alcoholUsuario
        .filter((a: string) => !alcohol.includes(a))
        .map((a: string) => `+ ${a}`);
      const remAlcohol: string[] = alcohol
        .filter((a: string) => !alcoholUsuario.includes(a))
        .map((a: string) => `- ${a}`);
      listaAlcohol = [...addAlcohol, ...remAlcohol];
    }
    if (listaAlcohol.length) {
      cambiosToxicomaniaAlcohol.push(`${t('tipo_de_alcohol')}: ${listaAlcohol.join(', ')}`);
    }
    if (toxicomania.alcohol.comentarios !== toxicomaniaUsuario.alcohol.comentarios) {
      cambiosToxicomaniaAlcohol.push(
        `${t('comentarios')}: ${toxicomaniaUsuario.alcohol.comentarios || vacio}`,
      );
    }
    // Otras drogas
    let listaOtrasDrogas: string[] = [];
    if (toxicomaniaUsuario.otrasDrogas.consumoOtrasDrogas === 'si') {
      const otrasDrogas: string[] = toxicomania.otrasDrogas.arrTipoDrogas.estatus.map(
        (e: string, inx) =>
          (e === 'activo'
            ? `+ ${t(toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga[inx])} (${
              toxicomania.otrasDrogas.arrTipoDrogas.cantidadDroga[inx]
            }), ${t(toxicomania.otrasDrogas.arrTipoDrogas.frecuencia[inx])}, ${t('desde_los')} ${
              toxicomania.otrasDrogas.arrTipoDrogas.edadInicio[inx]
            } ${t('anios')}`
            : `+ ${t(toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga[inx])} (${
              toxicomania.otrasDrogas.arrTipoDrogas.cantidadDroga[inx]
            }), ${t(toxicomania.otrasDrogas.arrTipoDrogas.frecuencia[inx])}, ${t('desde_los')} ${
              toxicomania.otrasDrogas.arrTipoDrogas.edadInicio[inx]
            } ${t('hasta_los')} ${toxicomania.otrasDrogas.arrTipoDrogas.edadTermino[inx]} ${t(
              'anios',
            )}`),
      );
      const otrasDrogasUsuario: string[] = toxicomaniaUsuario.otrasDrogas.arrTipoDrogas.estatus.map(
        (e: string, inx) =>
          (e === 'activo'
            ? `- ${t(toxicomaniaUsuario.otrasDrogas.arrTipoDrogas.tipoDroga[inx])} (${
              toxicomaniaUsuario.otrasDrogas.arrTipoDrogas.cantidadDroga[inx]
            }), ${t(toxicomaniaUsuario.otrasDrogas.arrTipoDrogas.frecuencia[inx])}, ${t(
              'desde_los',
            )} ${toxicomaniaUsuario.otrasDrogas.arrTipoDrogas.edadInicio[inx]} ${t('anios')}`
            : `- ${t(toxicomaniaUsuario.otrasDrogas.arrTipoDrogas.tipoDroga[inx])} (${
              toxicomaniaUsuario.otrasDrogas.arrTipoDrogas.cantidadDroga[inx]
            }), ${t(toxicomaniaUsuario.otrasDrogas.arrTipoDrogas.frecuencia[inx])}, ${t(
              'desde_los',
            )} ${toxicomaniaUsuario.otrasDrogas.arrTipoDrogas.edadInicio[inx]} ${t(
              'hasta_los',
            )} ${toxicomaniaUsuario.otrasDrogas.arrTipoDrogas.edadTermino[inx]} ${t('anios')}`),
      );
      const addOtrasDrogas: string[] = otrasDrogasUsuario.filter((a1: string) =>
        otrasDrogas.includes(a1));
      const remOtrasDrogas: string[] = otrasDrogas.filter((a1: string) =>
        otrasDrogasUsuario.includes(a1));
      listaOtrasDrogas = [...addOtrasDrogas, ...remOtrasDrogas];
    }
    if (listaOtrasDrogas.length) {
      cambiosToxicomaniaOtras.push(`${t('otras_drogas')}: ${listaOtrasDrogas.join(', ')}`);
    }
    if (toxicomania.otrasDrogas.comentarios !== toxicomaniaUsuario.otrasDrogas.comentarios) {
      cambiosToxicomaniaOtras.push(
        `${t('comentarios')}: ${toxicomaniaUsuario.otrasDrogas.comentarios || vacio}`,
      );
    }
    return [
      cambiosToxicomaniaCafeina.join(' | '),
      cambiosToxicomaniaTabaco.join(' | '),
      cambiosToxicomaniaAlcohol.join(' | '),
      cambiosToxicomaniaOtras.join(' | '),
    ];
  }
  return [];
}

export function getCambiosGinecoObstetrico(
  ginecoObstetrico: IGinecoObstetrico,
  ginecoObstetricoUsuario: IGinecoObstetrico,
  t: TFunction,
) {
  if (
    ginecoObstetricoUsuario.generalidades.menarquiaNA !== null ||
    ginecoObstetricoUsuario.generalidades.menarquia.length > 0 ||
    ginecoObstetricoUsuario.generalidades.menopausiaNA !== null ||
    ginecoObstetricoUsuario.generalidades.menopausia.length > 0 ||
    ginecoObstetricoUsuario.generalidades.lactancia.length > 0 ||
    ginecoObstetricoUsuario.generalidades.sintomasDelClimaterio.length > 0 ||
    ginecoObstetricoUsuario.generalidades.comentarios.length > 0 ||
    ginecoObstetricoUsuario.generalidades.fur.dia.length > 0 ||
    ginecoObstetricoUsuario.generalidades.fur.mes.length > 0 ||
    ginecoObstetricoUsuario.generalidades.fur.anio.length > 0 ||
    ginecoObstetricoUsuario.menstruacionHabitual.ritmoCada.length > 0 ||
    ginecoObstetricoUsuario.menstruacionHabitual.ritmoDuracion.length > 0 ||
    ginecoObstetricoUsuario.menstruacionHabitual.cantidad.length > 0 ||
    ginecoObstetricoUsuario.menstruacionHabitual.dolor.length > 0 ||
    ginecoObstetricoUsuario.menstruacionHabitual.comentarios.length > 0 ||
    ginecoObstetricoUsuario.anticonceptivo.metodoEnUso.some((m) => m.length) ||
    ginecoObstetricoUsuario.embarazos.embarazos.length > 0 ||
    ginecoObstetricoUsuario.embarazos.gestas.length > 0 ||
    ginecoObstetricoUsuario.embarazos.para.length > 0 ||
    ginecoObstetricoUsuario.embarazos.cesareas.length > 0 ||
    ginecoObstetricoUsuario.embarazos.abortos.length > 0 ||
    ginecoObstetricoUsuario.embarazos.ectopicos.length > 0 ||
    ginecoObstetricoUsuario.embarazos.nacidosVivos.length > 0 ||
    ginecoObstetricoUsuario.embarazos.comentarios.length > 0
  ) {
    const vacio = `(${t('vacio')})`;
    const nA = t('n_a');
    const cambiosGinecoObstetricoGeneralidades: string[] = [];
    const cambiosGinecoObstetricoMenstruacion: string[] = [];
    let cambiosGinecoObstetricoAnticonceptivo: string[] = [];
    const cambiosGinecoObstetricoEmbarazos: string[] = [];
    // Generalidades
    if (
      ginecoObstetricoUsuario.generalidades.menarquiaNA !== null &&
      ginecoObstetrico.generalidades.menarquiaNA !==
        ginecoObstetricoUsuario.generalidades.menarquiaNA
    ) {
      cambiosGinecoObstetricoGeneralidades.push(`${t('menarquia(edad)')}: ${nA}`);
    } else if (
      ginecoObstetrico.generalidades.menarquia !== ginecoObstetricoUsuario.generalidades.menarquia
    ) {
      cambiosGinecoObstetricoGeneralidades.push(
        `${t('menarquia(edad)')}: ${ginecoObstetrico.generalidades.menarquia || vacio}`,
      );
    }
    if ((ginecoObstetrico.generalidades.enGestacion || '') !== (ginecoObstetricoUsuario.generalidades.enGestacion || '')) {
      cambiosGinecoObstetricoGeneralidades.push(
        `${t('en_gestacion')}: ${
          ginecoObstetricoUsuario.generalidades.enGestacion ? t(ginecoObstetricoUsuario.generalidades.enGestacion) : vacio
        }`,
      );
    }
    if (
      JSON.stringify(ginecoObstetrico.generalidades.fur) !==
      JSON.stringify(ginecoObstetricoUsuario.generalidades.fur)
    ) {
      cambiosGinecoObstetricoGeneralidades.push(
        `${t('fur')}: ${ginecoObstetricoUsuario.generalidades.fur.dia}/${
          ginecoObstetricoUsuario.generalidades.fur.mes
        }/${ginecoObstetricoUsuario.generalidades.fur.anio}`,
      );
    }
    if (
      ginecoObstetrico.generalidades.lactancia !== ginecoObstetricoUsuario.generalidades.lactancia
    ) {
      cambiosGinecoObstetricoGeneralidades.push(
        `${t('lactancia')}: ${t(`${ginecoObstetricoUsuario.generalidades.lactancia}`) || vacio}`,
      );
    }
    if (
      ginecoObstetricoUsuario.generalidades.menopausiaNA !== null &&
      ginecoObstetrico.generalidades.menopausiaNA !==
        ginecoObstetricoUsuario.generalidades.menopausiaNA
    ) {
      cambiosGinecoObstetricoGeneralidades.push(`${t('menarquia(edad)')}: ${nA}`);
    } else if (
      ginecoObstetrico.generalidades.menopausia !== ginecoObstetricoUsuario.generalidades.menopausia
    ) {
      cambiosGinecoObstetricoGeneralidades.push(
        `${t('menopausia(edad)')}: ${ginecoObstetrico.generalidades.menarquia || vacio}`,
      );
    }
    // Menstruacion
    if (
      ginecoObstetrico.menstruacionHabitual.ritmoCada !==
        ginecoObstetricoUsuario.menstruacionHabitual.ritmoCada ||
      ginecoObstetrico.menstruacionHabitual.ritmoDuracion !==
        ginecoObstetricoUsuario.menstruacionHabitual.ritmoDuracion
    ) {
      cambiosGinecoObstetricoMenstruacion.push(
        `${t('menstruacion_habitual')}: ${
          ginecoObstetricoUsuario.menstruacionHabitual.ritmoCada.length &&
          ginecoObstetricoUsuario.menstruacionHabitual.ritmoDuracion.length
            ? `${t('cada')} ${ginecoObstetricoUsuario.menstruacionHabitual.ritmoCada} ${t(
              'dias',
            )} X ${ginecoObstetricoUsuario.menstruacionHabitual.ritmoDuracion} ${t('dias')}`
            : vacio
        }`,
      );
    }
    if (
      ginecoObstetrico.menstruacionHabitual.cantidad !==
      ginecoObstetricoUsuario.menstruacionHabitual.cantidad
    ) {
      cambiosGinecoObstetricoMenstruacion.push(
        `${t('aposito-dia')}: ${
          cambiosGinecoObstetricoMenstruacion.length ? '' : `${t('menstruacion_habitual')} | `
        }${ginecoObstetricoUsuario.menstruacionHabitual.cantidad || vacio}`,
      );
    }
    if (
      ginecoObstetrico.menstruacionHabitual.dolor !==
      ginecoObstetricoUsuario.menstruacionHabitual.dolor
    ) {
      cambiosGinecoObstetricoMenstruacion.push(
        `${t('dolor(intensidad)')}: ${
          cambiosGinecoObstetricoMenstruacion.length ? '' : `${t('menstruacion_habitual')} | `
        }${ginecoObstetricoUsuario.menstruacionHabitual.dolor || vacio}`,
      );
    }
    // Anticonceptivos
    const anticonceptivos: string[] = ginecoObstetrico.anticonceptivo.metodoEnUso
      .filter((a: string) => a)
      .map((a: string, i: number) =>
        (a && ginecoObstetrico.anticonceptivo.tiempoEnUso[i]
          ? `${t(a)} (${t(ginecoObstetrico.anticonceptivo.tiempoEnUso[i])})`
          : ''));
    const anticonceptivosUsuario: string[] = ginecoObstetricoUsuario.anticonceptivo.metodoEnUso
      .filter((a: string) => a)
      .map((a: string, i: number) =>
        (a && ginecoObstetricoUsuario.anticonceptivo.tiempoEnUso[i]
          ? `${t(a)} (${t(ginecoObstetricoUsuario.anticonceptivo.tiempoEnUso[i])})`
          : ''));
    const addAnticonceptivos: string[] = anticonceptivosUsuario
      .filter((a: string) => a && !anticonceptivos.includes(a))
      .map((a: string) => `+ ${a}`);
    const remAnticonceptivos: string[] = anticonceptivos
      .filter((a: string) => a && !anticonceptivosUsuario.includes(a))
      .map((a: string) => `- ${a}`);
    cambiosGinecoObstetricoAnticonceptivo = [...addAnticonceptivos, ...remAnticonceptivos];
    // Embarazos
    if (ginecoObstetrico.embarazos.embarazos !== ginecoObstetricoUsuario.embarazos.embarazos) {
      cambiosGinecoObstetricoEmbarazos.push(
        `${t('embarazos')}: ${t(ginecoObstetricoUsuario.embarazos.embarazos) || vacio}`,
      );
    }
    if (ginecoObstetricoUsuario.embarazos.embarazos === 'si') {
      if (ginecoObstetrico.embarazos.gestas !== ginecoObstetricoUsuario.embarazos.gestas) {
        cambiosGinecoObstetricoEmbarazos.push(
          `${t('gestas')}: ${t(ginecoObstetricoUsuario.embarazos.gestas) || vacio}`,
        );
      }
      if (ginecoObstetrico.embarazos.para !== ginecoObstetricoUsuario.embarazos.para) {
        cambiosGinecoObstetricoEmbarazos.push(
          `${t('para')}: ${t(ginecoObstetricoUsuario.embarazos.para) || vacio}`,
        );
      }
      if (ginecoObstetrico.embarazos.cesareas !== ginecoObstetricoUsuario.embarazos.cesareas) {
        cambiosGinecoObstetricoEmbarazos.push(
          `${t('cesareas')}: ${t(ginecoObstetricoUsuario.embarazos.cesareas) || vacio}`,
        );
      }
      if (ginecoObstetrico.embarazos.abortos !== ginecoObstetricoUsuario.embarazos.abortos) {
        cambiosGinecoObstetricoEmbarazos.push(
          `${t('abortos')}: ${t(ginecoObstetricoUsuario.embarazos.abortos) || vacio}`,
        );
      }
      if (
        ginecoObstetrico.embarazos.nacidosVivos !== ginecoObstetricoUsuario.embarazos.nacidosVivos
      ) {
        cambiosGinecoObstetricoEmbarazos.push(
          `${t('nacidos_vivos')}: ${t(ginecoObstetricoUsuario.embarazos.nacidosVivos) || vacio}`,
        );
      }
    }
    return [
      cambiosGinecoObstetricoGeneralidades.join(' | '),
      cambiosGinecoObstetricoMenstruacion.join(' | '),
      cambiosGinecoObstetricoAnticonceptivo.length
        ? `${t('anticonceptivo')}: ${cambiosGinecoObstetricoAnticonceptivo.join(', ')}`
        : '',
      cambiosGinecoObstetricoEmbarazos.join(' | '),
    ];
  }
  return [];
}

export function getCambiosDatosPaciente(
  datosExpediente: IExpediente,
  datosUsuario: IUsuarioPaciente,
  pais: 'mx' | 'uy',
  t: TFunction,
) {
  return {
    perfil: {
      datosPersonales: getCambiosDatosPersonales(
        datosExpediente.perfil.datosPersonales,
        datosUsuario.perfil.datosPersonales,
        t,
      ),
      datosUbicacion: getCambiosDatosUbicacion(
        datosExpediente.perfil.datosUbicacion,
        datosUsuario.perfil.datosUbicacion,
        datosUsuario.perfil.datosUbicacionNombres,
        pais,
        t,
      ),
      discapacidades: getCambiosDiscapacidades(
        datosExpediente.perfil.discapacidades,
        datosUsuario.perfil.discapacidades,
        t,
      ),
      infoEmergencia: getCambiosInfoEmergencia(
        datosExpediente.perfil.infoEmergencia,
        datosUsuario.perfil.infoEmergencia,
        t,
      ),
      segurosPlanes: getCambiosSegurosPlanes(
        datosExpediente.perfil.segurosPlanes,
        datosUsuario.perfil.segurosPlanes,
        t,
      ),
    },
    antNoPatologicos: {
      vivienda: getCambiosVivienda(
        datosExpediente.antNoPatologicos.vivienda,
        datosUsuario.antNoPatologicos.vivienda,
        t,
      ),
      higiene: getCambiosHigiene(
        datosExpediente.antNoPatologicos.higiene,
        datosUsuario.antNoPatologicos.higiene,
        t,
      ),
      alimentacion: getCambiosAlimentacion(
        datosExpediente.antNoPatologicos.alimentacion,
        datosUsuario.antNoPatologicos.alimentacion,
        t,
      ),
      actividadFisica: getCambiosActividadFisica(
        datosExpediente.antNoPatologicos.actividadFisica,
        datosUsuario.antNoPatologicos.actividadFisica,
        t,
      ),
      toxicomania: getCambiosToxicomania(
        datosExpediente.antNoPatologicos.toxicomania,
        datosUsuario.antNoPatologicos.toxicomania,
        t,
      ),
      ginecoObstetrico: datosExpediente.perfil.datosPersonales.sexo === 'M'
        ? getCambiosGinecoObstetrico(
          datosExpediente.antNoPatologicos.ginecoObstetrico,
          datosUsuario.antNoPatologicos.ginecoObstetrico,
          t,
        ) : [],
    },
  };
}

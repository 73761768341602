import { Check } from 'lucide-react';
import { FC } from 'react';
import { FormProgressStepsProps } from './type';

export const FormProgressSteps: FC<FormProgressStepsProps> = ({ label, steps }) => (
  <div className="w-full p-4 h-auto">
    {label && <h2 className="text-xl font-medium text-gray-900 mb-6">{label}</h2>}

    <div className="flex justify-evenly gap-4 w-full max-w-2xl mx-auto">
      {steps.map((step, index) => (
        <div key={step.title} className="flex flex-col items-center text-center w-[94px]">
          <div
            className={`
                  w-12 h-12 rounded-full 
                  flex items-center justify-center
                  text-lg font-medium mb-2 text-white
                  ${step.status === 'current' ? 'bg-[#337BEF]' : 'bg-[#1E40AF]'}
                `}
          >
            {step.status === 'completed' ? <Check /> : `${index + 1}`}
          </div>
          <p className="w-full text-[12px] font-semibold text-center max-w-[120px]">{step.title}</p>
        </div>
      ))}
    </div>
  </div>
);

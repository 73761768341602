import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setRutaAntecedentes, setRutaAntNoPatologicos, setRutaPrincipal } from '@actions/rutasExpediente/actions';
import { setDiagnosticosGenerales } from '@actions/paciente/actions';
import { ModalForm } from '@components/modals/ModalForm';
import { RootState } from 'src/store';
import { enfermedadesGuardarDiagnosticosUsuario } from '@utils/sendInfo';
import { setRequest } from '@actions/request/types';
import { IDiagnosticoGeneral, IDiagnosticosGenerales, diagnosticosGeneralesInitial } from './types';
import TablaDiagnosticos from './TablaDiagnosticos';
import { FormProgressSteps } from '@components/FormProgressSteps2';

function DiagnosticosGenerales() {
  const dispatch = useDispatch();
  const { idUsuario } = useSelector((state: RootState) => state.Me);
  const { diagnosticosGenerales } = useSelector(
    (state: RootState) => state.Paciente.expediente.antPatologicos,
  );
  const { t } = useTranslation('translation-patient');

  const [diagnosticosGeneralesTemp, setDiagnosticosGeneralesTemp] =
    useState<IDiagnosticosGenerales>(diagnosticosGeneralesInitial);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(0);

  const steps: string[] = [
    'corazon-cronico',
    'nervioso-dolor',
    'respiratorio-corporal',
  ];
  
  const handleSubmitForm = () => {
    const contenidoTabla = diagnosticosGeneralesTemp.contenidoTabla.map(
      (diagnostico: IDiagnosticoGeneral, index: number) => {
        if (
          diagnostico.posee !== diagnosticosGenerales.contenidoTabla[index].posee ||
          diagnostico.medicamentos !== diagnosticosGenerales.contenidoTabla[index].medicamentos ||
          diagnostico.noMedicamento !== diagnosticosGenerales.contenidoTabla[index].noMedicamento
        ) {
          return {
            ...diagnostico,
            validado: false,
          };
        }
        return diagnostico;
      },
    );
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: enfermedadesGuardarDiagnosticosUsuario({
          idUsuario,
          contenidoTabla,
        }),
        successFunction: (result) => {
          setDiagnosticosGeneralesTemp({
            loaded: true,
            contenidoTabla: result.datos.contenidoTabla,
          });
          dispatch(setDiagnosticosGenerales({
            loaded: true,
            contenidoTabla: result.datos.contenidoTabla,
          }));
          if (currentIndex < 2) {
            setCurrentIndex(currentIndex + 1);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          } else {
            dispatch(setRutaAntecedentes('personales-no-patologicos'));
            dispatch(setRutaAntNoPatologicos('vivienda'));  
          }
        },
      }),
    );
  };

  useEffect(() => {
    if (diagnosticosGenerales.loaded && !diagnosticosGeneralesTemp.loaded) {
      setDiagnosticosGeneralesTemp(diagnosticosGenerales);
      setLength(diagnosticosGenerales.contenidoTabla.length);
    }
  }, [diagnosticosGenerales]);

  return (
    <ModalForm
      title={t('mi_salud')}
      // progressSteps={steps}
      id="mi-salud"
      submitForm={handleSubmitForm}
      backAction={() => dispatch(setRutaPrincipal('main'))}
      noBorder
    >
      {/* <div className="text-gray-500 mt-4">{t('antecedentes-pp-diagnosticos-g-responde-1')}</div>
      <div className="text-gray-500 mb-2">{t('antecedentes-pp-diagnosticos-g-responde-2')}</div> */}
      {/* <div className="text-gray-500 my-2 font-bold">
        {t('antecedentes-pp-diagnosticos-g-presenta')}
      </div> */}
      <FormProgressSteps
        steps={steps.map(st => t(`antecedentes-pp-categoria-${st}`))}
        currentIndex={currentIndex}
        setIndex={(index) => setCurrentIndex(index)}
      />
      <TablaDiagnosticos
        currentStep={steps[currentIndex]}
        diagnosticos={diagnosticosGeneralesTemp}
        setDiagnosticos={setDiagnosticosGeneralesTemp}
        length={length}
      />
    </ModalForm>
  );
}

export default DiagnosticosGenerales;

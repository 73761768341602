/* eslint-disable */

import { actualizarCampo } from '@actions/infoUser/actions';
import { resetModal, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import { RootState } from '@reducer/index';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CheckboxInput } from '@components/FormElemntsLibrary/CheckboxInput';
import { ScaleInput } from '@components/FormElemntsLibrary/ScaleInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { ScaleValue, SectionId, SectionValue } from './type';
import { ModalForm } from '@components/modals/ModalForm';
import AlertDialog from '@components/AlertDialogs/AlertDialog';

import { AlcoholForm } from './Sections/Alcohol/Alcohol';
import { SmokingForm } from './Sections/Smoking/Smoking';
import { SubstanceForm } from './Sections/Substance/Substance';

import {
  antecedentesNoPatologicosGuardarToxicomania,
  antecedentesNoPatologicosUsuarioGuardarToxicomania,
  antecedentesNoPatologicosUsuarioPacienteGuardarToxicomania,
} from '@utils/sendInfo';

import {
  IAlcohol,
  IArrTipoDrogas,
  ITipoAlcohol,
  IToxicomania,
  toxicomaniaInitial,
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Toxicomania/types';
import { isOxxo } from '@constants/oxxo';

import { z } from 'zod';

export const schema = z.object({
  cafeina: z.object({
    consumoCafeina: z.enum(['', 'niega', 'activo']),
    tazaDeCafe: z.string(),
    lataDeBebidaEnergetica: z.string(),
    cafeinaAlDia: z.string(),
    alarmaConsumoExcesivo: z.boolean(),
    comentarios: z.string(),
  }),
  tabaco: z.object({
    consumoTabaco: z.enum(['', 'niega', 'activo', 'ex-fumador']),
    edadInicio: z.string(),
    edadTermino: z.string(),
    cigarrillosDia: z.string(),
    ipa: z.string(),
    alarmaTabaquismo: z.enum(['', 'leve', 'moderado', 'intenso']),
    comentarios: z.string(),
  }),
  alcohol: z.object({
    consumoAlcohol: z.enum(['', 'niega', 'activo', 'ex-bebedor']),
    edadInicio: z.string(),
    edadTermino: z.string(),
    frecuencia: z.string(),
    arrTipoAlcohol: z.object({
      arrTabla: z.array(z.string()),
      tipoAlcohol: z.array(
        z.object({
          label: z.string(),
          grUnidad: z.number(),
        }),
      ),
      cantidadAlcohol: z.array(z.string()),
    }),
    totalTragos: z.string(),
    totalAproxAlcohol: z.string(),
    alarmaConsumoIntensivo: z.boolean(),
    alarmaPosibleTrastorno: z.boolean(),
    comentarios: z.string(),
    consumeEnMenosDeDosHoras: z.enum(['', 'si', 'no']),
  }),
  otrasDrogas: z.object({
    consumoOtrasDrogas: z.enum(['', 'si', 'no']),
    arrTipoDrogas: z.object({
      arrTabla: z.array(z.string()),
      tipoDroga: z.array(z.string()),
      especifica: z.array(z.string()),
      cantidadDroga: z.array(z.string()),
      edadInicio: z.array(z.string()),
      edadTermino: z.array(z.string()),
      frecuencia: z.array(z.string()),
      estatus: z.array(z.enum(['activo', 'inactivo', 'abandonado'])),
    }),
    comentarios: z.string(),
  }),
  loaded: z.boolean(),
});

type FormData = z.infer<typeof schema>;

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';

const Habitos = () => {
  const [formData, setFormData] = useState<FormData>(toxicomaniaInitial);
  const [visibleSections, setVisibleSections] = useState<Record<string, string>>({});
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMensaje, setAlertMensaje] = useState<string>('');

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const {
    toxicomania,
    toxicomaniaUsuario,
    cambiosToxicomania,
    ginecoObstetrico: { generalidades: { enGestacion } },
  } = useSelector(
    (state: RootState) => state.InfoUser,
  );

  const {
    edad: { edad: edadValor },
  } = useSelector((state: RootState) => state.BasicosPaciente);

  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);

  const { idConsulta } = useSelector((state: RootState) => state.Consulta);

  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);

  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);

  const { sexo } = useSelector((state: RootState) => state.BasicosPaciente);

  const {
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: formData,
  });

  const handleSectionVisibilityChange = (sectionId: SectionId, value: SectionValue): void => {
    if (value === 'no' || value === 'niega') {
      setVisibleSections((prev) => {
        const newState = { ...prev };
        delete newState[sectionId];
        return newState;
      });

      // Resetear los datos según la sección
      setFormData((prev) => {
        const newState = { ...prev };

        switch (sectionId) {
          case 'coffee':
            newState.cafeina = {
              ...prev.cafeina,
              consumoCafeina: 'niega',
              tazaDeCafe: '',
              lataDeBebidaEnergetica: '',
              cafeinaAlDia: '',
              alarmaConsumoExcesivo: false,
              comentarios: '',
            };
            break;

          case 'smoking':
            newState.tabaco = {
              ...prev.tabaco,
              consumoTabaco: 'niega',
              edadInicio: '',
              edadTermino: '',
              cigarrillosDia: '',
              ipa: '',
              alarmaTabaquismo: '',
              comentarios: '',
            };
            break;

          case 'alcohol':
            newState.alcohol = {
              ...prev.alcohol,
              consumoAlcohol: 'niega',
              edadInicio: '',
              edadTermino: '',
              frecuencia: '',
              arrTipoAlcohol: {
                arrTabla: ['I'],
                tipoAlcohol: [{ label: '', grUnidad: 0 }],
                cantidadAlcohol: [''],
              },
              totalTragos: '',
              totalAproxAlcohol: '',
              alarmaConsumoIntensivo: false,
              alarmaPosibleTrastorno: false,
              comentarios: '',
              consumeEnMenosDeDosHoras: '',
            };
            break;

          case 'substances':
            newState.otrasDrogas = {
              ...prev.otrasDrogas,
              consumoOtrasDrogas: 'no',
              arrTipoDrogas: {
                arrTabla: ['I'],
                tipoDroga: [''],
                especifica: [''],
                cantidadDroga: [''],
                edadInicio: [''],
                edadTermino: [''],
                frecuencia: [''],
                estatus: ['activo'],
              },
              comentarios: '',
            };
            break;
        }

        return newState;
      });
      return;
    }

    // Actualizar la visibilidad de la sección
    setVisibleSections((prev) => ({
      ...prev,
      [sectionId]: value,
    }));

    // Actualizar el estado según la sección que se está mostrando
    setFormData((prev) => {
      const newState = { ...prev };

      switch (sectionId) {
        case 'coffee':
          newState.cafeina = {
            ...prev.cafeina,
            consumoCafeina: value as 'activo',
          };
          break;

        case 'smoking':
          newState.tabaco = {
            ...prev.tabaco,
            consumoTabaco: value as 'activo' | 'ex-fumador',
          };
          break;

        case 'alcohol':
          newState.alcohol = {
            ...prev.alcohol,
            consumoAlcohol: value as 'activo' | 'ex-bebedor',
          };
          break;

        case 'substances':
          newState.otrasDrogas = {
            ...prev.otrasDrogas,
            consumoOtrasDrogas: value as 'si',
          };
          break;
      }

      return newState;
    });
  };

  const handleAlcoholTypesChange = (
    index: number,
    tipoAlcohol: ITipoAlcohol,
    cantidad: string,
  ): void => {
    setFormData((prev) => {
      const newArrTipoAlcohol = { ...prev.alcohol.arrTipoAlcohol };

      // Si los valores están vacíos, interpretamos que es una eliminación
      if (tipoAlcohol.label === '' && cantidad === '' && index > 0) {
        newArrTipoAlcohol.arrTabla = newArrTipoAlcohol.arrTabla.filter((_, i) => i !== index);
        newArrTipoAlcohol.tipoAlcohol = newArrTipoAlcohol.tipoAlcohol.filter((_, i) => i !== index);
        newArrTipoAlcohol.cantidadAlcohol = newArrTipoAlcohol.cantidadAlcohol.filter(
          (_, i) => i !== index,
        );
      } else if (index >= newArrTipoAlcohol.tipoAlcohol.length) {
        // Para nuevo elemento, tomamos el último ID y añadimos una I
        const lastId = newArrTipoAlcohol.arrTabla[newArrTipoAlcohol.arrTabla.length - 1];
        newArrTipoAlcohol.arrTabla.push(`${lastId}I`);
        newArrTipoAlcohol.tipoAlcohol.push(tipoAlcohol);
        newArrTipoAlcohol.cantidadAlcohol.push(cantidad);
      } else {
        // Solo actualizamos los valores existentes sin tocar arrTabla
        newArrTipoAlcohol.tipoAlcohol[index] = tipoAlcohol;
        newArrTipoAlcohol.cantidadAlcohol[index] = cantidad;
      }

      return {
        ...prev,
        alcohol: {
          ...prev.alcohol,
          arrTipoAlcohol: newArrTipoAlcohol,
        },
      };
    });
  };

  const handleSubstancesChange = (newArrTipoDrogas: IArrTipoDrogas): void => {
    setFormData((prev) => ({
      ...prev,
      otrasDrogas: {
        ...prev.otrasDrogas,
        arrTipoDrogas: newArrTipoDrogas,
      },
    }));
  };

  const handleInputChange = (e: { name: string; value: any }) => {
    const { name, value } = e;

    // Asegurar que valores numéricos se guarden como strings
    const processedValue = typeof value === 'number' ? value.toString() : value;

    setFormData((prev: FormData) => {
      const [sectionId, field] = name.split('.');

      if (!field) {
        return prev;
      }

      switch (sectionId as SectionId) {
        case 'coffee':
          return {
            ...prev,
            cafeina: {
              ...prev.cafeina,
              [field]: processedValue,
            },
          };
        case 'smoking':
          return {
            ...prev,
            tabaco: {
              ...prev.tabaco,
              [field]: processedValue,
            },
          };
        case 'alcohol':
          return {
            ...prev,
            alcohol: {
              ...prev.alcohol,
              [field]: processedValue,
            },
          };
        case 'substances':
          return {
            ...prev,
            otrasDrogas: {
              ...prev.otrasDrogas,
              [field]: processedValue,
            },
          };
        default:
          return prev;
      }
    });
  };

  const getDatosToxicomaniaBase = (datos: FormData) => ({
    cafeina: datos.cafeina,
    tabaco: datos.tabaco,
    alcohol: datos.alcohol,
    otrasDrogas: datos.otrasDrogas,
  });

  const getDatosExpediente = (datos: FormData) => ({
    idMedico,
    idConsultorio,
    idPaciente,
    idUsuario,
    idConsulta,
    toxicomania: getDatosToxicomaniaBase(datos),
    enGestacion: enGestacion || '',
  });

  const getDatosUsuario = (datos: FormData) => ({
    idMedico,
    idConsultorio,
    idPaciente,
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    toxicomania: getDatosToxicomaniaBase(datos),
    enGestacion: enGestacion || '',
  });

  const processFormData = (data: FormData) => {
    // Procesar cafeína
    const processCafeina = () => {
      if (data.cafeina.consumoCafeina === 'activo') {
        const tazaCantidad = parseInt(data.cafeina.tazaDeCafe, 10) || 0;
        const lataCantidad = parseInt(data.cafeina.lataDeBebidaEnergetica, 10) || 0;
        const cafeinaAlDia = (tazaCantidad * 80 + lataCantidad * 120).toString();

        return {
          ...data.cafeina,
          cafeinaAlDia,
          alarmaConsumoExcesivo: Number(cafeinaAlDia) > 400,
        };
      }
      return data.cafeina;
    };

    // Procesar alcohol
    const processAlcohol = (alcohol: IAlcohol) => {
      const newAlcohol = { ...alcohol };
      let alarmaConsumoIntensivo = false;
      let alarmaPosibleTrastorno = false;

      const totalTragos = newAlcohol.arrTipoAlcohol.cantidadAlcohol
        .map((cant) => (cant ? parseInt(cant, 10) : 0))
        .reduce((acc, current) => acc + current, 0);

      const sumatoriaAlcohol = newAlcohol.arrTipoAlcohol.tipoAlcohol
        .map(
          (elem, indx) =>
            elem.grUnidad * (parseInt(newAlcohol.arrTipoAlcohol.cantidadAlcohol[indx], 10) || 0),
        )
        .reduce((acc, current) => acc + current, 0);

      const totalAproxAlcohol = Math.round(sumatoriaAlcohol * 10) / 10;

      if (alcohol.frecuencia !== '') {
        if (sexo === 'M') {
          if (totalAproxAlcohol >= 52 && alcohol.consumeEnMenosDeDosHoras === 'si') {
            alarmaConsumoIntensivo = true;
          }
        } else if (totalAproxAlcohol >= 65 && alcohol.consumeEnMenosDeDosHoras === 'si') {
          alarmaConsumoIntensivo = true;
        }
      }

      // Validaciones específicas de trastorno por sexo
      if (alcohol.frecuencia === 'diario') {
        if (sexo === 'M' && totalAproxAlcohol >= 39) {
          alarmaPosibleTrastorno = true;
        } else if (sexo !== 'M' && totalAproxAlcohol >= 52) {
          alarmaPosibleTrastorno = true;
        }
      } else if (sexo === 'M' && alcohol.frecuencia !== '') {
        if (totalAproxAlcohol >= 52 && alcohol.consumeEnMenosDeDosHoras === 'si') {
          alarmaPosibleTrastorno = true;
        }
      } else if (
        totalAproxAlcohol >= 78 &&
        alcohol.consumeEnMenosDeDosHoras === 'si' &&
        alcohol.frecuencia !== ''
      ) {
        alarmaPosibleTrastorno = true;
      }

      return {
        ...newAlcohol,
        totalTragos: totalTragos > 0 ? totalTragos.toString() : '',
        totalAproxAlcohol: totalAproxAlcohol > 0 ? totalAproxAlcohol.toString() : '',
        alarmaConsumoIntensivo,
        alarmaPosibleTrastorno,
      };
    };

    // Procesar tabaco
    const processTabaco = () => {
      if (
        data.tabaco.consumoTabaco !== 'niega' &&
        data.tabaco.cigarrillosDia &&
        data.tabaco.edadInicio
      ) {
        let aniosFumador = 0;
        const edadInicio = parseInt(data.tabaco.edadInicio, 10) || 0;
        const cigarrillosDia = parseInt(data.tabaco.cigarrillosDia, 10) || 0;
        const edadActual = parseInt(edadValor.toString(), 10) || 0;

        if (data.tabaco.consumoTabaco === 'ex-fumador' && data.tabaco.edadTermino) {
          const edadTermino = parseInt(data.tabaco.edadTermino, 10) || 0;
          aniosFumador = edadTermino - edadInicio;
        } else if (data.tabaco.consumoTabaco === 'activo') {
          aniosFumador = edadActual - edadInicio;
        }

        const ipa = (cigarrillosDia * aniosFumador) / 20;
        let alarmaTabaquismo: '' | 'leve' | 'moderado' | 'intenso' = '';

        if (ipa > 0) {
          if (ipa <= 4.9) alarmaTabaquismo = 'leve';
          else if (ipa <= 14.9) alarmaTabaquismo = 'moderado';
          else alarmaTabaquismo = 'intenso';
        }

        return {
          ...data.tabaco,
          ipa: ipa > 0 ? ipa.toString() : '',
          alarmaTabaquismo,
        };
      }
      return {
        ...data.tabaco,
        ipa: '',
        alarmaTabaquismo: '' as const,
      };
    };

    // Retornar datos procesados
    return {
      ...data,
      cafeina: processCafeina(),
      alcohol: processAlcohol(formData.alcohol),
      tabaco: processTabaco(),
    };
  };

  const handleSubmit = async () => {
    try {
      const processedData = processFormData(formData);
      const dataToSend = getDatosExpediente(processedData);

      console.log('Datos a enviar:', dataToSend);

      if (esPaciente) {
        dispatch(
          setRequest({
            type: 'send',
            requestFunction: antecedentesNoPatologicosUsuarioGuardarToxicomania(
              getDatosUsuario(processedData),
            ),
            successFunction: () => {
              dispatch(
                actualizarCampo('toxicomania', {
                  toxicomaniaInitial: {
                    ...processedData,
                    loaded: true,
                  },
                  loaded: true,
                }),
              );
              dispatch(resetModal('Habitos'));
              dispatch(setActiveModal('Higiene'));
            },
            errorFunction: (error) => {
              setAlertMensaje(error.message || 'Error al guardar los datos');
              setAlertOpen(true);
            },
          }),
        );
      } else {
        const sendFunctions = [
          antecedentesNoPatologicosGuardarToxicomania(getDatosExpediente(processedData)),
        ];

        if (idUsuario > 0) {
          sendFunctions.push(
            antecedentesNoPatologicosUsuarioPacienteGuardarToxicomania(
              getDatosUsuario(processedData),
            ),
          );
        }

        dispatch(
          setRequest({
            type: 'send',
            multiple: true,
            requestFunctions: sendFunctions,
            successFunction: () => {
              dispatch(
                actualizarCampo('toxicomania', {
                  toxicomaniaInitial: {
                    ...processedData,
                    loaded: true,
                  },
                  loaded: true,
                }),
              );
              dispatch(resetModal('Habitos'));
              dispatch(setActiveModal('Higiene'));
            },
            errorFunction: (error) => {
              setAlertMensaje(error.message || 'Error al guardar los datos');
              setAlertOpen(true);
            },
          }),
        );
      }
    } catch (error: any) {
      console.error('Error en la validación:', error);
      setAlertMensaje(error.message || 'Error en la validación');
      setAlertOpen(true);
    }
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    setAlertOpen(false);
  };

  useEffect(() => {
    setFormData({
      ...toxicomaniaInitial,
      cafeina: {
        ...toxicomaniaInitial.cafeina,
        ...toxicomania?.cafeina,
      },
      tabaco: {
        ...toxicomaniaInitial.tabaco,
        ...toxicomania?.tabaco,
      },
      alcohol: {
        ...toxicomaniaInitial.alcohol,
        ...toxicomania?.alcohol,
      },
      otrasDrogas: {
        ...toxicomaniaInitial.otrasDrogas,
        ...toxicomania?.otrasDrogas,
      },
      loaded: true,
    });

    // Set visible sections based on saved data
    const newVisibleSections: Record<string, string> = {};
    if (toxicomania?.cafeina?.consumoCafeina) {
      newVisibleSections.coffee = toxicomania.cafeina.consumoCafeina;
    }
    if (toxicomania?.tabaco?.consumoTabaco) {
      newVisibleSections.smoking = toxicomania.tabaco.consumoTabaco;
    }
    if (toxicomania?.alcohol?.consumoAlcohol) {
      newVisibleSections.alcohol = toxicomania.alcohol.consumoAlcohol;
    }
    if (toxicomania?.otrasDrogas?.consumoOtrasDrogas) {
      newVisibleSections.substances = toxicomania.otrasDrogas.consumoOtrasDrogas;
    }
    setVisibleSections(newVisibleSections);
  }, [toxicomania]);

  useEffect(() => {
    if (formData.alcohol.consumoAlcohol === 'activo' && formData.alcohol.arrTipoAlcohol) {
      const tragosTotal = formData.alcohol.arrTipoAlcohol.cantidadAlcohol.reduce(
        (sum, cant) => sum + Number(cant),
        0,
      );

      const alcoholTotal = formData.alcohol.arrTipoAlcohol.tipoAlcohol.reduce(
        (sum, tipo, index) => {
          const cantidad = Number(formData.alcohol.arrTipoAlcohol.cantidadAlcohol[index]) || 0;
          return sum + tipo.grUnidad * cantidad;
        },
        0,
      );

      setFormData((prev) => ({
        ...prev,
        alcohol: {
          ...prev.alcohol,
          totalTragos: tragosTotal.toString(),
          totalAproxAlcohol: alcoholTotal.toString(),
          alarmaConsumoIntensivo: tragosTotal >= 4,
          alarmaPosibleTrastorno: alcoholTotal >= 40,
        },
      }));
    }
  }, [formData.alcohol.arrTipoAlcohol]);

  useEffect(() => {
    if (formData.cafeina.consumoCafeina === 'activo') {
      const tazas = Number(formData.cafeina.tazaDeCafe) || 0;
      const latas = Number(formData.cafeina.lataDeBebidaEnergetica) || 0;

      const cafeinaTotal = (tazas * 80 + latas * 120).toString();

      setFormData((prev) => ({
        ...prev,
        cafeina: {
          ...prev.cafeina,
          cafeinaAlDia: cafeinaTotal,
          alarmaConsumoExcesivo: Number(cafeinaTotal) > 400,
        },
      }));
    }
  }, [formData.cafeina.tazaDeCafe, formData.cafeina.lataDeBebidaEnergetica]);

  const scaleOptions: Array<{ value: ScaleValue }> = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5+' },
  ];

  const guardarDatosExpediente = () => {};

  return (
    <>
      <ModalForm title="Hábitos" id="Habitos" submitForm={handleSubmit}>
        <div className="w-full space-y-4">
          {/* Sección Embarazo */}
          {/* {!isOxxo && (
            <section>
              <CheckboxInput
                name="enGestacion"
                label="¿Estás embarazada?"
                options={[
                  { value: 'si', label: 'Sí' },
                  { value: 'no', label: 'No' },
                ]}
                value={enGestacion}
                setValue={(event) => setEnGestacion(event.value as 'si' | 'no')}
                distribution="horizontal-inline"
                numberPosition={0}
              />
            </section>
          )} */}

          {/* Sección Cafeína */}
          <section>
            <CheckboxInput
              name="cafeina.consumoCafeina"
              label="Café / bebidas energéticas"
              options={[
                { value: 'activo', label: 'Sí' },
                { value: 'niega', label: 'No' },
              ]}
              value={formData.cafeina.consumoCafeina}
              setValue={(event) => handleSectionVisibilityChange('coffee', event.value)}
              distribution="horizontal-inline"
            />
            {visibleSections.coffee === 'activo' && (
              <div className="mt-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <ScaleInput
                    name="cafeina.tazaDeCafe"
                    label="Tazas de café al día"
                    options={scaleOptions}
                    value={formData.cafeina.tazaDeCafe}
                    setValue={handleInputChange}
                  />
                  <ScaleInput
                    name="cafeina.lataDeBebidaEnergetica"
                    label="Latas de bebida energética al día"
                    options={scaleOptions}
                    value={formData.cafeina.lataDeBebidaEnergetica}
                    setValue={handleInputChange}
                  />
                </div>
                <TextArea
                  name="cafeina.comentarios"
                  label="Comentarios"
                  value={formData.cafeina.comentarios}
                  setValue={handleInputChange}
                />
              </div>
            )}
          </section>

          {/* Sección Tabaco */}
          <section className="bg-gray-50 py-4">
            <CheckboxInput
              name="tabaco.consumoTabaco"
              label="Cigarro"
              options={[
                { value: 'niega', label: 'Niega' },
                { value: 'activo', label: 'Activo' },
                { value: 'ex-fumador', label: 'Ex fumador' },
              ]}
              value={formData.tabaco.consumoTabaco}
              setValue={(event) => handleSectionVisibilityChange('smoking', event.value)}
              distribution="vertical-flex"
            />
            {visibleSections.smoking && (
              <SmokingForm
                isExSmoker={visibleSections.smoking === 'ex-fumador'}
                formData={formData}
                onInputChange={handleInputChange}
              />
            )}
          </section>

          {/* Sección Alcohol */}
          <section>
            <CheckboxInput
              name="alcohol.consumoAlcohol"
              label="Alcohol"
              options={[
                { value: 'niega', label: 'Niega' },
                { value: 'activo', label: 'Activo' },
                { value: 'ex-bebedor', label: 'Ex bebedor' },
              ]}
              value={formData.alcohol.consumoAlcohol}
              setValue={(event) => handleSectionVisibilityChange('alcohol', event.value)}
              distribution="vertical-flex"
            />
            {visibleSections.alcohol && (
              <AlcoholForm
                isActive={visibleSections.alcohol === 'activo'}
                formData={formData}
                onInputChange={handleInputChange}
                onAlcoholTypesChange={handleAlcoholTypesChange}
              />
            )}
          </section>

          {/* Sección Otras Drogas */}
          <section className="bg-gray-50 py-4">
            <CheckboxInput
              name="otrasDrogas.consumoOtrasDrogas"
              label="Otras sustancias"
              options={[
                { value: 'no', label: 'No' },
                { value: 'si', label: 'Sí' },
              ]}
              value={formData.otrasDrogas.consumoOtrasDrogas}
              setValue={(event) => handleSectionVisibilityChange('substances', event.value)}
              distribution="vertical-flex"
            />
            {visibleSections.substances === 'si' && (
              <SubstanceForm
                formData={formData}
                onInputChange={handleInputChange}
                onSubstancesChange={handleSubstancesChange}
              />
            )}
          </section>
        </div>
      </ModalForm>
      <AlertDialog
        open={alertOpen}
        titulo={t('_atencion_')}
        descripcion={alertMensaje}
        nombreCancelar={t('regresar')}
        nombreAceptar={t('guardar')}
        callBackAceptar={guardarDatosExpediente}
        callBackClose={handleAlertClose}
      />
    </>
  );
};

export default Habitos;

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import { X } from 'lucide-react';
import { TransitionProps } from '@material-ui/core/transitions';
import IAlertDialogProps from './types';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import { SubmitButton } from '@components/FormElemntsLibrary/SubmitButton';

const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  )
);

function ControlDiagnostico(props: IAlertDialogProps) {
  const {
    open,
    diagnostico,
    setDiagnosticos,
    handleClose,
  } = props;
  const { t } = useTranslation();

  const frecuencia = ['siempre', 'casi_siempre', 'a_veces', 'rara_vez', 'nunca'].map(frec => (
    { value: frec, label: t(frec) }
  ));
  const controlesFrecuencia: { name: string, label: string, value?: string }[] = [
    {
      name: 'frecSigueIndicaciones',
      label: 'frec-sigue-indicaciones',
      value: diagnostico.control?.frecSigueIndicaciones,
    },
    {
      name: 'frecAcudeConsultas',
      label: 'frec-acude-consultas',
      value: diagnostico.control?.frecAcudeConsultas,
    },
    {
      name: 'frecSigueDieta',
      label: 'frec-sigue-dieta',
      value: diagnostico.control?.frecSigueDieta,
    },
  ]

  const handleInputChange = (e: { name: string, value: any }) =>
    setDiagnosticos((prevData) => ({
      ...prevData,
      contenidoTabla: prevData.contenidoTabla.map(d => d.idDiagnostico === diagnostico.idDiagnostico && diagnostico.control
        ? { ...diagnostico, control: { ...diagnostico.control, [e.name]: e.value } }
        : d,
      ),
    }));

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="control-diagnostico-titulo"
      aria-describedby="control-diagnostico-descripcion"
    >
      <DialogTitle>
        <div className="relative flex items-center h-[68px] bg-white border-b border-[#C4C4C4]">
          <div className="flex-1 text-center">
            <h4 className="font-semibold text-base">{t('antecedentes-pp-diagnosticos-g-control')}</h4>
            <h5 className="text-center font-normal text-sm mt-1">{diagnostico.nombre ? t(`antecedentes-pp-diagnosticos-g-${diagnostico.nombre}`) : ''}</h5>
          </div>
          <div className="absolute right-0 top-2 cursor-pointer" onClick={handleClose}>
            <X size={20} className="text-gray-600" />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <form className="w-full h-full md:mt-0 flex flex-col justify-between bg-white border-b-[1px] border-[#C4C4C4] md:z-40 md:max-w-screen-xl">
          <div className="w-full h-auto ">
            <section className="w-full h-fit py-4">
              {controlesFrecuencia.map(control => (
                <CheckboxGroup
                  name={control.name}
                  label={control.label ? t(`antecedentes-pp-diagnosticos-g-${control.label}`) : ''}
                  options={frecuencia}
                  distribution="vertical"
                  value={control.value}
                  setValue={handleInputChange}
                />
              ))}
              <NumberInput
                name="diasUltimaMedicion"
                label={diagnostico.nombre ? t(`antecedentes-pp-diagnosticos-g-ult-med-${diagnostico.nombre}`) : ''}
                placeholder=""
                value={diagnostico.control?.diasUltimaMedicion || null}
                setValue={handleInputChange}
                isInteger
                fixedValue={t('dias')}
              />
            </section>
          </div>
          <SubmitButton saveCallback={handleClose} label={t('aceptar')} />
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ControlDiagnostico;

import { useEffect, useMemo, useState } from 'react';

import { NavigateButton } from '@components/NavigateButton';
import { NavigationSection } from '@components/NavigationSection';
import { GLOBALS } from '@constants/prueba/globals';
import { menuData } from '@constants/prueba/menu';
import { Alimentacion } from '@containers/PatientForm/Alimentacion';
import { Discapacidades } from '@containers/PatientForm/Discapacidades';
import { EstiloDeVida } from '@containers/PatientForm/EstiloDeVida';
import { Mascotas } from '@containers/PatientForm/EstiloDeVida/Mascotas';
import Ginecologia from '@containers/PatientForm/Ginecologia';
import { Anticonceptivos } from '@containers/PatientForm/Ginecologia/Anticonceptivos';
import { TiempoDeUso } from '@containers/PatientForm/Ginecologia/TiempoDeUso';
import Habitos from '@containers/PatientForm/Habitos';
import { TipoDeAlcohol } from '@containers/PatientForm/Habitos/Alcohol/TipoDeAlcohol';
import { Higiene } from '@containers/PatientForm/Higiene';
import InformacionEmergencia from '@containers/PatientForm/InfoEmergencia';
import { Menu } from '@containers/PatientForm/Menu';
import MiSalud from '@containers/PatientForm/MiSalud';
import { Diabetes } from '@containers/PatientForm/MiSalud/Diabetes';
import { FemaleHealth } from '@containers/PatientForm/MiSalud/Gender/FemaleHealth';
import { MaleHealth } from '@containers/PatientForm/MiSalud/Gender/MaleHealth';
import { Hipertension } from '@containers/PatientForm/MiSalud/Hipertension';
import { NerveProblemsAndPain } from '@containers/PatientForm/MiSalud/NerveProblemsAndPain';
import MisMedidas from '@containers/PatientForm/MisMedidas';
import { Perfil } from '@containers/PatientForm/Perfil';
import { ResumenDeSalud } from '@containers/PatientForm/ResumenDeSalud';
import { AntecedentesLaborales } from '@containers/PatientForm/ResumenDeSalud/AntecedentesLaborales';
import { RiesgoSalud } from '@containers/PatientForm/ResumenDeSalud/RiesgoSalud';
import { Tratamientos } from '@containers/PatientForm/ResumenDeSalud/Tratamientos';
import { SubMenu } from '@containers/PatientForm/SubMenu';
import Ubication from '@containers/PatientForm/Ubicacion';
import { useBreakPoint } from '@hooks/useBreakpoint';

import {
  getPaciente,
  setPacienteID,
  setPacienteIdUsuario,
  setPacienteTipoEmpleado,
} from '@actions/basicosPaciente/actions';
import { actualizarCampo } from '@actions/infoUser/actions';
import setLoading from '@actions/loading/actions';
import { resetAllModals, setActiveModal } from '@actions/modal/actions';
import { setRequest } from '@actions/request/types';
import { setSnackComplete } from '@actions/snackbar/types';
import { edadCalculada } from '@common/functions';
import { ICatalogosEstandar } from '@common/types';
import SeleccionarTipoPaciente from '@components/ModalDialogs/SelecionarTipoPaciente';
import ValidarUsuarioCorreo from '@components/ModalDialogs/ValidarUsuarioCorreo';
import { isOxxo } from '@constants/oxxo';
import {
  IAntecedenteLaboral,
  IObjArrayAccidentesLaborales,
} from '@containers/TabsPaciente/Antecedentes/Laborales/types';
import {
  getCambiosActividadFisica,
  getCambiosAlimentacion,
  getCambiosDatosPersonales,
  getCambiosDatosUbicacion,
  getCambiosDiscapacidades,
  getCambiosGinecoObstetrico,
  getCambiosHigiene,
  getCambiosInfoEmergencia,
  getCambiosSegurosPlanes,
  getCambiosToxicomania,
  getCambiosVivienda,
} from '@containers/TabsPaciente/functionsCambios';
import { countProps } from '@utils/countProps';
import {
  getDatosPaciente,
  getDatosUsuarioPaciente,
  getPacienteDatosPersonales,
  getPacienteDatosUsuario,
} from '@utils/getCatalogos';
import { guardarTipoEmpleado, validarEmailUsuario } from '@utils/sendInfo';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ActividadFisica } from './ActividadFisica';
import { SegurosPlanes } from './SegurosPlanes';
import { HealthDataSubModals, initialHealthDataSubModals } from './type';

const obtenerFecha = (dia: string, mes: string, anio: string) => {
  const formatString = dia.length === 0 ? 'MMM-YYYY' : 'DD-MMM-YYYY';
  return dayjs(`${anio}-${mes}-${dia.length === 0 ? '1' : dia}`).format(formatString);
};

function PatientForm() {
  const [formDataSubModals, setFormDataSubModals] = useState<HealthDataSubModals>(
    initialHealthDataSubModals,
  );
  const [showModalValidacionCorreo, setShowModalValidacionCorreo] = useState<boolean>(false);
  const [emailPaciente, setEmailPaciente] = useState<string>('');
  const [errorCorreoInvalido, setErrorCorreoInvalido] = useState<boolean>(false);
  const [validacionPendiente, setValidacionPendiente] = useState<boolean>(true);
  const [showModalTipoPaciente, setShowModalTipoPaciente] = useState<boolean>(false);
  const [percentageBar, setPercentageBar] = useState<string>('0');

  const modals = useSelector((state: RootState) => state.Modal);
  
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const {
    tipoPaciente,
    datosUbicacionUsuario,
    discapacidadesUsuario,
    infoEmergenciaUsuario,
    segurosPlanesUsuario,
    viviendaUsuario,
    higieneUsuario,
    alimentacionUsuario,
    actividadFisicaUsuario,
    toxicomaniaUsuario,
    ginecoObstetricoUsuario,
    datosPersonales,
    datosPersonalesUsuario,
    datosUbicacion,
    datosUbicacionNombresUsuario,
    discapacidades,
    infoEmergencia,
    segurosPlanes,
    vivienda,
    higiene,
    alimentacion,
    actividadFisica,
    toxicomania,
    ginecoObstetrico,
    objLaboral,
  } = useSelector((state: RootState) => state.InfoUser);
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
    pais,
    enOrganizacion,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { idConsulta, idPaciente: idPacienteConsulta } = useSelector(
    (state: RootState) => state.Consulta,
  );
  const {
    idPaciente, idUsuario, sexo, tipoEmpleado,
  } = useSelector(
    (state: RootState) => state.BasicosPaciente,
  );

  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filterModals = datosPersonales.sexo === 'H' ?  modals.filter(modal => modal.name !== 'Ginecologia'): modals
  const filterMenu = datosPersonales.sexo === 'H' ?  menuData.filter(button => button.title !== 'Historia ginecológica'): menuData

  const llenarDatosPaciente = (datos: any) => {
    const excludeProps = sexo === 'H' ? ['ginecoObstetrico'] : [];
    const { filled, total } = countProps(datos, excludeProps);
    const porcentajeDeProgreso = (filled / total) * 100;

    setPercentageBar(porcentajeDeProgreso.toFixed(2));

    let accidentesLab: ICatalogosEstandar[] = [];
    const cargarDatos = (tipo: string, seccion: any) => {
      if (seccion) {
        dispatch(actualizarCampo(tipo, seccion));
      }
    };

    cargarDatos('exploracionFisica', datos.exploracionFisica);

    cargarDatos('datosPersonales', { ...datos.perfil.datosPersonales, loaded: true });
    cargarDatos('datosUbicacion', { ...datos.perfil.datosUbicacion, loaded: true });
    cargarDatos('discapacidades', { ...datos.perfil.discapacidades, loaded: true });
    cargarDatos('infoEmergencia', { ...datos.perfil.infoEmergencia, loaded: true });
    cargarDatos('segurosPlanes', { ...datos.perfil.segurosPlanes, loaded: true });

    if (datos.motivoConsulta) {
      cargarDatos('motivoConsulta', datos.motivoConsulta);
    }

    if (datos.antPatologicos) {
      const {
        enfReferidas,
        enfNegadas,
        grupoEnfNegadas,
        diagnosticosGenerales,
        tratamientos,
        sin_hospitalizaciones,
        hospitalizaciones,
        procDiagnosticos,
        procTerapeuticos,
        sinProcQuirurgicos,
        procQuirurgicos,
        sinAlergias,
        alergias,
        sinTransfusiones,
        transfusiones,
      } = datos.antPatologicos;

      if (enfReferidas?.length) {
        cargarDatos('enfReferidas', enfReferidas);
        cargarDatos(
          'catDiagnostico',
          enfReferidas.map((enf: any) => ({
            id: enf.idBD,
            label:
              enf.seleccionDiagnostico === 'cie10'
                ? enf.diagnosticoCie10.label
                : enf.diagnosticoMedico,
          })),
        );
      }
      if (enfNegadas?.length) cargarDatos('enfNegadas', enfNegadas);
      if (grupoEnfNegadas) cargarDatos('grupoEnfNegadas', grupoEnfNegadas);
      cargarDatos('diagnosticosGenerales', { ...diagnosticosGenerales, loaded: true });

      if (tratamientos?.length) {
        cargarDatos('tratamientos', tratamientos);

        const recetas = tratamientos
          .filter(
            (tratamiento: any) => tratamiento.actual
              && tratamiento.idConsulta === idConsulta
              && tratamiento.idReceta > 0,
          )
          .map((tratamiento: any) => tratamiento.idReceta);

        if (recetas.length) {
          const recetasUnicas = Array.from(new Set(recetas));
          const recetasOrdenadas = recetasUnicas.sort().map((id) => ({
            idBD: id,
            actualizado: false,
          }));

          cargarDatos('recetas', recetasOrdenadas);
        }
      }

      if (sin_hospitalizaciones) {
        cargarDatos('sin_hospitalizaciones', sin_hospitalizaciones);
      } else if (hospitalizaciones) {
        cargarDatos('hospitalizaciones', hospitalizaciones);
      }

      if (procDiagnosticos?.length) cargarDatos('procDiagnosticos', procDiagnosticos);
      if (procTerapeuticos?.length) cargarDatos('procTerapeuticos', procTerapeuticos);

      if (sinProcQuirurgicos) {
        cargarDatos('sinProcQuirurgicos', sinProcQuirurgicos);
      } else if (procQuirurgicos?.length) {
        cargarDatos('procQuirurgicos', procQuirurgicos);
      }

      if (sinAlergias) {
        cargarDatos('sinAlergias', sinAlergias);
      } else if (alergias) {
        cargarDatos('alergias', alergias);
      }

      if (sinTransfusiones) {
        cargarDatos('sinTransfusiones', sinTransfusiones);
      } else if (transfusiones) {
        cargarDatos('transfusiones', transfusiones);
      }
    }

    if (datos.antNoPatologicos) {
      const { antNoPatologicos } = datos;

      cargarDatos('vivienda', { ...antNoPatologicos.vivienda, loaded: true });
      cargarDatos('higiene', { ...antNoPatologicos.higiene, loaded: true });
      cargarDatos('alimentacion', { ...antNoPatologicos.alimentacion, loaded: true });
      cargarDatos('actividadFisica', { ...antNoPatologicos.actividadFisica, loaded: true });

      if (antNoPatologicos.inmunizaciones) cargarDatos('inmunizaciones', antNoPatologicos.inmunizaciones);
      cargarDatos('toxicomania', { ...antNoPatologicos.toxicomania, loaded: true });

      if (antNoPatologicos.ginecoObstetrico) {
        cargarDatos('ginecoObstetrico', { ...antNoPatologicos.ginecoObstetrico, loaded: true });
      }

      cargarDatos('historiaSexual', { ...antNoPatologicos.historiaSexual, loaded: true });
    }

    if (datos.antecedentesFamiliares?.length) {
      cargarDatos('antecedentesFamiliares', datos.antecedentesFamiliares);
    }

    if (datos.sinAntecedentesLaborales) {
      cargarDatos('objLaboral', { ...objLaboral, ...datos.sinAntecedentesLaborales });
    }
    if (datos.antecedentesLaborales && datos.antecedentesLaborales.length > 0) {
      cargarDatos('antecedentesLaborales', datos.antecedentesLaborales);
      accidentesLab = datos.antecedentesLaborales.reduce(
        (accidentes: ICatalogosEstandar[], antLab: IAntecedenteLaboral) => [
          ...accidentes,
          ...antLab.objLaboral.arrayAccidentesLaborales.map(
            (accidente: IObjArrayAccidentesLaborales) => ({
              id: accidente.id,
              label: `${obtenerFecha(
                accidente.fechaDia,
                accidente.fechaMes,
                accidente.fechaAnio,
              )} - ${accidente.radioCausaAccidente === 'catalogo'
                  ? accidente.tipoCausaAccidente.label
                  : accidente.txtCausaAccidente
                }`,
            }),
          ),
        ],
        [],
      );
      cargarDatos('accidentesLaborales', accidentesLab);
    }
    if (datos.aparatosYSistemas) {
      cargarDatos('aparatosYSistemas', datos.aparatosYSistemas);
    }
    if (datos.exploracionFisica) {
      cargarDatos('exploracionFisica', { ...datos.exploracionFisica, loaded: true });
    }
    if (datos.plan) {
      if (datos.plan.solProcDiagnosticos && datos.plan.solProcDiagnosticos.length > 0) {
        cargarDatos('solProcDiagnosticos', datos.plan.solProcDiagnosticos);
      }
      if (datos.plan.solProcTerapeuticos && datos.plan.solProcTerapeuticos.length > 0) {
        cargarDatos('solProcTerapeuticos', datos.plan.solProcTerapeuticos);
      }
      if (datos.plan.solProcQuirurgicos && datos.plan.solProcQuirurgicos.length > 0) {
        cargarDatos('solProcQuirurgicos', datos.plan.solProcQuirurgicos);
      }
      if (datos.plan.laboratorioSolicitud && datos.plan.laboratorioSolicitud.length > 0) {
        cargarDatos('laboratorioSolicitud', datos.plan.laboratorioSolicitud);
      }
      if (datos.plan.pronosticos && datos.plan.pronosticos.length > 0) {
        cargarDatos('pronosticos', datos.plan.pronosticos);
      }
    }

    if (datos.incapacidades) {
      cargarDatos(
        'incapacidades',
        datos.incapacidades.map((incapacidad: any) => ({
          ...incapacidad,
          accidenteLaboral: null,
        })),
      );
    }
  };

  const llenarCambiosUsuarioPaciente = (datos: any) => {
    dispatch(
      actualizarCampo('datosPersonalesUsuario', {
        ...datos.perfil.datosPersonales,
        comoEntero: datos.perfil.datosPersonales.comoEntero,
        otroComoEntero: datos.perfil.datosPersonales.otroComoEntero,
        loaded: true,
      }),
    );
    dispatch(actualizarCampo('datosUbicacionNombresUsuario', datos.perfil.datosUbicacionNombres));
    dispatch(
      actualizarCampo('datosUbicacionUsuario', {
        ...datosUbicacionUsuario,
        ...datos.perfil.datosUbicacion,
        loaded: true,
      }),
    );
    dispatch(
      actualizarCampo('discapacidadesUsuario', {
        ...discapacidadesUsuario,
        ...datos.perfil.discapacidades,
        loaded: true,
      }),
    );

    dispatch(
      actualizarCampo('infoEmergenciaUsuario', {
        ...infoEmergenciaUsuario,
        ...datos.perfil.infoEmergencia,
        loaded: true,
      }),
    );
    dispatch(
      actualizarCampo('segurosPlanesUsuario', {
        ...segurosPlanesUsuario,
        ...datos.perfil.segurosPlanes,
        loaded: true,
      }),
    );
    dispatch(
      actualizarCampo('diagnosticosGenerales', {
        ...datos.antPatologicos.diagnosticosGenerales,
        loaded: true,
      }),
    );
    dispatch(
      actualizarCampo('viviendaUsuario', {
        ...viviendaUsuario,
        ...datos.antNoPatologicos.vivienda,
        loaded: true,
      }),
    );
    dispatch(
      actualizarCampo('higieneUsuario', {
        ...higieneUsuario,
        ...datos.antNoPatologicos.higiene,
        loaded: true,
      }),
    );
    dispatch(
      actualizarCampo('alimentacionUsuario', {
        ...alimentacionUsuario,
        ...datos.antNoPatologicos.alimentacion,
        loaded: true,
      }),
    );
    dispatch(
      actualizarCampo('actividadFisicaUsuario', {
        ...actividadFisicaUsuario,
        ...datos.antNoPatologicos.actividadFisica,
        loaded: true,
      }),
    );
    dispatch(
      actualizarCampo('toxicomaniaUsuario', {
        ...toxicomaniaUsuario,
        ...datos.antNoPatologicos.toxicomania,
        loaded: true,
      }),
    );
    dispatch(
      actualizarCampo('ginecoObstetricoUsuario', {
        ...ginecoObstetricoUsuario,
        ...datos.antNoPatologicos.ginecoObstetrico,
        loaded: true,
      }),
    );
  };

  const obtenerDatosUsuarioPaciente = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getDatosUsuarioPaciente(idMiUsuario),
        successFunction: (result: any) => llenarDatosPaciente(result.data),
      }),
    );
  };

  const obtenerDatosExpedientePaciente = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getDatosPaciente(idMedico, idPaciente, idConsultorio, idConsulta),
        successFunction: (result: any) => {
          llenarDatosPaciente(result.data);
          if (result.data.datosUsuario) {
            llenarCambiosUsuarioPaciente(result.data.datosUsuario);
          }
          if (result.data.usuariosPorValidar) {
            setShowModalValidacionCorreo(true);
          } else {
            setValidacionPendiente(false);
          }
        },
        errorFunction: () => {
          // Error 300: Si el paciente no tiene snapshot (nunca ha tenido una consulta)
          // se consultan los datos del expediente directamente de las tablas
          // Por alguna razón no funciona con "setRequest" (al parecer no funcionan si están anidados)
          dispatch(setLoading(true));
          getPacienteDatosPersonales(idPaciente, idMedico, idConsultorio)
            .then((response) => response.json())
            .then((result) => {
              if (result.code === 200) {
                dispatch(
                  actualizarCampo('datosPersonales', {
                    ...result.data.datosPersonales,
                    loaded: true,
                  }),
                );
                dispatch(actualizarCampo('inmunizaciones', result.data.inmunizaciones));
                if (result.data.datosUsuario) {
                  llenarCambiosUsuarioPaciente(result.data.datosUsuario);
                }
                if (result.data.usuariosPorValidar) {
                  setShowModalValidacionCorreo(true);
                } else {
                  setValidacionPendiente(false);
                }
              }
              dispatch(setLoading(false));
            })
            .catch(() => {
              dispatch(setLoading(false));
            });
        },
        showMsgError: false,
      }),
    );
  };

  const handleOpenModal = (modalName: string) => {
    dispatch(resetAllModals());
    if (isWideScreen && modalName === 'SubMenu') {
      dispatch(setActiveModal('SubMenu'));
      dispatch(setActiveModal('Perfil'));
    } else {
      dispatch(setActiveModal(modalName));
    }
  };

  const handleTipoPaciente = (value: null | 'directo' | 'candidato' | 'contratista' | 'otro') => {
    dispatch(actualizarCampo('tipoPaciente', value));
  };

  const handleSeleccionarTipoPaciente = () => {
    if (idPaciente > 0) {
      if (tipoPaciente) {
        dispatch(
          setRequest({
            type: 'send',
            requestFunction: guardarTipoEmpleado({
              idMedico,
              idPaciente,
              idConsultorio,
              idOrganizacion,
              tipoEmpleado: tipoPaciente,
            }),
            successFunction: () => {
              dispatch(actualizarCampo('showModalTipoPaciente', false));
              dispatch(setPacienteTipoEmpleado(tipoPaciente));
            },
          }),
        );
      }
    } else {
      dispatch(actualizarCampo('showModalTipoPaciente', false));
    }
  };

  const handleValidarUsuarioCorreo = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: validarEmailUsuario({
          idMedico,
          idPaciente,
          idConsultorio,
          emailPaciente,
        }),
        successFunction: (result: any) => {
          dispatch(setPacienteIdUsuario(result.data));
          setShowModalValidacionCorreo(false);
          getPacienteDatosUsuario(idMedico, idPaciente, result.data, idConsultorio)
            .then((response) => response.json())
            .then((result2) => {
              if (result2.code === 200) {
                llenarCambiosUsuarioPaciente(result2.data);
              }
            });
          setValidacionPendiente(false);
        },
        errorFunction: (result: any) => {
          if (result.code === 201) {
            setErrorCorreoInvalido(true);
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('error')} ${result.msg}`,
              }),
            );
          }
        },
        showMsgError: false,
      }),
    );
  };

  useEffect(() => {
    if (isWideScreen && modals.filter((modal) => modal.isActive).length === 0) {
      dispatch(setActiveModal('SubMenu'));
      dispatch(setActiveModal('Perfil'));
    }
  }, [isWideScreen]);
  useEffect(() => {
    if (idMedico) {
      if (esPaciente) {
        // Si el usuario es el paciente se consultan sus datos de usuario
        obtenerDatosUsuarioPaciente();
      } else if (idPaciente > 0 && idConsultorio > 0 && idConsulta > 0 && !datosPersonales.loaded) {
        // Se consultan los datos del expediente y los datos del usuario del paciente
        obtenerDatosExpedientePaciente();
      } else {
        setValidacionPendiente(false);
      }
    }
  }, [idMedico, idPaciente, idConsultorio, idConsulta]);
  useEffect(() => {
    if (!validacionPendiente && enOrganizacion && (!tipoEmpleado || tipoEmpleado === 'externo')) {
      setShowModalTipoPaciente(true);
    }
  }, [validacionPendiente]);

  // Cambios en los datos del usuario del paciente
  useEffect(() => {
    if (idUsuario > 0 && datosPersonalesUsuario.loaded) {
      dispatch(
        actualizarCampo(
          'cambiosDatosPersonales',
          getCambiosDatosPersonales(datosPersonales, datosPersonalesUsuario, t),
        ),
      );
    }
  }, [idUsuario, datosPersonales, datosPersonalesUsuario]);

  useEffect(() => {
    if (idUsuario > 0 && datosUbicacionUsuario.loaded) {
      dispatch(
        actualizarCampo(
          'cambiosDatosUbicacion',
          getCambiosDatosUbicacion(
            datosUbicacion,
            datosUbicacionUsuario,
            datosUbicacionNombresUsuario,
            pais,
            t,
          ),
        ),
      );
    }
  }, [idUsuario, datosUbicacion, datosUbicacionUsuario, datosUbicacionNombresUsuario]);

  useEffect(() => {
    if (idUsuario > 0 && discapacidadesUsuario.loaded) {
      dispatch(
        actualizarCampo(
          'cambiosDiscapacidades',
          getCambiosDiscapacidades(discapacidades, discapacidadesUsuario, t),
        ),
      );
    }
  }, [idUsuario, discapacidades, discapacidadesUsuario]);

  useEffect(() => {
    if (idUsuario > 0 && infoEmergenciaUsuario.loaded) {
      dispatch(
        actualizarCampo(
          'cambiosInfoEmergencia',
          getCambiosInfoEmergencia(infoEmergencia, infoEmergenciaUsuario, t),
        ),
      );
    }
  }, [idUsuario, infoEmergencia, infoEmergenciaUsuario]);

  useEffect(() => {
    if (idUsuario > 0 && segurosPlanesUsuario.loaded) {
      dispatch(
        actualizarCampo(
          'cambiosSegurosPlanes',
          getCambiosSegurosPlanes(segurosPlanes, segurosPlanesUsuario, t),
        ),
      );
    }
  }, [idUsuario, segurosPlanes, segurosPlanesUsuario]);

  useEffect(() => {
    if (idUsuario > 0 && viviendaUsuario.loaded) {
      dispatch(
        actualizarCampo('cambiosVivienda', getCambiosVivienda(vivienda, viviendaUsuario, t)),
      );
    }
  }, [idUsuario, vivienda, viviendaUsuario]);

  useEffect(() => {
    if (idUsuario > 0 && higieneUsuario.loaded) {
      dispatch(actualizarCampo('cambiosHigiene', getCambiosHigiene(higiene, higieneUsuario, t)));
    }
  }, [idUsuario, higiene, higieneUsuario]);

  useEffect(() => {
    if (idUsuario > 0 && alimentacionUsuario.loaded) {
      dispatch(
        actualizarCampo(
          'cambiosAlimentacion',
          getCambiosAlimentacion(alimentacion, alimentacionUsuario, t),
        ),
      );
    }
  }, [idUsuario, alimentacion, alimentacionUsuario]);

  useEffect(() => {
    if (idUsuario > 0 && actividadFisicaUsuario.loaded) {
      dispatch(
        actualizarCampo(
          'cambiosActividadFisica',
          getCambiosActividadFisica(actividadFisica, actividadFisicaUsuario, t),
        ),
      );
    }
  }, [idUsuario, actividadFisica, actividadFisicaUsuario]);

  useEffect(() => {
    if (idUsuario > 0 && toxicomaniaUsuario.loaded) {
      dispatch(
        actualizarCampo(
          'cambiosToxicomania',
          getCambiosToxicomania(toxicomania, toxicomaniaUsuario, t),
        ),
      );
    }
  }, [idUsuario, toxicomania, toxicomaniaUsuario]);

  useEffect(() => {
    if (idUsuario > 0 && ginecoObstetricoUsuario.loaded) {
      dispatch(
        actualizarCampo(
          'cambiosGinecoObstetrico',
          getCambiosGinecoObstetrico(
            ginecoObstetrico,
            ginecoObstetricoUsuario,
            t,
          ),
        ),
      );
    }
  }, [idUsuario, ginecoObstetrico, ginecoObstetricoUsuario]);

  useEffect(() => {
    // Esta acción se ejecuta al hacer clic en el botón de volver a la consulta, pero se valida aquí por si se accede a través de la URL
    if (!esPaciente && idPacienteConsulta > 0 && idPaciente !== idPacienteConsulta) {
      dispatch(setPacienteID(idPacienteConsulta));
      dispatch(getPaciente(idPacienteConsulta, idMedico, idOrganizacion, idConsultorio));
    }
  }, [esPaciente, idPaciente, idPacienteConsulta]);

  useEffect(() => {
    if (
      datosPersonales.fechaNac.dia
      && datosPersonales.fechaNac.mes
      && datosPersonales.fechaNac.anio
    ) {
      dispatch(
        actualizarCampo(
          'edad',
          edadCalculada(
            datosPersonales.fechaNac.anio,
            datosPersonales.fechaNac.mes,
            datosPersonales.fechaNac.dia,
          ),
        ),
      );
    }
  }, [datosPersonales.fechaNac.anio, datosPersonales.fechaNac.mes, datosPersonales.fechaNac.dia]);

  const modalComponents: { [key: string]: JSX.Element } = {
    Perfil: <Perfil />,
    Habitos: <Habitos />,
    Ubication: <Ubication />,
    Discapacidades: <Discapacidades />,
    InformacionEmergencia: <InformacionEmergencia />,
    SegurosPlanes: <SegurosPlanes />,
    Higiene: <Higiene />,
    MiSalud: (
      <MiSalud formDataSubModals={formDataSubModals} setFormDataSubModals={setFormDataSubModals} />
    ),
    ActividadFisica: <ActividadFisica />,
    EstiloDeVida: (
      <EstiloDeVida
        formDataSubModals={formDataSubModals}
        setFormDataSubModals={setFormDataSubModals}
      />
    ),
    Alimentacion: <Alimentacion />,
    Diabetes: (
      <Diabetes formDataSubModals={formDataSubModals} setFormDataSubModals={setFormDataSubModals} />
    ),
    MaleHealth: <MaleHealth />,
    FemaleHealth: <FemaleHealth />,
    Hipertension: (
      <Hipertension
        formDataSubModals={formDataSubModals}
        setFormDataSubModals={setFormDataSubModals}
      />
    ),
    SubMenu: <SubMenu />,
    Mascotas: (
      <Mascotas formDataSubModals={formDataSubModals} setFormDataSubModals={setFormDataSubModals} />
    ),
    Ginecologia: <Ginecologia />,
    MisMedidas: <MisMedidas />,
    ResumenDeSalud: <ResumenDeSalud />,
    TipoDeAlcohol: <TipoDeAlcohol />,
    TiempoDeUso: (
      <TiempoDeUso
        onUpdate={function (tiempo: string): void {
          throw new Error('Function not implemented.');
        }}
      />
    ),
    NerveProblemsAndPain: <NerveProblemsAndPain />,
    Anticonceptivos: (
      <Anticonceptivos
        onUpdate={function (type: string): void {
          throw new Error('Function not implemented.');
        }}
      />
    ),
    AntecedentesLaborales: <AntecedentesLaborales />,
    RiesgoSalud: <RiesgoSalud />,
    Tratamientos: <Tratamientos />,
  };

  const activeModals = useMemo(() => filterModals.filter((modal) => modal.isActive), [modals]);

  return (
    <>
      <ValidarUsuarioCorreo
        open={showModalValidacionCorreo}
        email={emailPaciente}
        setEmail={setEmailPaciente}
        emailError={errorCorreoInvalido}
        setEmailError={setErrorCorreoInvalido}
        callBackAceptar={handleValidarUsuarioCorreo}
        callBackClose={() => {
          setShowModalValidacionCorreo(false);
          setValidacionPendiente(false);
        }}
      />
      <SeleccionarTipoPaciente
        open={showModalTipoPaciente}
        esExterno={tipoEmpleado === 'externo'}
        tipoPaciente={tipoPaciente}
        setTipoPaciente={handleTipoPaciente}
        callBackAceptar={handleSeleccionarTipoPaciente}
        callBackClose={() => dispatch(actualizarCampo('showModalTipoPaciente', false))}
      />
      <div
        className={`fixed w-full min-h-screen bg-[#D1D5DB] top-0 z-10 md:absolute ${esPaciente ? 'md:w-[100%]' : 'md:w-[calc(100vw-80px)]'
          } md:top-[144px] md:right-0 md:flex md:flex-col md:items-center md:justify-center md:min-h-[73dvh] md:h-fit`}
      >
        <Menu title="Mi expediente de salud" percentageBar={percentageBar}>
          {filterMenu.map((section, index) => (
            <NavigationSection key={section.title} title={section.title}>
              {section.buttons
                .filter((button) => button.isOxxo !== false || !isOxxo)
                .map(({ title, id }) => {
                  const isActive = filterModals.some((modal) => modal.name === id && modal.isActive);
                  return (
                    <NavigateButton
                      key={title}
                      title={title}
                      onClick={() => handleOpenModal(id)}
                      position={id === 'MiSalud' ? 'first' : (index === (filterMenu.length - 1) ? 'last' : '')}
                      isActive={isActive}                      
                    />
                  );
                })}
            </NavigationSection>
          ))}
        </Menu>

        <div className="w-full h-fit md:flex md:flex-col-reverse md:items-center md:justify-center">
          {activeModals.length > 0
            && activeModals.map((modal) => (
              <div className="w-full h-fit md:flex md:justify-center" key={modal.name}>
                {modalComponents[modal.name]}
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default PatientForm;
